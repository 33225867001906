import { FileModel } from './file.model'; 
import { BaseModel } from './basemodel';
export class DocumentMapping {
    public Id:number;
    public DocumentId:number;
    public ObjectId:number;
    public ObjectType:string;
    public DocumentType:string;
    public DocumentTypeCategory:string;
    public IsDefault:boolean;
    public FilePath:any;
    public IsPreview:boolean;
    public languagecode:boolean;
    public DocumentCaption:Array<ImageContentModel> = new Array<ImageContentModel>();
}

export class UploadImage extends FileModel {
	
	public Id:any;
	public CreatedBy:any;
	public FolderCode:any;
	public IsNewEntry:any;
	public IsOverride:any;
	public IsNewVersion:any;
	public DocumentTypeCode:any;
}
export class DocumentProperties{
	public ObjectId:any;
	public ObjectType:any;
	public DocumentType:any;
	public IsDefault:any;
}
export class UploadModel{
	public Document:ImageDocumentUpload = new ImageDocumentUpload();
	public DocumentProperties:DocumentObject = new DocumentObject();
}
export class ImageDocumentUpload extends BaseModel {
	public Id:any;
	public DocumentTypeCode:any;
	public IsNewEntry:any;
	public IsNewVersion:any;
	public IsOverride:any;
	public IsCopyRequired:any;
	public FileName:any;
	public FileFormat:any;
	public FolderCode:any;
	public FilePath:any;
	public File:any;
	public IsDefault:any;
}
export class DocumentObject {
	public  docId:any;
	public  ObjectId:any;
	public  ObjectType:any;
	public  DocumentType:any;
	public  ImageTypes:Array<string>;
	public  IsDefault:any;
}

export class ImageContentModel{
	
	public Id:any;
	public DocumentMappingId:any;
	public LanguageId:any;
	public Caption:any="";
	public Description:any="";
	public CreatedOn:any;
	public CreatedBy:any;
}