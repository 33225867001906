import { ItemCode } from './itemmodel';
import { BaseModel } from './basemodel';
import { ProductModel } from './productmodel';
export class VoucherOrderModel extends BaseModel{
    public CustomerId:number;
    public CustomerName:string;
    public CartType:string;
    public OrganizationId:number;
    public ProjectId:number;
    public UploadedProduct:Array<UploadedProduct> = new Array<UploadedProduct>();
}

export class UploadedProduct {
    public OrderId :number;
    public ProductId :number;
    public ProductGuid :string;
    public Quantity :number;
    public ProductVarianceId :number;
    public ProductName :string;
    public ProductDesc :string;
    public ProductType:ItemCode = new ItemCode();
    public InstcriptionLineCount :any;
    public InscriptionCharactersPerLine :any;
    public OrderStatus:any;
    public InscriptionStatus:any;
    public inscriptionDetail:Array<InscriptionDetail> = new Array<InscriptionDetail>();
}
export class InscriptionDetail{
    public Id :number;
    public OrderId :number;
    public InscriptionLine :any;
}

export class ApplyVoucherModel{
    public voucherCode:any;
    public cartId:any;
    public projectId:any;
    public date:any;
}