import { Component, OnInit,Inject, ChangeDetectorRef } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Http } from '@angular/http';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { operationService } from './_services/operation.service';
import { DOCUMENT } from '@angular/common';
import * as url from '../environments/environment';
import { Title }     from '@angular/platform-browser';
import 'jquery';
import * as bootstrap from "bootstrap";
import { Language,MappedLanguageModel } from './models/language.model';
import { ComponentintractService } from './_services/conponentinteraction.service';
import { GoogleAnalyticsService } from './_services/google-analytics.service';
import { Guid } from './models/guid.model';
//declare gives Angular app access to ga function
declare var gtag:any;
declare var dataLayer:any;
import { ProjectTypeEnum } from './models/producttypeenum';
import { EncryptionService } from './_services/aes-encryption.service';
import * as CryptoJS from 'crypto-js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { filter } from 'rxjs/operators';
import { DocumentTypeCodeEnum,ObjectTypeEnum,FolderCodeEnum } from './models/documenttypecode.enum';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers:[operationService]
})
export class AppComponent implements OnInit  {
    public showDialog:boolean = false;
    public firstTimeLoading:boolean = true;
    public firstCount:boolean = true;
    public routeUrl: string = ""//window.location.href.split('/')[3];
    public user:any;
    public boolUploadSpinner:boolean=false;
    public homePageStatus:boolean;
    public objProjectDetails:any={};
    public arrobjMappedLanguageModel:Array<MappedLanguageModel> = new Array<MappedLanguageModel>();
    public objMappedLanguageModel:MappedLanguageModel = new MappedLanguageModel();
    public selectedCurrencyId:any;
    public boolLoadPages:boolean=false;
    public resultURl:any;
    public ProjectTypeEnum : typeof ProjectTypeEnum = ProjectTypeEnum;
    public ObjectTypeEnum : typeof ObjectTypeEnum = ObjectTypeEnum;
    public boolFixFooter:boolean;
    public boolOrgHeader:boolean=false;
    /*Idle Time Out*/
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    title = 'angular-idle-timeout';

    constructor(private cdRef : ChangeDetectorRef,private idle: Idle, private keepalive: Keepalive,private objDeviceDetectorService:DeviceDetectorService,private objAESEncryptionService:EncryptionService,private objHttpUrlEncodingCodec:HttpUrlEncodingCodec,@Inject(DOCUMENT) private document: Document,private objGoogleAnalyticsService:GoogleAnalyticsService,private objTitle:Title,private objComponentintractService:ComponentintractService,private translate: TranslateService, private http:Http, private router: Router, private _operationService: operationService,private route: ActivatedRoute){
        
        this.timedOut = false;

        /*Google Tag Manager*/
        const navEndEvents = this.router.events.pipe(
            filter(event=>event instanceof NavigationEnd),
        )
        navEndEvents.subscribe((event:NavigationEnd)=>{

            this.boolFixFooter = event.urlAfterRedirects.includes("photomosaic-gallery");
            dataLayer.push({'URL':event.urlAfterRedirects});
        })
        this.objComponentintractService.getComponentdata().subscribe(
            data=>{

                if( data != undefined && data.boolLaod != undefined){
                    this.boolUploadSpinner = data.boolLaod;
                }
                if( data != undefined && data.boolMosaicMode != undefined){
                    this.boolFixFooter = data.boolMosaicMode;     
                }
            }
        )

        /*Idle TimeOut*/
            // sets an idle timeout of 5 seconds, for testing purposes.
        idle.setIdle(url.idleTime);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        idle.setTimeout(url.timeOutTime);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => { 
          this.idleState = 'No longer idle.' 
          this.reset();
        });
        
        idle.onTimeout.subscribe(() => {
          this.idleState = 'Timed out!';
          this.timedOut = true;
          $("#model-delete").modal("show");
        });
        
        idle.onIdleStart.subscribe(() => {
            this.idleState = 'You\'ve gone idle!'
        });
        
        idle.onTimeoutWarning.subscribe((countdown) => {
          this.idleState = 'You will time out in ' + countdown + ' seconds!' 
        });

        // sets the ping interval to 15 seconds
        keepalive.interval(15);

        keepalive.onPing.subscribe(() => this.lastPing = new Date());

        this.reset();
    }
    public activeURL:any="";
    ngOnInit() {
        this.timedOut = false;
        var windowsURl = window.location.href
        var arrUrlDetails = windowsURl.split('/');
        this.routeUrl = arrUrlDetails[url.urlIndex];
        this.boolUploadSpinner = true
        if( this.objDeviceDetectorService.browser != "IE" ){
            this.router.routeReuseStrategy.shouldReuseRoute = function(){
                return false;
            }

            if(!sessionStorage.getItem("SessionId")){

                sessionStorage.setItem("SessionId", Guid.newGuid() );
            }
            this.router.events.subscribe((evt) => {
                if (evt instanceof NavigationEnd) {
                    this.timedOut = false;
                    // trick the Router into believing it's last link wasn't previously loaded
                    this.router.navigated = false;
                    // if you need to scroll back to top, here is the right place
                    window.scrollTo(0, 0);
                    this.activeURL = evt.url;
                    this.boolOrgHeader = false;
                    this.routeUrl = this.activeURL.split("/")[1];
                    if( this.activeURL.includes("org") ){
                        this.objProjectDetails = {};
                        this.getOrgByCode(this.routeUrl);
                    } else {
                        this.GetProjectByCode();
                    }
                }
            });
            this.router.routeReuseStrategy.shouldReuseRoute = function(){
                return false;
            };
        } else {
            this.boolUnsupportedBrowser = false;
        }
    }

    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
    }
    public boolTimeOutReset:boolean=false;
    reloadApp(){
        this.logOut();
        this.reset();
        $("#model-delete").modal("hide");
        this.boolLoadPages = false;
        if(!sessionStorage.getItem("SessionId")){
            sessionStorage.setItem("SessionId", Guid.newGuid() );
        }
        if( this.activeURL.includes("org") ){
            this.objProjectDetails = {};
            this.getOrgByCode(this.routeUrl);
        } else {
            this.GetProjectByCode();
        }
    }
    public inputURl:any;
    encodeUrl(){
        var boolQuesryStringAvailable:boolean=false;
        boolQuesryStringAvailable = this.inputURl.includes("?", 0);
        if( boolQuesryStringAvailable ){
            var spiltedData = this.inputURl.split("?");
            var encryptedData = this.objAESEncryptionService.encryptData(spiltedData[1]); 
            this.resultURl = this.objHttpUrlEncodingCodec.encodeValue(encryptedData)
        } else {
            this.resultURl = "Quesrystring is not available"
        }
    }
    public boolUnsupportedBrowser:boolean=true;

    loginAgain(){
        this.showDialog = false;
    }
    public objorgDetails:any={};
    getOrgByCode(orgCode){
        this._operationService.get(url.bricsAPI+"Organization/GetOrganizationDetailsByCode?Code="+orgCode).subscribe(
            data=>{
                this.objorgDetails = data;
                this.boolOrgHeader = true;
                this.boolLoadPages = true;
                this.boolUploadSpinner = false;
                this.objTitle.setTitle(this.objorgDetails.Name);
                this.loadStyle(this.objorgDetails.CSSFile);
                this.getOrganizationMappedLanguages(data.Id,'/org')
            },error=>{
                this.boolUploadSpinner = false;
            }
        )
    }
    get getOrgDetails(){
        return this.objorgDetails;
    }
    GetProjectByCode(){
        
        this._operationService.get(url.bricsAPI+'Project/GetProjectByCode/?projectCode='+this.routeUrl).subscribe(
            data => {
                localStorage.setItem("url",data.ExternalURL);
                sessionStorage.setItem("projectname",data.ProjectName);
                sessionStorage.setItem("projeDisc",data.ProjectDescription);
                
                this.boolOrgHeader = false;
                dataLayer.push({'projectCode':this.routeUrl});
                this.objProjectDetails = data;
                this.objTitle.setTitle(this.objProjectDetails.ProjectName);
                this.loadStyle(this.objProjectDetails.CSSFile);
                this.setSessionData(data);
                this.getOrganizationMappedLanguages(data.OrganisationId,'/home',this.objProjectDetails.ProjectId);
            },error=>{
                this.boolUploadSpinner = false;
            }
        )
    }

    /* set Session data and Localstorage data*/
    setSessionData(data){
        sessionStorage.setItem("DisplayWebTemplate",data.DisplayWebTemplate);
        localStorage.setItem("DisplayWebTemplate",data.DisplayWebTemplate);

        sessionStorage.setItem("IsDiscountApplicable",data.IsDiscountApplicable);
        localStorage.setItem("IsDiscountApplicable",data.IsDiscountApplicable);

        sessionStorage.setItem("CompanyCode",data.CompanyCode);
        localStorage.setItem("CompanyCode",btoa(data.CompanyCode));

        sessionStorage.setItem("projectStatus",data.ProjectStatus);
        localStorage.setItem("projectStatus",btoa(data.ProjectStatus));

        sessionStorage.setItem("acceptMemberShip",data.IsMemberShipEnabled);
        localStorage.setItem("acceptMemberShip",btoa(data.IsMemberShipEnabled));

        sessionStorage.setItem("OrganizationName", data.OrganizationName);
        localStorage.setItem("OrganizationName", btoa(data.OrganizationName));

        sessionStorage.setItem("DefaultLangauge", data.DefaultLangauge);
        localStorage.setItem("DefaultLangauge", btoa(data.DefaultLangauge));

        sessionStorage.setItem("Published", data.Published);
        localStorage.setItem("Published", btoa(data.Published));

        sessionStorage.setItem("LoginVerification", data.IsVerificationRequired);
        localStorage.setItem("LoginVerification", btoa(data.IsVerificationRequired));

        sessionStorage.setItem("projectId", data.ProjectId);
        localStorage.setItem("projectId", btoa(data.ProjectId));

        sessionStorage.setItem("OrganisationId", data.OrganisationId);
        localStorage.setItem("OrganisationId", btoa(data.OrganisationId));

        sessionStorage.setItem("OrganisationGuid", data.OrganisationGuid);
        localStorage.setItem("OrganisationGuid", btoa(data.OrganisationGuid));

        sessionStorage.setItem("AuthType",btoa( data.AuthType.trim()));
        localStorage.setItem("AuthType", btoa(data.AuthType.trim()));

        return true;
    }
    
    /* Generate GUID -- */
    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    getOrganizationMappedLanguages(orgId,navigate,projectId?){

        this._operationService.get(url.bricsAPI+"Organization/GetOrganizationMappedLanguages/?organizationId="+orgId).subscribe(
            data=>{
                this.arrobjMappedLanguageModel = data;
                var langMappingId = sessionStorage.getItem("defaultLanguageId");
                if( langMappingId == null ){
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.IsDefault==true)[0];
                } else {
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.Id==JSON.parse(langMappingId))[0];
                }
                this.selectLocal(this.objMappedLanguageModel);
                if( projectId ){
                    this.getProjectTranslationDetail(this.objProjectDetails.ProjectId,this.ObjectTypeEnum.PROJECT,this.objMappedLanguageModel.LanguageCode);
                }
                this.boolLoadPages = true;

                this.boolUploadSpinner = false;
                if( this.timedOut ){

                    this.router.navigate([this.routeUrl+navigate]);
                }
            },error=>{
                this.boolUploadSpinner = false;
            }
        )
    }

    selectLocal(objMappedLanguageModel:MappedLanguageModel){
        sessionStorage.setItem("PGLanguageCulture",this.objMappedLanguageModel.PGLanguageCulture);
        localStorage.setItem("PGLanguageCulture",this.objMappedLanguageModel.PGLanguageCulture);

        sessionStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        localStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        
        sessionStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        localStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        
        sessionStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        localStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        
        sessionStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        localStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        
        sessionStorage.setItem("languageCode",this.objMappedLanguageModel.LanguageCode);
        localStorage.setItem("languageCode",btoa(this.objMappedLanguageModel.LanguageCode));
        
        sessionStorage.setItem("DefaultLangauge",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("DefaultLangauge",btoa(this.objMappedLanguageModel.ResourceFile.trim()));
        
        sessionStorage.setItem("activeLanguage",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("activeLanguage",btoa(this.objMappedLanguageModel.ResourceFile.trim()));
        
        this.translate.setDefaultLang((objMappedLanguageModel.ResourceFile.trim()).toLowerCase());
    }
    public objProjectTranslationDetail:any={}
    get projectTranslation():any{
        return this.objProjectTranslationDetail;    
    }

    getProjectTranslationDetail(objectId,objectType,languageCode){
        this._operationService.get(url.mainUrl+"Project/GetProjectTranslationDetail/?projectId="+objectId+"&LanguageCode="+languageCode).subscribe(
            data=>{
                this.objProjectTranslationDetail = data;

            },error=>{
                
                this.objProjectTranslationDetail={};
            }
        )
    }
    setLanguageSessionData(objMappedLanguageModel):boolean{
        sessionStorage.setItem("languageCode",objMappedLanguageModel.LanguageCode);
        localStorage.setItem("languageCode",btoa(objMappedLanguageModel.LanguageCode));

        sessionStorage.setItem("currencyId",objMappedLanguageModel.CurrencyId);
        localStorage.setItem("currencyId",btoa(objMappedLanguageModel.CurrencyId));

        sessionStorage.setItem("CurrencyCode",objMappedLanguageModel.CurrencyCode);
        localStorage.setItem("CurrencyCode",btoa(objMappedLanguageModel.CurrencyCode));

        sessionStorage.setItem("currencyIcon",objMappedLanguageModel.CurrencyIcon);
        localStorage.setItem("currencyIcon",btoa(objMappedLanguageModel.CurrencyIcon));
        return true;
    }
    get getProjectDetails(){
        return this.objProjectDetails;
    }
    get getMappedLanguage(){
        return this.arrobjMappedLanguageModel;
    }
    /*To be reviewd*/
    loadingStatusCheck(){
        this.routeUrl = sessionStorage.getItem("ProjectName");
        this._operationService.get(url.mainUrl + 'Project/GetProjectId/?projectCode='+ this.routeUrl).subscribe(
            data => {
                sessionStorage.setItem("CurrencyHtmlCode", data.CurrencyHtmlCode);
                if(data.Status == 1){
                    this.firstTimeLoading = true;
                } else {
                    this.firstTimeLoading = false;
                }
            },error=>{
        })
    }

    logOut(){
        sessionStorage.removeItem("isLogedin");
        sessionStorage.removeItem("userName");
        sessionStorage.removeItem("IsGuestUser");
        sessionStorage.removeItem("FirstName");
        sessionStorage.removeItem("access_token"); 
        sessionStorage.removeItem("UserId"); 
        sessionStorage.removeItem("orderCount"); 
        sessionStorage.removeItem("currencyIcon"); 
        sessionStorage.removeItem("isCartAvailable"); 
        sessionStorage.removeItem("CurentCompanyCode"); 
        sessionStorage.removeItem("OrderReference");
        sessionStorage.removeItem("isOrderReference");
        sessionStorage.removeItem("Email");
        sessionStorage.removeItem("SessionId");
    }
    loadStyle(styleName: string) {
        const head = this.document.getElementsByTagName('head')[0];
        let styleIdName = styleName+"_css";
        let themeLink = this.document.getElementById(styleIdName) as HTMLLinkElement;
        if (themeLink) {
            themeLink.href = './assets/css/'+styleName+'.css';
        } else {
            const style = this.document.createElement('link');
            style.id = styleIdName;
            style.rel = 'stylesheet';
            style.href = './assets/css/'+styleName+'.css';
            head.appendChild(style);
        }
    }
    ngAfterViewChecked() {
          this.cdRef.detectChanges();
        
      }
}
