import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { EncryptionService } from './aes-encryption.service';
import * as url from '../../environments/environment';

@Injectable()
export class loginService {
    constructor(private http: Http,private objEncryptionService:EncryptionService) { }
    getUser(Url: string) {
        Url = this.objEncryptionService.encryptURLQueryString(Url);
        return this.http.get(Url, this.tokenHeader()).map((response: Response) => response.json());
    }
    getToken(Url: string, tokenData: string,additionalData?) {
        Url = this.objEncryptionService.encryptURLQueryString(Url);
        return this.http.post(Url, tokenData, this.tokenHeader(additionalData)).map((response: Response) => response.json());
    }
    post(Url: string, tokenData:any) {
        Url = this.objEncryptionService.encryptURLQueryString(Url);
        return this.http.post(Url, tokenData, this.header()).map((response: Response) => response.json());
    }
    getLoginData(Url: string) {
        Url = this.objEncryptionService.encryptURLQueryString(Url);
        return this.http.get(Url, this.getLoginHeader()).map((response: Response) => response.json());
    }
    put(Url: string,data:any) {
        Url = this.objEncryptionService.encryptURLQueryString(Url);
        return this.http.put(Url, data, this.tokenHeader()).map((response: Response) => response.json());
    }
    public jwt() {
        // create authorization header with jwt token
        // let headers = new Headers({ 'Content-Type': "application/json",  'APPType': "Web", 'UserIP': sessionStorage.getItem("IP"),'requestid':''});
        let headers = new Headers({ 'Content-Type': "application/json",  'APPType': "Web",'requestid':''});
        return new RequestOptions({ headers: headers });
      
    }
    tokenHeader(additionalData?) {
        let Pathurl = window.location.href;
        let searchStr = Pathurl.search("localhost");
        if (searchStr > 0) {
            //Local
            Pathurl = Pathurl.split('/').slice(0, 3).join('/');
        }
        else {
            //Server
            Pathurl = Pathurl.split('/').slice(0, 4).join('/');
        }
        var headerData:any={};
        if( additionalData ){
            headerData = {'content-type': "application/json", 'apptype': "Web", 'userip': sessionStorage.getItem("IP"), 'requestid':'', URL: Pathurl,'orderreferencenumber':additionalData.orderReferenceNumber };
        } else {
            headerData = { 'content-type': "application/json", 'apptype': "Web", 'userip': sessionStorage.getItem("IP"), 'requestid':'', URL: Pathurl};
        }
        if(  url.boolEncryption ){
            headerData["X-AK"] = this.objEncryptionService.encryptData(sessionStorage.getItem("SessionId")+url.encryptionAPIKey);
        } 
        // let headers = new Headers({ 'content-type': "application/json", 'apptype': "Web", 'userip': sessionStorage.getItem("IP"), 'requestid':'', URL: Pathurl,'orderreferencenumber':additionalData.orderReferenceNumber });
        let headers = new Headers(headerData);

        return new RequestOptions({ headers: headers });
    }
    header(){
        let Pathurl = window.location.href;
        let searchStr = Pathurl.search("localhost");
        if (searchStr > 0) {
            //Local
            Pathurl = Pathurl.split('/').slice(0, 3).join('/');
        }
        else {
            //Server
            Pathurl = Pathurl.split('/').slice(0, 4).join('/');
        }
        let headers = new Headers({ 'content-type': "application/json", 'apptype': "Web", 'userip': sessionStorage.getItem("IP"), 'requestid':sessionStorage.getItem("SessionId"), URL: Pathurl,"X-AK":this.objEncryptionService.encryptData(sessionStorage.getItem("SessionId")+url.encryptionAPIKey)});
        return new RequestOptions({ headers: headers });
    }
    public getLoginHeader() {
        // create authorization header with jwt token
        let headers = new Headers({  'UserIP': sessionStorage.getItem("IP") , 'requestid':sessionStorage.getItem("SessionId") });
        return new RequestOptions({ headers: headers });

    }
}