export class IconModel{
	public Id:number;
	public DocumentId:number;
	public ImageName:any;
	public ImageTypeId:any;
	public DocumentVersionId:any;
	public Width:any;
	public Height:any;
	public FilePath:any;
	public ImageTypeName:any;
	public IsDefault:boolean;
}