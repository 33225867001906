/// <reference path="../../toastr.d.ts" />
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { operationService } from '../../_services/operation.service';
import { loginService } from '../../_services/login.service';
import { DataSharedService } from '../../_services/dataSharedService';
import { DatePipe } from '@angular/common';
import * as url from '../../../environments/environment';
import { NgForm } from '@angular/forms';
import { Observable ,  Subscription } from 'rxjs';
import { error } from 'util';
import { Date } from 'core-js/library/web/timers';
import * as moment from 'moment';
import { element } from 'protractor';
import { secureTradeJson, secureTradeCustomerPhone, secureTradeRequest } from '../../models/secureTradeJson';
import { StockAvailability } from '../../models/stockavailability.model';
import { CartModel,ZeroCartPaymentModel } from '../../models/cart.model';
import { ProductModel,DependentProduct } from '../../models/productmodel';
import { ComponentintractService } from '../../_services/conponentinteraction.service';
// import * as $ from 'jquery';// import Jquery here
@Component({
  selector: 'app-root',
  templateUrl: './myCart.html',
  providers: [operationService, loginService, DatePipe]
})

export class CheckoutComponent implements OnInit, OnDestroy {
    
    public boolIsVoucherApplied:boolean;
    public paymentSuccessUrl:any;
    public paymentCancel:any;
    public languageData:any={};
    public routeUrl: string = window.location.href.split('#').pop().split('/')[1];
    public objCartDetails:CartModel=new CartModel();
    public objProductDetails:ProductModel = new ProductModel();
    public ProductGuid:any;
    public CurrentCurrencyId:any;
    public projectId:any;
    public customerId:any=0;

    constructor(private objComponentintractService:ComponentintractService,private router: Router,private _operationService: operationService, private _loginService: loginService, private _router: Router, private _datePipe: DatePipe, private _DataSharedService: DataSharedService) {
        window.scrollTo(0, 0);
        this.ProductGuid = sessionStorage.getItem("productGuid");
        this.CurrentCurrencyId = sessionStorage.getItem("currencyId");
        this.paymentSuccessUrl = "";
        this.paymentCancel = "";
    }
    ngOnInit() {
        this.getProductDetails();
        setInterval(() => {
            this.languageData = this._DataSharedService.getLanguageJson;
        }, 100);
    }
    ngOnDestroy(){

    }

    getProductDetails(){
        this._operationService.get(url.mainUrl+'Product/?ProductGuid='+this.ProductGuid+"&CurrentCurrencyId="+sessionStorage.getItem("currencyId")).subscribe(
            data=>{
                this.objProductDetails = data;
                this.projectId = this.objProductDetails.Project[0].Id;
                this.getCartContents();
            },error=>{
                
            }
        )
    }
    getCartContents(){
        this.boolIsVoucherApplied = false;
        this.customerId = 0;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                this.customerId = sessionStorage.getItem("UserId");
            }
        }
        this._operationService.get(url.mainUrl+"Cart/GetCartContents/?projectId="+this.projectId+"&customerId="+this.customerId ).subscribe(
            data=>{
                this.objCartDetails = data;
                sessionStorage.setItem("orderCount",JSON.stringify(this.objCartDetails.Orders.length));
            },error=>{
                sessionStorage.setItem("orderCount","0");
                this.objCartDetails = new CartModel();
            }
        )
    }
    get getobjCartDetails(){
        return this.objCartDetails;
    }
    loginStatusCheck(){
        var boolLoggedIn:boolean = false;
        if( sessionStorage.getItem("isLogedin") && JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
            boolLoggedIn = true;
        }
        return boolLoggedIn;
    }
    emittOrderEvent($event){
        if( $event ){
            this.getCartContents();
        }
    }
    placeOrder(){
        this.objComponentintractService.setComponentdata({boolPlaceOrder:true})
    }
    login(){

        this.objComponentintractService.setComponentdata({boolLogin:true})
    }
}