import { Directive, HostListener } from '@angular/core';
import * as url from '../../environments/environment';
@Directive({
    selector: '[appBlockCopyPaste]'
})
export class BlockCopyPasteDirective {
    constructor() { }

    @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
        if( url.allowCopyPaste == false ){
            // alert("Copy paste is disabled");
            e.preventDefault();
        }
    }
    @HostListener('input', ['$event']) disallowCharacter(e: KeyboardEvent) {
        
    }

    @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
        if( url.allowCopyPaste == false ){
            e.preventDefault();
        }
    }

    @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
        if( url.allowCopyPaste == false ){
            e.preventDefault();
        }
    }
}