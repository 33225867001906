export enum CartTypeEnum{
	VOUCHERCART = "VOUCHER_CART",
	PRODUCTCART = "PRODUCT_CART",
	TILESCART = "TILES_CART",
	PMRETROCART = "PM_CHILD_CART"
}
export enum AuthTypeEnum{
	NONSSO = "NONSSO",
	SSO = "SSO",
}
export enum PaymentMethodEnum{
	OnlinePayment = "ONLINE",
	OffLinepayment = "OFFLINE"
}
export enum ProjectStatus{
	OPEN="Open",
	HOLD="Hold",
	CLOSED="Closed"
}
export enum TaxTypeEnum{
	USTAX = "USTAX",
	GSTTAX = "GSTTAX",
	UKTAX = "UKTAX"
}