import { Injectable } from '@angular/core';
import { HttpUrlEncodingCodec,HttpClient,HttpHeaders } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { EncryptionService } from './aes-encryption.service';
import { map } from 'rxjs/operators';
import * as url from '../../environments/environment';
enum RequestMethod {
    Get,
    Post,
    Put,
    Delete,
    Options,
    Head,
    Patch
}
@Injectable()

export class operationService {
    public boolEncryption:boolean;
    constructor(private httpClient:HttpClient,private http: Http,private objEncryptionService:EncryptionService) {}
    getCross(Url:string){
        Url = this.objEncryptionService.encryptURLQueryString(Url);
        return this.http.get(Url, this.jwt()).map((response: Response) => response.json());
    }
    getPayment(Url:string, data:any = {}){
        this.loadScript();
        return this.http.post(Url, data, this.paymentApi()).map((response: Response) => response.json());
    }
    get(Url: string) {
        Url = this.objEncryptionService.encryptURLQueryString(Url);
        // return this.httpClient.get<any>(Url,{'headers':this.Apiheader()})
        return this.http.get(Url, this.jwt()).map((response: Response) => response.json());
    }

    post(Url: string,data:any) {
        Url = this.objEncryptionService.encryptURLQueryString(Url);
        // data = JSON.stringify(this.objEncryptionService.encryptData(data));
        return this.http.post(Url, data, this.jwt()).map((response: Response) => response.json());
    }

    put(Url: string,data:any) {
        Url = this.objEncryptionService.encryptURLQueryString(Url);
        // data = this.objEncryptionService.encryptData(JSON.stringify(data));
        return this.http.put(Url, data, this.jwt()).map((response: Response) => response.json());
    }

    delete(Url: string) {
        Url = this.objEncryptionService.encryptURLQueryString(Url);
        return this.http.delete(Url,this.jwt()).map((response: Response) => response.json());
    } 
    // Fetch language json
    getLanguageJson(Url: string) {
        // Url = this.objEncryptionService.encryptURLQueryString(Url);
        return this.http.get(Url).map((response: Response) => response.json());
    }
    public Apiheader(){
        let Pathurl = window.location.href;
        let searchStr = Pathurl.search("localhost");
        if (searchStr > 0) {
            //Local
            Pathurl = Pathurl.split('/').slice(0, 3).join('/'); 
        } else {
            //Server
            Pathurl = Pathurl.split('/').slice(0, 4).join('/');
        }
        let headers = new HttpHeaders({'Accept' : '*/*','Access-Control-Allow-Origin':'*','Access-Control-Allow-Credentials':"true" ,'Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Headers':'Content-Type','apptype': 'Web', 'content-type': "application/json",'requestid':sessionStorage.getItem("SessionId"), URL: Pathurl,"X-AK":this.objEncryptionService.encryptData(sessionStorage.getItem("SessionId")+url.encryptionAPIKey)});
        return headers;
    }
    public jwt() {
        let Pathurl = window.location.href;
        let searchStr = Pathurl.search("localhost");
        if (searchStr > 0) {
            //Local
            Pathurl = Pathurl.split('/').slice(0, 3).join('/'); 
        } else {
            //Server
            Pathurl = Pathurl.split('/').slice(0, 4).join('/');
        }
        sessionStorage.setItem('Pathurl', Pathurl);
        var headers:any;
        if(  url.boolEncryption ){
            headers = new Headers({'Accept' : '*/*','Access-Control-Allow-Origin':'*','Access-Control-Allow-Credentials':true ,'Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Headers':'Content-Type','apptype': 'Web', 'content-type': "application/json",'requestid':sessionStorage.getItem("SessionId"), URL: Pathurl,"X-AK":this.objEncryptionService.encryptData(sessionStorage.getItem("SessionId")+url.encryptionAPIKey)});
        } else {
            headers = new Headers({'Accept' : '*/*','Access-Control-Allow-Origin':'*','Access-Control-Allow-Credentials':true ,'Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Headers':'Content-Type','apptype': 'Web', 'content-type': "application/json",'requestid':sessionStorage.getItem("SessionId"), URL: Pathurl});
        }
        return new RequestOptions({ headers: headers, method: RequestMethod.Get });
    }

    public paymentApi() {
        let headers = new Headers({Accept : 'application/json', 'Access-Control-Allow-Credentials':true , 'content-type': 'application/json',UserIP: sessionStorage.getItem("IP")});
        return new RequestOptions({headers:headers});
    }
    loadScript(){
            var cors_api_host = 'cors-anywhere.herokuapp.com';
            var cors_api_url = 'https://' + cors_api_host + '/';
            var slice = [].slice;
            var origin = window.location.protocol + '//' + window.location.host;
            var open = XMLHttpRequest.prototype.open;
            XMLHttpRequest.prototype.open = function() {
                var args = slice.call(arguments);
                var targetOrigin = /^https?:\/\/([^\/]+)/i.exec(args[1]);
                if (targetOrigin && targetOrigin[0].toLowerCase() !== origin &&
                    targetOrigin[1] !== cors_api_host) {
                    args[1] = cors_api_url + args[1];
                }
                return open.apply(this, args);
            };
        
    }
}