export class NewsTitleModel{
    public Id:any;
    public Title:any;
    public ProjectId:any;
    public NewsDetails:Array<NewsContentModel>=[];
}
export class NewsContentModel  {
	public Id:any;
	public NewsId:any;
	public LanguageId:any;
	public Header:any;
	public Description:any="";
	public NewsBackground:any;
	public CreatedOn:any;
}
export class BuildData{
	public newsContent:Array<NewsContentModel> = new Array<NewsContentModel>();
}