import { Component,Output, OnInit,ViewChild,ElementRef,Input,Directive,EventEmitter,AfterViewInit,NgZone,Injector } from '@angular/core';
import { NgForm,ControlValueAccessor,FormControl,NgControl,Validators } from '@angular/forms';
import { operationService } from '../_services/operation.service';
import { ReCaptchaAsyncValidator } from '../_services/recaptchaasyncvalidator.service';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import * as url from '../../environments/environment';

export interface ReCaptchaConfig {
	theme? : 'dark' | 'light';
	type? : 'audio' | 'image';
	size? : 'compact' | 'normal';
	tabindex? : number;
}
declare const grecaptcha : any;

declare global {
	interface Window {
		grecaptcha : any;
		reCaptchaLoad : () => void
	}
}

@Directive({
  selector: '[nbRecaptcha]',
  providers:[ReCaptchaAsyncValidator]
})
export class RecaptchaDirective implements OnInit, AfterViewInit, ControlValueAccessor{
	
	/*step 2 Properties*/
	@Input() key : string;
	@Input() config : ReCaptchaConfig = {};
	@Input() lang : string;
	@Output() captchaSubmitted = new EventEmitter<boolean>();

	private onChange : ( value : string ) => void;
	private onTouched : ( value : string ) => void;
	private widgetId : number;
	private control: FormControl;

	constructor( private http:Http,private objReCaptchaAsyncValidator:ReCaptchaAsyncValidator,private objOperationService:operationService,private element : ElementRef, private  ngZone : NgZone, private injector : Injector ) {}

	ngOnInit() {
		this.registerReCaptchaCallback();
		this.addScript();
	}

	/*7*/
	ngAfterViewInit() {
		// this.control = this.injector.get(NgControl).control;
		// this.setValidator();
	}
	/*step 1*/
	addScript() {
		let script = document.createElement('script');
		const lang = this.lang ? '&hl=' + this.lang : '';
		script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit${lang}`;
		script.async = true;
		script.defer = true;
		document.body.appendChild(script);
	}
	registerReCaptchaCallback() {
		
		window.reCaptchaLoad = () => {
			const config = {
				...this.config,
				'sitekey': this.key,
				'callback': this.onSuccess.bind(this),
				'expired-callback': this.onExpired.bind(this)
			};
			this.widgetId = this.render(this.element.nativeElement, config);
		};
	}
	private render( element : HTMLElement, config ) : number {
		return grecaptcha.render(element, config);
	}

	/*5*/
	writeValue( obj : any ) : void {}

	registerOnChange( fn : any ) : void {
		this.onChange = fn;
	}

	registerOnTouched( fn : any ) : void {
		this.onTouched = fn;
	}

	/*6*/
	onExpired() {
		this.ngZone.run(() => {
			this.onChange(null);
			this.onTouched(null);
		});
	}

	onSuccess( token : string ) {
		this.verifyToken(token);
		// this.ngZone.run(() => {
		// 	this.onChange(token);
		// 	this.onTouched(token);
		// });
	}
	verifyToken(token){
		// http://localhost:49712/api/SiteConfiguration/VarifySites?tokenString=
		this.objOperationService.get(url.mainUrl+"SiteConfiguration/VarifySites?tokenString="+token).subscribe(
			data=>{
				this.captchaSubmitted.emit(true);
			},error=>{

			}
		)
	}
	private setValidator() {
		this.control.setValidators(Validators.required);
		this.control.updateValueAndValidity();
	}
	verifyToken____( token : string ) {
		this.control.setAsyncValidators(this.objReCaptchaAsyncValidator.validateToken(token))
		this.control.updateValueAndValidity();
	}
}
