import { Component, OnInit,Input,ElementRef,ViewChild,OnChanges,Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { operationService } from '../../../_services/operation.service';
import { DataSharedService } from '../../../_services/dataSharedService';
import * as url from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PaymentModel,PaymentGatewayType } from '../../../models/payment.model';
import { CartModel,ZeroCartPaymentModel } from '../../../models/cart.model';
import { DOCUMENT } from '@angular/common';
import { ComponentintractService } from '../../../_services/conponentinteraction.service';
@Component({
    selector: 'app-paymentform',
    templateUrl: './payment-form.component.html',
    providers: [operationService, DatePipe]
})

export class PaymentFormComponent implements OnInit,OnChanges{
	@Input("objPaymentModel") objPaymentModel:PaymentModel = new PaymentModel();
	@Input("objCartDetails") objCartDetails:CartModel = new CartModel();
	@Input("boolDataChange") boolDataChange:boolean=false;
	@Input("projectCode") projectCode:any;
	@Input("boolPaymentSubmit") boolPaymentSubmit:boolean=false;
	@ViewChild('paymentForm') paymentForm:ElementRef;

	public authType:any;
    public paymentCancel = "";
	public paymentSuccessUrl = "";
	public PaymentGatewayType : typeof PaymentGatewayType = PaymentGatewayType;
	public baseUrl:any;
	public isInitiated:boolean=false;
    public routeUrl:any;
    public strBillingpremise:any="";

	constructor(@Inject(DOCUMENT) private document: Document,private objComponentintractService:ComponentintractService,private objOperationService: operationService, private _router: Router){
		this.objPaymentModel.PaymentGatewayId.Value
	}
	ngOnInit(){
		var arrUrlDetails = window.location.href.split('#');
        var baseUrl = arrUrlDetails[0];
        var projectCode = arrUrlDetails[1].split("/")[1];
        this.routeUrl = projectCode;
        this.paymentSuccessUrl = baseUrl+"#/"+projectCode+"/success";
        this.paymentCancel = baseUrl+"#/"+projectCode+"/cancel";
		this.authType = atob(sessionStorage.getItem("AuthType"));
		this.isInitiated = true;
        this.strBillingpremise = this.objCartDetails.billingAddress == null ?'': this.objCartDetails.billingAddress.Address1.substring(0, 25);
	}
	ngOnChanges(){
		if( this.isInitiated ){

            this.strBillingpremise = this.objCartDetails.billingAddress == null ?'': this.objCartDetails.billingAddress.Address1.substring(0, 25);
			if( this.boolPaymentSubmit ){
				this.payment()
			}
		}
	}
	getCurrentCurrency(){
        var CurrencyCode:any;
        if( sessionStorage.getItem("CurrencyCode") ){
            CurrencyCode = sessionStorage.getItem("CurrencyCode");
        }
        return CurrencyCode;
    }
    payment(){
        // if( this.authType == "SSO" ){
        //     this.multiBankoPayment();
        // } else {

        // }

        this.paymentForm.nativeElement.submit();
        this.boolPaymentSubmit = false;
        this.objComponentintractService.setComponentdata({boolLaod:false});
    }
    multiBankoPayment(){
        
        this.objOperationService.get(url.mainUrl+"Cart/GetCheckoutId/?entityId="+this.objPaymentModel.Sitereference+"&amount="+this.objCartDetails.cartTotalAmount.toFixed(2)+"&currency="+this.getCurrentCurrency()+"&paymentType=DB&CartReferenceNumber="+this.objCartDetails.CartReferenceNumber).subscribe(
            data=>{
                sessionStorage.setItem("checkOutId",btoa(data.ResponseData));
                localStorage.setItem("checkOutId",btoa(data.ResponseData));
                this.addDynamicMultiBankoScriptTag(data.ResponseData);
            },error=>{
                
            }
        )
    }
    get getCustomerEmail(){
        var userEmail:any;
        if( sessionStorage.getItem("Email") ){
            userEmail = sessionStorage.getItem("Email");
        }
        return userEmail;
    }

    addDynamicMultiBankoScriptTag(checkoutId) {
        const head = this.document.getElementsByTagName('head')[0];
        let scriptName = "multibanko";
        let multibanko = this.document.getElementById(scriptName) as HTMLLinkElement;
        if (multibanko) {
            // multibanko.src = "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId="+checkoutId;;
        } else {

            const node = this.document.createElement('script');
            node.src = "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId="+checkoutId;
            node.type = 'text/javascript';
            node.async = true;
            node.charset = 'utf-8';
            head.appendChild(node);
        }
    }
}
