import { Component,OnInit,ViewChild,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { operationService } from '../../_services/operation.service';
import { DatePipe } from '@angular/common';
// import * as url from '../../baseUrl';
import * as url from '../../../environments/environment';
import { error } from 'selenium-webdriver';
import { json } from 'body-parser';
import { ComponentintractService } from '../../_services/conponentinteraction.service';
import { DocumentProperties,UploadImage } from '../../models/documentMapping.model';
import { CartModel } from '../../models/cart.model';
import { Order } from '../../models/ordermodel';
import { AuthTypeEnum,CartTypeEnum } from '../../models/project.enum';
import { ModeratorEnum } from '../../models/ordermodel';
import { BuildDmsJSONService } from '../../_services/builddmsjsonservice';
import { DocumentTypeCodeEnum,FolderCodeEnum,ObjectTypeEnum } from '../../models/documenttypecode.enum';
import { PaymentStatus } from '../../models/payment.model';
@Component({
  selector: 'app-root',
  templateUrl: './order-history.html',
  providers: [operationService, DatePipe,BuildDmsJSONService]
})
export class OrderHistoryComponent implements OnInit { 
    public routeUrl: string = "" 
    public orderReferenceList:any=[];
    public orderHistoryList:Array<Order> = new Array<Order>();
    public sIndex: number = null;
    public invoiceDetails:CartModel = new CartModel();
    public OrderEditBtnDisplay:boolean = false;
    public orderEditData:any = {"productDetails":{}};
    public dynamicId:number = 0;
    public QuantityOfOrder:number;
    public CopyQuantityOfOrder:any = {};
    public objUser:any = {};
    public InscriptionStatus:boolean;
    public orderReferenceNumber:string;
    public LineCountNumber:number;
    public IntialCount:number = 0;
    public EditQuantity:number;
    public RemainingQunatity:number;
    public CurrencyHtmlCode:string = sessionStorage.getItem("CurrencyHtmlCode");
    public DocumentTypeCodeEnum : typeof DocumentTypeCodeEnum = DocumentTypeCodeEnum;
    public ObjectTypeEnum : typeof ObjectTypeEnum = ObjectTypeEnum;
    public FolderCodeEnum : typeof FolderCodeEnum = FolderCodeEnum;
    public ModeratorEnum : typeof ModeratorEnum = ModeratorEnum;
    public CartTypeEnum : typeof CartTypeEnum = CartTypeEnum;
    public AuthTypeEnum : typeof AuthTypeEnum = AuthTypeEnum;
    public PaymentStatus : typeof PaymentStatus = PaymentStatus;
    @ViewChild('pageTop') pageTop:ElementRef;

    constructor(private objComponentintractService:ComponentintractService,private objBuildDmsJSONService:BuildDmsJSONService,private _operationService: operationService, private _router: Router, private datePipe:DatePipe){ }
    ngOnInit(){
        if( sessionStorage.getItem("UserId") ){
            this.objUser = {Id:sessionStorage.getItem("UserId")}
        }
        var arrUrlDetails = window.location.href.split('/');
        this.routeUrl = arrUrlDetails[url.urlIndex];
        this.setStatusCLass()
        this.getResourceJSON(sessionStorage.getItem("activeLanguage").toLowerCase());
        this.getCardDetails();
    }
    getCardDetails(){
        var userId = sessionStorage.getItem("UserId");
        if( userId == null || userId == undefined ){
            userId = atob(localStorage.getItem("UserId"));
        }
        this.objComponentintractService.setComponentdata({boolLaod:true});
        this._operationService.get(url.mainUrl + 'Cart/GetCustomerCarts/?customerId='+userId ).subscribe(
            data => {
                this.objComponentintractService.setComponentdata({boolLaod:false});
                this.orderReferenceList = data.filter(orderItem=> orderItem.CartStatus.Value.trim() == 'CART_COMP')
                let index = 0;
                this.getOrderListByReference(this.orderReferenceList[0], index);
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }

    public grandTotalDeliveryAmount:any=0;
    public objOrder:any={};
    public orderTotal:any=0
    public inscriptionCharecterCount:any;
    public productionStatus:any={};
    public productionStatusClass:any={};
    public boolReuploadUserImage:boolean=false;
    public boolAllowTilesCertificateDownload:boolean=false;
    getOrderListByReference(order, index){
        this.objOrder = order;
        this.sIndex = index;
        $('.collapse').collapse('hide');
        this.boolReuploadUserImage = false;
        this._operationService.get(url.mainUrl + 'Cart/GetCartByGuid/?cartGuid='+order.CartGuid).subscribe(
            data => {
                this.objComponentintractService.setComponentdata({boolLaod:false});
                this.grandTotal=0;
                this.orderTotal=0;
                this.is_Retro_Order_Applicable(order.CartGuid);
                this.grandTotalDeliveryAmount=0;
                this.invoiceDetails= data;
                this.boolAllowTilesCertificateDownload = true;
                if(this.invoiceDetails.PaymentStatus.Code == PaymentStatus.PaymentRefund ){
                    this.boolAllowTilesCertificateDownload = false;
                }
                if( (this.invoiceDetails.Moderationstatus.Code == ModeratorEnum.Rejected && this.invoiceDetails.RejectionCount  >= this.invoiceDetails.ProjectModerationLevels) && (this.invoiceDetails.PaymentStatus.Code == PaymentStatus.PaymentCompleted || this.invoiceDetails.PaymentStatus.Code == PaymentStatus.PaymentNotApplicable) ){
                    this.boolReuploadUserImage = true;
                }
                if(this.invoiceDetails.Type == CartTypeEnum.VOUCHERCART){
                    this.invoiceDetails.IsInscriptionEditable = false;
                }
                // this.inscriptionCharecterCount = 0;

                this.grandTotal = this.grandTotal +  (this.invoiceDetails.OrderTotalInclTax + this.invoiceDetails.OrderShippingInclTax) - (this.invoiceDetails.CartVoucherTotalInclTax + this.invoiceDetails.CartDiscountTotalInclTax)
                this.grandTotalDeliveryAmount = this.grandTotalDeliveryAmount + this.invoiceDetails.OrderShippingInclTax;
                this.orderTotal = this.orderTotal + this.invoiceDetails.OrderTotalInclTax - this.invoiceDetails.CartDiscountTotalInclTax;
                
                this.invoiceDetails.Orders.forEach((value,index)=>{
                    if( value.ItemType.Value == "PM" || value.ItemType.Value == "TILE" ){
                        this.inscriptionCharecterCount = value.productDetails.InscriptionCharecterCount;
                    }
                })
                this.objPMOrder = this.invoiceDetails.Orders.filter(item=>item.ItemType.Value == "PM")[0];
                /**
                this.invoiceDetails.Orders.forEach((value,index)=>{
                    this.grandTotalDeliveryAmount = this.grandTotalDeliveryAmount + value.OrderDeliveryInclTax;
                    this.orderTotal = this.orderTotal + value.PriceInclTax;
                    if( value.ItemType.Value == "PM" ){
                        this.inscriptionCharecterCount = value.productDetails.InscriptionCharecterCount;
                    }
                    if( this.invoiceDetails.Type == CartTypeEnum.TILESCART && value.DependentOrders != null && value.DependentOrders.length>0 ){

                        this.grandTotalDeliveryAmount = this.grandTotalDeliveryAmount + (value.DependentOrders[0].OrderDeliveryInclTax * value.DependentOrders[0].Quantity);
                        this.orderTotal = this.orderTotal + (value.DependentOrders[0].PriceInclTax - value.DependentOrders[0].DiscountAmountInclTax);                       
                    }
                });
                this.grandTotal = this.orderTotal + this.grandTotalDeliveryAmount - this.invoiceDetails.CartVoucherTotalInclTax
                **/
                if( this.grandTotal < 0 ){
                    this.grandTotal = 0;
                }
            },error => {
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }

    isRetroOrderApplicable: boolean = false;
    is_Retro_Order_Applicable(guid){
        this._operationService.get(`${url.mainUrl}cart/IsRetroOrderApplicable/?cartGuid=${guid}`).subscribe({
            next:(resp:any)=>{
                this.isRetroOrderApplicable = resp;
            },
            error:(err:any)=>{
                this.isRetroOrderApplicable = false;
            }
        });
    }

    CreateRetrospectiveCart(){
        this._operationService.post(`${url.mainUrl}cart/CreateRetrospectiveCart/?cartGuid=${this.objOrder.CartGuid}`,{}).subscribe({
            next:(resp:any)=>{
                this._router.navigate([`${this.routeUrl}/pm-retro`])
            },
            error:(err:any)=>{
                toastr.error(JSON.parse(err._body).Message);
                
            }
        });
    }

   unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.Id] = arrElem;
      mappedArr[arrElem.Id]['children'] = [];
    }


    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parentOrderId != 0 && mappedElem.isParentOrder == false) {
          mappedArr[mappedElem['parentOrderId']]['children'].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }
  customTrackBy(index: number, obj: any): any {
    return index;
  }
  orderEditDetails(orderHistoryList){
    this.EditQuantity = 0;
    this.QuantityOfOrder = JSON.parse(JSON.stringify(orderHistoryList.Quantity));
    this.CopyQuantityOfOrder = JSON.parse(JSON.stringify(orderHistoryList));
    this.LineCountNumber = orderHistoryList.productDetails.InscriptionLineCount;
    this.IntialCount = JSON.parse(JSON.stringify(orderHistoryList.productDetails.InscriptionLineCount));
    if(JSON.parse(JSON.stringify(orderHistoryList.productDetails.StockQuantity)) != "Unlimited")
    {
      this.RemainingQunatity = parseInt(JSON.parse(JSON.stringify(orderHistoryList.productDetails.StockQuantity))) - JSON.parse(JSON.stringify(orderHistoryList.productDetails.SoldQuantity));  
    }
    else
    {
      this.RemainingQunatity = 100;
    }
    
    this.orderEditData = orderHistoryList;
    $("#appendTemp").empty();
    this.InscriptionStatus = false;
  }


    public objResourceJSON:any="";
    getResourceJSON(resourceFile){

        this._operationService.getLanguageJson(url.languageJsonUrl+resourceFile+".json").subscribe(
            data=>{
                this.objResourceJSON = data;
                this.setProductionStatus();
            }
        )
    }
    setStatusCLass(){
        this.productionStatusClass = {
                                    'PRODUCTION_COMPLETE':'text-success',
                                    'PRODUCTION_INITIATED':'text-info',
                                    'PRODUCTION_INPROGRESS':'text-info',
                                    'PRODUCTION_NOTINITIATED':'text-info',
                                    'MEMENTO_COMPLETE':'text-danger',
                                    'MEMENTO_INPROGRESS':'text-danger',
                                    'MEMENTO_NOTINITIATED':'text-danger'
                                }
    }
    setProductionStatus(){
        this.productionStatus = {
                                    'PRODUCTION_COMPLETE':this.objResourceJSON.Briconomics.OrderHistory.ProductionCompleted,
                                    'PRODUCTION_INITIATED':this.objResourceJSON.Briconomics.OrderHistory.ProductionInitated,
                                    'PRODUCTION_INPROGRESS':this.objResourceJSON.Briconomics.OrderHistory.ProductionInitated,
                                    'PRODUCTION_NOTINITIATED':this.objResourceJSON.Briconomics.OrderHistory.ProductionInitated,
                                    'MEMENTO_COMPLETE':this.objResourceJSON.Briconomics.OrderHistory.ProductionNotInitatedStatus,
                                    'MEMENTO_INPROGRESS':this.objResourceJSON.Briconomics.OrderHistory.ProductionNotInitatedStatus,
                                    'MEMENTO_NOTINITIATED':this.objResourceJSON.Briconomics.OrderHistory.ProductionNotInitatedStatus
                                }
    }
    orederIncriment(){
        if(this.EditQuantity<this.RemainingQunatity) {
            this.EditQuantity +=1;
        }
    }
    orederDecrement(){
        this.EditQuantity -=1;
    }

  addAnotherLine(){
    var that = this;
    this.dynamicId +=1;
    let temp = `<div class="input-group appendField" id="appendField_${this.dynamicId}">
                  <input type="text" class="form-control inputFileVisible" id="dynamic_id_${this.dynamicId}" maxlength="${this.CopyQuantityOfOrder.productDetails.InscriptionCharecterCount}"   placeholder="" name="inscrpt" >
                  <span class="input-group-btn"></span>
                    <span class="delete-btn" id="delete_${this.dynamicId}" style="padding-top: 11px"><i class="material-icons">delete</i></span>
              </div>`
    if(this.LineCountNumber > this.IntialCount ){
      this.IntialCount +=1;
      $('#appendTemp').append(temp);
    }
    
    $('.inputFileVisible').on('input',function(e){
      that.InscriptionStatus = true;
     });

     $(".delete-btn").unbind("click").click(function () {
      let DeleteId = (this.id).split("delete_")[1];
      // let inputId = (this.id).split("dynamic_id_")[1];
      $("#appendField_" + DeleteId).remove();
      that.IntialCount -=1
    })
  }
  valuechange(){
    this.InscriptionStatus = true;
  }
  submitUpdatedOrder(){
    let inscriptionValue = [];
    let OrderUpdateJson = this.getUpdateJson();
    // Modified
      // if(this.QuantityOfOrder != this.CopyQuantityOfOrder.Quantity){
      //     OrderUpdateJson.UploadedProduct[0].OrderStatus = "New";
      // }

      if(this.orderEditData.productDetails.OrderMinQuantity>this.EditQuantity || this.orderEditData.productDetails.OrderMaxQuantity < this.EditQuantity)
      {
          toastr.error("Minimum order quantity should be "+this.orderEditData.productDetails.OrderMinQuantity+" and Order maximum quantity should be "+this.orderEditData.productDetails.OrderMaxQuantity);
      }
      else
      {
        if(this.EditQuantity>0)
      {
        OrderUpdateJson.UploadedProduct[0].OrderStatus = "New";
      }
      if(this.InscriptionStatus== true){
          OrderUpdateJson.UploadedProduct[0].InscriptionStatus = "Edit";
      }
      for(let inscriptData of this.CopyQuantityOfOrder.inscriptionData){
          inscriptionValue.push({"InscriptionLine": inscriptData})
      }
    // End
   
    for(let i=0; i<= this.dynamicId; i++){
        let value = $("#dynamic_id_"+i).val();
        if(value){
          inscriptionValue.push({"InscriptionLine": value});
        }
          // inscriptionValue
    }

    OrderUpdateJson.UploadedProduct[0].inscriptionDetail = inscriptionValue;
    OrderUpdateJson.UploadedProduct[0].Quantity = this.EditQuantity; 
    //122.166.219.125:5057/ECommerceAPI/api/TilesOrder/UpdateTilesCartContent
    this._operationService.post(url.mainUrl + 'TilesOrder/UpdateTilesCartContent', OrderUpdateJson).subscribe(
      data => {
          toastr.success("Success");
          this.getOrderListByReference(this.invoiceDetails.CartReferenceNumber, this.sIndex);
          this.getCardDetails();
          $("#editOrderHistory").modal("hide");
      },
      error => {
        // console.log(error);
        $("#editOrderHistory").modal("hide");
        toastr.error(error.json().Message);
      })
      }
  }
  getUpdateJson(){
    // let dt = parseInt(sessionStorage.getItem("UserId"))
    let OrderJson:any ={
      "CustomerId": parseInt(sessionStorage.getItem("UserId")) ,
      "CustomerName": sessionStorage.getItem("FirstName"),
      "CartType": this.invoiceDetails.Type,//  Type
      "OrganizationId": parseInt(sessionStorage.getItem("OrganisationId")),
      "ProjectId": parseInt(sessionStorage.getItem("projectId")),
      "CreatedBy": this.orderEditData.CreatedBy,
      "CartReferenceNote": this.invoiceDetails.CartReferenceNumber,
      "CreatedOn": this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ssZ'),
      "UploadedProduct": [
        {
          "OrderId": this.orderEditData.Id,
          "ProductId": this.orderEditData.productDetails.Id,
          "ProductGuid": this.orderEditData.productDetails.ProductCode,//ProductCode
          "Quantity": this.orderEditData.Quantity,
          "ProductVarianceId": 0,
          "ProductName": this.orderEditData.productDetails.ProductName,
          "ProductDesc":  this.orderEditData.productDetails.ProductDescription,
          "ProductType":this.orderEditData.ItemType,
          "InstcriptionLineCount": this.orderEditData.productDetails.InstcriptionLineCount,
          "InscriptionCharactersPerLine": this.orderEditData.productDetails.InscriptionCharactersPerLine,
          "OrderStatus": null,
          "InscriptionStatus": null,
          "inscriptionDetail": [ ]
        }
      ],
      "ModifiedBy": null,
      "ModifiedOn": null,
      "Action": null
    } 
    return OrderJson;
  }

  resendCertificate(cartId)
  {
    //122.166.219.125:5057/ECommerceAPI/api/TilesOrder/UpdateTilesCartContent
    this._operationService.get(url.mainUrl +"Cart/ResendEmail/?objectId="+cartId).subscribe(
      data=>
      {
        toastr.success("Mail Resend was Successful.");
        
      },
      error=>
      {
        toastr.success("Mail Resend was Unsuccessful.");
        
      }
      );
  }
  goToHome(){
    this._router.navigate([this.routeUrl]);
  }
  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('_blank');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
        <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons"/>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css" />
          <link rel="stylesheet" href="./assets/css/spacing.css">
          <link rel="stylesheet" href="./assets/css/material.css">
          <link rel="stylesheet" href="./assets/css/cropper.css">
          <link rel="stylesheet" href="./assets/css/main.css">
          <link rel="stylesheet" href="./assets/css/style.css">
          <link rel="stylesheet" href="./assets/css/media-query.css">
          <link rel="stylesheet" href="./assets/css/print.css">
          <link rel="stylesheet" href="./assets/css/org-main.css">
          <style>
            //   li {
            //     list-style:none;
            // }
          </style>
        </head>
        <body onload="window.print();window.close()">
            <div class="page-mycart myorders-page md-mt-70">
            <div class="mycart-section">
                <div class="mycartlists">
                  <div class="clearfix"></div>
                    ${printContents}
                  </div>
              </div>
            </div>
        </body>
      </html>`
    );
    popupWin.document.close();
}
    public grandTotal:any=0;
    get getCartDetails(){
        return this.invoiceDetails;
    }
    dataEmitted($event){
        this.getOrderListByReference(this.objOrder, this.sIndex);
    }

    get currencyIcon(){
        var strClass:any="";
        if( this.invoiceDetails.CurrencyIcon ){

            strClass = this.invoiceDetails.CurrencyIcon;
        }
        return strClass;
    }
    getCurrentCurrencyIcon(){
        var strClass:any="";
        if( this.invoiceDetails.CurrencyIcon ){

            strClass = this.invoiceDetails.CurrencyIcon;
            // strClass = "fa-usd" //this.invoiceDetails.CurrencyIcon;
        }
        return strClass;
    }
    
    getCustomerName(){
        var customerName:string;
        var firstName:string="";
        var lastName:string="";
        if( this.invoiceDetails.customerDetails != undefined && this.invoiceDetails.customerDetails != null ){
            firstName = (this.invoiceDetails.customerDetails.FirstName != undefined && this.invoiceDetails.customerDetails.FirstName !=null)?this.invoiceDetails.customerDetails.FirstName:'';
            lastName = (this.invoiceDetails.customerDetails.LastName != undefined && this.invoiceDetails.customerDetails.LastName !=null)?this.invoiceDetails.customerDetails.LastName:'';
        }
        customerName = firstName+" "+lastName;
        return customerName
    }
    downloadDocument(objectId,objectType,isDefault,documentType){
        this.objComponentintractService.setComponentdata({boolLaod:true});
        this._operationService.get(url.mainUrl+"Cart/DownloadPDF/?cartReferenceNo="+this.invoiceDetails.Id+"&documentType="+documentType).subscribe(
            data=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
                window.open(data.ResponseData, '_blank');
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    getOrgBackground(PhotoMosaicBackgroundColor){
               
    }
    get getInscriptionCharecterCount(){
        return this.inscriptionCharecterCount;
    }
    
    get getcropperMinWidth(){
        return this.pageTop.nativeElement.offsetWidth;
    }
    public showCropImage:boolean=false;
    public strFileName:any;
    getImageFile($event){
        this.showCropImage = true;
        if( $event ){
            this.imageJson = Object.assign({},$event);
            this.imageJsonCopy = Object.assign({},$event);
            this.strFileName = this.imageJson.FileName;
        }
    }

    public strBase64: any;
    public strSelectedImageBase64: any;
    public imageJson: any = {};
    public imageJsonCopy: any = {};
    get getBase64Data(){
        var base64Data=""
        if( this.imageJsonCopy.File != undefined || this.imageJsonCopy.File == '' ){

            base64Data = this.imageJsonCopy.File;
        } else {
            base64Data = this.strBase64;
        }
        return base64Data;
    }
    captureImageBase64($event){
        if($event){
            this.strBase64 = $event;
        }
    }
    get getSettings(){
        return {width:4,height:3}
    }
    get getMinHeightWidth(){

        return {minHeight:this.objPMOrder.productDetails.PM_MinimumImageHeight,minWidth:this.objPMOrder.productDetails.PM_MinimumImageWidth}
    }
    getCroppedPMImage($event){
        this.imageJson.FilePath = $event.dataUrl
        var data = $event.dataUrl.split(',');
        this.strBase64 = data[1];
        this.strSelectedImageBase64 = data[1];
        this.showCropImage = false;
        this.updatePMImage();
    }
    get getImageUrl(){
        var imageUrl:any="";
        if( this.imageJsonCopy.File != undefined || this.imageJsonCopy.File == '' ){

            imageUrl = 'data:image/jpg;base64,'+this.imageJsonCopy.File;
        }
        return imageUrl
    }
    public objPMOrder:Order = new Order();
    public organisationGuid:any = sessionStorage.getItem("OrganisationGuid");
    updatePMImage(){
        this.objComponentintractService.setComponentdata({boolLaod:true});
        if( this.strSelectedImageBase64 != undefined && this.strSelectedImageBase64 != "" ){
            this.objComponentintractService.setComponentdata({boolLaod:true});
            let objectType:any;
            if( sessionStorage.getItem("isLogedin") && sessionStorage.getItem("IsGuestUser") ){
                if( JSON.parse(sessionStorage.getItem("IsGuestUser"))){
                    objectType = ObjectTypeEnum.GUESTORDER;   
                } else {
                    objectType = ObjectTypeEnum.ORDER; 
                }
            }
            var pmImageUpdate = this.objBuildDmsJSONService.buildDMSImageUploadJSON(this.objPMOrder.ImageId,false,false,this.strBase64,this.strFileName,this.objUser.Id,this.organisationGuid,DocumentTypeCodeEnum.PHOTOMOSAICORDERIMAGE,DocumentTypeCodeEnum.PMORDER,this.objPMOrder.Id,objectType);
            this._operationService.post(url.mainUrl+"DMS/UploadImage",pmImageUpdate).subscribe(
                data=>{
                    toastr.success(this.objResourceJSON.Briconomics.PhotoUploadWizard.Success.UploadSuccess);
                    this.updateModerationStatus(this.objOrder.CartGuid,ModeratorEnum.Pending);
                    var arrOrder = this.invoiceDetails.Orders.filter(item=>item.ItemType.Value == "PM");
                    if(arrOrder.length > 0 ){
                        this.updatePMStockQuantity(arrOrder[0].productDetails.Id); 
                    }
                },error=>{
                    toastr.error("Unable to upload")
                    this.getOrderListByReference(this.objOrder,this.sIndex);
                }
            )
        } else {
            this.objComponentintractService.setComponentdata({boolLaod:false});
            toastr.warning("Please upload image");
        }
    }
    updatePMStockQuantity(productId){
        this._operationService.put(url.mainUrl+"product/UpdatePMStockQuantity/?productId="+productId,{}).subscribe(
            data=>{

            },error=>{

            }
        )
    }
    updateModerationStatus(cartGUID,statusCode){

        this._operationService.put(url.mainUrl+"cart/UpdateModerationStatus?CartGuid="+cartGUID+"&StastusCode="+statusCode,{}).subscribe(
            data=>{
                this.getOrderListByReference(this.objOrder,this.sIndex);
            },error=>{

            }
        )
    }
}
