import { Injectable } from '@angular/core';
import { OrderDetails,Inscription } from '../models/ordermodel';
import { UploadImage,DocumentProperties } from '../models/documentMapping.model';
import { TilesOrder,TilesModel } from '../models/tiles.model';
@Injectable()
export class BuildDmsJSONService {

    public objUploadImage:UploadImage=new UploadImage();
    public objDocumentProperties:DocumentProperties = new DocumentProperties();
    
    buildImageJSON(boolAddInscription,productId,IsParent,IsReplica,parentProductId,orderAction,productCost,productGuid,quantity,productType,productName,inscriptionData?,documentData?,inscriptionLineCount?):OrderDetails{
        
        var objOrderDetails = new OrderDetails();
        objOrderDetails.ProductId = productId;
        objOrderDetails.ProductName = productName;
        objOrderDetails.IsCustomerImg = true;
        objOrderDetails.IsParent = IsParent;
        objOrderDetails.IsReplica = IsReplica;
        objOrderDetails.ParentProductId = parentProductId;
        objOrderDetails.OrderAction = orderAction;
        objOrderDetails.ProductCost = productCost;
        objOrderDetails.ProductGuid = productGuid;
        objOrderDetails.ProductVarianceId = null;
        objOrderDetails.Quantity = quantity;
        objOrderDetails.ProductType = productType;
        if( boolAddInscription ){
            objOrderDetails.UploadFileData = null;
            if( inscriptionData ){

                objOrderDetails.inscriptionDetail = inscriptionData;
            } else {
                // inscriptionLineCount
                for( let i=0;i<inscriptionLineCount;i++  ){

                    objOrderDetails.inscriptionDetail.push(new Inscription().deserialize({Id:0,OrderId:0,InscriptionLine:"",DisplayOrder:i+1}));
                }
            }
        } else {
            objOrderDetails.inscriptionDetail = null;
            var dmsImageJSON:any = this.buildDMSImageUploadJSON(documentData.docId,documentData.boolIsNewEntry,documentData.boolIsNewVersion,documentData.strbase64,documentData.strFileName,documentData.user.Id,documentData.guId,documentData.documentTypeCode,documentData.strFolderCode,documentData.objectId,documentData.objectType);
            objOrderDetails.UploadFileData.Document = dmsImageJSON.Document;
            objOrderDetails.UploadFileData.DocumentProperties = dmsImageJSON.DocumentProperties;
        }

        return objOrderDetails;
    }
    buildDMSImageUploadJSON(docId,isNewEntry,isNewVersion,base64textString,strFileName,userId,objectGUID,documentTypeCode,strFolderCode,objectId,ObjectType):any{

        this.objUploadImage.File = base64textString?base64textString:"";
        this.objUploadImage.FileFormat = "";
        this.objUploadImage.FileName = strFileName;
        this.objUploadImage.FilePath = "";
        this.objUploadImage.CreatedBy = isNewEntry ? userId:null;
        this.objUploadImage.CreatedOn = isNewEntry ? new Date():null;
        this.objUploadImage.ModifiedOn = isNewEntry ? null:new Date();
        this.objUploadImage.ModifiedBy = isNewEntry ? null:userId;
        this.objUploadImage.FolderCode = objectGUID+"_"+strFolderCode;
        this.objUploadImage.DocumentTypeCode = documentTypeCode;
        this.objUploadImage.Id = docId;
        this.objUploadImage.IsNewEntry = isNewEntry;
        this.objUploadImage.IsNewVersion = isNewVersion;
        this.objUploadImage.IsOverride = !isNewEntry;

        this.objDocumentProperties.DocumentType = documentTypeCode;
        this.objDocumentProperties.ObjectId = objectId;
        this.objDocumentProperties.IsDefault = true;
        this.objDocumentProperties.ObjectType = ObjectType;

        return {DocumentProperties:this.objDocumentProperties,Document:this.objUploadImage};
    }

    buildTilesOrderJSON(cartId,productId,parentProductId,productGuid,productName,productType,projectId,userId,currencyId,OrganizationId,price,action,inscriptionData,inscriptionLineCount,quantity,cartType,isGuestUser,isAccepted,isParent,isReplica){
        var objTilesOrder:TilesModel = new TilesModel();

        objTilesOrder.Notes = ""
        objTilesOrder.IsGuest = isGuestUser;
        objTilesOrder.CartId = cartId;
        objTilesOrder.CartType = cartType;
        objTilesOrder.ProjectId = projectId;
        objTilesOrder.CreatedBy = userId;
        objTilesOrder.CreatedOn = new Date();
        objTilesOrder.CustomerId = userId;
        objTilesOrder.CurrencyId = currencyId;
        objTilesOrder.IsAccepted = isAccepted;
        objTilesOrder.OrganizationId = OrganizationId;
        // this.buildImageJSON(boolAddInscription,productId,IsParent,IsReplica,parentProductId,orderAction,productCost,productGuid,quantity,productType,productName,inscriptionData?,documentData?,inscriptionLineCount?)
        objTilesOrder.Orders.push(this.buildImageJSON(true,productId,isParent,isReplica,parentProductId,action,price,productGuid,quantity,productType,productName,inscriptionData,null,inscriptionLineCount));
        return objTilesOrder;
    }
}