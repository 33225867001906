import { Component, OnInit,ViewChild,ElementRef,OnDestroy,HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { NgForm,FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { error } from 'util';
import { operationService } from '../../_services/operation.service';
import { DataSharedService } from '../../_services/dataSharedService';
import * as url from '../../../environments/environment';
import { loginService } from '../../_services/login.service';
import { Observable ,  concat ,  Subscription } from 'rxjs';
import { DomSanitizer,SafeUrl } from '@angular/platform-browser';
import { forEach } from '@angular/router/src/utils/collection';
import { DatePipe } from '@angular/common';
import { ProductModel,DependentProduct } from '../../models/productmodel';
import { ProductVariances } from '../../models/productmodel';
import { Order,Inscription } from '../../models/ordermodel';
import { OrderDetails,PhotoMosaicOrder } from '../../models/ordermodel';
import { CartModel } from '../../models/cart.model';
import { UserSignUpModel,UserTokenModal } from '../../models/user.model';
import { AddressModel } from '../../models/addressmodel';
import { ProductTypeEnum } from '../../models/producttypeenum';
import { CartTypeEnum,PaymentMethodEnum } from '../../models/project.enum';
/// <reference path="../../toastr.d.ts" />
import { PaymentModel } from '../../models/payment.model';
import { DocumentTypeCodeEnum,ObjectTypeEnum,FolderCodeEnum } from '../../models/documenttypecode.enum';
import { BuildDmsJSONService } from '../../_services/builddmsjsonservice';
import { ComponentintractService } from '../../_services/conponentinteraction.service';
import { ProjectAdditionalModel } from '../../models/project.model';
import { ProjectTranslationModel } from '../../models/projecttranslationdetail.model';
import { CustomerModel } from '../../models/user.model';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-gift-voucher',
    templateUrl: './gift-voucher.component.html',
    providers: [operationService,loginService, DatePipe,BuildDmsJSONService]
})
export class GiftVoucherComponent implements OnInit,OnDestroy  {

    public projectId:any;
    public arrobjVoucher:Array<ProductModel> = new Array<ProductModel>();
    public arrobjcopyVoucher:Array<ProductModel> = new Array<ProductModel>();
    public objVoucher:ProductModel = new ProductModel();
    public objOrderDetails:OrderDetails = new OrderDetails();
    public arrobjOrderDetails:Array<OrderDetails> = new Array<OrderDetails>();
    public objCartDetails:CartModel=new CartModel();
    public confirmEmail:any="";
    public objUser:any={};
    public subTotalChildOrders:any=0;
    public orderDeliveryCost:any=0;
    public productId:any=0;
    public objPhotoMosaicOrder:PhotoMosaicOrder=new PhotoMosaicOrder();
    public objUserSignUpModel:UserSignUpModel=new UserSignUpModel();
    public paymentSuccessUrl:any;
    public paymentCancel:any;
    public routeUrl: string = ""
    public objPaymentModel:PaymentModel = new PaymentModel();
    public ProductGuid:any;
    public parentProductId:any;
    public InscriptionCharactersPerLine:any;
    public arrobjSelectedAddressModel:Array<AddressModel> = new Array<AddressModel>();
    public showAddressList:boolean=false;
    public strsiteKey:any;
    public PaymentMethodEnum : typeof PaymentMethodEnum = PaymentMethodEnum;
    public objProjectTranslationModel:ProjectTranslationModel=new ProjectTranslationModel();
    myValueSub: Subscription;

    @ViewChild('paymentForm') public paymentForm:ElementRef;
    public boolIsGuestUser:boolean=true;
    public objCustomerModel:CustomerModel = new CustomerModel();
    public boolShowPaymentForm:boolean=false;
    public boolSubmit:boolean=false;
    public DocumentTypeCodeEnum : typeof DocumentTypeCodeEnum = DocumentTypeCodeEnum;
    public ObjectTypeEnum : typeof ObjectTypeEnum = ObjectTypeEnum;
    public activeLanguageId:any;
    public userConsent:boolean;
    public LanguageCulture = sessionStorage.getItem("PGLanguageCulture");
    public angularForm:FormGroup = new FormGroup ({
            version: new FormControl(),
            stprofile: new FormControl(),
            mainamount: new FormControl(),
            billingtown: new FormControl(),
            customertown: new FormControl(),
            sitereference: new FormControl(),
            currencyiso3a: new FormControl(),
            billingstreet: new FormControl(),
            billingcounty: new FormControl(),
            orderreference: new FormControl(),
            customerstreet: new FormControl(),
            ruleidentifier: new FormControl(),
            billingpremise: new FormControl(),
            customercounty: new FormControl(),
            billingpostcode: new FormControl(),
            billingfirstname: new FormControl(),
            billinglastname: new FormControl(),
            billingtelephone: new FormControl(),
            customerpostcode: new FormControl(),
            customerfirstname: new FormControl(),
            customerlastname: new FormControl(),
            billingcountryiso2a: new FormControl(),
            customertelephone: new FormControl(),
            declinedurlredirect: new FormControl(),
            billingtelephonetype: new FormControl(),
            customertelephonetype: new FormControl(),
            successfulurlredirect: new FormControl(),
            userConsent: new FormControl(),
            locale: new FormControl()
    });
    constructor(private objFormBuilder: FormBuilder,private objDeviceDetectorService:DeviceDetectorService,private objComponentintractService:ComponentintractService,private objBuildDmsJSONService:BuildDmsJSONService,private sanitizer: DomSanitizer,private _router: Router, private route: ActivatedRoute, private objOperationService: operationService, private _DataSharedService: DataSharedService, private _loginService: loginService, private datePipe: DatePipe) {}
    ngOnInit() {

        this.projectId = this.route.snapshot.params["projectId"];
        var arrUrlDetails = window.location.href.split('#');

        this.routeUrl = arrUrlDetails[1].split("/")[1];
        this.strsiteKey = url.sitekey;
        this.paymentSuccessUrl = arrUrlDetails[0]+"#/"+this.routeUrl+"/success";
        this.paymentCancel = arrUrlDetails[0]+"#/"+this.routeUrl+"/cancel";

        this.languageCode = sessionStorage.getItem("languageCode");

        this.authType = atob(sessionStorage.getItem("AuthType"));
        this.boolIsGuestUser = true;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                this.boolIsGuestUser = false;
            }
        }
        this.activeLanguageId = sessionStorage.getItem("activeLanguageId")
        this.getAllProduct();
        this.getCartContents();
        this.getProjectTranslationDetail("ONLOAD",this.ObjectTypeEnum.PROJECT,sessionStorage.getItem("activeLanguageId"));
        this.paymentGatewayList();
        this.getProjectVoucherImage();
        this.getVoucherStockAvailability();
        this.getResourceJSON(url.languageJsonUrl+sessionStorage.getItem("activeLanguage").toLowerCase()+'.json');
    }
    ngOnDestroy(){
        this.myValueSub;
    }

    getUserConsentVal(){
        let userconsnt:string; 
        if(this.userConsent != undefined ){
         userconsnt = (this.userConsent)?'YES':'NO'
        };
        
        return userconsnt;
     };
  
    getAllProduct() {
        this.arrobjVoucher = new Array<ProductModel>();
        var currentDate = new Date();
        this.myValueSub = this.objOperationService.get(url.bricsAPI+'Product/?projectId='+this.projectId+'&documentType=PRODUCT_IMAGE&imageType=THUMBNAIL').subscribe(
            data => {
                this.showAddressList = true;
                data.sort(function(a, b){return a.ProductCost - b.ProductCost});
                this.arrobjcopyVoucher = data.filter(item => item.ProductTypeCode == "VOU" && item.Published);
                this.arrobjcopyVoucher.forEach((value,index)=>{
                    if( value.StockQuantity != "Unlimited" ){
                        let voucherEndDate = new Date(value.AvailableEndDateTime);
                        let voucherStartDate = new Date(value.AvailableStartDateTime);
                        if( (Number(value.StockQuantity) >= value.SoldQuantity + value.OrderMinimumQuantity) && voucherEndDate >= currentDate && voucherStartDate <= currentDate){
                            this.arrobjVoucher.push(value);
                        }
                    } else {
                        this.arrobjVoucher.push(value);
                    }
                })
            },error => {

            }
        )
    }
    getProductDetails(){
        this.objOperationService.get(url.mainUrl + 'Product/?ProductGuid=' + this.ProductGuid+"&CurrentCurrencyId="+sessionStorage.getItem("currencyId")).subscribe(
            data=>{
                this.objVoucher = data;
                this.InscriptionCharactersPerLine = this.objVoucher.InscriptionCharactersPerLine;
                this.parentProductId = this.objVoucher.ProductId;
                this.productId = this.objVoucher.ProductId;
                if( this.arrobjOrderDetails.length > 0 ){

                    this.arrobjOrderDetails.forEach((voucher,index)=>{
                        if( voucher.ProductId == this.objVoucher.ProductId ){
                           this.objVoucher.SoldQuantity = this.objVoucher.SoldQuantity + voucher.Quantity;     
                        }
                    });
                }
            },error=>{

            }
        )
    }
    selectVoucher(objVoucher:ProductModel){
        this.objVoucher = new ProductModel();
        this.objVoucher = objVoucher;
        this.objVoucher.orderQuantity = 0;
        this.ProductGuid = objVoucher.ProductGuid;
        this.getProductDetails();
    }
    createCart(){
        if( !this.boolIsStockAvailble ){
            toastr.warning("Out of stock!");
            return false;
        }
        this.addOrderTocart();
    }

    /*
     * If there is no data in cart "CartId == 0",
     * call this API to create cart for first time  
     */
    placeOrder(){
        let isGuestUser:boolean = false;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) && JSON.parse(sessionStorage.getItem("IsGuestUser")) ){
                isGuestUser = true;
            } else {
                isGuestUser = false;
            }
        } else {
            isGuestUser = true;
        }

        this.objPhotoMosaicOrder = new PhotoMosaicOrder();
        this.objPhotoMosaicOrder.Notes = JSON.stringify(this.objDeviceDetectorService.getDeviceInfo());
        this.objComponentintractService.setComponentdata({boolLaod:true});
        this.objPhotoMosaicOrder.CreatedBy = sessionStorage.getItem("UserId")?sessionStorage.getItem("UserId"):0;
        this.objPhotoMosaicOrder.CustomerId = sessionStorage.getItem("UserId")?sessionStorage.getItem("UserId"):0;
        this.objPhotoMosaicOrder.CurrencyId = Number(sessionStorage.getItem("currencyId"));
        this.objPhotoMosaicOrder.CartType = "VOUCHER_CART";
        this.objPhotoMosaicOrder.CreatedOn = new Date();
        this.objPhotoMosaicOrder.IsAccepted = this.objCartDetails.IsAccepted;
        this.objPhotoMosaicOrder.OrganizationId = Number(sessionStorage.getItem("OrganisationId"));
        this.objPhotoMosaicOrder.ProjectId = Number(sessionStorage.getItem("projectId"));
        this.objPhotoMosaicOrder.IsGuest = isGuestUser;

        var uploadListImages:OrderDetails = this.objBuildDmsJSONService.buildImageJSON(true,this.objVoucher.ProductId,false,false,0,'New',this.objVoucher.Price,this.objVoucher.ProductGuid,this.objVoucher.orderQuantity,this.objVoucher.ProductType,this.objVoucher.Name,null,null,this.objVoucher.InstcriptionLineCount);
        this.objPhotoMosaicOrder.uploadListImages.push(uploadListImages);
        this.objOperationService.post(url.mainUrl+"Cart/CreateCart",this.objPhotoMosaicOrder).subscribe(
            data=>{
                this.objVoucher.orderQuantity = 0;
                toastr.success(this.objResourceJSON.Briconomics.VoucherWizard.Success.VoucherAddToCart);
                this.getCartContents();
                this.objVoucher = new ProductModel();
                sessionStorage.setItem("isCartAvailable","true");
                this.objComponentintractService.setComponentdata({boolLaod:false});
                if( this.boolIsGuestUser ){

                    this.setSessionData({IsGuestUser:true,});
                }
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }

    addOrderTocart(){
        this.showAddressList = false;
        if( this.objCartDetails.Id == undefined || this.objCartDetails.Id < 0 ){
            this.placeOrder();
        } else {
            this.objComponentintractService.setComponentdata({boolLaod:true});
            var objOrderDetails:OrderDetails;
            objOrderDetails = this.objBuildDmsJSONService.buildImageJSON(true,this.objVoucher.ProductId,false,true,0,'New',this.objVoucher.Price,this.objVoucher.ProductGuid,this.objVoucher.orderQuantity,this.objVoucher.ProductType,this.objVoucher.Name,null,null,this.objVoucher.InstcriptionLineCount);
            objOrderDetails.CartId = this.objCartDetails.Id;
            objOrderDetails.IsGuest = this.boolIsGuestUser;
            objOrderDetails.customerId = this.boolIsGuestUser ? 0:sessionStorage.getItem("UserId");
            this.objOperationService.post(url.mainUrl+"Order/PostOrder",objOrderDetails).subscribe(
                data=>{
                    objOrderDetails.OrderId = Number(data.ResponseData)
                    this.objVoucher.orderQuantity = 0;
                    toastr.success(this.objResourceJSON.Briconomics.VoucherWizard.Success.VoucherAddToCart);
                    this.getCartContents();
                    this.objVoucher = new ProductModel();
                    this.objVoucher.orderQuantity =0;
                },error=>{
                    this.objComponentintractService.setComponentdata({boolLaod:false});
                    this.objVoucher = new ProductModel();
                    this.objVoucher.orderQuantity=0;
                    toastr.error("Error");
                }
            )
        }
    }
    public productorderIndex:any;
    hideSuccessMessage(){
        this.productorderIndex = null;
    }
    updateInscription($event,objInscriptionLine:Inscription,productorderIndex){
        
        objInscriptionLine.InscriptionLine = objInscriptionLine.InscriptionLine.trim();
        this.boolIsInscriptionAvailable = true;
        this.objOperationService.put(url.mainUrl+"Order/UpdateOrderInscription?IsGuest="+this.boolIsGuestUser,objInscriptionLine).subscribe(
            data=>{
                this.productorderIndex = productorderIndex;
            },error=>{
                var objError = JSON.parse(error._body);
                if(objError.Type == "InvalidRequest"){

                    toastr.error(objError.Message)
                }
            }
        )
    }
 
    increaseProductQuantity(){
        var availableQty = Number(this.objVoucher.StockQuantity) - this.objVoucher.SoldQuantity;
        if( this.objVoucher.StockQuantity == "Unlimited" ){

            this.objVoucher.orderQuantity +=1;
        } else {
            if( availableQty == 0 ){
                toastr.warning(this.objResourceJSON.Briconomics.VoucherWizard.Warning.OutofStock);

                return false;
            }
            if( availableQty > this.objVoucher.orderQuantity ){
                this.objVoucher.orderQuantity +=1;
            } else {
                toastr.warning(this.objResourceJSON.Briconomics.VoucherWizard.Warning.ExceedWarning+availableQty+this.objResourceJSON.Briconomics.VoucherWizard.Items);
            }
        }
    }
    decreaseProductQuantity() {

        if( this.objVoucher.orderQuantity > 0 ){
            this.objVoucher.orderQuantity -=1;
        }
    }
    /* Login Guest user*/
    generateUserLoginToken(UserName,Password,isCheckOut,isValidationRequired,userType){
        this.objComponentintractService.setComponentdata({boolLaod:true});
        let tokenData = "UserName="+UserName+"&Password="+Password+"&grant_type=password&scope="+"BRICKS "+sessionStorage.getItem("SessionId")+' Customer '+isValidationRequired;
        this._loginService.getToken(url.loginUrl + 'token', tokenData).subscribe(
            data=>{
                if( userType != "IsGuestUser"){

                    sessionStorage.setItem("isLogedin",JSON.stringify(true));
                }
                sessionStorage.setItem("access_token", data.access_token);
                this.getUserDetails(isCheckOut);
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    getUserDetails(isCheckOut:boolean){
        this._loginService.getLoginData(url.SSOAPI+'Login/GetUserDetail').subscribe(
            data=>{
                this.objUser = data;
                this.setSessionData(data);
                if( isCheckOut ){
                    
                    this.transferGuestToCustomerCart(this.objUser);
                } else {
                    this.objComponentintractService.setComponentdata({boolLaod:false});
                    this.addOrderTocart();
                }
            },error=>{

            }
        )
    }
    setSessionData(data):boolean{

        this.boolIsGuestUser = data.IsGuestUser;
        
        sessionStorage.setItem("UserId", data.Id ? data.Id : "");
        localStorage.setItem("UserId", data.Id ? btoa(data.Id) : "");

        sessionStorage.setItem("isLogedin", "true");
        localStorage.setItem("isLogedin", btoa("true"));

        sessionStorage.setItem("CurentCompanyCode", data.CurCompanyCode ? data.CurCompanyCode:"");
        localStorage.setItem("CurentCompanyCode",data.CurCompanyCode ? btoa(data.CurCompanyCode) : "");

        sessionStorage.setItem("IsGuestUser", data.IsGuestUser);
        localStorage.setItem("IsGuestUser", btoa(data.IsGuestUser));

        sessionStorage.setItem("IsAdmin", data.IsAdmin ? data.IsAdmin : "");
        localStorage.setItem("IsAdmin", data.IsAdmin ? btoa(data.IsAdmin) : "");

        
        if( !data.IsGuestUser ){

            sessionStorage.setItem("Email", data.Email ? data.Email:"");
            localStorage.setItem("Email", data.Email ? btoa(data.Email) :"");

            sessionStorage.setItem("FirstName", data.FirstName ? data.FirstName : data.FirstName);
            localStorage.setItem("FirstName", data.FirstName ? btoa(data.FirstName) : data.FirstName);

            sessionStorage.setItem("LastName", data.LastName != null? data.LastName :'');
            localStorage.setItem("LastName", btoa(data.LastName != null? data.LastName :''));
        }
        return true;
    }

    public customerId:any=0;
    getCartContents(){
        this.customerId = 0;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                this.customerId = sessionStorage.getItem("UserId");
            }
        }
        this.subTotalChildOrders = 0;
        this.objCartDetails = new CartModel();
        this.objOperationService.get(url.mainUrl+"Cart/GetCartContents/?projectId="+this.projectId+"&customerId="+this.customerId).subscribe(
            data=>{  
                this.showAddressList = true;
                this.objComponentintractService.setComponentdata({boolLaod:false});  
                this.objCartDetails = data;
                if(this.objCartDetails.Orders.length > 0){

                    sessionStorage.setItem("isCartAvailable","true");
                } else {
                    sessionStorage.setItem("isCartAvailable","false");
                }
                sessionStorage.setItem("cartType",this.objCartDetails.Type)
                sessionStorage.setItem("orderCount",JSON.stringify(this.objCartDetails.Orders.length));
                
                if( this.objCartDetails.Id == null || this.objCartDetails.Id == 0 ){
                    this.objCartDetails = new CartModel();
                    this.arrobjOrderDetails = new Array<OrderDetails>();
                    return false;    
                }
                this.arrobjSelectedAddressModel = new Array<AddressModel>();
                if( this.objCartDetails.billingAddress != null){
                    this.objCartDetails.billingAddress.Action = "edit";
                    this.arrobjSelectedAddressModel.push(Object.assign(new AddressModel(),this.objCartDetails.billingAddress))
                }
                if(  this.objCartDetails.shippingAddress != null ){

                    // this.arrobjSelectedAddressModel.push(this.objCartDetails.shippingAddress)
                }
                
                sessionStorage.setItem("cartReference",this.objCartDetails.Id);
                localStorage.setItem("cartReference",btoa(this.objCartDetails.Id));
                this.preBuildOrderJSON();
                this.buildPaymentForm(this.objCartDetails,this.objPaymentModel);
                this.boolShowPaymentForm = true;
            },error=>{
                this.showAddressList = true;
                sessionStorage.setItem("isCartAvailable","false");
                sessionStorage.setItem("orderCount","0");
                this.objComponentintractService.setComponentdata({boolLaod:false});
                sessionStorage.setItem("isCartAvailable","false"); 
                this.objCartDetails=new CartModel();
                this.arrobjOrderDetails = new Array<OrderDetails>();
                this.boolShowPaymentForm = true;
            }
        )
    }
    buildPaymentForm(paymentData,objPaymentModel) {

        if(paymentData.shippingAddress == null){
            paymentData.shippingAddress = Object.assign({},new AddressModel)
        }
        try{
            this.angularForm = this.objFormBuilder.group({
                sitereference:objPaymentModel.Sitereference,
                stprofile:"default",
                currencyiso3a:this.getCurrentCurrency(),
                mainamount:paymentData.cartTotalAmount,
                version:2,
                orderreference:paymentData.CartGuid,
                billingfirstname:paymentData.customerDetails.FirstName, 
                billinglastname:paymentData.customerDetails.FirstName, 
                billingstreet:paymentData.billingAddress.Address1,
                billingpremise:"",
                billingtown:paymentData.billingAddress.City,
                billingcounty:paymentData.billingAddress.StateProvinceModel,
                billingpostcode:paymentData.billingAddress.ZipPostalCode,
                billingtelephone:paymentData.billingAddress.PhoneNumber,
                billingtelephonetype:"W",
                customerfirstname:paymentData.customerDetails.FirstName,
                customerlastname:paymentData.customerDetails.LastName,
                billingcountryiso2a:paymentData.billingAddress.countryModel.Code,
                customerstreet:paymentData.shippingAddress.Address1,
                customertown:paymentData.shippingAddress.City,
                customercounty:paymentData.shippingAddress.StateProvinceModel,
                customerpostcode:paymentData.shippingAddress.ZipPostalCode,
                customertelephone:paymentData.shippingAddress.PhoneNumber,
                customertelephonetype:"H",
                declinedurlredirect:this.paymentCancel,
                successfulurlredirect:this.paymentSuccessUrl, 
                ruleidentifier:"STR-6"
            });
        } catch {}
    }
    preBuildOrderJSON(){

        this.subTotalChildOrders=0;
        this.boolIsInscriptionAvailable = true;
        this.arrobjOrderDetails = new Array<OrderDetails>();
        this.orderDeliveryCost=0;
        this.objCartDetails.Orders.forEach((value,index)=>{
            this.orderDeliveryCost = this.orderDeliveryCost + value.OrderDeliveryInclTax; 
            if( !value.isParentOrder ){

                this.subTotalChildOrders = this.subTotalChildOrders + value.PriceInclTax - value.DiscountAmountInclTax;
            }
            let objOrderDetails:OrderDetails = new OrderDetails();
            objOrderDetails.ProductType
            objOrderDetails = this.objBuildDmsJSONService.buildImageJSON(true,value.ProductId,false,true,this.productId,"New",(value.PriceInclTax - value.DiscountAmountInclTax),value.productDetails.ProductCode,value.Quantity,value.ItemType.Id,value.productDetails.ProductName,value.inscriptions);
            objOrderDetails.OrderId = value.Id;
            objOrderDetails.OrderDeliveryInclTax = value.OrderDeliveryInclTax;
            objOrderDetails.inscriptionCharactersPerLine = value.productDetails.InscriptionCharecterCount;
            objOrderDetails.OrderDeliveryExcTax = value.OrderDeliveryExcTax;
            this.arrobjOrderDetails.push(objOrderDetails);
        }); 
    }
    public authType:any;
    userSignUpLogin(target?){
        this.objComponentintractService.setComponentdata({boolLaod:true});
        let el = document.getElementById(target);
        el.scrollIntoView();
        
        this.objUserSignUpModel.Password = this.makeid(5);
        this.objUserSignUpModel.CreatedOn = new Date();
        this.objUserSignUpModel.IsVerificationRequired = false;
        this.objOperationService.post(url.umsUrl+"Admin/Customer/AddCustomerDetails",this.objUserSignUpModel).subscribe(
            data=>{
                this.getCustomerProfile(data.ResponseData,true);
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
                if(error.json().ErrorCode == "DUPLICATE"){
                    if( this.authType == "SSO" ){

                        this.generateUserLoginToken(this.objUserSignUpModel.Email,this.objUserSignUpModel.Password,true,false,"User");
                    }
                }
                var objError = JSON.parse(error._body);
                if(objError.Type == "InvalidRequest"){

                    toastr.error(objError.Message)
                }
            }
        )
    }
    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    transferGuestToCustomerCart(objUser){
        
        this.objOperationService.get(url.mainUrl+"cart/TransferGuestToCustomerCart/?customerId="+objUser.Id+"&projectId="+this.projectId+"&guestCartId="+this.objCartDetails.Id+"&cartReferenceNumber="+this.objCartDetails.CartReferenceNumber+"&PaymentMethod="+PaymentMethodEnum.OnlinePayment).subscribe(
            data=>{
                this.getCartContents();
            },error=>{
                this.getCartContents();
            }
        )
    }
    stockAvalibalityCheck(){
        $("#logdelete").modal("hide");
        this.objComponentintractService.setComponentdata({boolLaod:true});
        this.objOperationService.get(url.bricsAPI + "Product/StockAvailability/?cartId=" + this.objCartDetails.Id).subscribe(
            data=>{
                if( data.StockAvailabilityStatus =="Pass"){
                    this.updatePaymentStatus(this.objCartDetails.CartGuid,this.angularForm.getRawValue());
                } else {

                    this.objComponentintractService.setComponentdata({boolLaod:false});
                }
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    updatePaymentStatus(cartGuid,objForm){
        this.objOperationService.put(url.bricsAPI+"cart/UpdatePaymentStatus/?CartGuid="+cartGuid+'&userConsent='+this.getUserConsentVal(),objForm).subscribe(
            data=>{
                // this.payment();
                this.setTimer()
                this.paymentForm.nativeElement.submit();
            },error=>{
                toastr.error(error.json().Message);
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    updateZeroCartAfterPayment(){
        this.objOperationService.put(url.mainUrl+"Cart/UpdateZeroCartAfterPayment/?cartOrderReference="+this.objCartDetails.CartReferenceNumber+"&languageCode="+sessionStorage.getItem("DefaultLangauge"),{}).subscribe(
            data=>{
                this._router.navigate([this.routeUrl+'/success/'+this.objCartDetails.CartReferenceNumber]);
            },error=>{

            }
        )
    }

    proceedToPayment(){
        this.boolSubmit = false;
        $("#logdelete").modal("show");
    }
    public isPaymentDataChange:boolean=false;
    payment(){
        this.isPaymentDataChange = !this.isPaymentDataChange
        this.boolSubmit = true;
    } 
    paymentGatewayList() {

        this.objPaymentModel.Sitereference
        this.objOperationService.get(url.bricsAPI+"PaymentgatewayConfig/GetAllPaymentConfiguration/?OrganizationId=" + sessionStorage.getItem("OrganisationId")).subscribe(
            data => {
                this.objPaymentModel = data[0];
            },error=> {

            }
        )
    }

    emittOrderEvent($event){
        if( $event ){
            this.showAddressList=false;
            this.getCartContents();
        }
    }

    isUserLoggedIn(){
        var boolLoggedIn:boolean = false;
        if( sessionStorage.getItem("isLogedin") && JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
            boolLoggedIn = true;
        }
        return boolLoggedIn;
    }

    getLoggedInUserEmail(){

        var email:any="";
        if( sessionStorage.getItem("isLogedin") && JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){

            if( sessionStorage.getItem("Email") ){
                email = sessionStorage.getItem("Email");
            }           
        }    
        return email;
    }
    
    getCurrentCurrency(){
        var CurrencyCode:any;
        if( sessionStorage.getItem("CurrencyCode") ){
            CurrencyCode = sessionStorage.getItem("CurrencyCode");
        }
        return CurrencyCode;
    }
    getCurrentCurrencyIcon(){
        var strClass:any;
        if( sessionStorage.getItem("currencyIcon") ){
            strClass = sessionStorage.getItem("currencyIcon");
        }
        return strClass;
    }
    logOut(){
        this.objComponentintractService.setComponentdata({isLogOut:true})
    }
    public boolIsConfirmEmailEntered:boolean=false;
    matchEmail($event){
        this.boolIsConfirmEmailEntered = true;
    }
    public boolIsInscriptionAvailable:boolean=false;
    isInscriptionAvailable(objOrder:OrderDetails){
        var inscAvailFlag:boolean=false;
        objOrder.inscriptionDetail.forEach((value,index)=>{
            if( value.InscriptionLine.trim() != "" ){
                inscAvailFlag = true;
            }
        });
        if( this.boolIsInscriptionAvailable ){
            if( inscAvailFlag ){
                this.boolIsInscriptionAvailable = true;
            } else {

                this.boolIsInscriptionAvailable = false;
            }
        }
        return inscAvailFlag;
    }
    refreshCartData($event){
        this.getCartContents();
    }

    get getCartId(){
        return this.objCartDetails.Id;
    }

    get getSelectedAddress(){
        return this.arrobjSelectedAddressModel;
    }
    public objResourceJSON:any={};
    getResourceJSON(productURL){

        this.objOperationService.getLanguageJson(productURL).subscribe(
            data=>{
                this.objResourceJSON = data;
            }
        )
    }
    public objDeleteOrder:OrderDetails = new OrderDetails();
    public isDeleteChanges:boolean=false;
    deleteOrder(objOrder:OrderDetails,isDeleteChanges){
        this.showConfirmdelete=true;
        this.isDeleteChanges = isDeleteChanges;
        this.objDeleteOrder = objOrder;
    }
    confirmDeleteOrder($event){
        if( $event ){
            this.showAddressList = false;
            // this.objOperationService.delete(url.mainUrl+"Order/DeleteOrder/?OrderId="+this.objDeleteOrder.OrderId).subscribe(
            this.objOperationService.delete(url.mainUrl+"Order/DeleteOrder/?OrderId="+this.objDeleteOrder.OrderId+"&IsGuest="+this.boolIsGuestUser).subscribe(
                data=>{
                    toastr.success(this.objResourceJSON.Briconomics.VoucherWizard.Success.VoucherDeleteFromCart);
                    this.getCartContents();
                },error=>{

                }
            )
        }
    }
    get getDeleteModelName(){
        return "voucher-order";
    }
    public showConfirmdelete:boolean=false;
    get getMessage(){
        return this.objResourceJSON.Briconomics.VoucherWizard.OrderDeleteConfirmation;
    }
    
    get getResourceMapping(){
        return this.objResourceJSON;
    }
    get getCartType(){
        return this.objCartDetails.Type;
    }
    updateTCIsAccepted($event){
        var isGusetUser:boolean;
        var isAccepted:boolean;
        if( this.objCartDetails.Id != undefined && this.objCartDetails.Id != ""  ){

            isAccepted = this.objCartDetails.IsAccepted;
            if( sessionStorage.getItem("IsGuestUser")){
                isGusetUser = JSON.parse(sessionStorage.getItem("IsGuestUser")) 
            }
            this.objOperationService.put(url.mainUrl+"Cart/UpdateIsAccepted/?IsAccepted="+isAccepted+"&CartId="+this.objCartDetails.Id+"&IsGuest="+isGusetUser,{}).subscribe(
                data=>{
                    
                },error=>{

                }
            )
        }
    }
    get getEmailPattern(){

        const regex = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/;
        var objEmailRegExp = new RegExp(regex, "i");
        return objEmailRegExp;
    }
    public termsCondAccessability:any;
    public objProjectAdditionalModel:ProjectAdditionalModel=new ProjectAdditionalModel();
    public boolIsRecaptchaSubmitted:boolean=false;
    confirmCaptcha($event){
        this.boolIsRecaptchaSubmitted = $event;
    }
    get getsitekey(){
        return this.strsiteKey;
    }
    public boolIsStockAvailble:boolean;
    getVoucherStockAvailability(){

        this.boolIsStockAvailble = true;
        this.objOperationService.get(url.mainUrl+"Product/VoucherStockAvailability/?projectId="+this.projectId).subscribe(
            data=>{
                if( data.StockAvailabilityStatus == "Fail" || data.StockAvailabilityStatus == "OutOfStock" ){

                    this.boolIsStockAvailble = false
                }
            },error=>{

            }
        )
    }
    public languageCode:any;
     getDynamicContents(){

        this.objOperationService.get(url.mainUrl+"Project/GetProjectTranslationDetail?projectId="+this.projectId+"&languageCode="+this.languageCode).subscribe(
            data=>{
                this.objProjectTranslationModel = data;
            },error=>{

            }
        )
    }
    getCustomerProfile(customerId,isCheckOut){
        this.objOperationService.get(url.umsUrl+"Admin/Customer/GetCustomerProfileDetails/?customerid="+customerId).subscribe(
            data=>{
                this.showAddressList = true;
                this.objCustomerModel = data;
                this.objCustomerModel.Id = data.CustomerId
                this.objCustomerModel.IsGuestUser = false;
                this.objUser = this.objCustomerModel;
                this.setSessionData(this.objCustomerModel);
                this.transferGuestToCustomerCart(this.objCustomerModel);
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    get getCartDetails(){
        return this.objCartDetails; 
    }
    get firstNamePattern(){
        const regex = /^[a-zA-Z]*$/;
        var objEmailRegExp = new RegExp(regex);
        return objEmailRegExp;        
    }
    public objDependentProduct:DependentProduct = new DependentProduct();

    get defaultActiveLanguage(){
        return sessionStorage.getItem("DefaultLangauge");
    }
    getProductDescriptionByLanguage(){
        var localLanguageDescription:any={};
        if( this.objVoucher.Description == undefined ){
            return "";
        } else {

            localLanguageDescription = JSON.parse(this.objVoucher.Description);
            return localLanguageDescription[this.defaultActiveLanguage];
        }
    }
    getProductDetailPopUP(objOrder:OrderDetails){
        this.objVoucher = this.arrobjVoucher.filter(item=>item.ProductId == objOrder.ProductId)[0];
        $("#glass-momento-voucher").modal("show");
    }
    editinscription($event){
        let el = document.getElementById('targetstep2');
        el.scrollIntoView();
    }
    buildPaymentURLs(url,replaceString){
        var strUrl:any = url.replace("[baseurl]",replaceString);
        return strUrl;
    }
    getProjectTranslationDetail(strType,objectType,languageId){
        this.languageCode = sessionStorage.getItem("languageCode");
        this.objOperationService.get(url.mainUrl+"Project/GetProjectTranslationDetail/?projectId="+this.projectId+"&LanguageCode="+this.languageCode).subscribe(
            data=>{
                this.objProjectAdditionalModel = data;
                if( strType == "ONLOAD" ){
                    this.objProjectTranslationModel = data;
                } else {
                    $("#terms-condition").modal("show");
                    if( strType == 'tc' ){
                        this.termsCondAccessability = this.objProjectAdditionalModel.TermsCond; 
                    } else {
                        this.termsCondAccessability = this.objProjectAdditionalModel.AccessabilityTnC; 
                    }
                }
            },error=>{
                
            }
        )
    }
    getProjectTranslationDetail_OLD(strType){
        this.languageCode = sessionStorage.getItem("languageCode");
        this.objOperationService.get(url.mainUrl+"Project/GetProjectTranslationDetail/?ProjectId="+this.projectId+"&LanguageCode="+this.languageCode).subscribe(
            data=>{
                this.objProjectAdditionalModel = data.Translations;
                if( strType == "ONLOAD" ){
                    this.objProjectTranslationModel = data.Translations;
                } else {
                    $("#terms-condition").modal("show");
                    if( strType == 'tc' ){
                        this.termsCondAccessability = this.objProjectAdditionalModel.TermsCond; 
                    } else {
                        this.termsCondAccessability = this.objProjectAdditionalModel.AccessabilityTnC; 
                    }
                }
            },error=>{
                
            }
        )
    }
    
    get getCutomerId(){
        var customerId:any=this.objCartDetails.CustomerId;
        if( this.objCartDetails.CustomerId == null || this.objCartDetails.CustomerId == undefined || this.objCartDetails.CustomerId =="" ){
            customerId = sessionStorage.getItem("UserId");
        }
        return customerId;
    }

    mappAddressToCart($event){
        this.objOperationService.put(url.mainUrl+"cart/UpdateCartAddress/?cartId="+this.objCartDetails.Id+"&billingId="+$event.billingId+"&shippingId="+$event.shippingId,{}).subscribe(
            data=>{
                this.getCartContents();
            }
        )
    }
    /*Set the timer to hide the error message.*/

    public objTimer:any;
    setTimer(){

        // set showloader to true to show loading div on view
        this.objTimer     = Observable.timer(10000); // 10000 millisecond means 5 seconds
        var subscription = this.objTimer.subscribe(() => {
            this.objComponentintractService.setComponentdata({boolLaod:false});
        });
    }
    public objVoucherImage:any;
    getProjectVoucherImage(){
        var docPreGetData = {ObjectId:this.projectId,ObjectType:ObjectTypeEnum.PROJECT,IsDefault:true,DocumentType:this.DocumentTypeCodeEnum.VOUCHERIMAGE}

        // var documentProperties:DocumentProperties = new DocumentProperties();
        // documentProperties = this.objBuildDmsJSONService.getImageJSON(this.DocumentTypeCodeEnum.VOUCHERIMAGE,true,this.intProjectId,ObjectTypeEnum.PROJECT)
        this.objOperationService.post(url.mainUrl+"DMS/GetDocuments",docPreGetData).subscribe(
            data=>{
                this.objVoucherImage = data[0];
            },error=>{

                // this.objVoucherImage = new UploadImage();
            }
        )
    };

    get taxType(){
        try{
            return this.objCartDetails.TaxType.Value;
        } catch {
            return "UKTAX";
        }
    }
}
