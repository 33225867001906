import { Component, OnInit,Input,Output,EventEmitter,ViewChild,ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { operationService } from '../../_services/operation.service';
import { DataSharedService } from '../../_services/dataSharedService';
import * as url from '../../../environments/environment';
import { Observable } from 'rxjs';
import { forEach } from '@angular/router/src/utils/collection';
import { element } from 'protractor';
import { Console } from '@angular/core/src/console';
import { create } from 'domain';
import { WSAESTALE } from 'constants';
import { DatePipe } from '@angular/common';
import { ProductVariances } from '../../models/productmodel';
import { CartModel } from '../../models/cart.model';
import { ProductModel,DependentProduct } from '../../models/productmodel';
import { OrderDetails,PhotoMosaicOrder,Order,Inscription } from '../../models/ordermodel';
import { ProductTypeEnum,ProjectTypeEnum } from '../../models/producttypeenum';
import { ComponentintractService } from '../../_services/conponentinteraction.service';
import { AuthTypeEnum,CartTypeEnum } from '../../models/project.enum';
import { ItemModel,ItemCode } from '../../models/itemmodel';
declare var ImageTools: any;

@Component({
    selector: 'order-details',
    templateUrl: './order-details.html',
    providers: [operationService,DatePipe]
})
export class OrderDetailComponent implements OnInit{

    @Input("data") data:any;
    @Input("boolSetPMorder") boolSetPMorder:boolean=false;
    @Input("currecnyIcon") currecnyIcon:any="";
    @Input("objCartDetails") objCartDetails:CartModel = new CartModel();
    @Input("IsInscriptionEditable") IsInscriptionEditable:boolean = false;
    @Input("objProductDetails") objProductDetails:ProductModel = new ProductModel();
    @Input("boolHideFeilds") boolHideFeilds:boolean=true;
    @Input("boolEditInsc") boolEditInsc:boolean=false;
    @Input("boolAllowReUpload") boolAllowReUpload:boolean=false;
    @Output() emitData = new EventEmitter<Order>();
    @Output() emitFile = new EventEmitter<any>();
    @Output() imageBase64 = new EventEmitter<any>();
    @Output() emitEditIncription = new EventEmitter<boolean>();
    @Input("inscriptionCharecterCount") inscriptionCharecterCount:any;
    @Input("cropperWidth") cropperWidth:any;

    public arrobjOrder:Array<Order>;
    public subTotalChildOrders:any=0;
    public orderDeliveryCost:any=0;
    public boolIsInitated:boolean=false;
    public deleteMessage:any="";
    public deleteModelName:any="order";
    public boolIsConfirmChange:boolean=false;
    public objOrder:Order = new Order();
    public boolShowDeletePopUp:boolean=true;
    public boolIsGuestUser:boolean=true;
    public AuthTypeEnum : typeof AuthTypeEnum = AuthTypeEnum;
    public CartTypeEnum : typeof CartTypeEnum = CartTypeEnum;
    public ProjectTypeEnum : typeof ProjectTypeEnum = ProjectTypeEnum;
    public objResourceJSON: any = {};
    public routeUrl: string ="";
    constructor(private objComponentintractService:ComponentintractService,private _router: Router, private route: ActivatedRoute, private _operationService: operationService, private _DataSharedService: DataSharedService, public datePipe: DatePipe) {}
    ngOnInit() {
        this.boolIsGuestUser = true;
        var arrUrlDetails = window.location.href.split('/');
        this.routeUrl = arrUrlDetails[url.urlIndex];
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                this.boolIsGuestUser = false;
            }
        }
        this.getResourceJSON(url.languageJsonUrl+sessionStorage.getItem("activeLanguage").toLowerCase()+'.json');
        this.boolIsInitated = true;
        if(this.boolSetPMorder){
            this.arrobjOrder = new Array<Order>();
            this.setPMOrder();
        }
        if( this.boolAllowReUpload ) {
            this.getFileFormat();
        }
    }

    ngOnChanges(){
        if( this.boolIsInitated ){
            this.boolIsGuestUser = true;
            if( sessionStorage.getItem("IsGuestUser") ){
                if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                    this.boolIsGuestUser = false;
                }
            }
            this.arrobjOrder = new Array<Order>();
            this.setPMOrder();
            this.getResourceJSON(url.languageJsonUrl+sessionStorage.getItem("activeLanguage").toLowerCase()+'.json');
            if( this.boolAllowReUpload ) {
                this.getFileFormat();
            }
        }
    }

    /**
     *This method is to sort the Order Array with the PM Order first. 
     */
    setPMOrder(){
        if( this.objCartDetails.Orders.length > 0 ){

            if( this.objCartDetails.Type != "VOUCHER_CART" ){
                this.arrobjOrder.push(this.objCartDetails.Orders.filter(item=>item.isParentOrder)[0]); 
            }
            this.objCartDetails.Orders.filter(item=>item.isParentOrder == false).forEach((value,index)=>{
                if( this.arrobjOrder.length > 0 ){

                    let order = this.arrobjOrder.filter(item=>item.Id == value.Id);
                    if( order.length == 0 ){

                        this.arrobjOrder.push(value);
                    }
                } else {
                    this.arrobjOrder.push(value);
                }
            });
            this.arrobjOrder.forEach((value,index)=>{
                this.orderDeliveryCost = this.orderDeliveryCost+value.OrderDeliveryInclTax;
                if( !value.isParentOrder ){
                    this.subTotalChildOrders = this.subTotalChildOrders + (value.PriceInclTax - value.DiscountAmountInclTax);
                }
            });
        } else {
            
        }
    }
    ngOnDestroy(){
        this.objCartDetails=null;
        this.objProductDetails=null;
    }

    getResourceJSON(productURL){

        this._operationService.getLanguageJson(productURL).subscribe(
            data=>{
                this.objResourceJSON = data;
            }
        )
    }
    public clickedButton:any=""
    deleteOrder(objOrder:Order,boolIsConfirmChange,clickedButton){
        this.clickedButton = clickedButton;
        if(objOrder.ItemType.Id == ProductTypeEnum.PhotoMosaicProductType){

            this.deleteMessage = this.objResourceJSON.Briconomics.CommonDeleteModal.DeletAllOrderItems;
        } else {

            this.deleteMessage = this.objResourceJSON.Briconomics.CommonDeleteModal.DeletOrderItem;
        }
        this.objOrder = objOrder;
        this.deleteModelName = "order";
        this.boolIsConfirmChange = boolIsConfirmChange;
    }

    confirmDelete($event){
        if( $event ){
            if( this.deleteModelName == "order" ){

                this.confirmDeleteOrder();
            }
        }
    }

    confirmDeleteOrder(){
        
        this._operationService.delete(url.mainUrl+"Order/DeleteOrder/?OrderId="+this.objOrder.Id+"&IsGuest="+this.boolIsGuestUser).subscribe(
            data=>{
                if(this.objOrder.isParentOrder){
                    this._router.navigate([`${this.routeUrl}/order-history`]);
                }
                toastr.success("Success");
                this.emitData.emit(this.objOrder);
                this.objOrder = new Order();
                
            },error=>{

            }
        )
    }
    get getMessage(){
        return this.deleteMessage;
    }

    get isDeleteChanges(){
        return this.boolIsConfirmChange;
    }
    public boolEditInscription:boolean=false;
    editInscription(objOrder:Order,index,clickedButton){
        this.clickedButton = clickedButton;
        this.cancelEditInsc();
        this.objOrder = objOrder;
        this.boolEditInscription = true;
    }
    isEmptyInscription(objOrder){

        var isInscriptionAvailable:boolean=false;
        if( objOrder != null ){
            objOrder.inscriptions.forEach((value,index)=>{
                if( value.InscriptionLine.trim() != "" ){
                    isInscriptionAvailable = true;
                }
            });
        }
        return isInscriptionAvailable
    }
    updateInscription(objOrder:Order,orderIndex){
        
        this._operationService.put(url.mainUrl+"order/UpdateInscriptions",objOrder.inscriptions).subscribe(
            data=>{
                toastr.success("Success");
                this.cancelEditInsc(objOrder);
            },error=>{
                var objError = JSON.parse(error._body);
                if(objError.Type == "InvalidRequest"){

                    toastr.error(objError.Message)
                }
            }
        )
    }
    cancelEditInsc(objOrder?){
        this.objOrder = null;
        this.emitData.emit(objOrder);
    }
    showEdit(objOrder:Order,orderIndex){
        var isEdit:boolean=false;
        if(this.objOrder !=null && this.objOrder.Id == objOrder.Id && this.clickedButton != 'delete'){
            isEdit =true;
        }
        return isEdit;
    }
    setCurrentCurrency(){
        var strClass:any;
        if( this.currecnyIcon ){
            strClass = this.currecnyIcon;
        } else {

            if( sessionStorage.getItem("currencyIcon") ){
                strClass = sessionStorage.getItem("currencyIcon");
            }
        }
        return strClass;
    }
    downloadVoucher(objVoucher,objOrder:Order){
        this.objComponentintractService.setComponentdata({boolLaod:true});

        this._operationService.get(url.mainUrl+"Cart/DownloadCertificate/?voucherCode="+objVoucher.Value+"&orderId="+objOrder.Id+"&projectId="+this.objCartDetails.project.Id).subscribe(
            data=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
                window.open(data.ResponseData, '_blank');
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    public objDependentProduct:DependentProduct = new DependentProduct();
    public inscriptionOrder:Array<Inscription> = new Array<Inscription>();
    public appendedHtml:any;
    public productTemplateURL:any="";

    getInscriptedImage(order:Order){
        // this.inscriptionOrder = order.inscriptions;
        this.appendedHtml = ""; 
        this.productTemplateURL = order.ScreenTemplate;
        this.getTemplates(order.Id,this.objCartDetails.Id,this.objCartDetails.project.Value);
        $("#order-detail-kstemppreview").modal("show");
    }
    getTemplates(orderId,cartId,projectGUID){
        this._operationService.get(url.mainUrl+"cart/GetPreviewImage/?orderId="+orderId+"&cartId="+cartId+"&IsGuest="+this.boolIsGuestUser+"&ProjectGuid="+projectGUID).subscribe(
            data=>{
                this.appendedHtml = data.ResponseData;
            },error=>{

            }
        )
    }
    closeScreenTemplates(){
        $("#order-detail-kstemppreview").modal("hide");
        this.appendedHtml = "";
    }
    editParentInscription(){
        this.emitEditIncription.emit(true);
    }
    // inscription-error-mesaage

    /*Image Reupload By Piyush Pallaw*/
    public imageJson: any = {};
    public strBase64: any;
    public strSelectedImageBase64: any;
    public showCropImage: boolean=false;
    getCroppedPMImage($event){
        this.imageJson.FilePath = $event.dataUrl
        var data = $event.dataUrl.split(',');
        this.strBase64 = data[1];
        this.strSelectedImageBase64 = data[1];
        this.showCropImage = false;
    }

    get getMinHeightWidth(){
        return {minHeight:750,minWidth:1000}
        // return {minHeight:this.objProductDetails.PM_MinimumImageHeight,minWidth:this.objProductDetails.PM_MinimumImageWidth}
    }

    get getSettings(){
        return {width:4,height:3}
    }


    public intMaximumImageFileSize:number=0;
    public intMinImageFileSize: number = 0;
    public file: File;
    public strFileName:any;
    public size:any;
    public prveImgUrl:string;
    public selectedImageTrusted:any;
    @ViewChild('fileUpload') fileUpload:ElementRef;
    public filterFileFormat:string="";
    public arrobjFileFormate:Array<ItemModel> = new Array<ItemModel>();
    getFileFormat() {

        this._operationService.get(url.mainUrl + 'DMS/GetFileTypes').subscribe(
            data => {
                this.arrobjFileFormate = data;
                this.filterFileFormat = "";
                this.arrobjFileFormate.forEach((value,index)=>{
                    
                    if( this.filterFileFormat == "" ){
                        this.filterFileFormat = value.Value
                    } else {

                        this.filterFileFormat = this.filterFileFormat +','+ value.Value;
                    }
                })
            },error => {

            }
        )
    }
    handleFileSelect($event) {
        this.intMaximumImageFileSize = Number(sessionStorage.getItem("maxfilesize"));
        this.intMinImageFileSize = Number(sessionStorage.getItem("minimumFileSize"));
        this.file = $event.target.files[0];
        this.size = this.file.size;
        this.strFileName = this.file.name;
        let fileSize:number = this.intMaximumImageFileSize * 1048576;
        let minfileSize: number = this.intMinImageFileSize * 1048576;
        
        if( this.arrobjFileFormate.filter(item=>item.Value == this.file.type).length == 0 ){
            toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.WrongFileFormat);
            return false;
        }
        if( this.intMaximumImageFileSize != null && this.intMaximumImageFileSize != 0 ){
            
            if( fileSize < this.size ){
                toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.FileSizemessage + this.intMaximumImageFileSize + " MB")
                return false;
            }
        }
        if (this.intMinImageFileSize != null && this.intMinImageFileSize != 0) {

            if ( minfileSize > this.file.size) {
                let message = this.intMinImageFileSize >= 1? " MB":" KB";
                toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.MinFileSizemessage + (this.intMinImageFileSize >= 1? this.intMinImageFileSize:(minfileSize/1024))+message)
                return false;
            }
        }
        this.objComponentintractService.setComponentdata({boolLaod:false});
        this.getOrientation(this.file, (orientation) => {
            this.getBase64(this.file, orientation);
        });    
    }
     // Step 2#
    getOrientation(file, callback) {
        var reader:any,
        target:EventTarget;
        reader = new FileReader();
        reader.onload = (event) => {

          var view = new DataView(event.target.result);

          if (view.getUint16(0, false) != 0xFFD8) return callback(-2);

          var length = view.byteLength,
            offset = 2;

          while (offset < length) {
            var marker = view.getUint16(offset, false);
            offset += 2;

            if (marker == 0xFFE1) {
              if (view.getUint32(offset += 2, false) != 0x45786966) {
                return callback(-1);
              }
              var little = view.getUint16(offset += 6, false) == 0x4949;
              offset += view.getUint32(offset + 4, little);
              var tags = view.getUint16(offset, little);
              offset += 2;

              for (var i = 0; i < tags; i++)
                if (view.getUint16(offset + (i * 12), little) == 0x0112)
                  return callback(view.getUint16(offset + (i * 12) + 8, little));
            }
            else if ((marker & 0xFF00) != 0xFF00) break;
            else offset += view.getUint16(offset, false);
          }
          return callback(-1);
        };

        reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
    };

    // Step 3#
    getBase64(file, orientation) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var base64 = reader.result;
            this.resetOrientation(base64, orientation, (resetBase64Image) => {
              this.dataURItoBlob(resetBase64Image);
            });
          };
        reader.onerror = (error) => {
            
        };
    }

    // Step 4#
    resetOrientation(srcBase64, srcOrientation, callback) {
        var img = new Image();

        img.onload = () => {
          var width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");

          // set proper canvas dimensions before transform & export
          if (4 < srcOrientation && srcOrientation < 9) {
            canvas.width = height;
            canvas.height = width;
          } else {
            canvas.width = width;
            canvas.height = height;
          }

          // transform context before drawing image
          switch (srcOrientation) {
            case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
            case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
            case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
            case 7: ctx.transform(0, -1, -1, 0, height, width); break;
            case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
            default: break;
          }

          // draw image
          ctx.drawImage(img, 0, 0);

          // export base64
          callback(canvas.toDataURL());
        };

        img.src = srcBase64;
      }

      dataURItoBlob(dataURI) {
  
        // convert base64 to raw binary data held in a string
        var byteString = atob(dataURI.split(',')[1]);

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var bb = new Blob([ab], { type: "image/jpeg" });
        ImageTools.resize(bb, { }, ((blob, didItResize) => {
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = (() => {
            var base64data = reader.result;
            this.uploadImage(base64data);

            ImageTools.resize(bb, { width: 300, height: 300 }, ((blob, didItResize) => {
              var reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = (() => {
                var base64data = reader.result;
                this.uploadIssueThumbnail(base64data);
              });
            }));
          });
        }));
        return bb;
    }
    public width:any;
    public intPhotomosaicMinWidth:number;
    public intPhotomosaicMinHeight:number;
    public height:any;
    public imagePath: string = "./assets/images/no-image-available.jpg";
    public imageJsonCopy: any = {};
    uploadImage(base64data){
        this.prveImgUrl = base64data;
        this.selectedImageTrusted="";
        var boolWidth:boolean=true;
        this.showCropImage=true;
        var boolHeight:boolean=true;
        if( this.width < this.intPhotomosaicMinWidth ){
            boolWidth = false;
        }
        if( this.height < this.intPhotomosaicMinHeight ){
            boolHeight = false;
        }
        if( boolWidth == true && boolHeight == true ){
            var arrString =  base64data.split(",");
            this.strBase64 = arrString[1];
            this.strSelectedImageBase64 = arrString[1];
            this.imagePath = arrString[1];
            this.imageJson = Object.assign({},{ FileName: this.file.name, FileType: this.file.type, File: this.imagePath });
            this.imageJsonCopy = Object.assign({},{ FileName: this.file.name, FileType: this.file.type, File: this.imagePath });
        } else {
            toastr.error(this.objResourceJSON.Briconomics.alertMessage.ImageSizeLabel+this.intPhotomosaicMinHeight+"*"+this.intPhotomosaicMinWidth);
        }
        // $("#imageprocessing").modal("show");
        this.emitFile.emit(this.imageJson);
        this.imageBase64.emit(this.strBase64);
        this.objComponentintractService.setComponentdata({boolLaod:false});
    }
    uploadIssueThumbnail(base64){
       
    }
    get getcropperMinWidth(){
        return this.cropperWidth;
    }
    get getBase64Data(){
        var base64Data=""
        if( this.imageJsonCopy.File != undefined || this.imageJsonCopy.File == '' ){

            base64Data = this.imageJsonCopy.File;
        } else {
            base64Data = this.strBase64;
        }
        return base64Data;
    }

    get getImageUrl(){
        var imageUrl:any="";
        if( this.imageJsonCopy.File != undefined || this.imageJsonCopy.File == '' ){

            imageUrl = 'data:image/jpg;base64,'+this.imageJsonCopy.File;
        }
        return imageUrl
    }
}
