import { ItemCode } from './itemmodel';
import { ItemModel } from './itemmodel';
import { BaseModel } from './basemodel';
import { IconModel } from './icon.model';
import { ImagesModel } from './images.model';
import { ImageDocumentUpload } from './documentMapping.model';
export class ProductModel extends BaseModel{

	public ProductName:string="";
	public ProductDesc:string="";
	public IsParent:any;
	public OrderStatus:any;
	public Description:any;
	public InscriptionStatus:any;
	public inscriptionDetail:any=[];
	public ProductPictures:any=[]
	public ProductSpecificationAttributes:any[];
	public ProductAttributes:any[];
	public ProductVariances:any[];
	public RelatedProducts:any=null;
	public ProductControlId:number;
	public ProductId:number;
	public ProductGuid:string;
	public ProductType:number;
	public ParentGroupedProductId:number;
	public VendorId:string;
	public VisibleIndividually:boolean;
	public Name:any;
	public ShortDescription:any;
	public FullDescription:any;
	public AdminComment:any;
	public ProductTemplateId:number;
	public ShowOnHomePage:boolean;
	public MetaKeywords:any;
	public MetaDescription:any;
	public MetaTitle:any;
	public AllowCustomerReviews:boolean;
	public ApprovedRatingSum:any;
	public NotApprovedRatingSum:any;
	public ApprovedTotalReviews:any;
	public NotApprovedTotalReviews:any;
	public Sku:any;
	public ManufacturerPartNumber:any;
	public Gtin:any;
	public IsGiftCard:boolean;
	public GiftCardTypeId:number;
	public OverriddenGiftCardAmount:number;
	public RequireOtherProducts:boolean;
	public RequiredProductIds:any=[];
	public ReletedOtherProducts:boolean;
	public ReleatedProductIds:any = null;
	public AutomaticallyAddRequiredProducts:boolean;
	public ProductAvailabilityRangeId:number;
	public IsShipEnabled:boolean;
	public IsFreeShipping:boolean;
	public ShipSeparately:boolean;
	public AdditionalShippingCharge:any;
	public DeliveryDateId:any;
	public IsTaxExempt:boolean;
	public TaxRateId:any=[];
	public TaxPrice:any;
	public UseMultipleWarehouses:boolean;
	public WarehouseId:any;
	public ProductTypeCode:any;
	public StockQuantity:any;
	public DisplayStockAvailability:boolean;
	public DisplayStockQuantity:boolean;
	public MinStockQuantity:any;
	public LowStockActivityId:any;
	public NotifyAdminForQuantityBelow:any;
	public OrderMinimumQuantity:any;
	public OrderMaximumQuantity:any;
	public NotReturnable:boolean;
	public DisableBuyButton:boolean;
	public DisableWishlistButton:boolean;
	public AvailableForPreOrder:boolean;
	public PreOrderAvailabilityStartDateTime:any;
	public Price:any;
	public OldPrice:any;
	public ProductCost:number=0;
	public Baseprice:any;
	public CurrencyId:any;
	public MarkAsNew:boolean;
	public MarkAsNewStartDateTime:any;
	public MarkAsNewEndDateTime:any;
	public HasDiscountsApplied:boolean;
	public Weight:any;
	public Length:any;
	public Width:any;
	public Height:any;
	public AvailableStartDateTime:any;
	public AvailableEndDateTime:any;
	public DisplayOrder:any;
	public Published:boolean;
	public Deleted:boolean;
	public CategoryId:any;
	public Project:Array<ItemModel> = new Array<ItemModel>();
	public Quantity:number = 0;
	public ImagePath:any;
	public InstcriptionLineCount:any;
	public InscriptionCharactersPerLine:any;
	public DisplayLogo:boolean;
	public IsReplica:boolean;
	public IsCustomerImg:boolean;
	public RelatedProduct:any=[];
	public DependentProduct:Array<DependentProduct>= new Array<DependentProduct>();
	public DependentProducts:any = [];
	public IsDependentProduct:boolean;
	public VideoURL:string;
	public ParentImagePath:any=null;
	public ParentProductId:any;
	public orderQuantity:any;
	public SoldQuantity:any;
	public PM_MinimumImageHeight:any;
	public PM_MinimumImageWidth:any;
	public MaximumImageFileSize:any;
	public TILE_CusImageHeight:any;
	public TILE_CusImageWidth:any;
	public DeliveryCodeId:any;
	public ProductCode:any;
	public ProductManufacturer:ItemModel = new ItemModel();
	public TileBackgroundColor:any = null;
	public TileFontColor:any = null;
	public DiscountString:any = null;
}
export class DependentProduct extends BaseModel{

	public ProductControlId:any;
	public ProductId:any;
	public ProductGuid:any;
	public ProductType:any;
	public ParentGroupedProductId:any;
	public VendorId:any;
	public VisibleIndividually:boolean;
	public Name:string;
	public ShortDescription:string;
	public FullDescription:string;
	public AdminComment:string;
	public ProductTemplateId:number;
	public ShowOnHomePage:boolean;
	public MetaKeywords:string;
	public MetaDescription:string;
	public MetaTitle:string;
	public AllowCustomerReviews:boolean;
	public ApprovedRatingSum:any;
	public NotApprovedRatingSum:any;
	public ApprovedTotalReviews:any;
	public NotApprovedTotalReviews:any;
	public Sku:string
	public ManufacturerPartNumber:any;
	public Gtin:any;
	public IsGiftCard:boolean;
	public GiftCardTypeId:any;
	public OverriddenGiftCardAmount:any;
	public RequireOtherProducts:boolean;
	public RequiredProductIds:any = [];
	public ReletedOtherProducts:boolean;
	public ReleatedProductIds:any;
	public AutomaticallyAddRequiredProducts:boolean;
	public ProductAvailabilityRangeId:any;
	public IsShipEnabled:boolean;
	public IsFreeShipping:boolean;
	public ShipSeparately:boolean;
	public AdditionalShippingCharge:any;
	public DeliveryDateId:any;
	public IsTaxExempt:boolean;
	public TaxRateId:any;
	public TaxPrice:any;
	public UseMultipleWarehouses:boolean;
	public WarehouseId:any;
	public StockQuantity:any;
	public DisplayStockAvailability:boolean;
	public DisplayStockQuantity:boolean;
	public MinStockQuantity:any;
	public LowStockActivityId:any;
	public NotifyAdminForQuantityBelow:any;
	public OrderMinimumQuantity:any;
	public OrderMaximumQuantity:any;
	public NotReturnable:boolean;
	public DisableBuyButton:boolean;
	public DisableWishlistButton:boolean;
	public AvailableForPreOrder:boolean;
	public PreOrderAvailabilityStartDateTime:any;
	public Price:any;
	public OldPrice:any;
	public ProductCost:any;
	public Baseprice:any;
	public CurrencyId:number;
	public MarkAsNew:boolean;
	public MarkAsNewStartDateTime:any;
	public MarkAsNewEndDateTime:any;
	public HasDiscountsApplied:boolean;
	public Weight:any;
	public Length:any;
	public Width:any;
	public Height:any;
	public AvailableStartDateTime:any;
	public AvailableEndDateTime:any;
	public DisplayOrder:number;
	public Published:boolean;
	public Deleted:boolean;
	public CategoryId:number;
	public Project:any;
	public ImagePath:any;
	public InstcriptionLineCount:number;
	public InscriptionCharactersPerLine:number;
	public DisplayLogo:boolean;
	public IsReplica:boolean;
	public IsCustomerImg:boolean;
	public RelatedProduct:any;
	public DependentProduct:any;
	public DependentProducts:any;
	public IsDependentProduct:any;
	public VideoURL:any;
	public ParentImagePath:any;
	public ParentProductId:any;
	public orderQuantity:any;
	public SoldQuantity:any;
	public PM_MinimumImageHeight:any;
	public PM_MinimumImageWidth:any;
	public MaximumImageFileSize:any;
	public CusImageHeight:any;
	public CusImageWidth:any;
	public DeliveryCodeId:any;
	public ProductCode:any;
	public ProductManufacturer:ItemModel = new ItemModel();
	public TileBackgroundColor:any;
	public TileFontColor:any;
	public DiscountString:any;
	public AgreedPrice:any;
	public ReplicaBasePrice:any;
	public ReplicaAgreedPrice:any;
	public ReplicaPrice:any;
	public ReplicaProductCost:any;
	public ReplicaTaxPrice:any;
	public IsOrgTaxExcempt:any;
	public ProductVariances:Array<ProductVariances> = new Array<ProductVariances>();
	public boolIsInscAdded:boolean=false

}

export class ProductVariances extends BaseModel{
	public Id:number;
	public ProductId:number;
	public Name:any;
	public IsDefault:boolean;
	public DisplayOrder:any;
	public PriceAdjustment:any;
	public StockQty:any;
	public Icon:IconModel = new IconModel();
	public Images:Array<ImagesModel> = new Array<ImagesModel>();
	public TotalCost:any;
	public orderQuantity:any;
}

export class ProductListModel extends ProductModel{
    public OrganizationId:any;
    public OrgGUID:any;
    public ProjectId:any;
    public Description:any;
    public CurrancyCode:any;
    public ProductTypeCode:any;
    public ProductTypeName:any;
    public ReplicaPrice:any;
    public ReplicaProductCost:any;
    public ReplicaTaxPrice:any;
    public CusImageHeight:any;
    public CusImageWidth:any;
    public productImage:Array<ImageDocumentUpload> = new Array<ImageDocumentUpload>();
}

export class PreAuthModel{
	Id: number;
	Project: number;
	Fields: any[] = []
}