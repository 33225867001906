import {Observable , Observer} from 'rxjs';

export class DataSharedService {
  globalVar:string;
  globalData:any;
  globalSalesList:any;
  storeProjectId:number;
  newUserEmail:string;
  newUserPassword:any;
  defaultHomeUrl:string;
  logoPath:string;
  loadHeaderClass:string;
  sharedImageJson:any;
  ShareUploadFile:any;
  VideoURL:string;
  OtherLangauge:any = [];
  DefaultLangauge:any = [];
  getLanguageJson:any = {};
  projectDetails:any;
  globalVarUpdate:Observable<string>;
  globalVarObserver:Observer<any>;

  constructor() {
    this.globalVarUpdate = Observable.create((observer:Observer<any>) => {
      this.globalVarObserver = observer;
    });
  }

  updateGlobalVar(globData:string, projectId: number, newUserEmail:string, newUserPassword:any, defaultHomeUrl:string, logoPath:string, loadHeaderClass:string, sharedImageJson:string, ShareUploadFile:any, VideoURL:string, OtherLangauge:any, DefaultLangauge:any, getLanguageJson:any, projectDetails:any) {
    this.globalData = globData;
    this.storeProjectId = projectId;
    this.newUserEmail = newUserEmail;
    this.newUserPassword = newUserPassword;
    this.defaultHomeUrl = defaultHomeUrl;
    this.logoPath = logoPath;
    this.loadHeaderClass = loadHeaderClass;
    this.sharedImageJson = sharedImageJson;
    this.ShareUploadFile = ShareUploadFile;
    this.VideoURL = VideoURL;
    this.OtherLangauge = OtherLangauge;
    this.DefaultLangauge = DefaultLangauge;
    this.getLanguageJson = getLanguageJson;
    this.projectDetails = projectDetails;
    this.globalVarObserver.next(this.globalVar);
  }
}