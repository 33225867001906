import { Component, OnInit,Input } from '@angular/core';
import { operationService } from '../../_services/operation.service';
import { DataSharedService } from '../../_services/dataSharedService';
import * as url from '../../../environments/environment';
import { ComponentintractService } from '../../_services/conponentinteraction.service';
import {TranslateService} from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import { ProjectStatus } from '../../models/project.enum';
class User{
    username:string="";
    password:string="";
    conversionEncryptOutput:string="";
    conversionDecryptOutput:string="";
}
@Component({
    selector: 'home-page',
    templateUrl: './home-page.html',
    providers: [operationService]
})
export class HomePageComponent implements OnInit {

    public homePageStatus:boolean;
    public firstTimeLoadGenerelHomePage:boolean = false;
    public firstTimePhotomosaicHomePage:boolean = false;
    public projectId:number;
    public OrganisationId:number;
    public status:boolean;
    public routeUrl: string ="";
    public boolIsRefreshed:boolean=false;
    public encryptionKye="8080808080808080";
    /*POC*/
    public objEncryptUser:User = new User();
    public objDecryptUser:User = new User();
    public ProjectStatus : typeof ProjectStatus = ProjectStatus;
    encryptUserData(){
        var key = CryptoJS.enc.Utf8.parse(this.encryptionKye);  
        var iv = CryptoJS.enc.Utf8.parse(this.encryptionKye);

        this.objEncryptUser.conversionEncryptOutput = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(this.objEncryptUser.username), key,  
                {  
                    keySize: 128 / 8,  
                    iv: iv,  
                    mode: CryptoJS.mode.CBC,  
                    padding: CryptoJS.pad.Pkcs7  
                });
        // this.objEncryptUser.conversionEncryptOutput = CryptoJS.AES.encrypt(JSON.stringify(this.objEncryptUser), this.objEncryptUser.password.trim()).toString();  
    }
    decryptUserData(){
        this.objDecryptUser = JSON.parse(CryptoJS.AES.decrypt(this.objDecryptUser.conversionDecryptOutput.trim(), this.objDecryptUser.password.trim()).toString(CryptoJS.enc.Utf8));  
    }

    constructor(private translate: TranslateService,private objComponentintractService:ComponentintractService,private _operationService: operationService, private _DataSharedService: DataSharedService) {}
    ngOnInit() { 
        var arrUrlDetails = window.location.href.split('/');
        this.routeUrl = arrUrlDetails[url.urlIndex];
        
        this.objComponentintractService.getComponentdata().subscribe(
            data=>{
                if( data!=undefined ){
                    if( data.boolIsRefreshed ){
                        this.boolIsRefreshed = true;
                        this.setProjectData(data.data);
                    }
                }
            }
        )
        if( !this.boolIsRefreshed ){
            this.GetProjectByCode();
        } else {

        }
        if(window.location.href.indexOf("ResetPassword") > -1) {
            $('#resetPassword').modal('show');
        }
    }
    ngAfterViewInit() {

    }

    /* This method will call the 'GetProjectByCode' will give the ProjectTypeCode
     * Based on ProjectTypeCode it will load the landing page.
     * general-landing-page or photomosaic-landing-page.
     * ProjectDetail JSON Object will send to further pages.
     */
    GetProjectByCode(){
        this._operationService.get(url.bricsAPI+'Project/GetProjectByCode/?projectCode='+this.routeUrl).subscribe(
            data => {
                this.setProjectData(data);
            },error=>{
               
            }
        )
    }
    setProjectData(data:any){
        let pageLoadStatus = data.ProjectTypeCode.trim();
        this._DataSharedService.loadHeaderClass = pageLoadStatus;
        this._DataSharedService.VideoURL = data.VideoURL;
        this._DataSharedService.OtherLangauge = data.OtherLangauge;
        this._DataSharedService.OtherLangauge = data.OtherLangauge;
        this._DataSharedService.DefaultLangauge = data.DefaultLangauge;
        if(pageLoadStatus == 'PM'){
            this.homePageStatus = true;
            this.firstTimePhotomosaicHomePage = true;
        } else {
            this.homePageStatus  = false;
            this.firstTimeLoadGenerelHomePage = true;
        }
        if(data.ProjectStatus == ProjectStatus.OPEN){
            this.status = true;
        } else {

            this.status = false;
        }
    }
}
