import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { operationService } from '../../_services/operation.service';
import { DataSharedService } from '../../_services/dataSharedService';
// import * as url from '../../baseUrl';
import * as url from '../../../environments/environment';
declare var OpenSeadragon: any;
import { Observable ,  Subscription,EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { EmbedVideoService } from 'ngx-embed-video';
import { setTimeout, Number } from 'core-js/library/web/timers';
import {DatePipe} from '@angular/common';
import { ComponentintractService } from '../../_services/conponentinteraction.service';
import { DocumentTypeCodeEnum,ObjectTypeEnum,DocumentTypeCategoryEnum } from '../../models/documenttypecode.enum';
import { ImageDocumentUpload,DocumentProperties,UploadImage,DocumentMapping } from '../../models/documentMapping.model';
import { ProductListModel } from '../../models/productmodel';
import { Language,MappedLanguageModel,OrganizationTranslation } from '../../models/language.model';
import { ProjectModel } from '../../models/project.model';
import { NewsContentModel,NewsTitleModel,BuildData } from '../../models/newsmodel';
// declare var viewer: any;
@Component({
    selector: 'org-landing-page',
    templateUrl: './orglandingpage.component.html',
    providers: [operationService, EmbedVideoService, DatePipe]
})
export class OrganizationLandingComponent implements OnInit {
	
	public routeUrl:any="";
    public objorgDetails:any={};
    public arrobjMappedLanguageModel:Array<MappedLanguageModel> = new Array<MappedLanguageModel>();
    public objMappedLanguageModel:MappedLanguageModel = new MappedLanguageModel();
    public arrobjOrgbackgroundImage:Array<DocumentMapping> = new Array<DocumentMapping>();
    public arrobjProjectModel:Array<ProjectModel> = new Array<ProjectModel>();
    public objOrganizationTranslation:OrganizationTranslation=new OrganizationTranslation();
    public baseUrl:any;
    public arrobjNewsContentModel:Array<NewsContentModel> = new Array<NewsContentModel>();
	constructor(private _router : Router,private translate: TranslateService,private objoperationService:operationService, private objComponentintractService:ComponentintractService,){}

	ngOnInit(){

        var windowsURl = window.location.href
        this.baseUrl = windowsURl.split('#')[0];
        var arrUrlDetails = windowsURl.split('/');
        this.routeUrl = arrUrlDetails[url.urlIndex];
        this.getOrgByCode(this.routeUrl);
	}

	getOrgByCode(orgCode){
        this.objoperationService.get(url.bricsAPI+"Organization/GetOrganizationDetailsByCode?Code="+orgCode).subscribe(
            data=>{
                this.objorgDetails = data;
                this.getOrganizationMappedLanguages(data.Id);
            },error=>{

            }
        )
    }
    gotToProject(objProjectModel:ProjectModel){
        if( url.environment.production == true ){
            window.location.href = objProjectModel.ExternalURL;
        } else {
            this._router.navigate([objProjectModel.StoreLocation+'/home']);
        }
        
    }
    getOrganizationMappedLanguages(orgId){

        this.objoperationService.get(url.bricsAPI+"Organization/GetOrganizationMappedLanguages/?organizationId="+orgId).subscribe(
            data=>{
                this.arrobjMappedLanguageModel = data;
                var langMappingId = sessionStorage.getItem("defaultLanguageId");
                if( langMappingId == null ){
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.IsDefault==true)[0];
                } else {
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.Id==JSON.parse(langMappingId))[0];
                }
                this.setLanguageSessionData(this.objMappedLanguageModel);
                this.getprojectList(orgId,this.objMappedLanguageModel.LanguageCode);
                this.getNews(orgId,ObjectTypeEnum.ORGANIZATION,this.objMappedLanguageModel.ResourceFile);

                var objProjectFilterDocument = new DocumentMapping();
                objProjectFilterDocument.ObjectId = orgId;
                objProjectFilterDocument.ObjectType = ObjectTypeEnum.ORGANIZATION;
                objProjectFilterDocument.languagecode = this.objMappedLanguageModel.ResourceFile;
                objProjectFilterDocument.DocumentTypeCategory = DocumentTypeCategoryEnum.OrganizationImage;
                this.getProjectImages(objProjectFilterDocument);
                this.getOrganisationTranslationDetail(orgId,ObjectTypeEnum.ORGANIZATION,this.objMappedLanguageModel.LanguageCode);
            },error=>{
                
            }
        )
    }

    setLanguageSessionData(objMappedLanguageModel):boolean{
        sessionStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        localStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);

        sessionStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        localStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        
        sessionStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        localStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        
        sessionStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        localStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        
        sessionStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        localStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        
        sessionStorage.setItem("languageCode",this.objMappedLanguageModel.LanguageCode);
        localStorage.setItem("languageCode",btoa(this.objMappedLanguageModel.LanguageCode));
        
        sessionStorage.setItem("DefaultLangauge",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("DefaultLangauge",btoa(this.objMappedLanguageModel.ResourceFile.trim()));
        
        sessionStorage.setItem("activeLanguage",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("activeLanguage",btoa(this.objMappedLanguageModel.ResourceFile.trim()));
        
        sessionStorage.setItem("activeLanguageId",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("activeLanguageId",this.objMappedLanguageModel.ResourceFile.trim());
        
        this.translate.setDefaultLang((objMappedLanguageModel.ResourceFile.trim()).toLowerCase());

        return true;
    }
    getOrganisationTranslationDetail(objectId,objectType,langugecode){
        this.objoperationService.get(url.mainUrl+"Organization/GetOrganizationTranslations/?organizationId="+objectId+"&LanguageCode="+langugecode).subscribe(
            data=>{
                this.objOrganizationTranslation = data;
                this.objComponentintractService.clearComponentdata();
                if(this.objOrganizationTranslation.OrganizationFooter){
                    this.objComponentintractService.setComponentdata({FooterText:this.objOrganizationTranslation.OrganizationFooter});
                };
                
            },error=>{
                
            }
        )
    }
    public boolIsEvenCount:boolean=false;
    getprojectList(objectId,languageCode){
    	this.boolIsEvenCount = true;
        this.objoperationService.get(url.bricsAPI+"Organization/GetProjectList/?OrganizationId="+objectId+"&LanguageCode="+languageCode).subscribe(
    		data=>{
    			this.arrobjProjectModel = data;
                if( this.arrobjProjectModel.length % 2 == 0 ){
                    this.boolIsEvenCount = true;
                }
    		},error=>{

    		}
		)
    }
	getProjectImages(objProjectDocument:DocumentMapping){

        this.arrobjOrgbackgroundImage = [];
        this.objoperationService.post(url.bricsAPI+"DMS/FetchDocuments",objProjectDocument).subscribe(
            data=>{
                this.arrobjOrgbackgroundImage = data.filter(item=>item.DocumentType == DocumentTypeCodeEnum.ORGANIZATIONBACKGROUNDIMAGE);
            },error=>{

            }
        )
    }

    /*News API's*/
    getNews(objectId,objectType,languageCode){
        this.arrobjNewsContentModel = [];
        this.objoperationService.get(url.bricsAPI+"News/GetNewsDetailsByLang?ObjectId="+objectId+"&ObjectType="+objectType+"&languageCode="+languageCode).subscribe(
            data=>{
                this.arrobjNewsContentModel = data;
                this.buildNewsCursoleJSON(this.arrobjNewsContentModel);
            },error=>{

            }
        )
    }

    public arrobjBuildData:Array<BuildData> = new Array<BuildData>();
    buildNewsCursoleJSON(arrobjNewsContentModel:Array<NewsContentModel>){
        var dataPerKey = 3;
        var arrlenght = JSON.stringify(arrobjNewsContentModel.length/dataPerKey).split(".");
        
        var resultantLength:any;

        resultantLength = arrlenght.length==1? JSON.parse(arrlenght[0]):JSON.parse(arrlenght[0])+1;

        for( resultantLength;resultantLength>0;resultantLength-- ){
            let objBuildData:BuildData = new BuildData();
            this.arrobjBuildData.push(objBuildData);
        }

        var startdataCounter:any=0;
        var enddataCounter:any=0;

        this.arrobjBuildData.forEach((value,index)=>{
            startdataCounter = index*dataPerKey;
            enddataCounter = startdataCounter + dataPerKey;

            while(startdataCounter < enddataCounter){
                if( arrobjNewsContentModel.length <= startdataCounter){
                    return true;
                }
                value.newsContent.push(arrobjNewsContentModel[startdataCounter]);
                startdataCounter++;
            } 
        })
    }
    get getCursoleData(){
        return this.arrobjBuildData;
    }
    public objProjectModel:ProjectModel = new ProjectModel();
    learnMore(objProjectModel:ProjectModel){
        $("#learn-more-popup").modal("show");
        this.objProjectModel = objProjectModel;
    }
}