import { ItemModel,ItemCode } from './itemmodel';

export class AddressModel{

	public Id:any=0;
	public CustomerId:any=null;
	public CustomerName:any="";
	public PhoneNumber:any=null;
	public Address1:any="";
	public Address2:any="";
	public ZipPostalCode:any=null;
	public City:any="";
	public countryModel:ItemCode = new ItemCode();
	public StateProvinceModel:any="";
	public Longitude:any=null;
	public Latitude:any=null;
	public isDelivery:boolean=false;
	public isInvoice:boolean=false;
	public AddressNote:any="";
	public Action:any="";
}
export class AddressId{
	public shippingId:any=0;
	public billingId:any=0;
}