import { Pipe, PipeTransform } from '@angular/core';
import {  getCurrencySymbol } from '@angular/common';
@Pipe({
    name: 'mycurrency',
  })
  export class MycurrencyPipe implements PipeTransform {
    transform(
        value: number,
        currencyCode: string = 'GBP',
        display:
            | 'code'
            | 'symbol'
            | 'symbol-narrow'
            | string
            | boolean = 'symbol',
        digitsInfo: string = '3.2-2',
        locale: string = 'fr',
    ): string | null {
        let formattedCurrency = getCurrencySymbol(currencyCode, 'wide')+" "+value;

        return formattedCurrency; //getCurrencySymbol(currencyCode, 'wide')
    }
}