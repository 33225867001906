import { Component, ViewChild,Input,OnInit, Type,NgZone,Output, EventEmitter, ElementRef, ViewEncapsulation,Injector } from '@angular/core';
import { DomSanitizer,SafeUrl } from '@angular/platform-browser';
import * as url from '../../../environments/environment';
import * as Cropper from 'cropperjs/dist/cropper';
import { DatePipe } from '@angular/common';

export interface ImageCropperSetting {
    width: number;
    height: number;
}

/*--------  Interface: Image Cropper Rresult  --------*/
export interface ImageCropperResult {
    imageData: Cropper.ImageData;
    cropData: Cropper.CropBoxData;
    blob?: Blob;
    dataUrl?: string;
}
@Component({
  selector: 'image-tool',
  styleUrls:['./styles.css'],
  templateUrl: './imagetools.html',
  providers: [DatePipe]
})

export class CropImageComponent implements OnInit {

    public objFile:File;
    public rotateBy:any;
    public intStartX:number;
    public strBase64:any;
    public intStartY:number;
    public imageType:any;
    public intToWidth:number;
    public intToHeight:number;
    public canvasImage:any;
    public objDocument:any=[];
    public intCreatedBy:number;
    public objImageConfig:any=[];
    public intImageTypeId:number;
    public intPreviousValue:number = 0;
    public strPreLoadImageURL:string=null;
    public strDocumentTypeCode:any;
    public strUploadBase64Data:any;
    public selectedImageTrusted:SafeUrl=null;
    public intPreviousZoomValue:number = 0;
    public intMinHeight:any;
    public intMinWidth:any;
    public boolIsinitiated:boolean=false;
    @ViewChild("imageCrop") imageCrop:ElementRef;

    constructor( private injector: Injector,private sanitizer: DomSanitizer, private zone: NgZone ) {

    }
    
    ngOnInit(){
        $("#imageprocessing").modal("show");
    }
    ngOnDestroy(){
        this.imageUrl=null;
        this.base64Data=null;
        this.settings=null;
        this.cropbox=null;
        this.loadImageErrorText=null;
        this.cropperOptions=null;
    }
    rotateImage($event){
        var diff:number;
        diff = $event - this.intPreviousValue;
        this.cropper.rotate(diff);
        this.intPreviousValue = $event;
    }
    zoomInOut($event){
        
        var diff:number;
        diff = $event - this.intPreviousZoomValue;
        this.cropper.zoom(diff);
        this.intPreviousZoomValue = $event;
    }

    rotate(side:any){
        if( side == 'right' ){

            this.cropper.rotate(90);
        } 
        if( side == 'left' ){

            this.cropper.rotate(-90);
        }
        this.getData();
    }
    
    loadBase64Image(){
        var tempImage =`data:`+this.imageType+`;base64,`+this.strBase64;
        this.selectedImageTrusted = this.sanitizer.bypassSecurityTrustUrl(tempImage);
    }
    public get savedImage() {
        return this.selectedImageTrusted;
    }

    public get croppdedImageUrl() {
        return this.croppedUrl;
    }

    /*--------  View child elements  --------*/
    @ViewChild('image') image: ElementRef;
 
    /*--------  Input properties  --------*/

    @Input() imageUrl: any;
    @Input() cropperMinWidth: any;
    @Input() base64Data: any;
    @Input() settings: ImageCropperSetting;
    @Input() cropbox: Cropper.CropBoxData;
    @Input() loadImageErrorText: string;
    @Input() cropperOptions: any;
    @Input() minHeightWidth:any={}

    /*--------  Output events  --------*/


    @Output() export = new EventEmitter<ImageCropperResult>();
    @Output() ready = new EventEmitter();
    @Output() boolHello = new EventEmitter<boolean>();

    /*--------  Properties  --------*/


    public isLoading: boolean = true;
    public cropper: Cropper;
    public imageElement: HTMLImageElement;
    public loadError: any;
    public isuploadimageFlag:boolean;
    public base64textString:any;
    public previewImage:boolean = false;
    public imagePath:any;

    /*--------  Controller  --------*/

    /**
     * Image lodaded event
     * @param {event} ev 
     */
    imageLoaded(ev: Event) {
        //
        // Unset load error state
        this.loadError = false;
        if( this.cropper ){
            this.croppedUrl="";
            this.cropperOptions = ""
            this.cropper.destroy();
        }
        //
        // Setup image element
        const image = ev.target as HTMLImageElement;
        this.imageElement = image;

        // 
        // Add crossOrigin?
        //if (this.cropperOptions.checkCrossOrigin) image.crossOrigin = 'anonymous';

        //
        // Image on ready event
        image.addEventListener('ready', () => {
            //
            // Emit ready
            this.ready.emit(true);

            //
            // Unset loading state
            this.isLoading = false;

            //
            // Validate cropbox existance
            if (this.cropbox) {

                // this.cropper.setCanvasData({"left":0,"top":16.46875,"width":825,"height":464.0625,"naturalWidth":1280,"naturalHeight":720});
                //
                // Set cropbox data
                // this.cropper.setData({"x":0,"y":0,"width":1024,"height":576,"rotate":0,"scaleX":1,"scaleY":1}) //setCropBoxData({"left":0,"top":0,"width":20,"height":50});
                this.cropper.setCropBoxData({"left":0,"top":0,"width":20,"height":50});
            }
            this.cropper.setDragMode("move");
            
        });
        // this.cropper.options.cropBoxResizable=false;
        //
        // Setup aspect ratio according to settings
        let aspectRatio = NaN;
        var boolResizable:boolean=false;
        if( this.settings != undefined && Object.keys(this.settings).length > 0 ){
            const { width, height } = this.settings;
            aspectRatio = width / height;
            boolResizable = false;
        }
        var minContainerWidth:any;
        if( this.cropperMinWidth >= url.minWidth){
            minContainerWidth = 900;
        } else {
            minContainerWidth = this.cropperMinWidth;
        }
        
        var minContainerHeight = (this.settings.height/this.settings.width)*minContainerWidth;
        //
        // Set crop options
        // extend default with custom config
        this.cropperOptions = Object.assign({
            aspectRatio,
            movable: true,
            responsive:true,
            restore:true,
            scalable: true,
            zoomable: true,
            viewMode: 1,
            zoomOnWheel:false,
            minContainerHeight:minContainerHeight,
            minContainerWidth:minContainerWidth,
            cropBoxResizable:boolResizable,
            zoomOnTouch:false,
            checkCrossOrigin: false,
            background:false,
            cropBoxMovable:false,
        }, this.cropperOptions);
        // Set cropperjs
        this.cropper = new Cropper(image, this.cropperOptions);
        // const imageDimention = new Promise(resolve=>{
        //     // if( this.cropper ){
        //         resolve.bind(this.cropper);
        //         return resolve(this.cropper);
        //     // }
        // })
        // imageDimention.then(res=>{
        //     console.log("Promis")
        //     console.log(res)
        //     this.cropper = res;
        //     this.cropper.setCropBoxData({"left":120,"top":130,"width":336,"height":252});
        // })
    }
    set setMaxWidth(strMsg){
        
         if (this.cropbox) {
            this.cropper.setCropBoxData({"left":0,"top":0,"width":336,"height":252});
        }
    }
    /**
     * Image load error
     * @param {event} event 
     */
    imageLoadError(event: any) {

        //
        // Set load error state
        this.loadError = true;

        //
        // Unset loading state
        this.isLoading = false;
    }

    getCroppedCanvas(){
        this.canvasImage = this.cropper.getCroppedCanvas({maxWidth:4096,maxHeight:4096});
    }
    getImageData(){
        //this.exportCanvas(this.strBase64);

        var data = this.cropper.getImageData()
        
        var cropdata = this.cropper.getCropBoxData()
    }
    croppedUrl:any;
    getData(){

        var data = this.cropper.getData();
    }
    startCropping(){
        var data = this.cropper.getData();
        if( this.intMinHeight > data.height && this.intMinWidth > data.width ){
            this.cropper.element.cropper.options.cropBoxResizable=true;
        }
    }
    getdesktopData(){
        this.cropper.getCroppedCanvas({width:600,height:450});
        this.getCroppedImage();
    }
    getMobileData(){
        this.cropper.setCropBoxData({"left":0,"top":0,"width":600,"height":450});
    }
    getContainerData(){
        var data = this.cropper.getContainerData();
    }
    dragMode(){
        this.cropper.setDragMode("move");
    }
    public zoomRation:any=0;
    public imageData:any={}
    zoomIn(){
        this.imageData = this.cropper.getData();
        if( this.imageData.width > this.minHeightWidth.minWidth ){

            this.cropper.zoom(0.1);

        }

    }
    zoomOut(){
        this.cropper.zoom(-0.1);
    }
   
    cropImageEvent(){

        // this.strBase64 = this.imageUrl.split(',')[1];
        // this.getData();
    }
    press(){
    
    }

    release(){

    }
    mouseUpDown(){
        
    }
    fileChangeListener( $event ){
        this.objFile = $event.target.files[0];
        if (this.objFile) {
            var objFileReader = new FileReader();

            objFileReader.onload =this._handleFileChangeReaderLoaded.bind(this);
            this.isuploadimageFlag=true;
            objFileReader.readAsBinaryString(this.objFile);
        }
    }
    imageReset(){
        if( this.cropper )
            this.cropper.destroy();

        $('#uploadImage').modal('show');
    }
    _handleFileChangeReaderLoaded(readerEvt:any) {
        this.selectedImageTrusted="";
        var binaryString = readerEvt.target.result;
        this.base64textString= btoa(binaryString);
        this.previewImage = true;
        this.imagePath = btoa(binaryString);
        this.strPreLoadImageURL=window.URL.createObjectURL(this.objFile);
        this.selectedImageTrusted=this.sanitizer.bypassSecurityTrustUrl(this.strPreLoadImageURL);
    }
   croppedtoBase64Conversion(file:File){
       var reader = new FileReader();
       reader.onload =this._handleReader.bind(this);
       reader.readAsBinaryString(file);
   }
   _handleReader(readerEvt) {
     var binaryString = readerEvt.target.result;
            this.base64textString= btoa(binaryString);
    }
    
    InvoicePopup() {

        $('#uploadImage').modal('show');
        $('#uploadImage').appendTo("body");
    }
    public imageResponse:any
    /**
     * Export canvas
     * @param {string} base64 
     */
    exportCanvas(base64?: any) {

        //
        // Get and set image, crop and canvas data
        const imageData = this.cropper.getImageData();
        const cropData = this.cropper.getCropBoxData();
        const canvas = this.cropper.getCroppedCanvas();
        const data = { imageData, cropData };

        //
        // Create promise to resolve canvas data
        const promise = new Promise(resolve => {

            //
            // Validate base64
            if (base64) {

                //
                // Resolve promise with dataUrl
                return resolve({
                    dataUrl: canvas.toDataURL('image/png')
                });
            }
            canvas.toBlob(blob => resolve({ blob }));
        });

        //
        // Emit export data when promise is ready
        var that = this;
        promise.then(res => {
            that.setImageCroppedUrl(res);
            that.export.emit(Object.assign(data, res));
            // that.cropper.destroy(); 
        });
    }
    getCroppedImage(){
        this.exportCanvas(this.base64Data);
    }
    setImageCroppedUrl(res:any){
        this.croppedUrl = res.dataUrl;
        if(this.croppedUrl){

            this.strUploadBase64Data = this.croppedUrl.split(',')[1];
        }
    }
    cancel(){
        // this.boolHello.emit(true);
    }
    getImageDimension(){
      
        return true
    }

    onMouseWheel($event){
        this.cropper.zoomOnWheel = false;
        this.imageData = this.cropper.getData();
        if( this.imageData.width > this.minHeightWidth.minWidth ){
              this.cropper.zoom(0.1);
        }

    }
}