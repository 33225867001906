import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { operationService } from '../../../_services/operation.service';
import { DataSharedService } from '../../../_services/dataSharedService';
// import * as url from '../../../baseUrl';
import * as url from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { CartModel,ZeroCartPaymentModel } from '../../../models/cart.model';
import { ComponentintractService } from '../../../_services/conponentinteraction.service';
@Component({
  selector: 'app-root',
  templateUrl: './payment-cancel.html',
  providers: [operationService]
})
export class PaymentCancelComponent {
    
    public routeUrl: string = "";
	public CurrencyHtmlCode:string = sessionStorage.getItem("CurrencyHtmlCode");
	public projectId:any;
	public objCartDetails:CartModel=new CartModel();
	public orderreference:any;

	constructor(private objComponentintractService:ComponentintractService,private _router: Router, private route: ActivatedRoute, private _operationService: operationService, private _DataSharedService: DataSharedService) { }
    ngOnInit(){
        var arrUrlDetails = window.location.href.split('/');
        this.routeUrl = arrUrlDetails[url.urlIndex];
        
		this.setSessionData();
        this.orderreference = sessionStorage.getItem("cartReference");

		this.projectId = JSON.parse(sessionStorage.getItem("projectId"));
		this.getCartByReference();
	}
    getCartByReference(){
        this._operationService.get(url.mainUrl + 'Cart/GetCartByReference/?cartReferenceId='+this.orderreference).subscribe(
            data=>{
                this.objCartDetails = data;
                this.objCartDetails.CreatedOn
            },error=>{

            }
        )
    }
	getCartContents(){
        this._operationService.get(url.mainUrl+"Cart/GetCartContents/?projectId="+this.projectId).subscribe(
            data=>{
            	this.objCartDetails = data;
            	this.objCartDetails.CreatedOn
            },error=>{

            }
        )
    }
    getCurrentCurrencyIcon(){
        var strCurrencyUnicode:any;
        if( sessionStorage.getItem("currencyIcon") ){
            strCurrencyUnicode = sessionStorage.getItem("currencyIcon");
        }
        return strCurrencyUnicode;
    }
	setSessionData():boolean{
        sessionStorage.setItem("Email", localStorage.getItem("Email") ? atob(localStorage.getItem("Email")):"");
        sessionStorage.setItem("cartReference", localStorage.getItem("cartReference") ? atob(localStorage.getItem("cartReference")):"");
        sessionStorage.setItem("checkOutId", localStorage.getItem("checkOutId") ? localStorage.getItem("checkOutId"):"");
        sessionStorage.setItem("AuthType", localStorage.getItem("AuthType") ? localStorage.getItem("AuthType"):"");
        sessionStorage.setItem("UserId", localStorage.getItem("UserId") ? atob(localStorage.getItem("UserId")):"");
        sessionStorage.setItem("IsAdmin", localStorage.getItem("IsAdmin") ? atob(localStorage.getItem("IsAdmin")):"");
        sessionStorage.setItem("FirstName", localStorage.getItem("FirstName") ? localStorage.getItem("FirstName"):"");
        sessionStorage.setItem("isLogedin", localStorage.getItem("isLogedin") ? atob(localStorage.getItem("isLogedin")):"");
        sessionStorage.setItem("IsGuestUser", localStorage.getItem("IsGuestUser") ? atob(localStorage.getItem("IsGuestUser")):"");
        sessionStorage.setItem("languageCode", localStorage.getItem("languageCode") ? atob(localStorage.getItem("languageCode")):"");
        sessionStorage.setItem("CurentCompanyCode",localStorage.getItem("CurentCompanyCode") ? atob(localStorage.getItem("CurentCompanyCode")):"");
        sessionStorage.setItem("paymentZeroAmount",localStorage.getItem("paymentZeroAmount")?atob(localStorage.getItem("paymentZeroAmount")):"");
        sessionStorage.setItem("DefaultLangauge",localStorage.getItem("DefaultLangauge")?atob(localStorage.getItem("DefaultLangauge")):"");
        sessionStorage.setItem("activeLanguage",localStorage.getItem("activeLanguage")?atob(localStorage.getItem("activeLanguage")):"");
        sessionStorage.setItem("projectId",localStorage.getItem("projectId")?atob(localStorage.getItem("projectId")):"");
        return true;
    }
    placeOrder(){
    	this._operationService.get(url.mainUrl+"Cart/CheckForActiveCart/?projectId="+this.projectId).subscribe(
    		data=>{

        		this.objComponentintractService.setComponentdata({boolPlaceOrder:true});
    		},error=>{
    			this._router.navigate([this.routeUrl]);
    		}
		)
    }
}