import { Component,OnChanges, OnInit,ViewChild,ElementRef,OnDestroy,HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { error } from 'util';
import { operationService } from '../../_services/operation.service';
import { DataSharedService } from '../../_services/dataSharedService';
import * as url from '../../../environments/environment';
import { loginService } from '../../_services/login.service';
@Component({
  selector: 'app-tiles-findmyphoto',
  templateUrl: './findmyphoto.component.html',
  providers:[loginService]
})

export class FindMyPhotoComponent implements OnInit,OnChanges,OnDestroy{
	constructor(){}
	ngOnInit(){

	}
	ngOnChanges(){

	}
	ngOnDestroy(){
		
	}
}