import { Component,Input } from '@angular/core';
import {DatePipe} from '@angular/common';
import { operationService } from '../../../_services/operation.service';
import * as url from '../../../../environments/environment';
import { DataSharedService } from '../../../_services/dataSharedService';
declare var OpenSeadragon: any;
import { Observable } from 'rxjs';
import { interval } from 'rxjs';
import { ComponentintractService } from '../../../_services/conponentinteraction.service';
import { ProjectAdditionalModel } from '../../../models/project.model';
import { EnquiryData } from '../../../models/enquiryData.model';
import { ObjectTypeEnum } from '../../../models/documenttypecode.enum';
import { Language,MappedLanguageModel } from '../../../models/language.model';
@Component({
  selector: 'footer-component',
  templateUrl: './footer.component.html',
  providers: [DatePipe, operationService]
})
export class FooterComponent {
    @Input("objProjectDetails") objProjectDetails:any={};
    @Input("arrobjMappedLanguageModel") arrobjMappedLanguageModel:Array<MappedLanguageModel> = new Array<MappedLanguageModel>();
    @Input("objProjectTranslationDetail") objProjectTranslationDetail:any;
    url: any = '';
    facebook_shere: any = '';
    // custom_text = 'Dear Friend, Please check this existing Project.';
    custom_text = 'So excited to be part of this!';
    tweeter_shere: any = '';
    whatsappUrl: any = '';
    public enqueryData:any = {};
    public languageData: any = {};
    public confirmStatus:boolean =true;
    public arrObjQuestionType:any=[];
    public IsLoggedIn:boolean = false;
    public projectInformation:any = {TermsCond:""};
    public objTermesAndCondition:any={};
    public projectId:any;
    public ProjectName:any;
    public strLanguage:any;
    public boolIsInitiated:boolean=false;
    public isChange:boolean=false;
    public ObjectTypeEnum : typeof ObjectTypeEnum = ObjectTypeEnum;
    public activeLanguageId:any;
    public footerText:string;
    public objMappedLanguageModel:MappedLanguageModel = new MappedLanguageModel();
    constructor( public datePipe:DatePipe, private _operationService:operationService, private _DataSharedService: DataSharedService,private objComponentintractService:ComponentintractService ){
    }
    ngOnInit(){
        this.url = encodeURIComponent(localStorage.getItem('url'));
        this.facebook_shere = encodeURIComponent(localStorage.getItem('url'));
        this.tweeter_shere = 'Please share with your friends and family so they can also be part of this exciting project.  Thank you for your support! We will post regular order updates and installation news on here and on our Facebook page.&url=' + encodeURIComponent(localStorage.getItem('url')) ;
        this.whatsappUrl = 'whatsapp://send?text=' + encodeURIComponent(localStorage.getItem('url'));
        this.setDefaultLanguage();
        this.activeLanguageId = this.objMappedLanguageModel.ResourceFile;
        this.strLanguage = sessionStorage.getItem("DefaultLangauge");
        this.boolIsInitiated =true;
        this.getProjectDetails();

        this.objComponentintractService.getComponentdata().subscribe(data=>{
            if(data && data.FooterText ){
                this.footerText = data.FooterText;
            }
        });

    }
    ngOnChanges(){
        if( this.boolIsInitiated ){
            this.setDefaultLanguage();
            this.activeLanguageId = this.objMappedLanguageModel.ResourceFile;
            this.getProjectDetails();
        }
    }
    setDefaultLanguage(){
        var langMappingId = sessionStorage.getItem("defaultLanguageId");
        if( this.arrobjMappedLanguageModel.length > 0 ){
            if( langMappingId == null ){
                this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.IsDefault==true)[0];
            } else {
                this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.Id==JSON.parse(langMappingId))[0];
            }
        }
    }
    getProjectDetails(){
        this.objTermesAndCondition = {};
        this.projectId = this.objProjectDetails.ProjectId;
        this.ProjectName = this.objProjectDetails.ProjectName;
        this.strLanguage = sessionStorage.getItem("Language");
    }

    getQuestionTypeList() {

        // if(sessionStorage.getItem("isLogedin") == 'undefined'){
        //     this.IsLoggedIn = false;
        // } else {
            // this.IsLoggedIn =true;
        // }

        this.IsLoggedIn =true;
        this._operationService.get(url.bricsAPI+"ChatBox/GetQuestionList/?IsLoggedIn="+this.IsLoggedIn).subscribe(
            data=>{
                this.arrObjQuestionType = data;
            },
            error=>{
                
            }
        )
    }
    public boolShowContactUs:boolean=false;
    openTicketPopUp(isChange){
        this.boolShowContactUs =true;
        this.isChange = isChange;
    }
    
    support(){
        this.objComponentintractService.setComponentdata({boolShowContactUs:true});
    }
    public objProjectAdditionalModel:ProjectAdditionalModel=new ProjectAdditionalModel();
    public languageCode:any;
    public termsCondAccessability:any;
    public objEnquiryData:EnquiryData = new EnquiryData();
    public popupMessage:any;
    public popUpHeader:any;
    getProjectTranslationDetail(strPopUpType,objectId,objectType,languageId){

        this.popUpType=strPopUpType;
        this.languageCode = sessionStorage.getItem("languageCode");
        this._operationService.get(url.mainUrl + 'Project/GetProjectTranslationDetail/?projectId='+objectId+'&LanguageCode='+this.languageCode).subscribe(
            data=>{
                $("#terms-condition-footer").modal("show");
                this.popupMessage = data[strPopUpType];
            },error=>{
                
            }
        )
    }
    public popUpType:any;
    submitQuery(){
        this.setEnquiryData();
        this._operationService.post(url.mainUrl+"ChatBox/SendQuery",this.objEnquiryData).subscribe(
            data=>{
                toastr.success("Success");
                // this.boolconfirmStatus = false;
            },error=>{

            }
        )
    }
    setEnquiryData(){
        if(sessionStorage.getItem("isLogedin") == 'true'){
            this.objEnquiryData.CustomerId = parseInt(sessionStorage.getItem('UserId'));
            this.objEnquiryData.CreatedBy = parseInt(sessionStorage.getItem('UserId'));
            this.objEnquiryData.CustomerName = sessionStorage.getItem('FirstName');
            this.objEnquiryData.CustomerEmail = sessionStorage.getItem('Email');
            this.objEnquiryData.OrganizationId = parseInt(sessionStorage.getItem('OrganisationId'));
            this.objEnquiryData.ProjectId = parseInt(sessionStorage.getItem('projectId'));
        }else{
            this.objEnquiryData.CustomerId = 0;
            this.objEnquiryData.CustomerName = '';
            this.objEnquiryData.CustomerEmail = '';
        }
    }
    closeTCpopUp(){
        $("#terms-condition-footer").modal("hide");
    }
}
