import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Observable ,  Subject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import * as url from '../../environments/environment';

@Injectable()
export class EncryptionService {

    public _dataOperation:any;
    constructor(private objHttpUrlEncodingCodec:HttpUrlEncodingCodec) { }
    encryptData(data:any){

        this._dataOperation = url.brics.substring(0, 8)+url.brics.substring(url.brics.length-8, url.brics.length);
        
    	var encryptedData = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), CryptoJS.enc.Utf8.parse(this._dataOperation),  
                {  
                    keySize: 128 / 8,  
                    iv: CryptoJS.enc.Utf8.parse(this._dataOperation),  
                    mode: CryptoJS.mode.CBC,  
                    padding: CryptoJS.pad.Pkcs7  
                }).toString();
    	return encryptedData;
    } 
    encryptURLQueryString(data){

        var encryptedURL:any=data;

        if( url.boolURLEncryption ){
            var boolQuesryStringAvailable:boolean = data.includes("?", 0);
            if( boolQuesryStringAvailable ){
                let arrUrlSplittedData = data.split("?");
                let encryptedQuesryString = this.encryptData(arrUrlSplittedData[1]);
                var resultURlQueryString = this.objHttpUrlEncodingCodec.encodeValue(encryptedQuesryString)

                encryptedURL = "";
                encryptedURL = arrUrlSplittedData[0]+"?"+resultURlQueryString;
            }
        }
        return encryptedURL;
    }

}
