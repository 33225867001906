import { ItemModel } from './itemmodel';
export class PaymentModel{
	public Id:any;
	public OrgnizationId:any;
	public PaymentGatewayId:ItemModel = new ItemModel();
	public CurrencyCode:ItemModel = new ItemModel();
	public Sitereference:any;
	public ReferenceMailId:any;
	public Token:any;
	public Type:any;
	public PGURL:any;
	public IsActive:any;
}
export class PaymentGatwayBasicModel{
	public OrderReference:any;
	public RequestReference:any;
	public SettleStatus:any;
	public SiteReference:any;
	public TransactionReference:any;
	public PaymentGatewayConfigId:any;
}
export class PaymentGatewayCallBackModel extends PaymentGatwayBasicModel{
	public ErrorCode:any;
	public PaymentTypeDescription:any;
}
export class PaymentHandlerModel extends PaymentGatwayBasicModel {
	public Code:any;
	public OrderReference:any;
	public Description:any;
	public paymentBrand:any;
	public RequestReference:any;
	public SettleStatus:any;
	public SiteReference:any;
	public TransactionReference:any;
	public PaymentGatewayConfigId:any;
	public CustomerEmail:any;
	public ReturnURL:any;
	public PaymentType:any;
}
export enum PaymentGatewayType{
	
	MULTIBANKO = "MultiBanco",
	SECURETRADING = "Secure Trading"
}
export enum PaymentType{
	MULTIBANKO = "MB",
	SECURETRADING = "ST"
}
export enum PaymentStatus{
	PaymentCompleted = "PAYMENT_COM",
	PaymentNotApplicable = "PAYMENT_NA",
	PaymentProcessing = "PAYMENT_PROC",
	PaymentDeclined = "PAYMENT_DECL",
	PaymentCancelled = "PAYMENT_CAN",
	PaymentFailed = "PAYMENT_FAILED",
	PaymentRefund = "PAYMENT_REFUND"
}
