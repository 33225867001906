export class  Language {
	public Id:any;
	public Name:any;
	public LanguageCulture:any;
	public Code:any;
	public ThreeLetterCode:any;
	public DefaultCurrencyId:any;
	public Published:any;
}
export class MappedLanguageModel{
	
	public Id:any;
    public OrganizationId:any;
    public LanguageId:any;
    public LanguageCode:any;
    public LanguageName:any;
    public IsDefault:any;
    public IsActive:any;
    public ResourceFile:any;
    public DisplayName:any;
    public CurrencyId:any;
    public CurrencyIcon:any="";
    public CurrencyCode:any="";
    public PGLanguageCulture: string
}

export class OrganizationTranslation{
    public OrganizationName:any="";
    public OrganizationDescription:any="";
    public AboutProjects:any="";
    public OrganizationFooter:any = "";
    public ProjectListHeaderContent:any="";
    public Matrix:Array<Matrix> = Array<Matrix>();
}
export class Matrix{
    public Description:any="";
    public Header:any="";
}

export class PreRegistrationModel{
    ProjectId: any;
    FirstName: string;
    LastName:string
    Email: string
    // Membership: string;
    CreatedDate: string;
    isTermAndConditionCheckboxChecked: boolean;
}