import { Component, OnInit, Input, Output,OnChanges, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CartModel } from '../../models/cart.model';
import { OrderDetails,PhotoMosaicOrder,Order,Inscription } from '../../models/ordermodel'; 
import { operationService } from '../../_services/operation.service';
import * as url from '../../../environments/environment';
import { CartTypeEnum,PaymentMethodEnum } from '../../models/project.enum';
import { DocumentTypeCodeEnum,ObjectTypeEnum } from '../../models/documenttypecode.enum';
import { DocumentProperties,UploadImage } from '../../models/documentMapping.model';
@Component({
    selector: "app-tiles-orderdetails",
    templateUrl: "./tilesorderdetails.component.html"
})
export class TilesOrderDetailsComponent implements OnInit,OnChanges {

    @Input("objCartDetails") objCartDetails:CartModel = new CartModel();
    @Input("boolShowFeilds") boolShowFeilds:boolean=false;
    @Input("boolEditInsc") boolEditInsc:boolean=false;
    @Input("IsInscriptionEditable") IsInscriptionEditable:boolean = false;
    @Input("boolDownloadorderCertificate") boolDownloadorderCertificate:boolean=false;
    @Input("inscriptionCharecterCount") inscriptionCharecterCount:any;
    @Input("hasCertificate") hasCertificate:boolean;
    
    @Output() emitData = new EventEmitter<Order>();
    public boolHide:boolean=true; 
    public arrobjOrder:Array<Order>=[];
    public orderDeliveryCost:any=0;
    public subTotalChildOrders:any=0;
    public deleteMessage:any=0;
    public isDeleteChanges:any=0;
    public boolIsInitated:boolean=false;
    public boolIsGuestUser:boolean=false;
    public clickedButton:any="";
    public deleteModelName:any="order";
    public objOrders:Order;
    public CartTypeEnum : typeof CartTypeEnum = CartTypeEnum;
    public DocumentTypeCodeEnum : typeof DocumentTypeCodeEnum = DocumentTypeCodeEnum;

    constructor(private objOperationService: operationService) {}
    ngOnInit(){
    	this.boolIsInitated = true;
        this.arrobjOrder = new Array<Order>();
        this.boolIsGuestUser = true;
        this.intProjectId = this.objCartDetails.project.Id;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                this.boolIsGuestUser = false;
            }
        }
    	this.setPMOrder();
        this.getProjectCertificateTemplates();
    }
    ngOnChanges(){
        
    	if( this.boolIsInitated ){
            this.intProjectId = this.objCartDetails.project.Id;
            this.boolIsGuestUser = true;
            if( sessionStorage.getItem("IsGuestUser") ){
                if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                    this.boolIsGuestUser = false;
                }
            }
            this.arrobjOrder = new Array<Order>();
    		this.setPMOrder();
            this.getProjectCertificateTemplates();
    	}
    }
    downloadTilesCertificate(objOrder:Order){
        
        this.objOperationService.get(url.mainUrl+"Cart/DownloadTilesCertificate/?cartReferenceNo="+objOrder.CartId+"&orderId="+objOrder.Id+"&documentType="+DocumentTypeCodeEnum.CERTIFICATE).subscribe(
            data=>{
                window.open(data.ResponseData, '_blank');
            },error=>{

            }
        )
    }
    showEdit(objOrder:Order,orderIndex){
        var isEdit:boolean=false;
        if(this.objOrders !=null && this.objOrders.Id == objOrder.Id && this.clickedButton != 'delete'){
            isEdit =true;
        }
        return isEdit;
    }
    editInscription(objOrder:Order,index,clickedButton){
        this.clickedButton = clickedButton;
        this.cancelEditInsc();
        this.objOrders = objOrder
        // this.boolEditInscription = true;
    }
    updateInscription(objOrder:Order,orderIndex){
        
        this.objOperationService.put(url.mainUrl+"order/UpdateInscriptions",objOrder.inscriptions).subscribe(
            data=>{
                toastr.success("Success");
                this.cancelEditInsc(objOrder);
            },error=>{
                var objError = JSON.parse(error._body);
                if(objError.Type == "InvalidRequest"){

                    toastr.error(objError.Message)
                }
            }
        )
    }
    public appendedHtml:any;
    getTemplates(orderId,cartId,projectGuid){
        this.objCartDetails.project.Value
        this.objOperationService.get(url.mainUrl+"cart/GetPreviewImage/?orderId="+orderId+"&cartId="+cartId+"&IsGuest="+this.boolIsGuestUser+"&ProjectGuid="+projectGuid).subscribe(
            data=>{
                $("#kstemppreview-order-detail").modal("show");
                this.appendedHtml = data.ResponseData;
            },error=>{

            }
        )
    }
    closeScreenTemplates(){
        $("#kstemppreview-order-detail").modal("hide");
        this.appendedHtml = "";
    }
    isEmptyInscription(objOrder){

        var isInscriptionAvailable:boolean=false;
        if( objOrder != null ){
            objOrder.inscriptions.forEach((value,index)=>{
                if( value.InscriptionLine.trim() != "" ){
                    isInscriptionAvailable = true;
                }
            });
        }
        return isInscriptionAvailable
    }
    cancelEditInsc(objOrder?){
        this.objOrders = null;
        this.emitData.emit(objOrder);
    }
    deleteTileOrder(objOrderDetails:Order,isGuestUser){
        this.objOperationService.delete(url.mainUrl+"Order/DeleteTileOrder/?OrderId="+objOrderDetails.Id+"&IsGuest="+isGuestUser).subscribe(
            data=>{
                this.emitData.emit(objOrderDetails);
            },error=>{
                this.emitData.emit(objOrderDetails);
            }
        )
    }
	setPMOrder(){
        if( this.objCartDetails.Orders.length > 0 ){

            this.objCartDetails.Orders.forEach((value,index)=>{
                if( this.arrobjOrder.length > 0 ){

                    let order = this.arrobjOrder.filter(item=>item.Id == value.Id);
                    if( order.length == 0 ){

                        this.arrobjOrder.push(value);
                    }
                } else {
                    this.arrobjOrder.push(value);
                }
            });
            this.arrobjOrder.forEach((value,index)=>{
                this.orderDeliveryCost = this.orderDeliveryCost+value.OrderDeliveryInclTax;
                if( !value.isParentOrder ){
                    this.subTotalChildOrders = this.subTotalChildOrders + (value.PriceInclTax - value.DiscountAmountInclTax);
                }
            });
        }
    }
    confirmDelete($event){
        this.deleteTileOrder(this.objOrders,this.checkGuestUser());
    }
    get getMessage(){
        return this.deleteMessage;
    }
    getCurrentCurrencyIcon(){
        var strClass:any;
        if( sessionStorage.getItem("currencyIcon") ){
            strClass = sessionStorage.getItem("currencyIcon");
        }
        return strClass;
    }
    openDeleteOrder(objOrders:Order,isDeleteChanges,clickedButton){
        this.clickedButton = clickedButton;
        this.objOrders = new Order();
        this.objOrders = objOrders;
        this.isDeleteChanges = isDeleteChanges; 
    }
    checkGuestUser():boolean{
        let isGuestUser:boolean = false;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) && JSON.parse(sessionStorage.getItem("IsGuestUser")) ){
                isGuestUser = true;
            } else {
                isGuestUser = false;
            }
        } else {
            isGuestUser = true;
        }
        return isGuestUser;
    }

    public arrobjCertificateTemplates:Array<UploadImage> = new Array<UploadImage>();
    public intProjectId:any;
    downloadTaxCertificate(){
        try{
            window.open(this.arrobjCertificateTemplates[0].FilePath,'_blank');
        }catch{

        }
    }
    getProjectCertificateTemplates(){
        var documentProperties:DocumentProperties = new DocumentProperties();
        documentProperties = this.getImageJSON(this.DocumentTypeCodeEnum.TAXCERTIFICATE,true,this.intProjectId,ObjectTypeEnum.PROJECT)
        
        this.objOperationService.post(url.mainUrl+"DMS/GetDocuments",documentProperties).subscribe(
            data=>{
                this.arrobjCertificateTemplates = data;
            },error=>{

                this.arrobjCertificateTemplates =[];
            }
        )
    }
    getImageJSON(documentType,isDefault,objectId,objectType):DocumentProperties{

        var objDocumentProperties:DocumentProperties = new DocumentProperties();

        objDocumentProperties.DocumentType = documentType;
        objDocumentProperties.IsDefault = isDefault;
        objDocumentProperties.ObjectId = objectId;
        objDocumentProperties.ObjectType = objectType;
        return objDocumentProperties;
    };

    getMaxcharecterCnt(){
        
        return 2; // this.inscriptionCharecterCount;
    }
}

