/// <reference path="../../toastr.d.ts" />
import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import * as url from '../../../environments/environment';
import { NgForm } from '@angular/forms';
declare var jquery: any;
declare var $: any;
import { error } from 'util';
import { loginService } from '../../_services/login.service';
import { operationService } from '../../_services/operation.service';
import { HeaderComponent } from '../layout/header/header.component';
import { DatePipe } from '@angular/common';
import { DataSharedService } from '../../_services/dataSharedService';
import { from ,  Observable } from 'rxjs';
import { setInterval } from 'core-js/library/web/timers';
import { ComponentintractService } from '../../_services/conponentinteraction.service';
import { CustomerModel } from '../../models/user.model';
import { AuthTypeEnum } from '../../models/project.enum';

@Component({
    selector: 'login-model',
    templateUrl: './login.html',
    providers: [loginService, DatePipe]
})
export class LoginComponent implements OnInit {

    @Output() messageEvent = new EventEmitter<string>();
    @Output() boolIsLogin = new EventEmitter<boolean>();
    @Input("boolisChange") isChange:boolean=false;
    @Input("objResourceJSON") objResourceJSON:any={};
    @Input("logoDocumentObject") logoDocumentObject:any={};
    public projectName: string;
    public user: any = {userName:""};
    public signUpData: any = {};
    public newUser: any = {};
    public forgotUser: any = {};
    public resnedOtpEmail: any = {};
    public resetPasswordData: any = {};
    public changePassword:any = {};
    public logoPath: string;
    public message;
    public localText;
    public salt:string;
    public pattern: string = "^(((?=.*[a-z])(?=.*[A-Z])(?=.*[\\d]))|((?=.*[a-z])(?=.*[A-Z])(?=.*[\\W]))|((?=.*[a-z])(?=.*[\\d])(?=.*[\\W]))|((?=.*[A-Z])(?=.*[\\d])(?=.*[\\W]))).{8,15}$";
    public routeUrl: string =""
    public agreeOfTerm: boolean = false;
    public languageData:any = {};
    public ProjectName:string;
    public IsAdminSession:string = sessionStorage.getItem("IsAdmin");
    public Published:any = sessionStorage.getItem("Published");
    public isValidDetail:boolean = true;
    public verifyStatus:boolean = false;
    public boolIsInitiated:boolean=false;
    public boolLoginModal:boolean=true;
    public confirmEmail:any="";
    public isLoginVerification = JSON.parse(sessionStorage.getItem("LoginVerification"));
    public authType:any;
    public objCustomerModel:CustomerModel = new CustomerModel();
    public AuthTypeEnum : typeof AuthTypeEnum = AuthTypeEnum;

    constructor(private objComponentintractService:ComponentintractService,private _loginService: loginService, private datePipe: DatePipe, private _DataSharedService: DataSharedService, private _router: Router, private _activatedRoute: ActivatedRoute, private _operationService: operationService) {
        toastr.options = { positionClass: 'toast-top-right' };
    }
    ngOnInit() { 
        this.boolLoginModal = true; 
        this.authType = atob(sessionStorage.getItem("AuthType"));
        this.projectName = sessionStorage.getItem('ProjectName');
        var arrUrlDetails = window.location.href.split('/');
        this.routeUrl = arrUrlDetails[url.urlIndex];
        $(".eyeSpan").click(function() {
            let id = this.id.split("_")[1];
            if ($(".password_"+id).attr("type") == "password") {
                $(".password_"+id).attr("type", "text");
            } else {
                $(".password_"+id).attr("type", "password");
            }
        });
        this.boolIsInitiated = true;
    }

    get sessionName(){
        return sessionStorage.getItem('ProjectName');
    }
    ngOnChanges(){

        if( this.boolIsInitiated ){
            this.authType = atob(sessionStorage.getItem("AuthType"));
        }
    }
    get getLogo() {

        var logo="";
        if (this.logoDocumentObject.FilePath != undefined) {
            logo = this.logoDocumentObject.FilePath;
        }
        return logo;
    }
    keyEnter(user, loginform: NgForm) {
        if (loginform.valid == true) {
            this.login(user);
        }
    }
    login(user): void {
        if (!sessionStorage.getItem("SessionId")) {
            sessionStorage.setItem("SessionId", this.newGuid());
        }
        // Modal Hide
        $('#petsloginform').modal('hide');
        this._DataSharedService.newUserEmail = user.userName;
        this._DataSharedService.newUserPassword = user.Password;
        let tokenData = "UserName=" + user.userName + "&Password=" + user.Password + "&grant_type=password&scope=" + "BRICKS " + sessionStorage.getItem("SessionId") + ' ' + 'Customer';
        this._loginService.getToken(url.loginUrl + 'token', tokenData).subscribe(
            data => {
                sessionStorage.setItem("userName", user.userName);
                sessionStorage.setItem("access_token", data.access_token);
                $("#loginmodal").modal("hide");
                this.getUserDetails();
                this.updateCart();
            },error => {
                let message = error.json();
                if (message.error_description == "OTP validation required.") {
                    $('#signupotpmodal').modal('show');
                } else {
                    toastr.error(this.languageData.Briconomics.alertMessage.invalid_grant);
                }
            }
        )
    }
    updateCart(){
    
        this._operationService.put(url.bricsAPI+'Cart/UpdateCartOrganization/?organizationId='+sessionStorage.getItem("OrganisationId")+'&&projectId='+sessionStorage.getItem("projectId"),null).subscribe(
            data => {
        
            },error=>{

            }
        )
    }
    /* Create Guid */
    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    /*Login api call*/
    public boolIsGuestUser:boolean;
    setSessionData(data):boolean{

        sessionStorage.setItem("FirstName", data.FirstName);
        localStorage.setItem("FirstName", btoa(data.FirstName));

        sessionStorage.setItem("UserId", data.Id);
        localStorage.setItem("UserId", btoa(data.Id));

        sessionStorage.setItem("isLogedin", "true");
        localStorage.setItem("isLogedin", btoa("true"));

        sessionStorage.setItem("CurentCompanyCode", data.CurCompanyCode);
        localStorage.setItem("CurentCompanyCode",btoa(data.CurCompanyCode));

        sessionStorage.setItem("IsGuestUser", data.IsGuestUser);
        localStorage.setItem("IsGuestUser", btoa(data.IsGuestUser));

        sessionStorage.setItem("IsAdmin", data.IsAdmin);
        localStorage.setItem("IsAdmin", btoa(data.IsAdmin));

        sessionStorage.setItem("Email", data.Email);
        localStorage.setItem("Email", btoa(data.Email));

        sessionStorage.setItem("isOrderReference", "true");
        localStorage.setItem("isOrderReference", btoa("true"));
        
        return true;
    }
    getUserDetails() {

        /*Check for Guest Login*/
        if( sessionStorage.getItem("IsGuestUser") ){
            this.boolIsGuestUser = JSON.parse(sessionStorage.getItem("IsGuestUser"));
        } else {
            this.boolIsGuestUser = false;
        }

        this._loginService.getLoginData(url.SSOAPI+'Login/GetUserDetail').subscribe(
            data => {
                this.setSessionData(data)

                this.Published = sessionStorage.getItem("Published");
                if(this.Published == "false") {
                    
                    if(data.IsAdmin == false) {
                        this.logOut();
                        this._router.navigate([this.routeUrl + '/home']);
                        toastr.error("Sorry, you cannot place order through this website.");
                    }
                }
                if( this.boolIsGuestUser ){
                    this.objComponentintractService.setComponentdata({boolTransferCart:true,objUser:data});
                } else {
                    this.objComponentintractService.setComponentdata({boolGetCart:true,objUser:data});
                }
                this.boolIsLogin.emit(true);
                this._router.navigate([this.routeUrl + '/order-history']);
                // if((sessionStorage.getItem("isLogedin")=="true") && ((window.location.href).search("order-edit")>0 || (window.location.href).search("TILE/Edit")>0 ||  (window.location.href).search("VOU/Edit")>0 )){
                //     this._router.navigate([this.routeUrl + '/cart']);
                // }
            },error => {

            }
        )
    }
    signUp(signUpData, signUpform: NgForm) {
        
        this._DataSharedService.newUserEmail = signUpData.Email;
        this._DataSharedService.newUserPassword = signUpData.Password;
        if (signUpform.invalid) {
            signUpform.errors.required = true
            return;
        }
        if(this.verifyStatus == true) {
            
            if(this.signUpData.VarifyEmail != this.signUpData.Email){
                toastr.error(this.languageData.Briconomics.alertMessage.compareEmailMessage);
                this.isValidDetail = false;
            } else {
                this.isValidDetail = true;
            }
            if(this.signUpData.Password != this.signUpData.ConfirmPassword) {
                toastr.error(this.languageData.Briconomics.alertMessage.comparePasswordMessage);
                this.isValidDetail = false;
            } else {
                this.isValidDetail = true;
            }
        }
        if(this.isValidDetail){
            let signUpJson: any ={
                                    Email: signUpData.Email,
                                    Password: signUpData.Password,
                                    CreatedOn: this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ssZ'),
                                    LastIpAddress: "",
                                    customerProfile: {
                                        FirstName: signUpData.FirstName,
                                        LastName: signUpData.LastName,
                                        Phone1: signUpData.Phone1
                                    },
                                    CompanyCode: sessionStorage.getItem("CompanyCode"),
                                    OrgnizationName: sessionStorage.getItem("OrganizationName"),
                                    OrgnizationLogo: this._DataSharedService.logoPath.split("/")[5] +"/"+this._DataSharedService.logoPath.split("/").pop(),
                                    OrganizationId:sessionStorage.getItem("OrganisationId")
                                }
            if(this.signUpData.VarifyEmail==undefined ||  this.signUpData.VarifyEmail == this.signUpData.Email) {
                this._loginService.getToken(url.umsUrl + 'Admin/Customer/AddCustomerDetails', signUpJson).subscribe(
                    data => {
                        if(sessionStorage.getItem("LoginVerification") == "true") {
                            $('#signupotpmodal').modal('show');
                            $("#signupmodal").modal('hide');
                        } else {
                            $("#signupmodal").modal('hide');
                            this.user.userName = this._DataSharedService.newUserEmail;
                            this.user.Password = this._DataSharedService.newUserPassword;
                            this.login(this.user);
                        }
                        toastr.success(this.languageData.Briconomics.alertMessage.success);
                        this.signUpData = {};
                    },error => {
                        toastr.error(this.languageData.Briconomics.alertMessage.DUPLICATE);
                    }
                ) 
            }
        }
    }
    confirmByOtp(newUserOtp) {
        
        this._loginService.getUser(url.umsUrl + 'Admin/Customer/VerifyCustomerOTP/?email=' + this._DataSharedService.newUserEmail + '&otp=' + newUserOtp.otp).subscribe(
            data => {
                $("#signupotpmodal").modal('hide');
                $("#signupmodal").modal('hide');
                $('body').css('padding-right', '0');
                this.user.userName = this._DataSharedService.newUserEmail;
                this.user.Password = this._DataSharedService.newUserPassword;
                this.login(this.user);
                toastr.success(this.languageData.Briconomics.alertMessage.success);
            }, error => {
                toastr.error(this.languageData.Briconomics.alertMessage.otdDoesNotMatched);
            }
        )
    }
    forgotPassword(forgotEmail) {
    
        var logoImageName = this._DataSharedService.logoPath.split("/")[5] +"/"+this._DataSharedService.logoPath.split("/").pop()
        this._loginService.getToken(url.umsUrl + 'Admin/Customer/ForgotPassword?emailId=' + forgotEmail.Email + '&storedLocation=' + this.routeUrl + '&orgLogo=' + logoImageName + '&orgName=' + sessionStorage.getItem("OrganizationName") + "&CompanyCode=" + sessionStorage.getItem("CompanyCode"), null).subscribe(
            data => {
                $('#forgotpasswordmodal').modal('hide');
                toastr.success(this.languageData.Briconomics.alertMessage.SendMailLabel);
            },error => {
                toastr.error(this.languageData.Briconomics.alertMessage.NoEmailReg);
            }
        )
    }
    resendOtp(resnedOtpEmail) {
        $('#resendotp').modal('hide');
        this._loginService.put(url.umsUrl + 'Admin/Customer/ResendOTP/?email=' + resnedOtpEmail.Email, null).subscribe(
            data => {
                toastr.success(this.languageData.Briconomics.alertMessage.success);
            },error => {
                toastr.error(this.languageData.Briconomics.alertMessage.error);
            }
        )
    }
    resendOtpImmidiate() {
        $('#resendotp').modal('hide');
        this._loginService.put(url.umsUrl + 'Admin/Customer/ResendOTP/?email=' + this._DataSharedService.newUserEmail, null).subscribe(
            data => {
                toastr.success(this.languageData.Briconomics.alertMessage.success);
            },error => {
                toastr.error(this.languageData.Briconomics.alertMessage.error);
            }
        )
    }
    /* Modal Hide and show*/
    hideLoginModal() {
        $('#loginmodal').modal('hide');
    }
    hideNewAccountModal() {
        $('#petssignupform').modal('hide');
    }
    forgotPasswordHideModal() {
        $('#petsforgotpwd').modal('hide');
    }
    hideSignupModal() {
        $('#signupmodal').modal('hide');
    }
    newUserModal() {
        $('#loginmodal').modal('hide');
        $('#signupmodal').css('overflow', 'scroll');
        $('#signupmodal').modal('show');
    }
    alreadyRegister() {
        $('#signupmodal').modal('hide');
        $('#loginmodal').modal('show');
    }
    /* End Modal Hide and show*/
    resetPassword(resetPassword) {
        if(resetPassword.OldPassword != resetPassword.NewPassword){
            toastr.error(this.languageData.Briconomics.alertMessage.passwordNotMatch);
        } else {
            let PasswordSalt = window.location.href.split("/").pop();
            let end = PasswordSalt.lastIndexOf("?");
            if (end >= 0) { 
                PasswordSalt = PasswordSalt.substring(0, end); 
            }
            this.salt = PasswordSalt;
            resetPassword.PasswordSalt = this.salt;
            resetPassword.CompanyCode = sessionStorage.getItem("CompanyCode");
            resetPassword.OrgnizationLogo = this._DataSharedService.logoPath.split("/")[5] +"/"+this._DataSharedService.logoPath.split("/").pop();
            this._loginService.put(url.umsUrl+"Admin/Customer/ResetPassword", resetPassword).subscribe(
                data=>{
                    toastr.success(this.languageData.Briconomics.alertMessage.success);
                    this._router.navigate([this.routeUrl]);
                    $('#resetPassword').modal('hide');
                },error=>{
                    toastr.error(this.languageData.Briconomics.alertMessage.error)
                }
            )
        }
    }
    changeUserPassword(changePassword){
        if(changePassword.NewPassword != changePassword.ConfirmPassword ){
            toastr.error(this.languageData.Briconomics.alertMessage.passwordNotMatch);
        } else {
            changePassword.UserName = sessionStorage.getItem("userName");
            changePassword.CompanyCode = sessionStorage.getItem("CompanyCode");
            changePassword.OrgnizationLogo  =  this._DataSharedService.logoPath.split("/")[5] +"/"+this._DataSharedService.logoPath.split("/").pop()
            this._loginService.put(url.umsUrl+"Admin/Customer/ChangePassword", changePassword).subscribe(
                data=>{
                    $('#ChangePassword').modal('hide');
                    toastr.success(this.languageData.Briconomics.alertMessage.passwordChangeSuccess);
                    this.logOut();
                },error=>{
                    toastr.error(this.languageData.Briconomics.alertMessage.CHANGEPASSWORDFAILED);
                }
            )
        }
    }
    logOut(){
        this._router.navigate([this.routeUrl]);
        sessionStorage.removeItem("isLogedin");
        sessionStorage.removeItem("userName"); 
        sessionStorage.removeItem("SessionId");
        sessionStorage.removeItem("IsGuestUser");
        sessionStorage.removeItem("FirstName");
        sessionStorage.removeItem("access_token"); 
        sessionStorage.removeItem("UserId"); 
        sessionStorage.removeItem("CurentCompanyCode");
        sessionStorage.removeItem("OrderReference"); 
        sessionStorage.removeItem("Email");
    }
    ngAfterViewInit() {
        $('#newAccount').click(function (evt) {
            $('#petsloginform').modal('hide');
            $('#petssignupform').modal('show');
        });
        $('#signUpAccount').click(function () {
            $('#petsloginform').modal('show');
            $('#petssignupform').modal('hide');
        });
    }
    public errorMessage:any="";
    generateUserLoginToken(UserName,Password,OrderReferenceNumber,isValidationRequired){
        let tokenData = "UserName="+UserName+"&Password="+Password+"&grant_type=password&scope="+"BRICKS "+sessionStorage.getItem("SessionId")+' Customer '+isValidationRequired;
        this._loginService.getToken(url.loginUrl + 'token', tokenData,{orderReferenceNumber:OrderReferenceNumber}).subscribe(
            data=>{
                $("#loginmodal").modal("hide");
                sessionStorage.setItem("isLogedin",JSON.stringify(true));
                sessionStorage.setItem("access_token", data.access_token);
                this.getUserDetails();
            },error=>{
                
                toastr.error(this.objResourceJSON.Briconomics.Login.WrongEmailORN);
            }
        )
    }
    userLogin(){
        if( this.authType == "" || this.authType ==AuthTypeEnum.NONSSO ){
            this.findMyorder(this.user.userName,this.user.OrderReferenceNumber);
        } else if( this.authType == AuthTypeEnum.SSO ){
        
            this.generateUserLoginToken(this.user.userName,"",this.user.OrderReferenceNumber,false)
        }
    }
    findMyorder(userEmail,ORN){
        this._operationService.get(url.mainUrl+"cart/FindMyOrder?EmailId="+userEmail+"&orderReferenceNumber="+ORN).subscribe(
            data=>{
                $("#loginmodal").modal("hide");
                this.objCustomerModel = data;
                this.objCustomerModel.IsGuestUser = false;
                this.objCustomerModel.Id = data.CustomerId;
                this.setSessionData(this.objCustomerModel);
                if( this.boolIsGuestUser ){
                    this.objComponentintractService.setComponentdata({boolTransferCart:true,objUser:data});
                } else {
                    this.objComponentintractService.setComponentdata({boolGetCart:true,objUser:data});
                }
                this.boolIsLogin.emit(true);
                this._router.navigate([this.routeUrl + '/order-history']);
            },error=>{
                toastr.error(this.objResourceJSON.Briconomics.Login.WrongEmailORN);
            }
        )
    }
    public boolIsConfirmEmailEntered:boolean=false;
    matchEmail($event){
        this.boolIsConfirmEmailEntered = true;
    }
    enterReferenceData($event){
        
    }
    
    get getEmailPattern(){

        const regex = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/;
        var objEmailRegExp = new RegExp(regex, "i");
        return objEmailRegExp;
    }
}
