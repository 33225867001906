import { ItemModel } from './itemmodel';
import { BaseModel } from './basemodel';
import { UploadModel } from './documentMapping.model'
import { Deserializable } from './Ideserializable';
export class Order extends BaseModel {
	public Id:any;
	public OrderNumber:any;
	public CartId:any;
	public ProductId:any;
	public ItemName:any;
	public ItemDesc:any;
	public ItemType:any;
	public ImageId:any;
	public ParentOrderNumber:any;
	public ProductVarianceId:any;
	public CurrancyCode:any;
	public StoreId:any;
	public CustomerId:any;
	public OrderStatusId:any;
	public Quantity:any;
	public UnitPriceInclTax:any;
	public UnitPriceExclTax:any;
	public PriceInclTax:any;
	public PriceExclTax:any;
	public OrderDeliveryExcTax:any;
	public OrderDeliveryInclTax:any;
	public DiscountAmountInclTax:any;
	public DiscountAmountExclTax:any;
	public OriginalProductCost:any;
	public TaxRates:Array<TaxRate>=[];
	public OrderTax:any;
	public OrderDiscount:any;
	public AttributeDescription:any;
	public AttributesXml:any;
	public RefundedAmount:any;
	public RewardPointsHistoryEntryId:any;
	public CustomerIp:any;
	public Deleted:any;
	public ProductionBatchId:any;
	public ProductionStatusId:any;
	public FilePath:any;
	public productDetails:OrderProductModel=new OrderProductModel();
	public inscriptionData:Array<Inscription>=[];
	public inscriptions:Array<Inscription>=[];
	public DependentOrders:Array<Order>=[];
	public OrderNotes:Array<OrderNote>=[];
	public parentOrderId:any;
	public isParentOrder:any;
	public DependentProductId:Array<number>=[];
	public VoucherCode:any;
	public isDeliveryExist:any;
	public IsReplica:any;
	public PrintTemplate:any;
	public ScreenTemplate:any;
}

export class TaxRate extends ItemModel{
	public Percentage:any;
}
export class OrderProductModel {

	public  Id:any;
	public  ProductCode:any;
	public  ProductName:any;
	public  ProductPrice:any;
	public  ProductDescription:any;
	public  IsCustomerImage:any;
	public  Code:any;
	public  ImagePath:any;
	public  ProductManufacturer:any;
	public  InscriptionLineCount:any;
	public  InscriptionCharecterCount:any;
	public  StockQuantity:any;
	public  PM_MinimumImageHeight:any;
	public  PM_MinimumImageWidth:any;
	public  SoldQuantity:any;
	public  OrderMinQuantity:any;
	public  OrderMaxQuantity:any;
	public  Common:any;
}
export class OrderNote extends BaseModel{
	public  OrderId:any;
	public  Note:any;
	public  DisplayToCustomer:any;
}
export class Inscription implements Deserializable{
	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}
	public Id:any=0;
	public OrderId:any;
	public InscriptionLine:any="";
	public DisplayOrder:any;
}
export class OrderDetails{
	public UploadFileData:UploadModel = new UploadModel();
	public IsGuest:any;
	public CartGuid:any;
	public IsReplica:any;
	public IsCustomerImg:any;
	public ParentOrderNumber:any;
	public ProductId:any;
	public ProductGuid:any;
	public Quantity:any;
	public ProductVarianceId:any;
	public ProductName:any;
	public ProductDesc:any;
	public ProductCost:any;
	public ProductType:any;
	public IsParent:any;
	public PriceInclTax:any;
	public DiscountAmountInclTax:any;
	public ParentProductId:any;
	public ParentOrderId:any;
	public OrderAction:any;
	public OrderId:any;
	public FilePath:any;
	public DocumentId:any;
	public InscriptionAction:any;
	public OrderNumber:any;
	public CartId:any;
	public customerId:any;
	public CustomerId:any;
	public OrderDeliveryExcTax:any;
	public OrderDeliveryInclTax:any;
	public PrintTemplate:any;
	public ScreenTemplate:any;
	public orderAmount:any;
	public inscriptionCharactersPerLine:any;
	public inscriptionDetail:Array<Inscription> = new Array<Inscription>();
	public DependentOrder:any={};
}
export class PhotoMosaicOrder{
	public uploadListImages:Array<OrderDetails> = new Array<OrderDetails>();
	public CustomerId:any;
	public CustomerName:any;
	public CartType:any;
	public OrganizationId:any;
	public ProjectId:any;
	public Notes:any;
	public CreatedBy:any;
	public CreatedOn:any;
	public IsGuest:any;
	public CurrencyId:any;
	public IsAccepted:boolean;
	public IsAccessable:boolean;
}

export enum ModeratorEnum{
	Approved = "MOD_APPROVED",
	Pending = "MOD_PENDING",
	Rejected = "MOD_REJECTED"
}