import { FileModel } from './file.model';
export class CustomerModel{
	public Id:any;
	public CustomerId:any;
	public FirstName:any;
	public MiddleName:any;
	public LastName:any;
	public Email:any;
	public Phone1:any;
	public Phone2:any;
	public Image: FileModel =  new FileModel();
	public DOB:any
	public FullName:string;
	public IsGuestUser:any;

}
export class UserName{
	public FirstName:any;
	public LastName:any;
	public CustomerInformation:Array<CustomerInformation> = new Array<CustomerInformation>();
}
export class UserTokenModal{
	public UserName:any;
	public Password:any;
	public grantType:any;
	public scope:any;

}
export class UserSignUpModel{

	public Email:any="";
	public Password:any;
	public CreatedOn:any;
	public IsVerificationRequired:any;
	public customerProfile:UserName = new UserName();
}
export class CustomerInformation {
	public OrganizationId:any;
	public InfoField1:any;
}
export class AdminLoginModel extends UserSignUpModel {
	public CustomerEmail:any;
	public AdminEmail:any;
}