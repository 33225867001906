import { Component, OnInit,Input,ViewChild,ElementRef,OnChanges, AfterContentChecked, HostListener, Renderer2 } from '@angular/core';
import { operationService } from '../../../_services/operation.service';
import { TranslateService } from '@ngx-translate/core';
import { DataSharedService } from '../../../_services/dataSharedService';
import { DatePipe } from '@angular/common';
import { loginService } from '../../../_services/login.service';
import { Language,MappedLanguageModel } from '../../../models/language.model';
import { DocumentTypeCodeEnum,ObjectTypeEnum } from '../../../models/documenttypecode.enum';
import { Router,ActivatedRoute } from '@angular/router';
import * as url from '../../../../environments/environment';
import { Guid } from '../../../models/guid.model';
import { CartTypeEnum } from '../../../models/project.enum';
import { PreAuthModel, ProductListModel } from '../../../models/productmodel';
import { from ,Observable,interval } from 'rxjs';
import { ImageDocumentUpload } from '../../../models/documentMapping.model';
import { ProductTypeCodeEnum } from '../../../models/producttypeenum';
import { ComponentintractService } from '../../../_services/conponentinteraction.service';
import { ProjectStatus } from '../../../models/project.enum';
@Component({
    selector: 'tilesheader-component',
    templateUrl: './tilesheader.component.html',
    providers: [operationService, DatePipe,loginService]
})
export class TilesHeaderComponent implements OnInit,OnChanges  {
	@Input("objProjectDetails") objProjectDetails:any={};

    @Input() set objProjectTranslationDetail(value: any) {
        this.objProjectTranslationDetail$ = value;  
    }

    // projectStatus: string = sessionStorage.getItem("projectStatus");
    public get ProjectStatus(): typeof ProjectStatus{
        return ProjectStatus;
    }
    PreAuthObj = new PreAuthModel();
    ErrorMessage: string;
    isPreAuthFlag: boolean = false;
    
    @ViewChild('fileUpload') public fileUpload:ElementRef;
    
    @Input("arrobjMappedLanguageModel") arrobjMappedLanguageModel:Array<MappedLanguageModel> = new Array<MappedLanguageModel>();
    objProjectTranslationDetail$:any = {};
    facebook_shere: any = '';
    url: any = '';
    // custom_text = 'Dear Friend, Please check this existing Project.';
    custom_text = 'So excited to be part of this!';
    tweeter_shere: any = '';
    whatsappUrl: any = '';
    public userType: boolean;
    public projectId: any;
    public loggedName: any;
    public loginStatus:boolean = false;
    public boolIsInitiated:boolean = false;
    public ProjectName:string;
    public OrganisationId:number;
    public objOrgLogo:any={};
    public mobileView:boolean=false;
    public storeLocation:any;
    public routeUrl:any;
    public projectCode:any;
    public navBarOptionStatus:boolean = false;
    public boolIsAdminUser:boolean = false;
    public logoPath:string ='';
    public logoDisplayStatus:boolean = false;
    public selectedCurrencyId:any;
    public productGuid:any;
    public boolShowContactUs:boolean=false;
    public arrobjImageDocumentUpload:Array<ImageDocumentUpload> = new Array<ImageDocumentUpload>();
    public arrObjProductListModel:Array<ProductListModel> = new Array<ProductListModel>();
    public objMappedLanguageModel:MappedLanguageModel = new MappedLanguageModel();
    public DocumentTypeCodeEnum : typeof DocumentTypeCodeEnum = DocumentTypeCodeEnum;
    public ProductTypeCodeEnum : typeof ProductTypeCodeEnum = ProductTypeCodeEnum;
    public boolContactUsChange:boolean=false;
    // public ProjectStatus : typeof ProjectStatus = ProjectStatus;
	constructor(private translate: TranslateService,
        private objComponentintractService:ComponentintractService,
        private _DataSharedService : DataSharedService,
        private _router : Router,
        private _operationService: operationService){

	}
	ngOnInit(){
       
        this.facebook_shere = encodeURIComponent(localStorage.getItem('url'));
        this.tweeter_shere = 'Please share with your friends and family so they can also be part of this exciting project.  Thank you for your support! We will post regular order updates and installation news on here and on our Facebook page.&url=' + encodeURIComponent(localStorage.getItem('url')) ;
        this.url = encodeURIComponent(localStorage.getItem('url'));
        this.whatsappUrl = 'whatsapp://send?text=' + encodeURIComponent(localStorage.getItem('url'));
		var arrUrlDetails = window.location.href.split('/');
        this.storeLocation = arrUrlDetails[url.urlIndex];
        this.routeUrl = arrUrlDetails[url.urlIndex];
        this.projectCode = arrUrlDetails[url.urlIndex];
        this.routeUrl = "/"+this.routeUrl;
        this.boolIsInitiated = true;
        if( sessionStorage.getItem("currencyId") ){
            this.selectedCurrencyId = JSON.parse(sessionStorage.getItem("currencyId"))
        }

        this.objComponentintractService.getComponentdata().subscribe(
            data=>{
                if( data!=undefined ){
                    if( data.findOutMore ){
                        this.openProductPopUp('METREP',true)
                    }
                    if( data.boolShowContactUs ){
                        this.boolShowContactUs = true;
                        this.boolContactUsChange = !this.boolContactUsChange;
                    }
                    if( data.isLogOut ){
                        this.logOut();
                    }
                    if( data.boolPlaceOrder ){
                        this.buyNow();
                    }
                    if( data.boolLogin ){
                        this.login(!this.boolChange);
                    }
                }
            }
        )
        if( this.productGuid == null || this.productGuid == "" ){
            interval(2000).subscribe(x => {
                if( sessionStorage.getItem("productGuid") ){
                    this.productGuid = sessionStorage.getItem("productGuid");
                }
            });
        }
	}

    expandMenu(){
        // this.mobileView = !this.mobileView
    }

    openClick(url){
        // whatsapp://send?text=
        window.open('whatsapp://send?text='+ url,"MsgWindow","width=600,height=600")
    }
    
    public boolChange:boolean=false;
    public boolShowLogin:boolean = false;
    login(boolChange:boolean){
        this.boolChange = boolChange;
        this.boolShowLogin=true;
        $("#loginmodal").modal("show");
    }
	ngOnChanges(){
		if( this.boolIsInitiated ){
            var langMappingId = sessionStorage.getItem("defaultLanguageId");
            if( this.arrobjMappedLanguageModel.length > 0 ){
                if( langMappingId == null ){
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.IsDefault==true)[0];
                } else {
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.Id==JSON.parse(langMappingId))[0];
                }
                this.selectLocal(this.objMappedLanguageModel,false);
                if( sessionStorage.getItem("productGuid") ){

                    this.productGuid = sessionStorage.getItem("productGuid");
                }
			    this.setProjectData(this.objProjectDetails);
            }
		}
	}
    showProjectCurrency(){

        var boolShowCurrency:boolean=true;
        if( this.arrobjMappedLanguageModel.length <= 1 ){
            boolShowCurrency = false;
        } else {
            if(JSON.parse(sessionStorage.getItem("isCartAvailable"))){
                boolShowCurrency = false;
            }
        }
        return boolShowCurrency;
    } 
    getLoginStatus(){
        if (sessionStorage.getItem("isLogedin") && JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false && JSON.parse(sessionStorage.getItem("isOrderReference"))) { 
            this.userType = true;
            this.loggedName = sessionStorage.getItem("FirstName");
            if(this.loginStatus == false ){
                this.loginStatus = true;
            }
            return true;
        } else {
            this.userType = false;
            this.loginStatus = false;
            return false;
        }
    }

    getOrderCount(){
        var orderCount:any=0;
        if( sessionStorage.getItem("orderCount") ){
            orderCount = sessionStorage.getItem("orderCount");
        } 
        return orderCount;
    }
    
    setProjectData(data:any){
        this.projectId = data.ProjectId;
        this.ProjectName = data.ProjectName;
        this.OrganisationId = data.OrganisationId;
        this.getLogo();
    }
    /*getlogo*/
    getLogo(){

        var docPreGetData = {ObjectId:this.OrganisationId,ObjectType:"ORGANIZATION",IsDefault:true,DocumentType:this.DocumentTypeCodeEnum.ORGANIZATIONLOGO}
        this._operationService.post(url.bricsAPI+"DMS/GetDocuments",docPreGetData).subscribe(
            data=>{
                this.objOrgLogo = data[0];
                this.logoDisplayStatus = true;
                this.logoPath = data[0].FilePath;
            }
        )
    }
    collapsNavMenu(){

    }
    public boolHideNavBar:boolean=false;
    myCart(){
        if( this.boolHideNavBar ){

            this.collapsNavMenu();
        }
        let customerId:any=0;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                customerId = sessionStorage.getItem("UserId");
            }
        }
        if( sessionStorage.getItem("isLogedin") || sessionStorage.getItem("IsGuestUser")){
            this._operationService.get(url.mainUrl+"Cart/CheckForActiveCart/?projectId="+this.projectId+"&IsGuest="+JSON.parse(sessionStorage.getItem("IsGuestUser"))+"&CustomerId="+customerId).subscribe(
                data=>{
                    sessionStorage.setItem("cartType",data.CartType);
                    if( data !=null ){
                        if( data.CartType == CartTypeEnum.TILESCART ){
                            this._router.navigate([this.routeUrl + '/tiles/' + this.projectId]); 
                        }
                        if( data.CartType == CartTypeEnum.VOUCHERCART ){
                            this._router.navigate([this.routeUrl+'/voucher/'+this.projectId]);
                        }
                    } else {
                        this._router.navigate([this.routeUrl + '/cart']);
                    }
                },error=>{
                    this._router.navigate([this.routeUrl + '/cart']);
                }
            ) 
        } else {
            this._router.navigate([this.routeUrl + '/cart']); 
        }
    }

    buyNow(){
        this.objComponentintractService.setComponentdata({boolLaod:true});
        this.isPreAuthFlag = false;
        this.getProjectStatus();
    }
    getProjectStatus(){
        this._operationService.get(url.bricsAPI+"Project/GetProjectStatus?projectCode="+this.projectCode+"&lang="+sessionStorage.getItem("activeLanguage")).subscribe(
            data=>{
                sessionStorage.setItem("projectStatus",data.ProjectStatus);
                if( data.ProjectStatus == ProjectStatus.OPEN ){
                    this.purchaseProducts();
                } else {
                    toastr.warning(data.Message);
                    this.objComponentintractService.setComponentdata({boolLaod:false});
                }            
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            } 
        )
    }

    purchaseProducts(){
        var orderCount = sessionStorage.getItem("orderCount") == null ? 0:JSON.parse(sessionStorage.getItem("orderCount"));
        if( orderCount == null || orderCount == 0 ){
            this.logOut();
        }
        let customerId:any=0;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                customerId =  sessionStorage.getItem("UserId");
            }
        }
        if( sessionStorage.getItem("isLogedin") || sessionStorage.getItem("IsGuestUser")){

            this._operationService.get(url.mainUrl+"Cart/CheckForActiveCart/?projectId="+this.projectId+"&IsGuest="+JSON.parse(sessionStorage.getItem("IsGuestUser"))+"&CustomerId="+customerId).subscribe(
                data=>{
                    sessionStorage.setItem("cartType",data.CartType);
                    this.PreAuthObj = new PreAuthModel();
                    
                    $("#buynow-modalform-tiles").modal("show");
                    this.objComponentintractService.setComponentdata({boolLaod:false});
                },error=>{
                    $("#buynow-modalform-tiles").modal("show");
                    this.objComponentintractService.setComponentdata({boolLaod:false});
                }
            )       
        } else {
            $("#buynow-modalform-tiles").modal("show");
            this.objComponentintractService.setComponentdata({boolLaod:false});
        }
        if(sessionStorage.getItem("OrganisationId") == undefined || sessionStorage.getItem("OrganisationId") == "" ){
            this.GetProjectByCode();
        }
    }
    GetProjectByCode(){
        this._operationService.get(url.bricsAPI+'Project/GetProjectByCode/?projectCode='+this.storeLocation).subscribe(
            data => {
                sessionStorage.setItem("ProjectName", data.ProjectName);
                sessionStorage.setItem("projectId", JSON.stringify(data.ProjectId));
                sessionStorage.setItem("OrganisationId", JSON.stringify(data.OrganisationId));
                sessionStorage.setItem("OrganisationGuid", data.OrganisationGuid);
                sessionStorage.setItem("ProjectGUID",data.ProjectGUID);
            },error=>{
            
            }
        )  
    }
    scrollTOGiftSection(){
        $("#buynow-modalform-tiles").modal("hide");
        if( sessionStorage.getItem("cartType") != CartTypeEnum.VOUCHERCART ){
            this.logOut();
        }
        this._router.navigate([this.routeUrl+'/voucher/'+this.projectId]);
    }
    public boolIsVoucher:boolean=false;
    public voucherPopUpHeader:any;
    openProductPopUp(strProductType,isPmPage?){
        this.boolIsVoucher = false;
        this.voucherPopUpHeader = "";
        this.arrObjProductListModel = [];
        
        if( strProductType == "VOU" ){
            this.boolIsVoucher = true;
            this.voucherPopUpHeader = this.objProjectTranslationDetail$.GiftVoucherPopUpHeader;
        } else if( strProductType == ProductTypeCodeEnum.TilesProductTypeCode ){
            this.voucherPopUpHeader = this.objProjectTranslationDetail$.KeepsakePopupHeader;
        }
        $("#findoutmore").modal("show");
        if( this.boolHideNavBar && !isPmPage ){

            this.collapsNavMenu();
        }

        if( this.selectedCurrencyId ){
            this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.CurrencyId == this.selectedCurrencyId)[0];
        } else {
            this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.IsDefault==true)[0];
        }
        if( strProductType == ProductTypeCodeEnum.TilesProductTypeCode ){
            this.getAllProduct(strProductType);
        } else if ( strProductType == ProductTypeCodeEnum.VoucherProductTypeCode ){
            this.getVouchers();
        }
    }
    getVouchers(){
        var currentDate = new Date();
        this._operationService.get(url.bricsAPI+"Product/?projectId="+this.projectId+"&documentType=PRODUCT_IMAGE&imageType=THUMBNAIL").subscribe(
            data=>{
                this.arrObjProductListModel = [];
                data.sort(function(a, b){return a.ProductCost - b.ProductCost});
                this.arrObjProductListModel = data.filter(item =>item.ProductTypeCode == ProductTypeCodeEnum.VoucherProductTypeCode && item.Published && item.StockQuantity != "Unlimited" && (Number(item.StockQuantity) >= item.SoldQuantity + item.OrderMinimumQuantity) && new Date(item.AvailableEndDateTime) >= currentDate && new Date(item.AvailableStartDateTime) <= currentDate);
                this.arrObjProductListModel.forEach((value,index)=>{
                    if( value.ProductTypeCode == ProductTypeCodeEnum.VoucherProductTypeCode ){

                        this.getProductImages(value.ProductId,ObjectTypeEnum.PRODUCT,true,DocumentTypeCodeEnum.PRODUCTIMAGE,index);
                    }
                })
            },error=>{

            }
        )
    }
    getAllProduct(productType?) {

        var currentDate = new Date();
        this._operationService.get(url.bricsAPI+"Product/GetTilesProduct/?projectId="+this.projectId+"&imageType=LARGE&CurrentCurrencyId="+sessionStorage.getItem("currencyId")).subscribe(
            data => {
                this.arrObjProductListModel = [];
                data.sort(function(a, b){return a.ProductCost - b.ProductCost});
                //  console.log(data.filter(res => res.Published == true && res.ProductTypeCode == productType));
                this.arrObjProductListModel = data.filter(item => item.ProductTypeCode == productType && item.Published == true);
                this.arrObjProductListModel.forEach((value,index)=>{
                    value.DependentProducts = this.arrObjProductListModel.filter(item=>item.ParentProductId == value.ProductId);
                    if( value.ProductTypeCode == ProductTypeCodeEnum.TilesProductTypeCode ){
                        this.getProductImages(value.ProductId,ObjectTypeEnum.PRODUCT,true,DocumentTypeCodeEnum.PRODUCTIMAGE,index);
                    }
                })
            },error => {

            }
        )
    }

    get defaultActiveLanguage(){
        return sessionStorage.getItem("DefaultLangauge");
    }
    getProductDescriptionByLanguage(objProductModel:ProductListModel){
        try{
            var localLanguageDescription = JSON.parse(objProductModel.Description);
            return localLanguageDescription[this.defaultActiveLanguage];
        } catch{
            
        }
    }
    getProductImages(objectId,ObjectType,IsDefault,documentType,index?){

        var docPreGetData = {ObjectId:objectId,ObjectType:ObjectType,IsDefault:IsDefault,DocumentType:documentType}
        this._operationService.post(url.bricsAPI+"DMS/GetDocuments",docPreGetData).subscribe(
            data=>{
                this.arrobjImageDocumentUpload = data;
                this.arrObjProductListModel[index].productImage = this.arrobjImageDocumentUpload;
            }
        )
    }
    logOut(){
        sessionStorage.removeItem("isLogedin");
        sessionStorage.removeItem("userName"); 
        sessionStorage.removeItem("SessionId");
        sessionStorage.removeItem("IsGuestUser");
        sessionStorage.removeItem("FirstName");
        sessionStorage.removeItem("access_token"); 
        sessionStorage.removeItem("UserId"); 
        sessionStorage.removeItem("orderCount");
        sessionStorage.removeItem("isCartAvailable"); 
        sessionStorage.removeItem("CurentCompanyCode"); 
        sessionStorage.removeItem("OrderReference");
        sessionStorage.removeItem("isOrderReference");
        sessionStorage.removeItem("Email");
        
        sessionStorage.setItem("SessionId",Guid.newGuid());
    }
    buyTilesProduct_OLD(){
        $("#buynow-modalform-tiles").modal("hide");
        this._router.navigate([this.routeUrl+'/tiles/'+this.projectId]);
    }
    buyTilesProduct(){
        $("#buynow-modalform-tiles").modal("hide");
        if( sessionStorage.getItem("cartType") != CartTypeEnum.TILESCART ){
            this.logOut();
        }
        this._router.navigate([this.routeUrl+'/tiles/'+this.projectId]);
    }
    buyVoucher(){
        $("#buynow-modalform-tiles").modal("hide");
        this._router.navigate([this.routeUrl+'/voucher/'+this.projectId]);
    }
    public objResourceJSON:any={};
    getResourceJSON(resourceFile){

        this._operationService.getLanguageJson(url.languageJsonUrl+resourceFile+".json").subscribe(
            data=>{
                this.objResourceJSON = data;
                this._DataSharedService.getLanguageJson = data;
            }
        )
    }
    selectLocal(objMappedLanguageModel:MappedLanguageModel,boolLoad){
        this.objMappedLanguageModel = objMappedLanguageModel;
        sessionStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        localStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        
        sessionStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        localStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        
        sessionStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        localStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        
        sessionStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        localStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        
        sessionStorage.setItem("languageCode",this.objMappedLanguageModel.LanguageCode);
        localStorage.setItem("languageCode",btoa(this.objMappedLanguageModel.LanguageCode));
        
        sessionStorage.setItem("DefaultLangauge",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("DefaultLangauge",btoa(this.objMappedLanguageModel.ResourceFile.trim()));
        
        sessionStorage.setItem("activeLanguage",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("activeLanguage",btoa(this.objMappedLanguageModel.ResourceFile.trim()));
        
        if( boolLoad ){
            this.objComponentintractService.setComponentdata({boolChangeCurrency:true});
        }
        this.translate.setDefaultLang((objMappedLanguageModel.ResourceFile.trim()).toLowerCase());
        this.getResourceJSON((objMappedLanguageModel.ResourceFile.trim()).toLowerCase());
    }
    getLoginDetails($event){
        if( $event ){
            let customerId:any=0;
            if( sessionStorage.getItem("IsGuestUser") ){
                if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                    customerId = sessionStorage.getItem("UserId");
                }
            }
            this._operationService.get(url.mainUrl+"Cart/CheckForActiveCart/?projectId="+this.projectId+"&IsGuest="+JSON.parse(sessionStorage.getItem("IsGuestUser"))+"&CustomerId="+customerId).subscribe(
                data=>{
                    sessionStorage.setItem("orderCount",data.Orders)
                }
            )
        }
    }
    /*Gets*/
    get getCartBag(){
        return "assets/images/icon-bag.png"
    }
    get getOrgLogoObject(){
        return this.objOrgLogo;
    }

    closePreAuthForm(){
        this.ErrorMessage = undefined;
        this.PreAuthObj = new PreAuthModel();
        this.isPreAuthFlag = false;
    }

    
    PreAuthForm(){
        this.PreAuthObj.Id = 0;
        this.PreAuthObj.Project = Number(sessionStorage.getItem("projectId")) 
        this._operationService.post(`${url.bricsAPI}PreAuthorization/PreAuthoriseUser`,this.PreAuthObj).subscribe({
            next: (resp:any)=>{
                // this.buyTilesProduct();  
                this.isPreAuthFlag = true;
                this.PreAuthObj = new PreAuthModel();
            },
            error: (err:any)=>{
               this.ErrorMessage = this.objProjectTranslationDetail$.PreAuthError;
            }
        })

    }


    ErrorSms(){
        this.ErrorMessage = undefined;
    }

    shoandHideMenue(menue){
        if( menue == 'FindMyPhoto' || menue == 'FindMyOrder' || menue == 'BuyNow' || menue == 'MyCart' ){
            return sessionStorage.getItem("projectStatus");
        }
    }


}