import { Component,OnInit,OnChanges,Input,Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { loginService } from '../../../_services/login.service';
import { operationService } from '../../../_services/operation.service';
import { Observable } from 'rxjs';
import { DataSharedService } from '../../../_services/dataSharedService';
import * as url from '../../../../environments/environment';
import { AddressModel,AddressId } from '../../../models/addressmodel';
import { CountryModel } from '../../../models/countrymodel';
import { AuthTypeEnum,CartTypeEnum } from '../../../models/project.enum';
@Component({
  selector: 'app-address',
  templateUrl: './address.html',
  providers: [loginService, operationService]
})
export class AddressComponent implements OnInit,OnChanges{
    
    @Input("boolChange") boolChange:boolean=false;
    @Input("objResource") objResource:any={};
    @Input("cartId") cartId:any;
    @Input("cartType") cartType:any;
    @Input("boolUpdate") boolUpdate:boolean = false;
    @Input("arrobjSelectedAddressModel") arrobjSelectedAddressModel:Array<AddressModel> = new Array<AddressModel>();
    @Output() boolEmitData = new EventEmitter<boolean>();
    @Input("boolSkipShippingAddress") boolSkipShippingAddress:boolean=false;

    public arrobjCopySelectedAddressModel:Array<AddressModel> = new Array<AddressModel>();
    public arrobjAddressModel:Array<AddressModel> = new Array<AddressModel>();
    public objAddressModel:AddressModel = new AddressModel();
    public boolIsinitated:boolean=false;
    public boolIsAddAddressDataChange:boolean=false;
    public boolShowAddressAdd:boolean=false;
    public authType:any;
    public arrobjCountryModel:Array<CountryModel> = new Array<CountryModel>();
    public AuthTypeEnum : typeof AuthTypeEnum = AuthTypeEnum;
    public CartTypeEnum : typeof CartTypeEnum = CartTypeEnum;

    constructor(private objoperationService: operationService, private objloginService: loginService) {
        
    }

    ngOnInit(){
        this.currentIndex = 0;
        this.boolIsinitated = true;
        this.authType = atob(sessionStorage.getItem("AuthType"));
        this.getCountryList();
        this.boolCopyAddress=false;
        if(this.authType == AuthTypeEnum.NONSSO){
            // this.boolShowAddressAdd =true;
            this.strMode = "Add";
            this.generateAddressArray()
        } else {
            this.getCustomerAddressList();
        }
        $("#chooseaddresses").modal("show");
    }

    ngOnChanges(){
        if( this.boolIsinitated ){
            this.boolCopyAddress=false;
            this.currentIndex = 0;
            this.getCountryList();
            this.arrobjAddressModel = new Array<AddressModel>();
            this.authType = atob(sessionStorage.getItem("AuthType"));
            if(this.authType == AuthTypeEnum.NONSSO){
                this.strMode = "Add";
                this.boolIsAddAddressDataChange = !this.boolIsAddAddressDataChange;
                this.generateAddressArray()
            } else {
                this.getCustomerAddressList();
            }
            $("#chooseaddresses").modal("show");
        }
    }

    public boolIsDeliveryAndBillingAddressAvailable:boolean=false;
    getCustomerAddressList(){
        
        this.objloginService.getUser(url.umsUrl+'Admin/Customer/ListCustomerAddress/?id=' + sessionStorage.getItem("UserId")).subscribe(
            data=>{
                this.arrobjAddressModel = data;
                if(this.authType == AuthTypeEnum.NONSSO){
                    // this.mappAddressToCart();
                }
            },error=>{
                if(error.json().ErrorCode == "NOCONTENT"){

                }
            }
        )
    }
    public strMode:string="";
    openAddAddressPopUp(boolIsAddAddressDataChange){
        this.objAddressModel = new AddressModel();
        this.boolIsAddAddressDataChange = boolIsAddAddressDataChange;
        this.strMode = "Add";
        this.boolShowAddressAdd = true;

    }
    get isaddressAvailable(){
        var boolAddressAvailable:boolean=true;
        if( this.arrobjAddressModel.length == 0 ){
            boolAddressAvailable = false;
        }
        return boolAddressAvailable;
    }
    refreshCustomerList($event){
        if( $event ){
            this.boolShowAddressAdd = false;
            this.getCustomerAddressList();
        }
    }
    public boolIsConfirmChange:boolean=true;
    get getDeleteModelName(){
        return "address"
    }
    get isDeleteChanges(){    
        return this.boolIsConfirmChange;
    }
    deleteAddress(boolIsConfirmChange,selectAddresData:AddressModel){

        this.objAddressModel = selectAddresData;
        this.boolIsConfirmChange = boolIsConfirmChange;
    }
    confirmDelete($event){
        this.confirmDeleteAddress();
    }
    confirmDeleteAddress() {
        $("#chooseaddresses").modal("hide");
        this.objoperationService.delete(url.umsUrl + 'Admin/Customer/DeleteCustomerAddress/?id=' + this.objAddressModel.Id).subscribe(
            data => {
                if( this.objResource.Briconomics != undefined ){

                    toastr.success(this.objResource.Briconomics.AddressModule.AddressDeleteSuccess);
                }
                this.objAddressModel = new AddressModel();
                this.boolEmitData.emit(true);
                this.getCustomerAddressList();
            },error => {
                /* API Needs to send flag*/
                if( this.objResource.Briconomics != undefined ){

                    toastr.error(this.objResource.Briconomics.AddressModule.AddressDeleteError);
                }
                this.boolEmitData.emit(true);
            }
        )
    }
    get validateContinueToCart(){
        var isValid:boolean=true;
        var delivery = this.arrobjAddressModel.filter(item=>item.isDelivery);
        var shipping = this.arrobjAddressModel.filter(item=>item.isInvoice);
        
        if( delivery.length == 0 || shipping.length == 0 ){
           isValid = false;
        }
        return isValid;
    }
    setAddressType(objAddressModel:AddressModel){
        
        this.objloginService.put(url.umsUrl + 'Admin/Customer/UpdateCustomerAddress', objAddressModel).subscribe(
            data => { 
                this.getCustomerAddressList();
            },error => {

                toastr.error("Error");
            }
        )
    }

    mappAddressToCart(objMappingIds:any){

        this.objoperationService.put(url.mainUrl+"cart/UpdateCartAddress/?cartId="+this.cartId+"&billingId="+objMappingIds.billingId+"&shippingId="+objMappingIds.shippingId,{}).subscribe(
            data=>{
                
                $("#chooseaddresses").modal("hide");
                this.boolEmitData.emit(true);
            }
        )
    }
    mappAddressToCart_OLD(objMappingIds:any){
        var billingId:any;
        var shippingId:any;
        this.arrobjAddressModel.forEach((value,index)=>{
            if( value.isDelivery ){

                shippingId = value.Id
            }
            if( value.isInvoice ){

                billingId = value.Id;
            }
        })
        this.objoperationService.put(url.mainUrl+"cart/UpdateCartAddress/?cartId="+this.cartId+"&billingId="+billingId+"&shippingId="+shippingId,{}).subscribe(
            data=>{
                
                $("#chooseaddresses").modal("hide");
                this.boolEmitData.emit(true);
            }
        )
    }
    editAddress(objAddressModel:AddressModel,boolIsAddAddressDataChange){
        this.boolIsAddAddressDataChange = boolIsAddAddressDataChange;
        this.strMode = "Edit";
        this.boolShowAddressAdd = true;
        this.objAddressModel = objAddressModel;
        $("#addaddress").modal("show");
    }
    get getUserAddress(){
        return this.objAddressModel;
    }

    get getResourceMapping(){
        return this.objResource;
    }
    public boolCopyAddress:boolean;

    generateAddressArray(){
        
        var objBillingAddress = new AddressModel();
        if( this.arrobjSelectedAddressModel.length == 0 ){
                
            this.arrobjSelectedAddressModel = new Array<AddressModel>();
            
            objBillingAddress.isInvoice = true;
            objBillingAddress.Action = "add";
            objBillingAddress.CustomerId = sessionStorage.getItem("UserId");
            this.arrobjSelectedAddressModel.push(objBillingAddress);
            
            if( !this.boolSkipShippingAddress ){
                let objShippingAddress = new AddressModel();
                objShippingAddress.isDelivery = true;
                objShippingAddress.Action = "add";
                objShippingAddress.CustomerId = sessionStorage.getItem("UserId");
                this.arrobjSelectedAddressModel.push(objShippingAddress); 
            }
        } else {

            if( !this.boolSkipShippingAddress ){
                let deliveryAddress:any = this.arrobjSelectedAddressModel.filter(item=>item.isDelivery==true);
                if( deliveryAddress.length == 0 ){
                    let objShippingAddress = new AddressModel();
                    objShippingAddress.isDelivery = true;
                    objShippingAddress.Action = "add";
                    objShippingAddress.CustomerId = sessionStorage.getItem("UserId");
                    this.arrobjSelectedAddressModel.push(objShippingAddress); 
                }
            }
            objBillingAddress = this.arrobjSelectedAddressModel.filter(item=>item.isInvoice==true)[0]
        }

        this.objAddressModel = objBillingAddress; 
    }
    public currentIndex:any;
    selectAddress(objAddress:AddressModel,index){
        this.currentIndex = index;
        this.objAddressModel = objAddress;
    }
    submitAddress(){
        var validationStatus:any={};
        validationStatus = this.validateAddress();
        var isValidBillingAddress:any = validationStatus.validBillingAddress;
        var isValidShippingAddress:any = validationStatus.validshipingAddress;
        if( !isValidShippingAddress ){
            toastr.error("Please Enter shipping Address");
            return false;
        }
        
        if( !isValidBillingAddress ){
            toastr.error("Please Enter billing address")
            return false;
        }
        this.addAddressBulk(this.arrobjSelectedAddressModel);
    }
    updateAddressSubmit(){

        var validationStatus:any= this.validateAddress();
        var isValidBillingAddress:any = validationStatus.validBillingAddress;
        var isValidShippingAddress:any = validationStatus.validshipingAddress;

        if( !isValidShippingAddress ){
            toastr.error("Please Enter shipping Address");
            return false;
        }
        
        if( !isValidBillingAddress ){
            toastr.error("Please Enter billing address")
            return false;
        }

        this.arrobjSelectedAddressModel.forEach((value,index)=>{
            this.updateAddress(value);
        })
    }
    changeCopy($event){

        if( this.boolCopyAddress ){

            var objBillingAddress = this.arrobjSelectedAddressModel.filter(item=>item.isInvoice==true)[0]; 
            this.arrobjSelectedAddressModel = this.arrobjSelectedAddressModel.map((value,index)=>{
                if( value.isDelivery ){
                    let addressId:any = value.Id; 
                    Object.assign(value,objBillingAddress);
                    value.isDelivery = true;
                    value.isInvoice = false;
                    value.Action = "add";
                    value.Id = addressId;
                }
                return value;
            })
        } else {
            this.arrobjSelectedAddressModel = this.arrobjSelectedAddressModel.map((value,index)=>{
                if( value.isDelivery ){
                    Object.assign(value,new AddressModel());
                    value.isDelivery = true;
                    value.isInvoice = false;
                }
                return value;
            })
        }
        this.currentIndex = 1
        this.objAddressModel = this.arrobjSelectedAddressModel.filter(item=>item.isDelivery==true)[0];
    }

    addNewAddress(objAddress:AddressModel,boolMap?) { 

        objAddress.CustomerId = sessionStorage.getItem("UserId");
        this.objloginService.post(url.umsUrl + 'Admin/Customer/AddCustomerAddress',objAddress).subscribe(
            data => {
                if( this.objResource.Briconomics != undefined ){

                    toastr.success(this.objResource.Briconomics.AddressModule.AddressAddSuccess);
                }
                objAddress.Id = JSON.parse(data.ResponseData);
                this.updateAddressId(JSON.parse(data.ResponseData),objAddress,boolMap);
            },error => {
                $("#addaddress").modal("hide");
                toastr.error("Error");
            }
        )
    }
    public objAddressId:AddressId = new AddressId();
    updateAddressId(addressId:any,objAddress:AddressModel,boolMap){
        var objIds:any={};
        
        if( boolMap ){
            this.objAddressId = new AddressId();
            this.arrobjSelectedAddressModel.forEach((value,index)=>{
                if( value.isDelivery ){
                    this.objAddressId.shippingId = value.Id
                } 
                if( value.isInvoice ) {
                    this.objAddressId.billingId = value.Id  
                } 
            })
            this.mappAddressToCart(this.objAddressId);
        }
    }
    getCountryList() {
        
        this.objoperationService.get(url.umsUrl + 'Admin/Master/GetAllCountries').subscribe(
            data => {
                this.arrobjCountryModel = data;
            },error => {
    
            }
        )
    }
    public boolShowAddressError:boolean=false;
    public addressErrorMessage:any="";
    addAddressBulk(arrobAddress:Array<AddressModel>){

        this.boolShowAddressError = false;
        this.objloginService.post(url.umsUrl+"Admin/Customer/AddCustomerAddresses",arrobAddress).subscribe(
            data=>{
                this.arrobjSelectedAddressModel  = new Array<AddressModel>();
                this.arrobjSelectedAddressModel  = data;
                this.updateAddressId(null,null,true);
            },error=>{
                var objError = JSON.parse(error._body);
                if(objError.Type == "InvalidRequest"){
                    this.boolShowAddressError = true;
                    this.addressErrorMessage = objError.Message;
                    // this.setTimer();
                }
            }
        )
    }
    public objTimer:any;

    /*Set the timer to hide the error message.*/
    setTimer(){

        // set showloader to true to show loading div on view
        this.objTimer     = Observable.timer(5000); // 5000 millisecond means 5 seconds
        var subscription = this.objTimer.subscribe(() => {
            this.boolShowAddressError = false;
        });
    }
    updateAddress(objAddress:AddressModel){
        this.boolShowAddressError = false;
        this.objloginService.put(url.umsUrl + 'Admin/Customer/UpdateCustomerAddress', objAddress).subscribe(
            data => {
                toastr.success(this.objResource.Briconomics.AddressModule.AddressUpdateSuccess);
                this.boolEmitData.emit(true);
                $("#chooseaddresses").modal("hide");
            },error => {
                var objError = JSON.parse(error._body);
                if(objError.Type == "InvalidRequest"){
                    this.boolShowAddressError = true;
                    this.addressErrorMessage = objError.Message;
                    this.setTimer();
                }
            }
        )
    }
    public strClass:any="address-active";
    validateAddress(){
        var isValidBillingAddress:boolean = true;
        var isValidShippingAddress:boolean = true;

        this.arrobjSelectedAddressModel.forEach((value,inde)=>{
            if( value.isInvoice ){
                if(value.CustomerName =="" ){
                    isValidBillingAddress = false;
                }
                if(value.PhoneNumber =="" ){
                    isValidBillingAddress = false;
                }
                if(value.Address1 =="" ){
                     isValidBillingAddress = false;   
                }
                if(value.City =="" ){
                     isValidBillingAddress = false;   
                }
                if(value.ZipPostalCode =="" ){
                     isValidBillingAddress = false;   
                }
                if(value.countryModel.Id == undefined || value.countryModel.Id == 0 ){
                     isValidBillingAddress = false;   
                }
            }
            if( value.isDelivery ){

                if(value.CustomerName =="" ){
                    isValidShippingAddress = false;
                }
                if(value.PhoneNumber =="" ){
                    isValidShippingAddress = false;
                }
                if(value.Address1 =="" ){
                    isValidShippingAddress = false;   
                }
                if(value.City =="" ){
                    isValidShippingAddress = false;   
                }
                if(value.ZipPostalCode =="" ){
                    isValidShippingAddress = false;   
                }
                if(value.countryModel.Id == undefined || value.countryModel.Id == 0 ){
                    isValidShippingAddress = false;   
                }
            }
        })

        return {"validBillingAddress":isValidBillingAddress,"validshipingAddress":isValidShippingAddress};
    }
    closeErrorMessage(){
        this.boolShowAddressError = false;
    }
    ngOnDestroy() {
        // if ( this.subscription && this.subscription instanceof Subscription) {
        //     this.subscription.unsubscribe();
        // }
    }
}

