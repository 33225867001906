import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class ComponentintractService {

    constructor() { }
    private data = new Subject<any>();
    
  	setComponentdata(obj: any) {
        this.data.next(obj);
    }
 
    clearComponentdata() {
        this.data.next();
    }
 
    getComponentdata(): Observable<any> {
        return this.data.asObservable();
    }

}
