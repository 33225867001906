import { Component, OnInit,Input,ViewChild,ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from ,Observable,interval } from 'rxjs';
import { operationService } from '../../../_services/operation.service';
import { DataSharedService } from '../../../_services/dataSharedService';
import * as url from '../../../../environments/environment';
import { Router,ActivatedRoute } from '@angular/router';
import { DomSanitizer,SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { error } from 'util';
import { LowerCasePipe } from '@angular/common/src/pipes/case_conversion_pipes';
import { DatePipe } from '@angular/common';
import { WSAESTALE } from 'constants';
import {Http, Response} from '@angular/http';
import { ComponentintractService } from '../../../_services/conponentinteraction.service';
import { DocumentTypeCodeEnum,ObjectTypeEnum } from '../../../models/documenttypecode.enum';
import { ProjectStatus } from '../../../models/project.enum';
import { Language,MappedLanguageModel, PreRegistrationModel } from '../../../models/language.model';
import { loginService } from '../../../_services/login.service';
import { ProductListModel,PreAuthModel } from '../../../models/productmodel';
import { ItemModel,ItemCode } from '../../../models/itemmodel';
import { ImageDocumentUpload } from '../../../models/documentMapping.model';
import { Guid } from '../../../models/guid.model';
import { AuthTypeEnum,CartTypeEnum } from '../../../models/project.enum';
import { Meta } from '@angular/platform-browser';
// Image exif Orientation 
declare var ImageTools: any;

@Component({
    selector: 'header-component',
    templateUrl: './header.component.html',
    providers: [operationService, DatePipe,loginService]
})
export class HeaderComponent implements OnInit {

    @Input("objProjectDetails") objProjectDetails:any={};
    
    @Input() set objProjectTranslationDetail(value: any) {
            this.objProjectTranslationDetail$ = value;  
    }
    @Input("arrobjMappedLanguageModel") arrobjMappedLanguageModel:Array<MappedLanguageModel> = new Array<MappedLanguageModel>();
    url: any = '';
    
    PreAuthObj = new PreAuthModel();
    ErrorMessage: string;
    isPreAuthFlag: boolean = false;

    objProjectTranslationDetail$: any = {}
    facebook_shere: any = '';
    tweeter_shere: any = '';
    custom_text = 'So excited to be part of this!';
    // custom_text = 'Dear Friend, Please check this existing Project.';
    whatsappUrl: any = '';
    public seletedLanguage: string = "En";
    public seletedLanguageStatus:boolean =true
    public OtherLangauge:any= [];
    public userType: boolean;
    public userName: string;
    public loggedName:string;
    public logoPath:string ='';
    public loginStatus:boolean = false;
    public intialLogoLoad:boolean = true;
    public logoDisplayStatus:boolean = false;
    public headerLoadClass:string;
    public OrganisationId:number;
    public routeUrl: string = "";
    public homePageStatus:boolean ;
    public firstTimePhotomosaicHomePage:boolean = false;
    public firstTimeLoadGenerelHomePage:boolean = false;
    public LanguageLoadStatus:boolean = false;
    public navBarOptionStatus:boolean = false;
    public salt:string;
    public voucherList:any = [];
    public projectId: number;
    public projectDescription:any = {};
    public ProjectName:string;
    public CurrencyHtmlCode:string = sessionStorage.getItem("CurrencyHtmlCode");
    public disabledStatus:boolean = false;
    public myData:any = {};
    public strLanguage:string;
    public boolShowLogin:boolean = false;
    public boolChange:boolean=false;
    public boolShowContactUs:boolean=false;
    public boolContactUsChange:boolean=false;
    public boolIsRefreshed:boolean=false;
    public DocumentTypeCodeEnum : typeof DocumentTypeCodeEnum = DocumentTypeCodeEnum;
    public ProjectStatus : typeof ProjectStatus = ProjectStatus;
    public ObjectTypeEnum : typeof ObjectTypeEnum = ObjectTypeEnum;
    public arrobjLanguage:Array<Language> = new Array<Language>();
    public objDefaultLanguage:Language = new Language();
    public productGuid:any=sessionStorage.getItem("productGuid");
    public objUser:any={};
    public arrObjProductListModel:Array<ProductListModel> = new Array<ProductListModel>();
    public arrobjFileFormate:Array<ItemModel> = new Array<ItemModel>();
    public arrobjcopyVoucher:Array<ProductListModel> = new Array<ProductListModel>();
    public objProductListModel:ProductListModel = new ProductListModel();
    public arrobjImageDocumentUpload:Array<ImageDocumentUpload> = new Array<ImageDocumentUpload>();
    public objImageDocumentUpload:ImageDocumentUpload = new ImageDocumentUpload();
    public isLoginVerification = JSON.parse(sessionStorage.getItem("LoginVerification"));
    public routeBase:any="/fifa";
    public boolIsActive:boolean=false;
    public strCartType:any;
    public projectDetails:any={};
    public className:any="fa-usd";
    public selectedCurrencyId:any;
    public objMappedLanguageModel:MappedLanguageModel = new MappedLanguageModel();
    public boolIsInitiated:boolean=false;
    @ViewChild('fileUpload') public fileUpload:ElementRef;
    public strDefaultLangauge = sessionStorage.getItem("DefaultLangauge");
    public AuthTypeEnum : typeof AuthTypeEnum = AuthTypeEnum;
    public CartTypeEnum : typeof CartTypeEnum = CartTypeEnum;
    public projectCode:any;
    constructor(private _loginService:loginService,
                private translate: TranslateService, 
                private _operationService: operationService, 
                private _router : Router, 
                private _DataSharedService : DataSharedService, 
                private _activatedRoute:ActivatedRoute , 
                private datePipe: DatePipe, 
                private _http: Http,
                private objComponentintractService:ComponentintractService,
                private meta: Meta,
                public sanitizer: DomSanitizer) {
        // this.RefreshProductType();
    }
    public storeLocation:any="";
    ngOnInit(){
        
        this.facebook_shere = encodeURIComponent(localStorage.getItem('url'));
        this.url = encodeURIComponent(localStorage.getItem('url'));
        this.tweeter_shere = 'Please share with your friends and family so they can also be part of this exciting project.  Thank you for your support! We will post regular order updates and installation news on here and on our Facebook page.&url=' + encodeURIComponent(localStorage.getItem('url')) ;
        this.whatsappUrl = 'whatsapp://send?text=' + encodeURIComponent(localStorage.getItem('url'));
        var arrUrlDetails = window.location.href.split('/');
        this.storeLocation = arrUrlDetails[url.urlIndex];
        this.routeUrl = arrUrlDetails[url.urlIndex];
        this.projectCode = arrUrlDetails[url.urlIndex];
        this.routeUrl = "/"+this.routeUrl;
        if( sessionStorage.getItem("productGuid") ){

            this.productGuid = sessionStorage.getItem("productGuid");
        }
        if( sessionStorage.getItem("currencyId") ){
            this.selectedCurrencyId = JSON.parse(sessionStorage.getItem("currencyId"))
        }
        this.objComponentintractService.getComponentdata().subscribe(
            data=>{
                if( data!=undefined ){
                    if( data.findOutMore ){
                        this.openProductPopUp('METREP',true)
                    }
                    if( data.boolShowContactUs ){
                        this.boolShowContactUs = true;
                        this.boolContactUsChange = !this.boolContactUsChange;
                    }
                    if( data.isLogOut ){
                        this.logOut();
                    }
                    if( data.boolPlaceOrder ){
                        this.buyNow();
                    }
                    if( data.boolLogin ){
                        this.login(!this.boolChange);
                    }
                }
            }
        )
        if( this.productGuid == null || this.productGuid == "" ){
            interval(2000).subscribe(x => {
                if( sessionStorage.getItem("productGuid") ){
                    this.productGuid = sessionStorage.getItem("productGuid");
                }
            });
        }
        this.boolIsInitiated = true;
    }
    public defaultLanguageId:any;
    ngOnChanges(){

        if( this.boolIsInitiated ){

            var langMappingId = sessionStorage.getItem("defaultLanguageId");
            if( this.arrobjMappedLanguageModel.length > 0 ){
                if( langMappingId == null ){
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.IsDefault==true)[0];
                } else {
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.Id==JSON.parse(langMappingId))[0];
                }
                this.selectLocal(this.objMappedLanguageModel,false);
                if( sessionStorage.getItem("productGuid") ){

                    this.productGuid = sessionStorage.getItem("productGuid");
                }
                this.setProjectData(this.objProjectDetails);
            }
        }
    }
    getCurrentCurrencyIcon(){
        var strCurrencyUnicode:any;

        this.objMappedLanguageModel.CurrencyIcon

        if( this.objMappedLanguageModel.CurrencyIcon ){
            strCurrencyUnicode = this.objMappedLanguageModel.CurrencyIcon;
        }
        return strCurrencyUnicode;
    }
    ngAfterViewInit(){
        $(function () {
            $(document).scroll(function () {
                var $nav = $(".fixed-top");
                $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
            });
        });
    }
    RefreshProductType(){
        this._operationService.get(url.mainUrl + 'ProductType/RefreshProductType').subscribe(
            data => {       
            
            },error=>{
            
            }
        )
    }
    boolIsVoucher:boolean=false;
    public boolHideNavBar:boolean=false;
    public voucherPopUpHeader:any;
    public giftVoucherPopUpHeader:any;
    public KeepsakePopupHeader:any;
    openProductPopUp(strProductType,isPmPage?){
        this.boolIsVoucher = false;
        this.voucherPopUpHeader = "";

        if( strProductType == "VOU" ){
            this.boolIsVoucher = true;
            this.voucherPopUpHeader = this.objProjectTranslationDetail$.GiftVoucherPopUpHeader;
        } else if( strProductType == 'METREP' ){
            this.voucherPopUpHeader = this.objProjectTranslationDetail$.KeepsakePopupHeader;
        }
        $("#findoutmore").modal("show");
        if( this.boolHideNavBar && !isPmPage ){

            this.collapsNavMenu();
        }

        if( this.selectedCurrencyId ){
            this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.CurrencyId == this.selectedCurrencyId)[0];
        } else {
            this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.IsDefault==true)[0];
        }
        
        this.getAllProduct(strProductType);
    }
    public mobileView:boolean=false;
    expandMenu(){
        this.boolHideNavBar = true;
        // this.mobileView = !this.mobileView;
    }
    collapsNavMenu(){
        if( this.boolHideNavBar ){

            $('.navbar-toggler').trigger( "click" );
        }
    }
    public objResourceJSON:any={};
    getResourceJSON(resourceFile){

        this._operationService.getLanguageJson(url.languageJsonUrl+resourceFile+".json").subscribe(
            data=>{
                this.objResourceJSON = data;
                this._DataSharedService.getLanguageJson = data;
            }
        )
    }
    getLoginStatus(){
        if (sessionStorage.getItem("isLogedin") && JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false && JSON.parse(sessionStorage.getItem("isOrderReference"))) { 
            this.userType = true;
            this.loggedName = sessionStorage.getItem("FirstName");
            if(this.loginStatus == false ){
                this.loginStatus = true;
            }
            return true;
        } else {
            this.userType = false;
            this.loginStatus = false;
            return false;
        }
    }
    logOut(){
        sessionStorage.removeItem("isLogedin");
        sessionStorage.removeItem("userName"); 
        sessionStorage.removeItem("SessionId");
        sessionStorage.removeItem("IsGuestUser");
        sessionStorage.removeItem("FirstName");
        sessionStorage.removeItem("access_token"); 
        sessionStorage.removeItem("UserId"); 
        sessionStorage.removeItem("orderCount");
        sessionStorage.removeItem("isCartAvailable"); 
        sessionStorage.removeItem("CurentCompanyCode"); 
        sessionStorage.removeItem("OrderReference");
        sessionStorage.removeItem("isOrderReference");
        sessionStorage.removeItem("Email");
        
        sessionStorage.setItem("SessionId",Guid.newGuid());
    }
    goToHome(){
        this._router.navigate([this.routeUrl]);
    }
    scrollTOSection(){
        setTimeout(() => {
            window.scrollTo(0, 890);
        },1000);
        var offset = 20;
        $('html, body').animate({
            scrollTop: $("#product-type").offset().top + offset
        }, 1000);
    }
    public objOrgLogo:any={}
    get getOrgLogoObject(){
        return this.objOrgLogo;
    }
    // get list of product
    
    login(boolChange:boolean){
        this.boolChange = boolChange;
        this.boolShowLogin=true;
        $("#loginmodal").modal("show");
    }
    setProjectData(data:any){
        this.projectId = data.ProjectId;
        this.ProjectName = data.ProjectName;
        this.OrganisationId = data.OrganisationId;
        if(this.seletedLanguageStatus == true){
            if(data.ProjectTypeCode == "PM"){
                this.navBarOptionStatus = true;
            }
            this.LanguageLoadStatus = true;
            this.seletedLanguageStatus = false;
            this.OtherLangauge = data.OtherLangauge;
        }
        this.getLogo();
    }
    getLogo(){

        var docPreGetData = {ObjectId:this.OrganisationId,ObjectType:"ORGANIZATION",IsDefault:true,DocumentType:this.DocumentTypeCodeEnum.ORGANIZATIONLOGO}
        this._operationService.post(url.bricsAPI+"DMS/GetDocuments",docPreGetData).subscribe(
            data=>{
                this.objOrgLogo = data[0];
                this.logoDisplayStatus = true;
                this.logoPath = data[0].FilePath;
                this._DataSharedService.logoPath = this.logoPath;
            }
        )
    }
    get allowedFileFormat(){

        return this.filterFileFormat;
    }
    public filterFileFormat:string="";
    getFileFormat() {

        this._operationService.get(url.mainUrl + 'DMS/GetFileTypes').subscribe(
            data => {
                this.arrobjFileFormate = data;
                this.filterFileFormat = "";
                this.arrobjFileFormate.forEach((value,index)=>{
                    
                    if( this.filterFileFormat == "" ){
                        this.filterFileFormat = value.Value
                    } else {

                        this.filterFileFormat = this.filterFileFormat +','+ value.Value;
                    }
                })
            },error => {

            }
        )
    }

    buyNow(){
        this.objComponentintractService.setComponentdata({boolLaod:true});
        this.isPreAuthFlag =false;
        this.getFileFormat();
        this.getProjectStatus();

    }
    
    shoandHideMenue(menue){
        if( menue == 'FindMyPhoto' || menue == 'FindMyOrder' || menue == 'BuyNow' || menue == 'MyCart' ){
            return sessionStorage.getItem("projectStatus");
        }
    }

    getProjectStatus(){
        this._operationService.get(url.bricsAPI+"Project/GetProjectStatus?projectCode="+this.projectCode+"&lang="+sessionStorage.getItem("activeLanguage")).subscribe(
            data=>{
                sessionStorage.setItem("projectStatus",data.ProjectStatus);
                if( data.ProjectStatus == ProjectStatus.OPEN ){
                    this.purchaseProducts();
                } else {
                    toastr.warning(data.Message);
                    this.objComponentintractService.setComponentdata({boolLaod:false});
                }            
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            } 
        )
    }
    purchaseProducts(){
        var orderCount = sessionStorage.getItem("orderCount") == null ? 0:JSON.parse(sessionStorage.getItem("orderCount"));
        if( orderCount == null || orderCount == 0 ){
            this.logOut();
        }
        let customerId:any=0;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                customerId =  sessionStorage.getItem("UserId");
            }
        }
        if( sessionStorage.getItem("isLogedin") || sessionStorage.getItem("IsGuestUser")){

            this._operationService.get(url.mainUrl+"Cart/CheckForActiveCart/?projectId="+this.projectId+"&IsGuest="+JSON.parse(sessionStorage.getItem("IsGuestUser"))+"&CustomerId="+customerId).subscribe(
                data=>{
                    this.strCartType = data.CartType;
                    sessionStorage.setItem("cartType",data.CartType);
                    
                    $("#buynow-modalform").modal("show");
                    this.objComponentintractService.setComponentdata({boolLaod:false});
                },error=>{
                    $("#buynow-modalform").modal("show");
                    this.objComponentintractService.setComponentdata({boolLaod:false});
                }
            )       
        } else {
            $("#buynow-modalform").modal("show");
            this.objComponentintractService.setComponentdata({boolLaod:false});
        }
        if(sessionStorage.getItem("OrganisationId") == undefined || sessionStorage.getItem("OrganisationId") == "" ){
            this.GetProjectByCode();
        }
    }

    checkProjetcStatus(langCode){

        
    }
    get getcartType(){

        // return sessionStorage.getItem("orderCount");
        return sessionStorage.getItem("cartType");
    }
    navigateToUrl(navigateTo){

        this._router.navigate(navigateTo); 
    }
    getOrderCount(){
        var orderCount:any=0;
        if( sessionStorage.getItem("orderCount") ){
            orderCount = sessionStorage.getItem("orderCount");
        } 
        return orderCount;
    }
    getLoginDetails($event){
        if( $event ){
            let customerId:any=0;
            if( sessionStorage.getItem("IsGuestUser") ){
                if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                    customerId = sessionStorage.getItem("UserId");
                }
            }
            this._operationService.get(url.mainUrl+"Cart/CheckForActiveCart/?projectId="+this.projectId+"&IsGuest="+JSON.parse(sessionStorage.getItem("IsGuestUser"))+"&CustomerId="+customerId).subscribe(
                data=>{
                    sessionStorage.setItem("orderCount",data.Orders)
                }
            )
        }
    }
    getAllProduct(productType?) {

        var currentDate = new Date();
        this._operationService.get(url.bricsAPI+'Product/?projectId='+this.projectId+'&documentType=PRODUCT_IMAGE&imageType=THUMBNAIL').subscribe(
            data => {
                this.arrObjProductListModel = [];
                if( productType == 'VOU' ){
                    this.arrObjProductListModel = data.filter(item => item.ProductTypeCode == "VOU" && item.Published && item.StockQuantity != "Unlimited" && (Number(item.StockQuantity) >= item.SoldQuantity + item.OrderMinimumQuantity) && new Date(item.AvailableEndDateTime) >= currentDate && new Date(item.AvailableStartDateTime) <= currentDate);
                    this.arrObjProductListModel.forEach((value,index)=>{
                        if( value.ProductTypeCode == 'VOU' ){

                            this.getProductImages(value.ProductId,ObjectTypeEnum.PRODUCT,true,DocumentTypeCodeEnum.PRODUCTIMAGE,index);
                        }
                    })
                } else if( productType == 'METREP'){

                    this.arrObjProductListModel = data.filter(item => item.ProductTypeCode == productType);
                    this.arrObjProductListModel.forEach((value,index)=>{
                        if( value.ProductTypeCode == 'METREP' ){
                            this.getProductImages(value.ProductId,ObjectTypeEnum.PRODUCT,true,DocumentTypeCodeEnum.PRODUCTIMAGE,index);
                        }
                    })
                }
            },error => {

            }
        )
    }
    getProductImages(objectId,ObjectType,IsDefault,documentType,index?){

        var docPreGetData = {ObjectId:objectId,ObjectType:ObjectType,IsDefault:IsDefault,DocumentType:documentType}
        this._operationService.post(url.bricsAPI+"DMS/GetDocuments",docPreGetData).subscribe(
            data=>{
                this.arrobjImageDocumentUpload = data;
                this.arrObjProductListModel[index].productImage = this.arrobjImageDocumentUpload;
            }
        )
    }
    getPreviouseproductImage(objImage:ImageDocumentUpload,objProductModel:ProductListModel,productIndex,imageIndex){
        // 
        this.objProductListModel = objProductModel;
        this.objImageDocumentUpload = this.objProductListModel.productImage[imageIndex];
    }
    getNextproductImage(objImage:ImageDocumentUpload){

    }
    isActive(){
        var isActive:boolean=true;
        if(sessionStorage.getItem("cartType")){
            if( sessionStorage.getItem("cartType") == "PRODUCT_CART" ){
                isActive = false;
            }
        }
        return isActive;
    }
    getParameter(){
        var urlParameter:any;
        if( sessionStorage.getItem("projectId") ){
            urlParameter = JSON.parse(sessionStorage.getItem("projectId"));
        }
        return urlParameter;
    }
    selectLocal(objMappedLanguageModel:MappedLanguageModel,boolLoad){
        this.objMappedLanguageModel = objMappedLanguageModel;
        sessionStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        localStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        
        sessionStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        localStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        
        sessionStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        localStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        
        sessionStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        localStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        
        sessionStorage.setItem("languageCode",this.objMappedLanguageModel.LanguageCode);
        localStorage.setItem("languageCode",btoa(this.objMappedLanguageModel.LanguageCode));
        
        sessionStorage.setItem("DefaultLangauge",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("DefaultLangauge",btoa(this.objMappedLanguageModel.ResourceFile.trim()));
        
        sessionStorage.setItem("activeLanguage",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("activeLanguage",btoa(this.objMappedLanguageModel.ResourceFile.trim()));
        
        if( boolLoad ){
            this.objComponentintractService.setComponentdata({boolChangeCurrency:true});
        }
        this.translate.setDefaultLang((objMappedLanguageModel.ResourceFile.trim()).toLowerCase());
        this.getResourceJSON((objMappedLanguageModel.ResourceFile.trim()).toLowerCase());
    }
    checkCartAvailability(){
        if( sessionStorage.getItem("isCartAvailable") ){
            return true;
        } else {
            return false;
        }
    }
    setCurrentCurrency(){
        var strClass:any;
        if( sessionStorage.getItem("currencyId") ){
            strClass = sessionStorage.getItem("currencyId");
        }
        return strClass;
    }
    showProjectCurrency(){

        var boolShowCurrency:boolean=true;
        if( this.arrobjMappedLanguageModel.length <= 1 ){
            boolShowCurrency = false;
        } else {
            if(JSON.parse(sessionStorage.getItem("isCartAvailable"))){
                boolShowCurrency = false;
            }
        }
        return boolShowCurrency;
    }

    /*PM Image Upload Code */

    public imagePath: string;
    public imageJson: any = {};
    public file: File;
    public size: any;
    public width: any;
    public height: any;
    public intMaximumImageFileSize: number = 0;
    public intMinImageFileSize: number = 0;
    public languageData: any = {};
    
    /* Convert px to cm */
    public getSizeInCM(sizeInPX) {
       return sizeInPX * 2.54 / (96 * window.devicePixelRatio)
    };
    handleFileSelect($event) {
        
        if( sessionStorage.getItem("cartType") != CartTypeEnum.PRODUCTCART ){
            this.logOut();
        }
        
        this.intMaximumImageFileSize = Number(sessionStorage.getItem("maxfilesize"));
        this.intMinImageFileSize = Number(sessionStorage.getItem("minimumFileSize"));
        this.file = $event.target.files[0];
        this.size = this.file.size;
        let fileSize: number = this.intMaximumImageFileSize * 1048576;
        let minfileSize: number = this.intMinImageFileSize * 1048576;
        if( this.arrobjFileFormate.filter(item=>item.Value == this.file.type).length == 0 ){
            toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.WrongFileFormat);
            return false;
        }
        if (this.intMaximumImageFileSize != null && this.intMaximumImageFileSize != 0) {

            if (fileSize < this.file.size) {
                toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.FileSizemessage + this.intMaximumImageFileSize + " MB")
                return false;
            }
        }

        if (this.intMinImageFileSize != null && this.intMinImageFileSize != 0) {

            if ( minfileSize > this.file.size) {
                    let message = this.intMinImageFileSize >= 1? " MB":" KB";
                    toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.MinFileSizemessage + (this.intMinImageFileSize >= 1? this.intMinImageFileSize:(minfileSize/1024))+message)
                    return false;
            }
        }
        this.getOrientation(this.file, (orientation) => {
            this.getBase64(this.file, orientation);
        });
    }

     // Step 2#
    getOrientation(file, callback) {
        var reader:any,
        target:EventTarget;
        reader = new FileReader();
        reader.onload = (event) => {

          var view = new DataView(event.target.result);

          if (view.getUint16(0, false) != 0xFFD8) return callback(-2);

          var length = view.byteLength,
            offset = 2;

          while (offset < length) {
            var marker = view.getUint16(offset, false);
            offset += 2;

            if (marker == 0xFFE1) {
              if (view.getUint32(offset += 2, false) != 0x45786966) {
                return callback(-1);
              }
              var little = view.getUint16(offset += 6, false) == 0x4949;
              offset += view.getUint32(offset + 4, little);
              var tags = view.getUint16(offset, little);
              offset += 2;

              for (var i = 0; i < tags; i++)
                if (view.getUint16(offset + (i * 12), little) == 0x0112)
                  return callback(view.getUint16(offset + (i * 12) + 8, little));
            }
            else if ((marker & 0xFF00) != 0xFF00) break;
            else offset += view.getUint16(offset, false);
          }
          return callback(-1);
        };

        reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
    };

    // Step 3#
    getBase64(file, orientation) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var base64 = reader.result;
            this.resetOrientation(base64, orientation, (resetBase64Image) => {
              this.dataURItoBlob(resetBase64Image);
            });
          };
        reader.onerror = (error) => {
            
        };
    }

    // Step 4#
    resetOrientation(srcBase64, srcOrientation, callback) {
        var img = new Image();

        img.onload = () => {
          var width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");

          // set proper canvas dimensions before transform & export
          if (4 < srcOrientation && srcOrientation < 9) {
            canvas.width = height;
            canvas.height = width;
          } else {
            canvas.width = width;
            canvas.height = height;
          }

          // transform context before drawing image
          switch (srcOrientation) {
            case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
            case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
            case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
            case 7: ctx.transform(0, -1, -1, 0, height, width); break;
            case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
            default: break;
          }

          // draw image
          ctx.drawImage(img, 0, 0);

          // export base64
          callback(canvas.toDataURL());
        };

        img.src = srcBase64;
      }

      dataURItoBlob(dataURI) {
  
        // convert base64 to raw binary data held in a string
        var byteString = atob(dataURI.split(',')[1]);

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var bb = new Blob([ab], { type: "image/jpeg" });
        ImageTools.resize(bb, {}, ((blob, didItResize) => {
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = (() => {
            var base64data = reader.result;
            this._handleReaderLoaded(base64data);

            // ImageTools.resize(bb, { width: 300, height: 300 }, ((blob, didItResize) => {
            //   var reader = new FileReader();
            //   reader.readAsDataURL(blob);
            //   reader.onloadend = (() => {
            //     var base64data = reader.result;
            //     this.uploadIssueThumbnail(base64data);
            //   });
            // }));
          });
        }));
        return bb;
    }


    uploadIssueThumbnail(base64){
       
    }
    // uploadImage(base64data){

    // }
    _handleReaderLoaded(objReader: any) {

        $("#buynow-modalform").modal("hide");
        var UploadImageCalculate = {'height':this.height,'width':this.width};
        sessionStorage.setItem('UploadImageCalculate', JSON.stringify(UploadImageCalculate));
        var arrString = objReader.split(",");
        this.imagePath = arrString[1];
        this.imageJson = { FileName: this.file.name, FileType: this.file.type, File: this.imagePath };
        this.objComponentintractService.setComponentdata({imageJson:this.imageJson,ShareUploadFile:this.file});
        
        this._DataSharedService.sharedImageJson = this.imageJson;
        this._DataSharedService.ShareUploadFile = this.file;
        // this.uploadingStatus = true;
        this.objComponentintractService.setComponentdata({boolLaod:true});
        setTimeout(() => {
            this._router.navigate([this.routeUrl + '/photo-upload/' + sessionStorage.getItem("productGuid")]);
            this.objComponentintractService.setComponentdata({boolLaod:false});
        }, 2500)
    }
    
    scrollTOGiftSection(){
        $("#buynow-modalform").modal("hide");
        if( sessionStorage.getItem("cartType") != CartTypeEnum.VOUCHERCART ){
            this.logOut();
        }
        this._router.navigate([this.routeUrl+'/voucher/'+this.projectId]);
    }
    gotoPmCartPage(){

        if( sessionStorage.getItem("cartType") == CartTypeEnum.VOUCHERCART ){
            return "";
        }
        $("#buynow-modalform").modal("hide");
        if( sessionStorage.getItem("cartType") != CartTypeEnum.PRODUCTCART ){
            this.logOut();
        }
        this._router.navigate([this.routeUrl + '/photo-upload/' + this.productGuid])
        // this.navigateToUrl(this._router.navigate([this.routeUrl + '/photo-upload/' + this.productGuid]));
    }
    myCart(){
        if( this.boolHideNavBar ){

            this.collapsNavMenu();
        }
        let customerId:any=0;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                customerId = sessionStorage.getItem("UserId");
            }
        }
        if( sessionStorage.getItem("isLogedin") || sessionStorage.getItem("IsGuestUser")){
            this._operationService.get(url.mainUrl+"Cart/CheckForActiveCart/?projectId="+this.projectId+"&IsGuest="+JSON.parse(sessionStorage.getItem("IsGuestUser"))+"&CustomerId="+customerId).subscribe(
                data=>{
                    sessionStorage.setItem("cartType",data.CartType);
                    if( data !=null ){
                        if( data.CartType == CartTypeEnum.PRODUCTCART ){
                            this._router.navigate([this.routeUrl + '/photo-upload/' + this.productGuid]); 
                        }
                        if( data.CartType == CartTypeEnum.VOUCHERCART ){
                            this._router.navigate([this.routeUrl+'/voucher/'+sessionStorage.getItem("projectId")]);
                        }
                    } else {
                        this._router.navigate([this.routeUrl + '/cart']);
                    }
                },error=>{
                    this._router.navigate([this.routeUrl + '/cart']);
                }
            ) 
        } else {
            this._router.navigate([this.routeUrl + '/cart']); 
        }
    }
    get getCartBag(){
        return "assets/images/icon-bag.png"
    }
    get defaultActiveLanguage(){
        return sessionStorage.getItem("DefaultLangauge");
    }
    getProductDescriptionByLanguage(objProductModel:ProductListModel){
        try{
            var localLanguageDescription = JSON.parse(objProductModel.Description);
            return localLanguageDescription[this.defaultActiveLanguage];
        } catch{
            
        }
    }

    public projectByCode:any = {};
    GetProjectByCode(){
        this._operationService.get(url.bricsAPI+'Project/GetProjectByCode/?projectCode='+this.storeLocation).subscribe(
            data => {
                sessionStorage.setItem("ProjectName", data.ProjectName);
                sessionStorage.setItem("projectId", JSON.stringify(data.ProjectId));
                sessionStorage.setItem("OrganisationId", JSON.stringify(data.OrganisationId));
                sessionStorage.setItem("OrganisationGuid", data.OrganisationGuid);
                sessionStorage.setItem("ProjectGUID",data.ProjectGUID);
            },error=>{
            
            }
        )  
    }
    gotToOrgHome(url){
        window.location.href = url;
    }

    
    PreAuthForm(){
        this.PreAuthObj.Id = 0;
        this.PreAuthObj.Project = Number(sessionStorage.getItem("projectId")) 
        this._operationService.post(url.bricsAPI + "PreAuthorization/PreAuthoriseUser",this.PreAuthObj).subscribe({
            next: (resp:any)=>{
                this.isPreAuthFlag = true;
                // this.fileUpload.nativeElement.click();
                // this.objProjectDetails.isPreAuthorisationEnabled = !this.objProjectDetails.isPreAuthorisationEnabled
            },
            error: (err:any)=>{
                this.ErrorMessage = this.objProjectTranslationDetail$.PreAuthError;
            }
        });
    }

    ErrorSms(){
        this.ErrorMessage = undefined;
    }

    closePreAuthForm(){
        this.ErrorMessage = undefined;
        this.isPreAuthFlag = false;
        this.PreAuthObj = new PreAuthModel();
    }

  
}
