export class ProjectTranslationModel {
    public Id:any;
    public ProjectId:any;
    public LanguageId:any;
    public Certificate:any;
    public HomePageHeader:any;
    public HomePageBody:any;
    public FAQ:any;
    public TermsCond:any;
    public BrickCardHeader:any;
    public BrickCardText:any;
    public ThankYouNote:any;
    public AccessabilityTnC:any;
    public ProjectName:any;
    public VoucherPageContent:any;
    public ProjectDescription:any;
    public HomepageContent1Step1:any;
    public HomepageContent1Step2:any;
    public HomepageContent1Step3:any;
    public HomepageContent1Step4:any;
    public HomepageContent2:any;
    public HomePageContent2Header:any;
    public ProductPageHeader:any;
    public ProductPageDescription:any;
    public VoucherPageHeader:any;
    public FMPStep1:any;
    public FMPStep2:any;
    public FMPStep3:any;
    public documentmapping:any;
}