import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { operationService } from '../../../_services/operation.service';
import { DataSharedService } from '../../../_services/dataSharedService';
import * as url from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CartModel } from '../../../models/cart.model';
import { ComponentintractService } from '../../../_services/conponentinteraction.service';
import { CartTypeEnum,PaymentMethodEnum } from '../../../models/project.enum';
import { ObjectTypeEnum } from '../../../models/documenttypecode.enum';

@Component({
	selector: 'app-thankyou',
	templateUrl: './paymentzeroamount.component.html',
	providers: [operationService, DatePipe]
})

export class PaymentZeroAmountComponent implements OnInit {
	public paymentDetails:any = [];
	public paymentSuccessData:any = {billingAddress:{StateProvinceModel:{Value:''}, countryModel:{Value:''}} };
	public orderTree:any = [];
	public logoPath:string;
	public orderreference:string;
	public routeUrl: string = "";
	public projectId: number;
	public ThankYouNote:string="";
	public CurrencyHtmlCode:string = sessionStorage.getItem("CurrencyHtmlCode");
	public objCartDetails:CartModel=new CartModel();
	public activeLanguage:any;
	public cartGUID:any;
    public CartTypeEnum : typeof CartTypeEnum = CartTypeEnum;
    public PaymentMethodEnum : typeof PaymentMethodEnum = PaymentMethodEnum;
    public ObjectTypeEnum : typeof ObjectTypeEnum = ObjectTypeEnum;
	constructor(private objComponentintractService:ComponentintractService,private _router: Router, private route: ActivatedRoute, private _operationService: operationService, private _DataSharedService: DataSharedService, private datePipe: DatePipe) {
		
	}
	ngOnInit() {
		var arrUrlDetails = window.location.href.split('/');
        this.routeUrl = arrUrlDetails[url.urlIndex];
        this.routeUrl = "/"+this.routeUrl;

        this.orderreference = this.route.snapshot.params["reference"];
        this.cartGUID = this.route.snapshot.params["cartGUID"];
        this.boolShoworderSummery = false;

        this.activeLanguage = sessionStorage.getItem("activeLanguage");
		this.projectId = JSON.parse(sessionStorage.getItem("projectId"));
		this.updateZeroCartAfterPayment();
		this.dsabledBack();
	}
	dsabledBack(){
		var that = this;
		history.pushState(null, null, location.href);
		window.onpopstate = function(event) {
			let browserLoaction = window.location.href;
			history.go(1);
		};
	}
	public grandTotal:any=0;
	public subTotalTotal:any=0;
	public grandTotalDeliveryAmount:any=0;
	public show:boolean=false;
    public boolShoworderSummery:boolean=false;

	updateZeroCartAfterPayment(){
		this._operationService.put(url.mainUrl+"Cart/UpdateZeroCartAfterPayment/?cartOrderReference="+this.orderreference,{}).subscribe(
			data=>{

				this.getPaymentSuccessDetails();
			},error=>{
				this.objComponentintractService.setComponentdata({boolLaod:false});
			}
		)
	}
	getPaymentSuccessDetails(){
		this._operationService.get(url.mainUrl+"cart/GetCartByGuid/?cartGuid="+this.cartGUID).subscribe(
			data => {
				this.boolShoworderSummery = true;
				sessionStorage.setItem("orderCount","0");
				this.grandTotal=0;
				this.grandTotalDeliveryAmount=0;
				this.paymentSuccessData = data;
				this.objCartDetails = data;
				this.grandTotalDeliveryAmount = this.grandTotalDeliveryAmount + this.objCartDetails.OrderShippingInclTax;
				this.subTotalTotal = this.subTotalTotal + this.objCartDetails.OrderTotalInclTax - this.objCartDetails.CartDiscountTotalInclTax;
				/**
				this.objCartDetails.Orders.forEach((value,index)=>{
					this.grandTotalDeliveryAmount = this.grandTotalDeliveryAmount + value.OrderDeliveryInclTax;
					this.subTotalTotal = this.subTotalTotal + (value.PriceInclTax - value.DiscountAmountInclTax); //+ value.OrderDeliveryInclTax;
					if(value.DependentOrders.length>0){
	                    this.subTotalTotal = this.subTotalTotal + (value.DependentOrders[0].PriceInclTax - value.DependentOrders[0].DiscountAmountInclTax);                       
	                }
				})
				this.grandTotal = this.subTotalTotal - this.paymentSuccessData.CartVoucherTotalInclTax
				*/
				this.grandTotal = this.subTotalTotal - this.paymentSuccessData.CartVoucherTotalInclTax
				if( this.grandTotal < 0 ){
					this.grandTotal = 0;
				}
				this.objComponentintractService.setComponentdata({boolLaod:false});
				this.confirmOrder();
				this.getProjectTranslationDetail(this.objCartDetails.project.Id,this.ObjectTypeEnum.PROJECT,sessionStorage.getItem("languageCode"));
			}, error => {
				this.objComponentintractService.setComponentdata({boolLaod:false});
				sessionStorage.setItem("orderCount","0");
			}
		)
	}
	get getCartDetails(){
		return this.objCartDetails;
	}
	showCart(){
		this.show =true;
	}
    getProjectTranslationDetail(objectId,objectType,languageCode){
        this._operationService.get(url.mainUrl + 'Project/GetProjectTranslationDetail/?projectId='+objectId+'&LanguageCode='+languageCode).subscribe(
            data => {
                try{

                    this.ThankYouNote = data.ThankYouNote != null? data.ThankYouNote:'';
                } catch{}
            },error=>{

            }
        )
    }

	confirmOrder(){
        
        this._operationService.get(url.mainUrl+"Cart/ConfirmOrder/?cartGuid="+this.cartGUID+"&languageCode="+sessionStorage.getItem("activeLanguage")+"&sentStatus=false").subscribe(
            data=>{

            },error=>{

            }
        )
    }

    getCustomerName(){
        var customerName:string;
        var firstName:string="";
        var lastName:string="";
        if( this.paymentSuccessData.customerDetails != undefined && this.paymentSuccessData.customerDetails != null ){
            firstName = (this.paymentSuccessData.customerDetails.FirstName != undefined && this.paymentSuccessData.customerDetails.FirstName !=null)?this.paymentSuccessData.customerDetails.FirstName:'';
            lastName = (this.paymentSuccessData.customerDetails.LastName != undefined && this.paymentSuccessData.customerDetails.LastName !=null)?this.paymentSuccessData.customerDetails.LastName:'';
        }
        customerName = firstName+" "+lastName;
        return customerName
    }
    getCurrentCurrencyIcon(){
        var strClass:any;
        if( sessionStorage.getItem("currencyIcon") ){
            strClass = sessionStorage.getItem("currencyIcon");
        }
        return strClass;
    }
}
