export class ProjectModel{
	public Id:any;
    public ObjectId:any;
    public ObjectType:any;
    public LanguageId:any;
    public projectGuid:any;
    public projectstatus:any;
    public ProjectName:any;
    public StoreLocation:any;
    public ExternalURL:any;
    public InternalURL:any;
    public ProjectShortDescription:any;
    public ProjectDescription:any;
    public ProjectLearnMoreDescription:any;
    public ImagePath:any;
}
export class ProjectAdditionalModel {

	public Id:any;
	public ProjectId:any;
	public LanguageId:any;
	public Certificate:any;
	public HomePageHeader:any;
	public HomePageBody:any;
	public FAQ:any;
	public TermsCond:any="";
	public BrickCardHeader:any;
	public BrickCardText:any;
	public ThankYouNote:any;
	public documentmapping:any;
	public AccessabilityTnC:any;
	public ProjectName:any;
	public ProjectDescription:any;
	public HomepageContent1Step1:any;
	public HomepageContent1Step2:any;
	public ProjectClosureMessage:any="";
	public ProjectStatusMessage:any="";
	public CountDownMessage:any="";
	public CustomerInfoField1Label:any="";
	public ProductPageHeader:any;
	public HomepageContent1Step3:any;
	public HomePageStepsHeader:any;
	public GiftVoucherPopUpHeader:any;
	public BuyNowPopupHeader:any;
	public ProductPageDescription:any;
	public OrderPlacementInstruction:any;
	public KeepsakePopupHeader:any;
	public HomepageContent1Step4:any;
	public HomepageContent2:any;
	public HomePageContent2Header:any;
	public ProjectFooter:any;
}
export class FMPProjectDetailsModel{
	public ProjectId:any;
	public ProjectGuid:any;
	public IsFMPEnabled:any;
	public FMPBackgroundColor:any;
	public UIConfigurations:FMPConfigurations = new FMPConfigurations();
}
export class FMPConfigurations{
	public DefaultZoomLevel:any=0;
	public MinimumZoomLevel:any=0;
	public MaximumZoomLevel:any=0;
}
export class FindMyPhoto{
	public CartId:any;
	public CartGuid:any;
	public CartReferenceNumber:any;
	public CustomerId:any;
	public X:any;
	public Y:any;
	public Height:any;
	public Width:any;
	public ImageHeight:any;
	public ImageWidth:any;
	public CropX:any;
	public CropY:any;
	public CropHeight:any;
	public CropWidth:any;
	public ImagePath:any;
	public CustomerName:any;
	public customeremailid:any;
}
export class LocatePhotoModel{

	public CartGuid:any;
	public ImagePath:any;
	public CartReferenceNumber:any;
}

export class FindmyphotoWithRefereneNumber{
	public customeremailid:string;
	public CartReferenceNumber:string;
	
};