/// <reference path="../../toastr.d.ts" />
import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { loginService } from '../../_services/login.service';
import { operationService } from '../../_services/operation.service';
import { Observable } from 'rxjs';
import { DataSharedService } from '../../_services/dataSharedService';
// import * as url from '../../baseUrl';
import * as url from '../../../environments/environment';
import { AddressModel } from '../../models/addressmodel';
import { CustomerModel } from '../../models/user.model';

@Component({
    selector: 'app-my-profile',
    templateUrl: './my-profile.component.html',
    providers: [loginService, operationService],
    styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {

    public changePassword:any={};
    public boolIsAddActive:boolean = true;
    public userAddress:AddressModel = new AddressModel();
    public userAddressCopy:AddressModel = new AddressModel();
    public userProfile: any = {};
    public AddressList: any = [];
    public CountryList: any = [];
    public StateList: any = [];
    public CopyCountryList: any = [];
    public CopyCityList: any = [];
    public addUpdateBtnStatus:boolean = true;
    public languageData:any = {}
    public routeUrl: string = window.location.href.split('#').pop().split('/')[1];
    public objCustomerModel:CustomerModel = new CustomerModel();
    public logoPath:any="";
    public pattern:any="";
    constructor(private _operationService: operationService, private _loginService: loginService, private _DataSharedService: DataSharedService) { 
        this.getCustomerProfile();
        this.getCustomerAddressList();
        this.getCountryList();
    }

    ngOnInit() {
        setInterval(() => {
            this.languageData = this._DataSharedService.getLanguageJson;
        }, 100);
    }
    getCustomerProfile() {
        this._loginService.getUser(url.umsUrl + 'Admin/Customer/GetCustomerProfileDetails/?customerid=' + sessionStorage.getItem("UserId")).subscribe(
            data => {
                // this.userProfile = data;
                this.objCustomerModel = data;
            },error => {

            }
        )
    }

    getCustomerAddressList() {
        this._loginService.getUser(url.umsUrl + 'Admin/Customer/ListCustomerAddress/?id=' + sessionStorage.getItem("UserId")).subscribe(
            data => {
                this.AddressList = data;
                this.readeMore();
            }, error => {
                this.AddressList = [];
            }
        )
    }
    getCountryList() {
        this._operationService.get(url.umsUrl + 'Admin/Master/GetAllCountries').subscribe(
            data => {
                this.CountryList = data;
                this.CopyCountryList = JSON.parse(JSON.stringify(this.CountryList));
            },error => {

            }
        )
    }

    readeMore() {
        var that = this;
        $(document).ready(function () {
            var maxL = 30;
            $('.read-more-cart-address1').each(function (i, div) {
                var text = $(div).text();
                if (text.length > maxL) {
                    var begin = text.substr(0, maxL),
                    end = text.substr(maxL);
                    $(div).html(begin).append($(`<a class="more-address-size" id="dynamicId_${i}" style="color:black" data-toggle="modal" data-target="#newsModal"/>`).attr('href', '#').html('...'))
                }
            });
            $('.read-more-cart-address2').each(function (i, div) {
                var text = $(div).text();
                if (text.length > maxL) {
                    var begin = text.substr(0, maxL),
                    end = text.substr(maxL);
                    $(div).html(begin).append($(`<a class="more-address-size" id="dynamicId_${i}" style="color:black" data-toggle="modal" data-target="#newsModal"/>`).attr('href', '#').html('...'))
                }
            });
            $('.cust-name-more').each(function (i, div) {
                var charLength = 18;
                var text = $(div).text();
                if (text.length > charLength) {
                    var begin = text.substr(0, charLength),
                    end = text.substr(charLength);
                    $(div).html(begin).append($(`<a class="more-address-size" id="dynamicId_${i}" style="color:black" data-toggle="modal" data-target="#newsModal"/>`).attr('href', '#').html('...'))
                }
            });
        })
    }

	editAddress(selctedAddress){
		this.userAddress = Object.assign(new AddressModel(),selctedAddress);
		this.userAddressCopy = Object.assign(new AddressModel(),selctedAddress);
		this.addUpdateBtnStatus = false;
		this.boolIsAddActive = false;
	}

	cancel(){
	  
	  	this.userAddress = Object.assign(new AddressModel(),this.userAddressCopy);
	}
	UpdateAddress(){

		this._loginService.put(url.umsUrl + 'Admin/Customer/UpdateCustomerAddress', this.userAddress).subscribe(
			data => {
				this.userAddress = new AddressModel();
				this.getCustomerAddressList();
				this.getCountryList();
				this.addUpdateBtnStatus = true;
				toastr.success(this.languageData.Briconomics.alertMessage.success);
			},error => {
				toastr.error(this.languageData.Briconomics.alertMessage.error);
			}
		)
	}
	addNewAddress(newAddressDetails){
		this.userAddress.CustomerId = parseInt(sessionStorage.getItem("UserId"));
		this._loginService.post(url.umsUrl + 'Admin/Customer/AddCustomerAddress', this.userAddress).subscribe(
			data => {
				this.userAddress = new AddressModel();
				this.getCustomerAddressList();
				toastr.success(this.languageData.Briconomics.alertMessage.success);
			},error => {
				toastr.error(this.languageData.Briconomics.alertMessage.error);
			}
		)
	}

    OLD_addNewAddress(newAddressDetails) {
    
        // let StateListFilter = this.StateList.filter(state => state.Id === newAddressDetails.StateProvinceModel);
        let CountryFilter = this.CountryList.filter(country => country.Id === newAddressDetails.countryModel);
        // let state: any = { Id: StateListFilter[0].Id, Value: StateListFilter[0].Name };
        let country = { Id: CountryFilter[0].Id, Value: CountryFilter[0].Name };
        // isdelivery and isInvoice value change
        let isDelivery, isInvoice;
        if (newAddressDetails.isDelivery) {
            isDelivery = 1
        } else {
            isDelivery = 0
        }
        ////////////
        if (newAddressDetails.isInvoice) {
            isInvoice = 1
        } else {
            isInvoice = 0
        }
        if(!newAddressDetails.AddressNote){
            newAddressDetails.AddressNote = null;
        }
        if(!newAddressDetails.Address2){
            newAddressDetails.Address2 = null;
        }
        // End
        let newAddressJson: any = {
            "CustomerId": parseInt(sessionStorage.getItem("UserId")),
            "CustomerName": newAddressDetails.CustomerName,
            "Address1": newAddressDetails.Address1,
            "Address2": newAddressDetails.Address2 ,
            "ZipPostalCode": newAddressDetails.ZipPostalCode,
            "City": newAddressDetails.City,
            "countryModel": country,
            "StateProvinceModel": newAddressDetails.StateProvinceModel,
            "Longitude": null,
            "Latitude": null,
            "isDelivery": isDelivery,
            "isInvoice": isInvoice,
            "AddressNote": newAddressDetails.AddressNote,
            "PhoneNumber": parseInt(newAddressDetails.PhoneNumber) 
        }
        this._loginService.post(url.umsUrl + 'Admin/Customer/AddCustomerAddress', newAddressJson).subscribe(
            data => {
                this.userAddress = new AddressModel();
                this.getCustomerAddressList();
                toastr.success(this.languageData.Briconomics.alertMessage.success);
            }, error => {
                toastr.error(this.languageData.Briconomics.alertMessage.error);
            }
        )
    }
    addAddress() {
        this.addUpdateBtnStatus = true;
        this.userAddress = new AddressModel();
        this.boolIsAddActive = true;
    }
    deleteAddress(selectAddresData) {
        this._operationService.delete(url.umsUrl + 'Admin/Customer/DeleteCustomerAddress/?id=' + selectAddresData.Id).subscribe(
            data => {
                this.getCustomerAddressList();
                this.userAddress = new AddressModel();
                toastr.success(this.languageData.Briconomics.alertMessage.success);
            }, error => {

            }
        )
    }
    changeUserPassword(strChangePassword){

    }
}
