import { ItemCode,ItemModel } from './itemmodel'
import { Order } from './ordermodel';
import { BaseModel } from './basemodel';
import { AddressModel } from './addressmodel';

export class CartModel extends BaseModel{
	public Id:any;
	public CartReferenceNumber:any;
	public Type:any;
	public CustomerId:any;
	public CurrancyId:any;
	public CartGuid:any;
	public CurrancyCode:any;
	public IsGiftAid:any;
	public CustomerName:any;
	public IsAccepted:boolean=false;
	public IsAccessable:boolean=false;
	public BillingAddressId:any;
	public ShippingAddressId:any;
	public CartStatus:ItemModel = new ItemModel();
	public ShippingStatus:ItemModel = new ItemModel();
	public PaymentStatus:ItemCode = new ItemCode();
	public PaymentMethod:any;
	public OrderTotalInclTax:any = 0;
	public OrderTotalExclTax:any;
	public OrderTotalDiscountInclTax:any;
	public OrderTotalDiscountExclTax:any;
	public CartDiscountTotalInclTax:any=0;
	public CartDiscountTotalExclTax:any;
	public CartVoucherTotalInclTax:any=0;
	public VoucherCode:any;
	public ProjectModerationLevels:any;
	public RejectionCount:any;
	public CartAppliedDiscountId:any;
	public DiscountCode:any;
	public OrderShippingInclTax:any;
	public OrderShippingExclTax:any;
	public PaymentMethodAdditionalFeeInclTax:any;
	public PaymentMethodAdditionalFeeExclTax:any;
	public PaymentTransactionId:any;
	public PaymentTransactionResult:any;
	public PaidDate:any;
	public Deleted:any;
	public CurrencyIcon:any;
	public CurrencyCode:any;
	public CurrencyId:any;
	public ProjectHasGiftAid:any;
	public HasTaxCertificate:any;
	public OrganizationGUID:string;
	public organization:ItemModel = new ItemModel();
	public TaxType:ItemModel = new ItemModel();
	public Moderationstatus:ItemCode = new ItemCode();
	public project:ItemModel = new ItemModel();
	public Orders:Array<Order> = new Array<Order>();
	public billingAddress:AddressModel = new AddressModel();
	public shippingAddress:AddressModel = new AddressModel();
	public customerDetails:any;
	public cartTotalAmount:any;
	public CartLoked:any;
	public Notes:any;
	public CustomerMailId:any;
	public IsInscriptionEditable:boolean;
	public ProductionStatus:ItemCode = new ItemCode();
	public HasCertificate?:boolean;
}

export class ZeroCartPaymentModel{
	
	public errorCode:any;
	public orderReference:any;
	public paymentTypeDescription:any;
	public requestReference:any;
	public settleStatus:any;
	public siteReference:any;
	public transactionReference:any;
	public paymentGatewayConfigId:any;
}