// import { window } from "rxjs/operator/window";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//Local URL
// export const baseUrl = loadJSON('/assets/config.json');

// Test Deployable URL
// export const baseUrl = loadJSON('/eComm/APP/FrontOffice/assets/config.json');

//UAT Deployable URL
// export const baseUrl = loadJSON('/BRICSUAT/APP/Store/assets/config.json');

// live
export const baseUrl = loadJSON('/BRICS/APP/Store/assets/config.json')

//Bricks 20.0 BRICSUAT/APP
// export const baseUrl = loadJSON('/BRICSUAT/APP/FrontOffice/assets/config.json');

export const brics                = "y-R?#5YjHF}nep->BAS;J{v/4YB}^c?E)PMp5M=;<P$j<C*9m";
export const dmsUrl               = baseUrl.urls.dmsUrl;
export const umsUrl               = baseUrl.urls.umsUrl;
export const SSOAPI               = baseUrl.urls.SSOAPI;
export const mainUrl              = baseUrl.urls.mainUrl;
export const loginUrl             = baseUrl.urls.loginUrl;
export const bricsAPI             = baseUrl.urls.bricsAPI;
export const paymentUrl           = "https://webservices.securetrading.net/json/";
export const fileTypeUrl          = baseUrl.urls.fileTypeUrl;
export const userDetailUrl        = "";
export const languageJsonUrl      = "assets/i18n/";
export const encryptionAPIKey     = "kH2tjN0C07sZikn27C6cAhX20aGTz0P4YWjTD3tEijMQ";
export const paymentCallBackUrl   = baseUrl.urls.paymentCallBackUrl;


export const sitekey              = baseUrl.bricsG;
export const minWidth             = baseUrl.minWidth;
export const idleTime             = baseUrl.idleTime;
export const urlIndex             = baseUrl.urlIndex;
export const imageType            = baseUrl.imageType;
export const timeOutTime          = baseUrl.timeOutTime;
export const boolEncryption       = baseUrl.boolEncryption;
export const allowCopyPaste       = baseUrl.allowCopyPaste;
export const boolURLEncryption    = baseUrl.boolURLEncryption;
export const contactCommentLength = baseUrl.contactCommentLength;
// ==============JSON for URLS from external file=======
function loadJSON(filePath) {
  const json = loadTextFileAjaxSync(filePath, "application/json");
  return JSON.parse(json);
}

function loadTextFileAjaxSync(filePath, mimeType) {
  const xmlhttp = new XMLHttpRequest();
  xmlhttp.open("GET", filePath, false);
  if (mimeType != null) {
    if (xmlhttp.overrideMimeType) {
      xmlhttp.overrideMimeType(mimeType);
    }
  }
  xmlhttp.send();
  if (xmlhttp.status == 200) {
    return xmlhttp.responseText;
  }
  else {
    return null;
  }
}

  // Pentechs Server
export const environment = {
  production: false,
  API_URL: 'http://122.166.219.125:5057/'
};

// Amazon server
// export const environment = {
//   production: false,
//   API_URL: 'http://23.21.170.75/'
// };
