export enum DocumentTypeCodeEnum{

	USER 				 		 = "USER",
	VOUCHER				 		 = "VOUCHER",
	INVOICE 				 	 = "INVOICE",
	PMORDER						 = "PMORDER",
	CERTIFICATE 				 = "CERTIFICATE",
	PRODUCTIMAGE 				 = "PRODUCT_IMAGE",
	ORGANIZATION 				 = "ORGANIZATION",
	VOUCHERIMAGE 	    		 = "VOUCHER_IMAGE",
	PROJECTIMAGE 				 = "PROJECT_IMAGE",
	PROJECTSLIDER 				 = "PROJECT_SLIDER",
	MASTERVOUCHER 				 = "MASTERVOUCHER",
	TILEORDERIMAGE 				 = "TILE_ORDER_IMAGE",
	TAXCERTIFICATE 				 = 'TAXCERTIFICATE',
	FRMASTERVOUCHER				 = "FR_MASTERVOUCHER",
	DEMASTERVOUCHER				 = "DE_MASTERVOUCHER",
	PTMASTERVOUCHER				 = "PT_MASTERVOUCHER",
	ENMASTERVOUCHER     		 = "EN_MASTERVOUCHER",
	PRODUCTIONBATCH     		 = "PRODUCTIONBATCH",
	ORGANIZATIONLOGO    		 = "ORGANIZATION_LOGO",
	MASTERCERTIFICATE   		 = "MASTERCERTIFICATE",
	PROJECTSLIDEIMAGE   		 = "PROJECT_SLIDE_IMAGE",
	PRODUCTVARIANTICON  		 = "PRODUCT_VARIANT_ICON",
	PRODUCTIMAGESLIDER  		 = "PRODUCT_IMAGE_SLIDER",
	FRMASTERCERTIFICATE 		 = "FR_MASTERCERTIFICATE",
	DEMASTERCERTIFICATE 		 = "DE_MASTERCERTIFICATE",
	PRODUCTVARIANTIMAGE 		 = "PRODUCT_VARIANT_IMAGE",
	PTMASTERCERTIFICATE 		 = "PT_MASTERCERTIFICATE",
	GUESTTILEORDERIMAGE 		 = "GUEST_TILE_ORDER_IMAGE",
	ENMASTERCERTIFICATE 		 = "EN_MASTERCERTIFICATE",
	CUSTOMERPROFILEIMAGE		 = "CUSTOMER_PROFILE_IMAGE",
	PHOTOMOSAICORDERIMAGE 		 = "PHOTO_MOSAIC_ORDER_IMAGE",
	PRODUCTPHOTOMOSAICIMAGE 	 = "PRODUCT_PHOTOMOSAIC_IMAGE",
	PHOTOMOSAICBACKGROUNDIMAGE 	 = "PHOTOMOSAIC_BACKGROUND_IMAGE",
	GUESTPHOTOMOSAICORDER_IMAGE  = "GUEST_PHOTO_MOSAIC_ORDER_IMAGE",
	ORGANIZATIONBACKGROUNDIMAGE = "ORGANIZATION_BACKGROUND_IMAGE",
	PROJECTBACKGROUNDIMAGE 	    = "PROJECT_BACKGROUND_IMAGE",
	FMPBANNER = 'FMPBANNER',
	FMPIMAGE = 'FMPIMAGE',
	HOMEPAGEIMAGE2 = 'HOMEPAGEIMAGE2',
	HOMEPAGEIMAGE1 = 'HOMEPAGEIMAGE1'
}
export enum ObjectTypeEnum{
	CART = "CART",
	PRODUCT = "PRODUCT", 
	ORDER = "ORDER", 
	GUESTORDER = "GUESTORDER", 
	PRODUCTVARIANT = "PRODUCT_VARIANT",
	PROJECT = "PROJECT",
	ORGANIZATION = "ORGANIZATION"
}

export enum DocumentTypeCategoryEnum{
	ProjectImage = "PROJECT_IMAGE",
	ProjectTemplates = "PROJECT_TEMPLATE",
	OrganizationImage = "ORGANIZATION_IMAGE"
}
export enum FolderCodeEnum{
	PRODUCT = "PRODUCT",
	ATTACHMENTS = "ATTACHMENTS",
	BOOKIMAGES = "BOOKIMAGES",
	Book = "Book",
}