import { Component, OnInit,Input,ViewChild,ElementRef,OnChanges } from '@angular/core';
import { operationService } from '../../../_services/operation.service';
import { TranslateService } from '@ngx-translate/core';
import { DataSharedService } from '../../../_services/dataSharedService';
import { DatePipe } from '@angular/common';
import { loginService } from '../../../_services/login.service';
import { Language,MappedLanguageModel } from '../../../models/language.model';
import { DocumentTypeCodeEnum,ObjectTypeEnum } from '../../../models/documenttypecode.enum';
import { Router,ActivatedRoute } from '@angular/router';
import * as url from '../../../../environments/environment';
import { Guid } from '../../../models/guid.model';
import { CartTypeEnum } from '../../../models/project.enum';
import { ProductListModel } from '../../../models/productmodel';
import { from ,Observable,interval } from 'rxjs';
import { ImageDocumentUpload } from '../../../models/documentMapping.model';
import { ProductTypeCodeEnum } from '../../../models/producttypeenum';
import { ComponentintractService } from '../../../_services/conponentinteraction.service';
import { ProjectStatus } from '../../../models/project.enum';
import { ProjectModel } from '../../../models/project.model';
@Component({
    selector: 'orgheader-component',
    templateUrl: './orgheader.component.html',
    providers: [operationService, DatePipe,loginService]
})
export class OrgHeaderComponent implements OnInit,OnChanges {
	@Input("objOrgDetails") objOrgDetails:any={};
    @Input("objProjectTranslationDetail") objProjectTranslationDetail:any={};
    @Input("arrobjMappedLanguageModel") arrobjMappedLanguageModel:Array<MappedLanguageModel> = new Array<MappedLanguageModel>();

	constructor(private translate: TranslateService,private objComponentintractService:ComponentintractService,private _DataSharedService : DataSharedService,private _router : Router,private _operationService: operationService){}
	

    public ObjectTypeEnum : typeof ObjectTypeEnum = ObjectTypeEnum;
    public DocumentTypeCodeEnum : typeof DocumentTypeCodeEnum = DocumentTypeCodeEnum;
    public objOrgLogo:any={};
    public logoPath:any="";
    public routeUrl: string = "";
    public arrobjProjectModel:Array<ProjectModel> = new Array<ProjectModel>();
    public boolIsInitiated:boolean=false;
    public objMappedLanguageModel:MappedLanguageModel = new MappedLanguageModel();

    ngOnInit(){

        var arrUrlDetails = window.location.href.split('/');
        this.routeUrl = arrUrlDetails[url.urlIndex];
        this.routeUrl = "/"+this.routeUrl;
        this.boolIsInitiated = true;
        this.getLogo(this.objOrgDetails.Id,ObjectTypeEnum.ORGANIZATION,this.DocumentTypeCodeEnum.ORGANIZATIONLOGO);
    }
	ngOnChanges(){
        if( this.boolIsInitiated ){
            var langMappingId = sessionStorage.getItem("defaultLanguageId");
            if( this.arrobjMappedLanguageModel.length > 0 ){
                if( langMappingId == null ){
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.IsDefault==true)[0];
                } else {
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.Id==JSON.parse(langMappingId))[0];
                }
                this.selectLocal(this.objMappedLanguageModel);
            }
            this.getprojectList(this.objOrgDetails.Id,this.objMappedLanguageModel.ResourceFile);
            this.getLogo(this.objOrgDetails.Id,ObjectTypeEnum.ORGANIZATION,this.DocumentTypeCodeEnum.ORGANIZATIONLOGO);
        }
    }
    selectLocal(objMappedLanguageModel:MappedLanguageModel){
        this.objMappedLanguageModel = objMappedLanguageModel;
        sessionStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        localStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        
        sessionStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        localStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        
        sessionStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        localStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        
        sessionStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        localStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        
        sessionStorage.setItem("languageCode",this.objMappedLanguageModel.LanguageCode);
        localStorage.setItem("languageCode",btoa(this.objMappedLanguageModel.LanguageCode));
        
        sessionStorage.setItem("DefaultLangauge",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("DefaultLangauge",btoa(this.objMappedLanguageModel.ResourceFile.trim()));
        
        sessionStorage.setItem("activeLanguage",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("activeLanguage",btoa(this.objMappedLanguageModel.ResourceFile.trim()));

        this.translate.setDefaultLang((objMappedLanguageModel.ResourceFile.trim()).toLowerCase());
        this.getResourceJSON((objMappedLanguageModel.ResourceFile.trim()).toLowerCase());
    }

    getLogo(objectId,objectType,docType){
        var docPreGetData = {ObjectId:objectId,ObjectType:objectType,IsDefault:true,DocumentType:docType}
        this._operationService.post(url.bricsAPI+"DMS/GetDocuments",docPreGetData).subscribe(
            data=>{
                this.objOrgLogo = data[0];
                this.logoPath = data[0].FilePath;
            }
        )
    }
    getprojectList(objectId,languageCode){
        this._operationService.get(url.bricsAPI+"Translations/GetProjectList/?ObjectId="+objectId+"&LanguageCode="+languageCode).subscribe(
            data=>{
                this.arrobjProjectModel = data;
            },error=>{

            }
        )
    }
    
    gotToProject(objProjectModel:ProjectModel){
        if( url.environment.production == true ){
            window.location.href = objProjectModel.ExternalURL;
        } else {
            this._router.navigate([objProjectModel.StoreLocation+'/home']);
        }
        
    }
    showProjectCurrency(){

        var boolShowCurrency:boolean=true;
        if( this.arrobjMappedLanguageModel.length <= 1 ){
            boolShowCurrency = false;
        } else {
            if(JSON.parse(sessionStorage.getItem("isCartAvailable"))){
                boolShowCurrency = false;
            }
        }
        return boolShowCurrency;
    }
    getCurrentCurrencyIcon(){
        var strCurrencyUnicode:any;

        this.objMappedLanguageModel.CurrencyIcon

        if( this.objMappedLanguageModel.CurrencyIcon ){
            strCurrencyUnicode = this.objMappedLanguageModel.CurrencyIcon;
        }
        return strCurrencyUnicode;
    }
    public objResourceJSON:any={};
    getResourceJSON(resourceFile){

        this._operationService.getLanguageJson(url.languageJsonUrl+resourceFile+".json").subscribe(
            data=>{
                this.objResourceJSON = data;
                this._DataSharedService.getLanguageJson = data;
            }
        )
    }
}