import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Guid } from '../models/guid.model';
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private _router: Router) { }
 
    canActivate() {
        
        if(!sessionStorage.getItem("SessionId")){

            sessionStorage.setItem("SessionId", Guid.newGuid());
        }
        if( sessionStorage.getItem("SessionId") != localStorage.getItem("SessionId") ){
            localStorage.setItem("SessionId",sessionStorage.getItem("SessionId"));
        }
        // if (sessionStorage.getItem("isLogedin")) {
        //     // logged in so return true         
        //     return true;
        // }
        //  // not logged in so redirect to login page
        // else{
        //     this._router.navigate(['/home']);
        // } 
        // return false;
        // let splitPath = window.location.href.split("/").pop();
        // this._router.navigate([splitPath]);
        return true;
    }
}