import { Injectable,InjectionToken,Inject } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { NgForm,ControlValueAccessor,FormControl,NgControl,Validators,AbstractControl } from '@angular/forms';

// export const RECAPTCHA_URL = new InjectionToken('RECAPTCHA_URL');

enum RequestMethod {
    Get,
    Post,
    Put,
    Delete,
    Options,
    Head,
    Patch
}

@Injectable()
export class ReCaptchaAsyncValidator {

    public url = "http://127.0.0.1:4200/assets/js/recaptcha.server.js"
    constructor( private http : Http) {}

    post(Url: string,data:any) {
        return this.http.post(Url, data, this.jwt()).map((response: Response) => response.json());
    }

    public jwt() {
        let Pathurl = window.location.href;
        let searchStr = Pathurl.search("localhost");
        if (searchStr > 0) {
            //Local
            Pathurl = Pathurl.split('/').slice(0, 3).join('/');
            
        }
        else {
            //Server
            Pathurl = Pathurl.split('/').slice(0, 4).join('/');
        }
        sessionStorage.setItem('Pathurl', Pathurl)
        let headers = new Headers({'Accept' : '*/*','Access-Control-Allow-Origin':'*','Access-Control-Allow-Credentials':true ,'Access-Control-Allow-Methods': 'POST','Access-Control-Allow-Headers':'Content-Type','apptype': 'Web', 'content-type': "application/json"});
        return new RequestOptions({ headers: headers, method: RequestMethod.Get });
    }
     validateToken( token : string ) {
        return ( _ : AbstractControl ) => {
          return this.http.get(this.url, { params: { token } }).map(res => res.json()).map(res => {
            if( !res.success ) {
              return { tokenInvalid: true }
            }
            return null;
          });
        }
    }
}


