import { BaseModel } from './basemodel';
import { ItemModel } from './itemmodel';
export class EnquiryData extends BaseModel{
    
    public Id:any;
    public CustomerId:any;
    public CustomerName:any;
    public CustomerEmail:any;
    public QueryContent:any="";
    public PhoneNumber:any;
    public OrganizationId:any;
    public ProjectId:any;
    public QuestionType:ItemModel=new ItemModel();
	public ReferenceNumber:any;
    public OrderReferenceNo:any;
    public MessageReferenceNumber:any;
	public IsRead:any;
    public HasAutoResponse:any;
	public AdminReply:any;
}
