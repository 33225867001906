import { Inject,Component, OnInit,ViewChild,ElementRef,LOCALE_ID } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { NgForm,FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { error } from 'util';
import { operationService } from '../../_services/operation.service';
import { DataSharedService } from '../../_services/dataSharedService';
import * as url from '../../../environments/environment';
import { loginService } from '../../_services/login.service';
import { Observable ,  concat } from 'rxjs';
import { DomSanitizer,SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { forEach } from '@angular/router/src/utils/collection';
import { DatePipe } from '@angular/common';
import { ProductModel,DependentProduct } from '../../models/productmodel';
import { ProductVariances } from '../../models/productmodel';
import { Order,Inscription } from '../../models/ordermodel';
import { OrderDetails,PhotoMosaicOrder } from '../../models/ordermodel';
import { CartModel,ZeroCartPaymentModel } from '../../models/cart.model';
import { UserSignUpModel,UserTokenModal,CustomerInformation } from '../../models/user.model';
import { AddressModel } from '../../models/addressmodel';
import { ProductTypeEnum } from '../../models/producttypeenum';
/// <reference path="../../toastr.d.ts" />
import { PaymentModel,PaymentGatewayType } from '../../models/payment.model';
import { BlobConvertorService } from '../../_services/blobconvertor.service';
import { DocumentTypeCodeEnum,ObjectTypeEnum,FolderCodeEnum } from '../../models/documenttypecode.enum';
import { ApplyVoucherModel } from '../../models/voucherorder.model';
import { MappedLanguageModel } from '../../models/language.model';
import { CartTypeEnum,PaymentMethodEnum } from '../../models/project.enum';
import { ProjectAdditionalModel } from '../../models/project.model';
import { BuildDmsJSONService } from '../../_services/builddmsjsonservice';
import { ComponentintractService } from '../../_services/conponentinteraction.service';
import { ReCaptchaAsyncValidator } from '../../_services/recaptchaasyncvalidator.service';
import { ItemModel,ItemCode } from '../../models/itemmodel';
import { ProjectTranslationModel } from '../../models/projecttranslationdetail.model';
import { CustomerModel } from '../../models/user.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProjectStatus } from '../../models/project.enum';
import { locale } from 'moment';

// Image exif Orientation 
declare var ImageTools: any;

@Component({
  selector: 'app-root',
  templateUrl: './photo-upload.html',
  providers: [ReCaptchaAsyncValidator,operationService, loginService, DatePipe,BlobConvertorService,BuildDmsJSONService]
})
export class PhotoUploadComponent implements OnInit {

    public imagePath: string = "./assets/images/no-image-available.jpg";
    public uploadDetails: any = {intialLabel:""};
    public sizeArray: any = [];
    public uploadSize: any = 2;
    public ProductGuid: any;
    public objProductDetails:ProductModel = new ProductModel();
    public dependetnProduct: any = [];
    public relatedProduct: any = [];
    public CopyDependetnProduct: any = [];
    public CopyRelatedProduct: any = [];
    public phtoMosaicJson: any = { uploadListImages: []};
    public projectId: number;
    public inscriptionCount: number;
    public buttonClickCount: number = 1;
    public flagCount: number = 1;
    public uploadFlag: boolean = false;
    public FileFormatData: any = {};
    public objResourceJSON: any = {};
    public uploadJsonData: any = {};
    public firstTimeUpload: boolean = true;
    public uploadImageStatus: boolean = true;
    public InscriptionCharactersPerLine: number=5;
    public appendDivStatus: boolean = true;
    public oneClick: boolean = false;
    public listOfFrames: any = [];
    public CopylistOfFrames:any = [];
    public logoPath: string;
    public routeUrl: string = ""
    public productQuantity: number;
    public framQty: number;
    public parentProductId:number;
    public Copy_parentProductId:number
    public uploadImageToCart:boolean = false;
    public continueBtnStatus:boolean = false;
    public dependentBtnStatus:boolean = false;
    public firstCroppingCount:number = 0;
    public FrameListArray:any = [];
    public photoUploadSuccessStatus:boolean = false;
    public uploadImageCropBtn:boolean = false;
    public languageData:any = {};
    public CurrencyHtmlCode:string = sessionStorage.getItem("CurrencyHtmlCode");
    public CopyMyDefaultOrderList: any;
    public selectedItemProductId:number;
    public resetStatus:boolean = false;
    public myOrderList: any;
    public arrobjInscriptionLine:any=[];
    public intIncriptionRowCount:number=1;
    public IsDefaultImageFlag:boolean=false;
    public objDependentProduct:DependentProduct = new DependentProduct();
    public arrobjDependentProduct:Array<DependentProduct> = new Array<DependentProduct>();
    public imageJson: any = {};
    public imageJsonCopy: any = {};
    public strBase64:any;
    public strSelectedImageBase64:any;
    public file: File; 
    public width:any;
    public height:any;
    public size:any;
    public intPhotomosaicMinWidth:number;
    public intPhotomosaicMinHeight:number;
    public intMaximumImageFileSize:number=0;
    public selectedImageTrusted:any;
    public objOrder:Order;
    public arrobjOrder:Array<Order> = new Array<Order>();
    public boolTC:boolean=false;
    public objPhotoMosaicOrder:PhotoMosaicOrder=new PhotoMosaicOrder();
    public objOrderDetails:OrderDetails = new OrderDetails();
    public arrobjOrderDetails:Array<OrderDetails> = new Array<OrderDetails>();
    public objUser:any={};
    public objPMOrder:Order = new Order();
    public orgId:any = sessionStorage.getItem("OrganisationId");
    public organisationGuid:any = sessionStorage.getItem("OrganisationGuid");
    public objCartDetails:CartModel=new CartModel();
    public productId:any;
    public deleteMessage:string;
    public orderId:any;
    public subTotalChildOrders:any=0;
    public deleteModelName:string="-address";
    public boolIsConfirmChange:boolean=true;
    public objUserTokenModal:UserTokenModal = new UserTokenModal();
    public userAddress:AddressModel = new AddressModel();
    public objAddressModel:AddressModel = new AddressModel();
    public objBillingAddressModel:AddressModel = new AddressModel();
    public objShippingAddressModel:AddressModel = new AddressModel();
    public arrobjSelectedAddressModel:Array<AddressModel> = new Array<AddressModel>();
    public arrobjAddressModel:Array<AddressModel> = new Array<AddressModel>();
    public intMinImageFileSize: number = 0;
    @ViewChild('keepshakesection') keepshakesection:ElementRef;
    @ViewChild('pageTop') pageTop:ElementRef;
    @ViewChild('pmImage') pmImage:ElementRef;
    @ViewChild('fileUpload') fileUpload:ElementRef;
    @ViewChild('paymentForm') paymentForm:ElementRef;
    @ViewChild('addressForm') addressForm:ElementRef;
    @ViewChild("imageCrop") imageCrop:ElementRef;
    @ViewChild('iframe') iframe: ElementRef;
    public objUserSignUpModel:UserSignUpModel=new UserSignUpModel();
    public arrobjPaymentModel:Array<PaymentModel> = new Array<PaymentModel>(); 
    public objPaymentModel:PaymentModel = new PaymentModel(); 
    public paymentSuccessUrl:any;
    public paymentCancel:any;
    public objApplyVoucherModel:ApplyVoucherModel = new ApplyVoucherModel();
    public DiscountCode:string;
    public captureDiscount:string;
    public selectedCurrencyId:any;
    public confirmEmail:any="";
    public strFileName:any;
    public boolIsVoucherApplied:boolean=false;
    public orderDeliveryCost:any=0;
    public addUpdateBtnStatus:boolean=true;
    public CountryList:any=[];
    public CopyCountryList:any=[];
    public paymentData:any={};
    public paymentInfo:any={};
    public boolIsImageCropChange:boolean=false;
    public showCropImage:boolean=false;
    public objPaymentZero:ZeroCartPaymentModel=new ZeroCartPaymentModel();
    public boolIsConfirmEmailEntered:boolean=false;
    public languageCode:any;
    public showAddressList:boolean=false;
    public arrobjFileFormate:Array<ItemModel> = new Array<ItemModel>();
    public ProjectStatus : typeof ProjectStatus = ProjectStatus;
    public objCustomerModel:CustomerModel = new CustomerModel();
    public prveImgUrl:string;
    public strsiteKey:any;
    public boolIsGuestUser:boolean=true;
    public authType:any;
    public PaymentGatewayType : typeof PaymentGatewayType = PaymentGatewayType;
    public isPaymentDataChange:boolean=false;

    public customerId:any=0;
    public addressModalName:string;
    public multiBankoCheckOutData:any;
    public boolSubmit:boolean=false;
    public boolSkipAddress:boolean=false;
    public strDefaultLanguage:any;
    public projectGuid:any;
    public CartTypeEnum : typeof CartTypeEnum = CartTypeEnum;
    public PaymentMethodEnum : typeof PaymentMethodEnum = PaymentMethodEnum;
    public activeLanguageId:any;
    public ObjectTypeEnum : typeof ObjectTypeEnum = ObjectTypeEnum;
    public IsDiscountApplicable:boolean;
    public DisplayWebTemplate = sessionStorage.getItem("DisplayWebTemplate");
    constructor(private objFormBuilder: FormBuilder,private objDeviceDetectorService:DeviceDetectorService,@Inject(DOCUMENT) private document: Document,private objComponentintractService:ComponentintractService,private objBuildDmsJSONService:BuildDmsJSONService,private objBlobConvertorService:BlobConvertorService,private sanitizer: DomSanitizer,private _router: Router, private route: ActivatedRoute, private _operationService: operationService, private _DataSharedService: DataSharedService, private _loginService: loginService, private datePipe: DatePipe) {}
    ngOnInit() {
        window.scrollTo(0, 0);
        var arrUrlDetails = window.location.href.split('#');
        var baseUrl = arrUrlDetails[0];
        this.routeUrl = arrUrlDetails[1].split("/")[1];
        this.strsiteKey = url.sitekey;
        this.paymentSuccessUrl = arrUrlDetails[0]+"#/"+this.routeUrl+"/success";
        this.paymentCancel = arrUrlDetails[0]+"#/"+this.routeUrl+"/cancel";
        
        this.projectId = Number(sessionStorage.getItem("projectId"));
        this.ProductGuid = this.route.snapshot.params["ProductGuid"];
        this.strDefaultLanguage = sessionStorage.getItem("DefaultLangauge");
        this.IsDiscountApplicable = JSON.parse(sessionStorage.getItem('IsDiscountApplicable'));
        this.authType = atob(sessionStorage.getItem("AuthType"));
        this.boolIsGuestUser = true;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                this.boolIsGuestUser = false;
            }
        }
        this.signInPromGroup();
        this.deleteModelName = "dependent-order";
        if( sessionStorage.getItem("UserId") ){
            this.objUser = {Id:sessionStorage.getItem("UserId")}
        }
        if( sessionStorage.getItem("Email") ){

            this.objUserSignUpModel.Email = sessionStorage.getItem("Email"); 
            this.confirmEmail = sessionStorage.getItem("Email"); 
        }
        this.arrobjInscriptionLine.push(this.uploadDetails);
        if(this._DataSharedService.sharedImageJson ){
            this.imageJson = Object.assign({},this._DataSharedService.sharedImageJson);
            this.imageJsonCopy = Object.assign({},this._DataSharedService.sharedImageJson);
            this.strBase64= this.imageJson.File;
            this.file = this._DataSharedService.ShareUploadFile;
            this.strFileName = this.file.name;
            this.uploadFlag = true;
            this.photoUploadSuccessStatus = true;
            this.showCropImage=true;
            $("#imageprocessing").modal("show");
        }
        this.objComponentintractService.getComponentdata().subscribe(
            data=>{
                if( data!=undefined ){
                    if( data.boolChangeCurrency ){
                        this.getProductDetails(); 
                        this.getProjectTranslationDetail("ONLOAD",this.projectId,this.ObjectTypeEnum.PROJECT,sessionStorage.getItem("languageCode"));
                        this.getResourceJSON(sessionStorage.getItem("activeLanguage").toLowerCase());
                    }
                    if( data.boolTransferCart ){
                        this.transferGuestToCustomerCart(data.objUser);
                    }
                    if( data.boolGetCart ){
                        this.getCartContents();
                    }
                }
            }
        )
        this.languageCode = sessionStorage.getItem("languageCode");
        this.getProductDetails();
        this.getCartContents();
        this.paymentGatewayList();
        this.getResourceJSON(sessionStorage.getItem("activeLanguage").toLowerCase());
        this.getFileFormat();
        this.activeLanguageId = sessionStorage.getItem("activeLanguageId");
        this.getProjectTranslationDetail("ONLOAD",this.projectId,this.ObjectTypeEnum.PROJECT,sessionStorage.getItem("activeLanguageId"));
    }
    public userConsent:boolean;
    public LanguageCulture = sessionStorage.getItem("PGLanguageCulture");
    public angularForm:FormGroup = new FormGroup ({
            version: new FormControl(),
            stprofile: new FormControl(),
            mainamount: new FormControl(),
            billingtown: new FormControl(),
            customertown: new FormControl(),
            sitereference: new FormControl(),
            currencyiso3a: new FormControl(),
            billingstreet: new FormControl(),
            billingcounty: new FormControl(),
            orderreference: new FormControl(),
            customerstreet: new FormControl(),
            ruleidentifier: new FormControl(),
            billingpremise: new FormControl(),
            customercounty: new FormControl(),
            billingpostcode: new FormControl(),
            billingfirstname: new FormControl(),
            billinglastname: new FormControl(),
            billingcountryiso2a:new FormControl(),
            billingtelephone: new FormControl(),
            customerpostcode: new FormControl(),
            customerfirstname: new FormControl(),
            customerlastname: new FormControl(),
            customercountryiso2a: new FormControl(),
            customertelephone: new FormControl(),
            declinedurlredirect: new FormControl(),
            billingtelephonetype: new FormControl(),
            customertelephonetype: new FormControl(),
            successfulurlredirect: new FormControl(),
            userConsent: new FormControl(),
            locale: new FormControl()
    });
    initatePaymentForm(){
        // this.angularForm
    }
    ngOnDestroy(){
        this._DataSharedService.sharedImageJson=undefined;
    }
    /*API to get product details based on ProductGuid*/
    getProductDetails(){
        this._operationService.get(url.mainUrl+'Product/?ProductGuid='+this.ProductGuid+"&CurrentCurrencyId="+sessionStorage.getItem("currencyId")).subscribe(
            data=>{
                this.objProductDetails = data;

                this.objProductDetails.DependentProduct.sort(function(a, b){return a.ProductCost - b.ProductCost});
                this.InscriptionCharactersPerLine = this.objProductDetails.InscriptionCharactersPerLine;
                this.parentProductId = this.objProductDetails.ProductId;
                this.productId = this.objProductDetails.ProductId;
                this.arrobjDependentProduct = this.objProductDetails.DependentProduct;
                if(this._DataSharedService.sharedImageJson != undefined){

                }
                this.intMaximumImageFileSize = data.MaximumImageFileSize;
                this.objComponentintractService.setComponentdata({boolLaod:false});
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }

    getUserConsentVal(){
        let userconsnt:string; 
        if(this.userConsent != undefined ){
         userconsnt = (this.userConsent)?'YES':'NO'
        }
        
        return userconsnt;
     };

    /* -- Image exif Orientation Ccode will go here  -- */
    // Step 1#
    handleFileSelectDetect(event) {    
      var file = event.target.files[0];
      this.getOrientation(file, (orientation) => {
        this.getBase64(file, orientation);
      });
    }
    /* !end imag Exif Orientation code here - */

    /*Upload the physical image and convert to base64 string*/
    handleFileSelect($event) {
        this.intMaximumImageFileSize = Number(sessionStorage.getItem("maxfilesize"));
        this.intMinImageFileSize = Number(sessionStorage.getItem("minimumFileSize"));
        this.file = $event.target.files[0];
        this.size = this.file.size;
        this.strFileName = this.file.name;
        let fileSize:number = this.intMaximumImageFileSize * 1048576;
        let minfileSize: number = this.intMinImageFileSize * 1048576;
        
        if( this.arrobjFileFormate.filter(item=>item.Value == this.file.type).length == 0 ){
            toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.WrongFileFormat);
            return false;
        }
        if( this.intMaximumImageFileSize != null && this.intMaximumImageFileSize != 0 ){
            
            if( fileSize < this.size ){
                toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.FileSizemessage + this.intMaximumImageFileSize + " MB")
                return false;
            }
        }
        if (this.intMinImageFileSize != null && this.intMinImageFileSize != 0) {

            if ( minfileSize > this.file.size) {
                let message = this.intMinImageFileSize >= 1? " MB":" KB";
                toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.MinFileSizemessage + (this.intMinImageFileSize >= 1? this.intMinImageFileSize:(minfileSize/1024))+message)
                return false;
            }
        }
        this.objComponentintractService.setComponentdata({boolLaod:false});
        this.getOrientation(this.file, (orientation) => {
            this.getBase64(this.file, orientation);
          });

        // var objReader = new FileReader();
        // let that = this; 
        // objReader.onload = ()=>{
        //     var objImg = new Image();
        //         objImg.onload = () => {
        //         this.width = objImg.width;
        //         this.height = objImg.height;
        //         that._handleReaderLoaded(objReader.result);
        //     };
        //     objImg.src = objReader.result;
        // };
        // objReader.readAsDataURL(this.file);
    }

     // Step 2#
    getOrientation(file, callback) {
        var reader:any,
        target:EventTarget;
        reader = new FileReader();
        reader.onload = (event) => {

          var view = new DataView(event.target.result);

          if (view.getUint16(0, false) != 0xFFD8) return callback(-2);

          var length = view.byteLength,
            offset = 2;

          while (offset < length) {
            var marker = view.getUint16(offset, false);
            offset += 2;

            if (marker == 0xFFE1) {
              if (view.getUint32(offset += 2, false) != 0x45786966) {
                return callback(-1);
              }
              var little = view.getUint16(offset += 6, false) == 0x4949;
              offset += view.getUint32(offset + 4, little);
              var tags = view.getUint16(offset, little);
              offset += 2;

              for (var i = 0; i < tags; i++)
                if (view.getUint16(offset + (i * 12), little) == 0x0112)
                  return callback(view.getUint16(offset + (i * 12) + 8, little));
            }
            else if ((marker & 0xFF00) != 0xFF00) break;
            else offset += view.getUint16(offset, false);
          }
          return callback(-1);
        };

        reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
    };

    // Step 3#
    getBase64(file, orientation) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var base64 = reader.result;
            this.resetOrientation(base64, orientation, (resetBase64Image) => {
              this.dataURItoBlob(resetBase64Image);
            });
          };
        reader.onerror = (error) => {
            
        };
    }

    // Step 4#
    resetOrientation(srcBase64, srcOrientation, callback) {
        var img = new Image();

        img.onload = () => {
          var width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");

          // set proper canvas dimensions before transform & export
          if (4 < srcOrientation && srcOrientation < 9) {
            canvas.width = height;
            canvas.height = width;
          } else {
            canvas.width = width;
            canvas.height = height;
          }

          // transform context before drawing image
          switch (srcOrientation) {
            case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
            case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
            case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
            case 7: ctx.transform(0, -1, -1, 0, height, width); break;
            case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
            default: break;
          }

          // draw image
          ctx.drawImage(img, 0, 0);

          // export base64
          callback(canvas.toDataURL());
        };

        img.src = srcBase64;
      }

      dataURItoBlob(dataURI) {
  
        // convert base64 to raw binary data held in a string
        var byteString = atob(dataURI.split(',')[1]);

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var bb = new Blob([ab], { type: "image/jpeg" });
        ImageTools.resize(bb, { }, ((blob, didItResize) => {
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = (() => {
            var base64data = reader.result;
            this.uploadImage(base64data);

            ImageTools.resize(bb, { width: 300, height: 300 }, ((blob, didItResize) => {
              var reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = (() => {
                var base64data = reader.result;
                this.uploadIssueThumbnail(base64data);
              });
            }));
          });
        }));
        return bb;
    }


    uploadIssueThumbnail(base64){
       
    }

    uploadImage(base64data){
        this.prveImgUrl = base64data;
        this.selectedImageTrusted="";
        var boolWidth:boolean=true;
        this.showCropImage=true;
        var boolHeight:boolean=true;
        if( this.width < this.intPhotomosaicMinWidth ){
            boolWidth = false;
        }
        if( this.height < this.intPhotomosaicMinHeight ){
            boolHeight = false;
        }
        if( boolWidth == true && boolHeight == true ){
            var arrString =  base64data.split(",");
            this.strBase64 = arrString[1];
            this.strSelectedImageBase64 = arrString[1];
            this.imagePath = arrString[1];
            this.imageJson = Object.assign({},{ FileName: this.file.name, FileType: this.file.type, File: this.imagePath });
            this.imageJsonCopy = Object.assign({},{ FileName: this.file.name, FileType: this.file.type, File: this.imagePath });
        } else {
            toastr.error(this.languageData.Briconomics.alertMessage.ImageSizeLabel+this.intPhotomosaicMinHeight+"*"+this.intPhotomosaicMinWidth);
        }
        $("#imageprocessing").modal("show");
        this.objComponentintractService.setComponentdata({boolLaod:false});
    }

    /*Convert to base64 string*/
    _handleReaderLoaded(objReader:any){
        this.selectedImageTrusted="";
        var boolWidth:boolean=true;
        this.showCropImage=true;
        var boolHeight:boolean=true;
        if( this.width < this.intPhotomosaicMinWidth ){

            boolWidth = false;
        }
        if( this.height < this.intPhotomosaicMinHeight ){
            boolHeight = false;
        }
        if( boolWidth == true && boolHeight == true ){
            var arrString =  objReader.split(",");
            this.strBase64 = arrString[1];
            this.strSelectedImageBase64 = arrString[1];
            this.imagePath = arrString[1];
            this.imageJson = Object.assign({},{ FileName: this.file.name, FileType: this.file.type, File: this.imagePath });
            this.imageJsonCopy = Object.assign({},{ FileName: this.file.name, FileType: this.file.type, File: this.imagePath });
        } else {
            toastr.error(this.languageData.Briconomics.alertMessage.ImageSizeLabel+this.intPhotomosaicMinHeight+"*"+this.intPhotomosaicMinWidth);
        }
        $("#imageprocessing").modal("show");
    }

    /*DOM Method, Return Uploaded image */
    isImageAvalable(){
       var boolisImageAvailable:boolean=false;
        if( this.imageJson.FilePath != undefined && this.imageJson.FilePath != '' ){
            boolisImageAvailable = true;
        } else {
            if( this.imageJson.File != undefined && this.imageJson.File != ''  ){

                boolisImageAvailable = true;
            } 
        } 
        return boolisImageAvailable;
    }
    get getDependentImage(){
        var imageUrl:any="";
        //return this.prveImgUrl;
        if( this.imageJson.FilePath != undefined && this.imageJson.FilePath != '' ){
            return this.imageJson.FilePath;
        } else {
            if( this.imageJson.File != undefined && this.imageJson.File != ''  ){

                return 'data:image/jpg;base64,'+this.imageJson.File;
            } else {
                return "";//"assets/images/uploadfreshimg.png";
            }
        }
    }
    get checkValidation(){

        var isValid:boolean=true;
        
        if(this.imageJson.File == undefined){

            if( this.imageJson.FilePath == undefined ){
                isValid = false;
            }    
        }
        if( this.objCartDetails.IsAccepted == false ){
            isValid = false;
        }
        return isValid;
    }
    get isValidAddress(){

        var isValid:boolean=true;
        if( this.objCartDetails.Orders.length > 1 ){
            if( this.objCartDetails.shippingAddress == null ){
               isValid = false; 
            }
        }
        return isValid;
    }
    increaseProductQuantity(dependentProduct){
        var dependentIndex = this.objProductDetails.DependentProduct.indexOf(dependentProduct)
        if( dependentProduct.StockQuantity == "Unlimited" ){

            this.objProductDetails.DependentProduct[dependentIndex].orderQuantity +=1; 
        } else {
            if( dependentProduct.StockQuantity == 0 ){
                toastr.warning("Out of stock");
                return false;
            }
            if( this.objProductDetails.DependentProduct[dependentIndex].StockQuantity > this.objProductDetails.DependentProduct[dependentIndex].orderQuantity ){
                this.objProductDetails.DependentProduct[dependentIndex].orderQuantity +=1;
            } else {
                toastr.warning("You cannot add more than "+this.objProductDetails.DependentProduct[dependentIndex].StockQuantity+" items");
            }
        }
    }

    decreaseProductQuantity(dependentProduct) {
        var dependentIndex = this.objProductDetails.DependentProduct.indexOf(dependentProduct);
        if( this.objProductDetails.DependentProduct[dependentIndex].orderQuantity > 0 ){
            this.objProductDetails.DependentProduct[dependentIndex].orderQuantity -=1;
            // this.objProductDetails.DependentProduct[dependentIndex].addedItem=false;
        }
    }
    addTobasket(){

    }
    placePMOrder(){

        let objectType:any;
        let isGuestUser:boolean = false;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) && JSON.parse(sessionStorage.getItem("IsGuestUser")) ){
                isGuestUser = true;
                objectType = this.ObjectTypeEnum.GUESTORDER;
            } else {
                isGuestUser = false;
                objectType = this.ObjectTypeEnum.ORDER;
            }
        } else {
            isGuestUser = true;
            objectType = this.ObjectTypeEnum.GUESTORDER;
        }
        this.objComponentintractService.setComponentdata({boolLaod:true});
        this.objPhotoMosaicOrder.Notes = JSON.stringify(this.objDeviceDetectorService.getDeviceInfo());
        this.objPhotoMosaicOrder.IsGuest = isGuestUser;
        this.objPhotoMosaicOrder.CreatedBy = sessionStorage.getItem("UserId")?sessionStorage.getItem("UserId"):0;
        this.objPhotoMosaicOrder.CustomerId = sessionStorage.getItem("UserId")?sessionStorage.getItem("UserId"):0;
        this.objPhotoMosaicOrder.CurrencyId = Number(sessionStorage.getItem("currencyId"));
        this.objPhotoMosaicOrder.CartType = "PRODUCT_CART";
        this.objPhotoMosaicOrder.CreatedOn = new Date();
        this.objPhotoMosaicOrder.IsAccepted = this.objCartDetails.IsAccepted;
        this.objPhotoMosaicOrder.IsAccessable = this.objCartDetails.IsAccessable;
        this.objPhotoMosaicOrder.OrganizationId = Number(sessionStorage.getItem("OrganisationId"));
        this.objPhotoMosaicOrder.ProjectId = Number(sessionStorage.getItem("projectId"));
        
        var uploadListImages:OrderDetails = this.objBuildDmsJSONService.buildImageJSON(false,this.productId,true,false,0,'New',this.objProductDetails.Price,this.ProductGuid,1,this.objProductDetails.ProductType,this.objProductDetails.ProductName,null,{docId:0,guId:this.organisationGuid,IsDefault:true,strbase64:this.strBase64,user:this.objUser,boolIsNewEntry:true,strFileName:this.strFileName,documentTypeCode:DocumentTypeCodeEnum.PHOTOMOSAICORDERIMAGE,objectId:0,objectType:objectType,strFolderCode:DocumentTypeCodeEnum.PMORDER,boolIsNewVersion:false});
        this.objPhotoMosaicOrder.uploadListImages = new Array<OrderDetails>();
        this.objPhotoMosaicOrder.uploadListImages.push(uploadListImages);
        this.objComponentintractService.setComponentdata({boolLaod:true});
        this._operationService.post(url.mainUrl+"Cart/CreateCart",this.objPhotoMosaicOrder).subscribe(
            data=>{
                toastr.success(this.objResourceJSON.Briconomics.PhotoUploadWizard.Success.UploadSuccess);
                this.objComponentintractService.setComponentdata({boolLaod:false});
                this.getCartContents();
                sessionStorage.setItem("isCartAvailable","true");
                if( this.boolIsGuestUser ){

                    this.setSessionData({IsGuestUser:true,});
                }
            },error=>{
                this.strSelectedImageBase64="";
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
       
    }

    addToList(dependentProduct:DependentProduct){
        this.showAddressList = false;
        this.objComponentintractService.setComponentdata({boolLaod:true});
        var objOrderDetails:OrderDetails = new OrderDetails();
        objOrderDetails = this.objBuildDmsJSONService.buildImageJSON(true,dependentProduct.ProductId,false,true,this.productId,"New",dependentProduct.Price,dependentProduct.ProductGuid,dependentProduct.orderQuantity,dependentProduct.ProductType,dependentProduct.Name,null,null,this.objProductDetails.InstcriptionLineCount);
        var order = this.objCartDetails.Orders.filter(item=>item.isParentOrder == true);

        objOrderDetails.CartId = this.objCartDetails.Id;
        objOrderDetails.IsGuest = this.boolIsGuestUser;
        objOrderDetails.customerId = this.boolIsGuestUser ? 0:this.objUser.Id;
        objOrderDetails.ParentOrderId = order[0].Id;
        var dependentIndex = this.objProductDetails.DependentProduct.indexOf(dependentProduct)
        this.objProductDetails.DependentProduct[dependentIndex].orderQuantity =0;
        this._operationService.post(url.mainUrl+"Order/PostOrder",objOrderDetails).subscribe(
            data=>{
                toastr.success(this.objResourceJSON.Briconomics.PhotoUploadWizard.Success.AddKeepsake);
                objOrderDetails.OrderId = Number(data.ResponseData);
                this.objProductDetails.DependentProduct[dependentIndex].boolIsInscAdded = true;
                this.getCartContents();
            },error=>{

                this.showAddressList = true;
                toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.AddKeepsake);
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    checkInscriptionAvailablity(objDependentProduct:DependentProduct){
        
        var boolIsInscriptionAvailable:boolean=true;
        if( this.arrobjOrderDetails.filter(item=>item.ProductId == objDependentProduct.ProductId ).length == 0){
            boolIsInscriptionAvailable = false;
        }
        return boolIsInscriptionAvailable;
    }
    /* Login Guest user*/
    generateUserLoginToken(UserName,Password,isCheckOut,isValidationRequired,userType){
        let tokenData = "UserName="+UserName+"&Password="+Password+"&grant_type=password&scope="+"BRICKS "+sessionStorage.getItem("SessionId")+' Customer '+isValidationRequired;
        this._loginService.getToken(url.loginUrl + 'token', tokenData).subscribe(
            data=>{
                localStorage.setItem("access_token", data.access_token);
                sessionStorage.setItem("access_token", data.access_token);
                this.getUserDetails(isCheckOut);
            },error=>{

            }
        )
    }
    getUserDetails(isCheckOut:boolean){
        this._loginService.getLoginData(url.SSOAPI+'Login/GetUserDetail').subscribe(
            data=>{
                this.showAddressList = true;
                this.objUser = data;
                this.setSessionData(data);
                if( isCheckOut ){

                    this.transferGuestToCustomerCart(this.objUser);
                } else {
                    this.placePMOrder();
                }
            },error=>{

            }
        )
    }
    setSessionData(data):boolean{

        this.boolIsGuestUser = data.IsGuestUser;

        sessionStorage.setItem("UserId", data.Id ? data.Id : "");
        localStorage.setItem("UserId", data.Id ? btoa(data.Id) : "");

        sessionStorage.setItem("isLogedin", "true");
        localStorage.setItem("isLogedin", btoa("true"));

        sessionStorage.setItem("CurentCompanyCode", data.CurCompanyCode ? data.CurCompanyCode:"");
        localStorage.setItem("CurentCompanyCode",data.CurCompanyCode ? btoa(data.CurCompanyCode) : "");

        sessionStorage.setItem("IsGuestUser", data.IsGuestUser);
        localStorage.setItem("IsGuestUser", btoa(data.IsGuestUser));

        sessionStorage.setItem("IsAdmin", data.IsAdmin ? data.IsAdmin : "");
        localStorage.setItem("IsAdmin", data.IsAdmin ? btoa(data.IsAdmin) : "");

        
        if( !data.IsGuestUser ){

            sessionStorage.setItem("Email", data.Email ? data.Email:"");
            localStorage.setItem("Email", data.Email ? btoa(data.Email) :"");

            sessionStorage.setItem("FirstName", data.FirstName ? data.FirstName : data.FirstName);
            localStorage.setItem("FirstName", data.FirstName ?data.FirstName : data.FirstName);

            sessionStorage.setItem("LastName", data.LastName != null? data.LastName :'');
            localStorage.setItem("LastName", data.LastName != null? data.LastName :'');
        }
        return true;
    }
    get getCutomerId(){
        var customerId:any=this.objCartDetails.CustomerId;
        if( this.objCartDetails.CustomerId == null || this.objCartDetails.CustomerId == undefined || this.objCartDetails.CustomerId =="" ){
            customerId = sessionStorage.getItem("UserId");
        }
        return customerId;
    }
    getCartContents(){
        this.boolIsVoucherApplied = false;
        this.customerId = 0;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                this.customerId = sessionStorage.getItem("UserId");
            }
        }
        this.boolSkipAddress = true;
        this._operationService.get(url.mainUrl+"Cart/GetCartContents/?projectId="+this.projectId+"&customerId="+this.customerId ).subscribe(
            data=>{
                this.imageJson.File = "";
                this.showAddressList = true;
                this.objCartDetails = data;
                sessionStorage.setItem("cartType",data.Type);
                this.projectGuid = this.objCartDetails.project.Value;
                
                if(this.objCartDetails.Orders.length > 0){

                    sessionStorage.setItem("isCartAvailable","true");
                } else {
                    sessionStorage.setItem("isCartAvailable","false");
                }
                sessionStorage.setItem("orderCount",JSON.stringify(this.objCartDetails.Orders.length));
                if(this.objCartDetails.VoucherCode != undefined && this.objCartDetails.VoucherCode != null && this.objCartDetails.VoucherCode != "" ){
                    this.boolIsVoucherApplied = true;
                }
                this.objApplyVoucherModel.voucherCode = (this.objCartDetails.VoucherCode == undefined || this.objCartDetails.VoucherCode == null || this.objCartDetails.VoucherCode == "" )?"":this.objCartDetails.VoucherCode;
                this.DiscountCode = (this.objCartDetails.DiscountCode == undefined || this.objCartDetails.DiscountCode == null || this.objCartDetails.DiscountCode == "" )?undefined:this.objCartDetails.DiscountCode;
                this.captureDiscount = (this.objCartDetails.DiscountCode == undefined || this.objCartDetails.DiscountCode == null || this.objCartDetails.DiscountCode == "" )?undefined:this.objCartDetails.DiscountCode;
                
                if( this.objCartDetails.Id == null || this.objCartDetails.Id == 0 ){
                    this.objCartDetails = new CartModel();
                    this.arrobjOrderDetails = new Array<OrderDetails>();
                    return false;    
                }
                this.arrobjSelectedAddressModel = new Array<AddressModel>();
                if( this.objCartDetails.billingAddress != null ){
                    this.objCartDetails.billingAddress.Action = "edit";
                    this.arrobjSelectedAddressModel.push(Object.assign(new AddressModel(),this.objCartDetails.billingAddress))
                }
                if(  this.objCartDetails.shippingAddress != null){
                    this.objCartDetails.shippingAddress.Action = "edit";
                    this.arrobjSelectedAddressModel.push(Object.assign(new AddressModel(),this.objCartDetails.shippingAddress))
                }
                if( this.objCartDetails.Orders.length > 1 ){
                    this.boolSkipAddress = false;
                }
                /* filter PM Order objcet*/
                var order  = this.objCartDetails.Orders.filter(item=>item.isParentOrder==true);
                this.objPMOrder = order[0];

                /* get the file name from URL*/
                if( order.length > 0){
                    
                    var arrUrl:any=[]
                    arrUrl = this.objPMOrder.FilePath.split('/');
                    this.strFileName = arrUrl[arrUrl.length-1];
                    this.imageJson.FilePath = order[0].FilePath;
                    this.imageJsonCopy.FilePath = order[0].FilePath;
                }
                sessionStorage.setItem("cartReference",this.objCartDetails.Id);
                localStorage.setItem("cartReference",btoa(this.objCartDetails.Id));
                this.preBuildOrderJSON();
                this.objComponentintractService.setComponentdata({boolLaod:false});
                this.getVoucherStockAvailability();
                this.boolShowPaymentForm = true;
                this.buildPaymentForm(this.objCartDetails,this.objPaymentModel);
            },error=>{
                this.arrobjSelectedAddressModel = [];
                this.showAddressList = true;
                this.objComponentintractService.setComponentdata({boolLaod:false});
                sessionStorage.setItem("isCartAvailable","false"); 
                sessionStorage.setItem("orderCount","0");
                this.objCartDetails=new CartModel();
                this.arrobjOrderDetails = new Array<OrderDetails>();
                this.getVoucherStockAvailability();
                this.boolShowPaymentForm = true;
            }
        )
    }

    

    preBuildOrderJSON(){
        this.subTotalChildOrders=0;
        this.boolIsInscriptionAvailable = true;
        this.arrobjOrderDetails = new Array<OrderDetails>();
        this.orderDeliveryCost=0;
        this.objCartDetails.Orders.forEach((value,index)=>{

            this.orderDeliveryCost = this.orderDeliveryCost + value.OrderDeliveryInclTax; 
            if( !value.isParentOrder ){

                this.subTotalChildOrders = this.subTotalChildOrders + (value.PriceInclTax - value.DiscountAmountInclTax);
            }
            let objOrderDetails:OrderDetails = new OrderDetails();
            objOrderDetails.ProductType
            objOrderDetails = this.objBuildDmsJSONService.buildImageJSON(true,value.ProductId,false,true,this.productId,"New",(value.PriceInclTax - value.DiscountAmountInclTax),value.productDetails.ProductCode,value.Quantity,value.ItemType.Id,value.productDetails.ProductName,value.inscriptions);
            objOrderDetails.OrderId = value.Id;
            objOrderDetails.OrderDeliveryInclTax = value.OrderDeliveryInclTax;
            objOrderDetails.OrderDeliveryExcTax = value.OrderDeliveryExcTax;
            objOrderDetails.PrintTemplate = "";
            objOrderDetails.ScreenTemplate = value.ScreenTemplate;
            objOrderDetails.IsGuest = this.boolIsGuestUser;
            objOrderDetails.customerId = this.boolIsGuestUser ? 0:this.objUser.Id;;
            this.arrobjOrderDetails.push(objOrderDetails);
        }); 
    }
    public productorderIndex:any;
    updateInscription($event,objInscriptionLine:Inscription,orderindex){
        
        objInscriptionLine.InscriptionLine = objInscriptionLine.InscriptionLine.trim();
        this.boolIsInscriptionAvailable = true;
        this._operationService.put(url.mainUrl+"Order/UpdateOrderInscription?IsGuest="+this.boolIsGuestUser,objInscriptionLine).subscribe(
            data=>{
                this.productorderIndex = orderindex;
            },error=>{
                
                var objError = JSON.parse(error._body);
                if(objError.Type == "InvalidRequest"){

                    toastr.error(objError.Message)
                }
            }
        )
    }
    deleteOrder(objOrderDetails:OrderDetails,boolIsConfirmChange){
        if(objOrderDetails.ProductType == ProductTypeEnum.PhotoMosaicProductType){

            this.deleteMessage = this.objResourceJSON.Briconomics.CommonDeleteModal.DeletAllOrderItems;
        } else {

            this.deleteMessage = this.objResourceJSON.Briconomics.CommonDeleteModal.DeletOrderItem;
        }
        this.objOrderDetails = objOrderDetails;
        this.boolIsConfirmChange = boolIsConfirmChange;
    }
    get getDeleteModelName(){
        return this.deleteModelName;
    }
    get isDeleteChanges(){
        return this.boolIsConfirmChange;
    }
    confirmDelete($event){
        if( $event ){
            if( this.deleteModelName == "dependent-order" ){

                this.confirmDeleteOrder();
            } else if( this.deleteModelName == "address" ){
                // this.confirmDeleteAddress();
            }
        }
    }
    get getMessage(){
        return this.deleteMessage;
    }
    emittOrderEvent($event:Order){

        if( $event ){
            this.showAddressList=false;
            if( $event.ItemType.Id == ProductTypeEnum.PhotoMosaicProductType ){
                this.imageJson = {};
                this.imageJsonCopy = {};
            }
            if( $event.isParentOrder ){

                this.scrollToSection(this.pageTop);
            }
            this.getCartContents();
        }
    }
    confirmDeleteOrder(){
        this.showAddressList = false;
        this._operationService.delete(url.mainUrl+"Order/DeleteOrder?OrderId="+this.objOrderDetails.OrderId+"&IsGuest="+this.boolIsGuestUser).subscribe(
            data=>{
                toastr.success(this.objResourceJSON.Briconomics.PhotoUploadWizard.Success.DeleteKeepsake);
                this.getCartContents();
            },error=>{

            }
        )
    }
    public scrollToSection(specifiedSection:ElementRef):void {
        specifiedSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
     // data-target="#viewkeepsake" data-toggle="modal"
    proceedTocheckOut(){
        this.arrobjOrderDetails.forEach((value,index)=>{
            if( !value.IsParent ){

                this.objPhotoMosaicOrder.uploadListImages.push(value);
            }
        })
    }
    target:HTMLElement;
    public customerInfo:any="";
    userSignUpLogin(target?){

        try{
            this.objComponentintractService.setComponentdata({boolLaod:true});
            let el = document.getElementById(target);
            el.scrollIntoView();
            this.objUserSignUpModel.Password = this.makeid(5);
            this.objUserSignUpModel.CreatedOn = new Date();
            this.objUserSignUpModel.IsVerificationRequired = false;
            this.objUserSignUpModel.customerProfile.CustomerInformation = [];
            if( this.ismemberShippedAllowed == true ){
                let userMemberShip:CustomerInformation = new CustomerInformation();
                userMemberShip.InfoField1 = this.customerInfo;
                userMemberShip.OrganizationId = this.objCartDetails.organization.Id;
                this.objUserSignUpModel.customerProfile.CustomerInformation.push(userMemberShip);
            }
        } catch{
            this.objComponentintractService.setComponentdata({boolLaod:false});
        }
        this._operationService.post(url.umsUrl+"Admin/Customer/AddCustomerDetails",this.objUserSignUpModel).subscribe(
            data=>{
                this.getCustomerProfile(data.ResponseData,true);
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
                this.proceedTocheckOut();
                if(error.json().ErrorCode == "DUPLICATE"){
                    if( this.authType == "SSO" ){
                        this.generateUserLoginToken(this.objUserSignUpModel.Email,this.objUserSignUpModel.Password,true,false,"User");
                    } else if ( this.authType == "NONSSO" ) {

                        // this.getCustomerProfile();
                    }
                }
                var objError = JSON.parse(error._body);
                if(objError.Type == "InvalidRequest"){

                    toastr.error(objError.Message)
                }
            }
        )
    }
    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    transferGuestToCustomerCart(objUser){
        this._operationService.get(url.mainUrl+"cart/TransferGuestToCustomerCart/?customerId="+objUser.Id+"&projectId="+this.projectId+"&guestCartId="+this.objCartDetails.Id+"&cartReferenceNumber="+this.objCartDetails.CartReferenceNumber+"&PaymentMethod="+PaymentMethodEnum.OnlinePayment).subscribe(
            data=>{
                this.getCartContents();
            },error=>{
                this.getCartContents();
            }
        )
    }

    /*Address*/
    getCustomerAddressList(){
        this._loginService.getUser(url.umsUrl+'Admin/Customer/ListCustomerAddress/?id=' + sessionStorage.getItem("UserId")).subscribe(
            data=>{
                this.arrobjAddressModel = data;
            },error=>{
                if(error.json().ErrorCode == "NOCONTENT"){

                    this.addUpdateBtnStatus = true;
                }
            }
        )
    }
    changeAddress(){
        $("#chooseaddresses").modal("show");
        this.getCustomerAddressList();
    }
    openAddAddressPopUp(){
        this.addressModalName = "Add";
        this.addUpdateBtnStatus = true;
        this.userAddress = new AddressModel();
        $("#addaddress").modal("show");
    }
    stockAvalibalityCheck(paymentForm){
        $("#logdelete").modal("hide");
        this.objComponentintractService.setComponentdata({boolLaod:true});
        this._operationService.get(url.bricsAPI+"Project/GetProjectStatus?projectCode="+this.routeUrl+"&lang="+sessionStorage.getItem("activeLanguage")).subscribe(
            data=>{
                sessionStorage.setItem("projectStatus",JSON.stringify(data.IsActive==0?false:true));
                if( data.ProjectStatus == ProjectStatus.OPEN ){
                    this.updatePaymentStatus(paymentForm,this.objCartDetails.CartGuid,this.objCartDetails.cartTotalAmount,this.angularForm.getRawValue())
                } else {
                    this.objComponentintractService.setComponentdata({boolLaod:false});
                    toastr.warning(data.Message);
                }            
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            } 
        )
    }
    updatePaymentStatus(paymentForm,cartGuid,cartTotalAmount,objForm){
        this._operationService.put(url.bricsAPI+"cart/UpdatePaymentStatus/?CartGuid="+cartGuid+'&userConsent='+this.getUserConsentVal(), objForm).subscribe(
            data=>{
                if( cartTotalAmount > 0 ){
                    this.setTimer();
                    this.paymentForm.nativeElement.submit(); 
                } else if (cartTotalAmount == 0) {
                    // this.objComponentintractService.setComponentdata({boolLaod:false});
                    this._router.navigate([this.routeUrl+'/success/'+this.objCartDetails.Id+"/"+this.objCartDetails.CartGuid]);
                } else {
                    toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.CartOrderError);
                    this.getCartContents();
                }
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
                toastr.error(error.json().Message);
            }
        )
    }
    multiBankoPayment(){

        var paymentData:any = "entityId=8ac7a4c96b6fa71b016b7036c16502eb"+
                              "&amount="+this.objCartDetails.cartTotalAmount+
                              "&currency="+this.getCurrentCurrency()+
                              "&paymentType=DB"+
                              "&customParameters[SHOPPER_CartReferenceNumber]="+this.objCartDetails.CartReferenceNumber+
                              "&customParameters[SHOPPER_CustomerEmail]="+this.getCustomerEmail+
                              "&testMode=EXTERNAL"+
                              "customParameters[SIBS_ENV]=QLY";
        this._operationService.get(url.mainUrl+"api/Cart/GetCheckoutId/?data="+paymentData).subscribe(
            data=>{
                this.addDynamicMultiBankoScriptTag(data.ResponseData);
            },error=>{

            }
        )
    }
    addDynamicMultiBankoScriptTag(checkoutId) {
        // <script src="https://test.oppwa.com/v1/paymentWidgets.js?checkoutId={checkoutId}"></script>

        const head = this.document.getElementsByTagName('head')[0];
        let scriptName = "multibanko";
        let multibanko = this.document.getElementById(scriptName) as HTMLLinkElement;
        if (multibanko) {
            // multibanko.src = "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId="+checkoutId;;
        } else {

            const node = this.document.createElement('script');
            node.src = "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId="+checkoutId;
            node.type = 'text/javascript';
            node.async = true;
            node.charset = 'utf-8';
            head.appendChild(node);
        }
    }
    get getCustomerEmail(){
        var userEmail:any;
        if( sessionStorage.getItem("Email") ){
            userEmail = sessionStorage.getItem("Email");
        }
        return userEmail;
    }
    
    payment(){
        this.isPaymentDataChange = !this.isPaymentDataChange;
        this.boolSubmit = true;
        this.paymentForm.nativeElement.submit();
        // $( "#formSecureTrading" ).submit();
    }
    /*Set the timer to hide the error message.*/

    public objTimer:any;
    setTimer(){

        // set showloader to true to show loading div on view
        this.objTimer     = Observable.timer(10000); // 10000 millisecond means 5 seconds
        var subscription = this.objTimer.subscribe(() => {
            this.objComponentintractService.setComponentdata({boolLaod:false});
        });
    }
    submitPayment($event){
        // console.log($event);
    }
    paymentGatewayList() {

        this.objPaymentModel.Sitereference
        this._operationService.get(url.bricsAPI+"PaymentgatewayConfig/GetAllPaymentConfiguration/?OrganizationId=" + sessionStorage.getItem("OrganisationId")).subscribe(
            data => {
                this.objPaymentModel = data[0];
            },error=> {

            }
        )
    }
    get getcropperMinWidth(){
        return this.pageTop.nativeElement.offsetWidth;
    }
    cropPMImage(boolIsImageCropChange){
        this.boolIsImageCropChange = boolIsImageCropChange;
        var self = this;
        if( this.imageJsonCopy.FilePath != undefined || this.imageJsonCopy.FilePath == '' ){

            this.objBlobConvertorService.generateBase64(this.imageJsonCopy.FilePath,function(myBase64){
                let imageData = myBase64.split(",");
                self.strBase64 = imageData[1];
                self.imageJsonCopy.File = imageData[1];
                self.imageJson.File = imageData[1];
            });
        }
        if( this.showCropImage ){
            $("#imageprocessing").modal("show");
        }
        this.showCropImage = true;    
    }
    get getImageUrl(){
        var imageUrl:any="";
        if( this.imageJsonCopy.File != undefined || this.imageJsonCopy.File == '' ){

            imageUrl = 'data:image/jpg;base64,'+this.imageJsonCopy.File;
        }
        return imageUrl
    }
    get getBase64Data(){
        var base64Data=""
        if( this.imageJsonCopy.File != undefined || this.imageJsonCopy.File == '' ){

            base64Data = this.imageJsonCopy.File;
        } else {
            base64Data = this.strBase64;
        }
        return base64Data;
    }
    get getSettings(){
        return {width:4,height:3}
    }
    getCroppedPMImage($event){
        this.imageJson.FilePath = $event.dataUrl
        var data = $event.dataUrl.split(',');
        this.strBase64 = data[1];
        this.strSelectedImageBase64 = data[1];
        this.showCropImage = false;
    }
    isUserLoggedIn(){
        var boolLoggedIn:boolean = false;
        if( sessionStorage.getItem("isLogedin") && JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
            boolLoggedIn = true;
        }
        return boolLoggedIn;
    }
    checkCartAvaliablity(){

    }
    voucherApply(){

        this.objApplyVoucherModel.cartId = this.objCartDetails.Id
        this.objApplyVoucherModel.date = new Date();
        this.objApplyVoucherModel.projectId = this.projectId;

        let paymentJson:any = {
            "StringParameter": [
            window.location.href
            ]
        }
        this._operationService.post(url.mainUrl+"Voucher/ApplyVoucherToCart/?Action=Apply",this.objApplyVoucherModel).subscribe(
            data=>{
                toastr.success(this.objResourceJSON.Briconomics.PhotoUploadWizard.Success.VoucherApplied);
                this.getCartContents();
            },error=>{
                
                /*If Voucher Is InCorrect*/
                if(error.json().ErrorCode == "INCORRECTVOUCHER"){
                    toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.IncorrectVoucherCode);
                }

                /*If Voucher Is InCorrect*/
                if(error.json().ErrorCode == "VOUCHEREXPIRED"){
                    toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.VoucherExpired);
                }

                /*If Voucher Is Redeemed*/
                if(error.json().ErrorCode == "REDEEMEDVOUCHER"){
                    toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.RedeemedVoucherCode);
                }
            }
        )
    }
    removeVoucher(){

        this.objApplyVoucherModel.cartId = this.objCartDetails.Id
        this.objApplyVoucherModel.date = new Date();
        this.objApplyVoucherModel.projectId = this.projectId;

        let paymentJson:any = {
            "StringParameter": [
            window.location.href
            ]
        }
        this._operationService.put(url.mainUrl+"Voucher/RemoveVoucher/?Action=Remove",this.objApplyVoucherModel).subscribe(
            data=>{
                toastr.success(this.objResourceJSON.Briconomics.PhotoUploadWizard.Success.VoucherRemoved);
                this.getCartContents();
            },error=>{
                /*Voucher expiries and other error condition should check */

            }
        )
    };

    applyDiscount(action){
        this._operationService.post(url.mainUrl+"Discount/MapDiscountCart/?DiscountCode="+this.captureDiscount+"&cartGuid="+this.objCartDetails.CartGuid+"&Action="+action,{}).subscribe(
            data=>{
                if(action == 'Link'){
                    this.DiscountCode = this.captureDiscount;
                    toastr.success('Discount applied successfully');
                }else if (action == 'Delink'){
                    this.captureDiscount = undefined;
                    this.DiscountCode = undefined;
                    toastr.success('Discount removed');
                };
                toastr.success(this.objResourceJSON.Briconomics.PhotoUploadWizard.Success.VoucherApplied);
                this.getCartContents();
            },error=>{                
                toastr.error(error.json().Message);
            }
        )
    };

    removeDiscount(){ 
        this.captureDiscount = undefined;
        this.DiscountCode = undefined;
    };
    updateZeroCartAfterPayment(){
        this._operationService.put(url.mainUrl+"Cart/UpdateZeroCartAfterPayment/?cartOrderReference="+this.objCartDetails.Id+"&languageCode="+sessionStorage.getItem("DefaultLangauge"),{}).subscribe(
            data=>{
                this._router.navigate([this.routeUrl+'/success/'+this.objCartDetails.Id+"/"+this.objCartDetails.CartGuid]);
            },error=>{

            }
        )
    }
    updatePMImage(){

        if( this.strSelectedImageBase64 != undefined && this.strSelectedImageBase64 != "" ){
            this.objComponentintractService.setComponentdata({boolLaod:true});
            let objectType:any;
            if( sessionStorage.getItem("isLogedin") && sessionStorage.getItem("IsGuestUser") ){
                if( JSON.parse(sessionStorage.getItem("IsGuestUser"))){
                    objectType = this.ObjectTypeEnum.GUESTORDER;   
                } else {
                    objectType = this.ObjectTypeEnum.ORDER; 
                }
            }
            var pmImageUpdate = this.objBuildDmsJSONService.buildDMSImageUploadJSON(this.objPMOrder.ImageId,false,false,this.strBase64,this.strFileName,this.objUser.Id,this.organisationGuid,DocumentTypeCodeEnum.PHOTOMOSAICORDERIMAGE,DocumentTypeCodeEnum.PMORDER,this.objPMOrder.Id,objectType);
            this._operationService.post(url.mainUrl+"DMS/UploadImage",pmImageUpdate).subscribe(
                data=>{
                    toastr.success(this.objResourceJSON.Briconomics.PhotoUploadWizard.Success.UploadSuccess);
                    this.getCartContents();
                },error=>{
                    toastr.error("Unable to upload")
                    this.getCartContents();
                }
            )
        } else {
            toastr.warning("Please upload image");
        }
    }
    public currency = "\u00A3";
    public currency1 = "GBP";
    getCurrentCurrencyIcon(){
        var strCurrencyUnicode:any;
        if( sessionStorage.getItem("currencyIcon") ){
            strCurrencyUnicode = sessionStorage.getItem("currencyIcon");
        }
        return strCurrencyUnicode;
    }
    getCurrentCurrency(){
        var CurrencyCode:any;
        if( sessionStorage.getItem("CurrencyCode") ){
            CurrencyCode = sessionStorage.getItem("CurrencyCode");
        }
        return CurrencyCode;
    }
    isPMOrderPlaced(){
        var isPmOrderPlaced:boolean = true;
        var pmOrder = this.objCartDetails.Orders.filter(item=>item.isParentOrder == true);
        if( pmOrder.length == 0 ){
            isPmOrderPlaced = false;
        }
        return isPmOrderPlaced;
    }
    get checkSelectedImage(){
        var isImageIsSelected:boolean=false;
        if( this.strSelectedImageBase64 != undefined && this.strSelectedImageBase64 != "" ){
            isImageIsSelected = true;
        }
        return isImageIsSelected; 
    }
    getLoggedInUserEmail(){

        var email:any="";
        if( sessionStorage.getItem("isLogedin") && JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){

            if( sessionStorage.getItem("Email") ){
                email = sessionStorage.getItem("Email");
            }           
        }    
        return email;
    }
    public boolIsEmailEntered:boolean=false;
    matchEmail($event){
        this.boolIsConfirmEmailEntered = true;
    }
    public boolIsInscriptionAvailable:boolean=true;
    isInscriptionAvailable(objOrder:OrderDetails){
        
        var inscAvailFlag:boolean=false;
        objOrder.inscriptionDetail.forEach((value,index)=>{
            if( value.InscriptionLine.trim() != "" ){
                inscAvailFlag = true;
            }
        });
        if( this.boolIsInscriptionAvailable ){

            if( inscAvailFlag ){
                this.boolIsInscriptionAvailable = true;
            } else {

                this.boolIsInscriptionAvailable = false;
            }
        }
        return inscAvailFlag;
    }

    updateTCIsAccepted($event){
        var isGusetUser:boolean;
        var isAccepted:boolean;
        if( this.objCartDetails.Id != undefined && this.objCartDetails.Id != ""  ){

            isAccepted = this.objCartDetails.IsAccepted;
            if( sessionStorage.getItem("IsGuestUser")){
                isGusetUser = JSON.parse(sessionStorage.getItem("IsGuestUser")) 
            }
            this._operationService.put(url.mainUrl+"Cart/UpdateIsAccepted/?IsAccepted="+isAccepted+"&CartId="+this.objCartDetails.Id+"&IsGuest="+isGusetUser,{}).subscribe(
                data=>{
                    
                },error=>{

                }
            )
        }
    }
    updateAccessabilitysAccepted($event){
        var isGusetUser:boolean;
        var isAccessable:boolean;
        if( this.objCartDetails.Id != undefined && this.objCartDetails.Id != ""  ){

            isAccessable = this.objCartDetails.IsAccessable;
            if( sessionStorage.getItem("IsGuestUser")){
                isGusetUser = JSON.parse(sessionStorage.getItem("IsGuestUser")) 
            }
            this._operationService.put(url.mainUrl+"Cart/UpdateIsAccessable/?IsAccessable="+isAccessable+"&CartId="+this.objCartDetails.Id+"&IsGuest="+isGusetUser,{}).subscribe(
                data=>{
                    toastr.success("Success");
                },error=>{

                }
            )
        }
    }
    public objProjectAdditionalModel:ProjectAdditionalModel=new ProjectAdditionalModel();
    public termsCondAccessability:any;
    public strType:any;
    public productPageDescription:any;

    getProjectTranslationDetail(strType,objectId,objectType,languageCode){
        this.strType =strType;
        this.languageCode = sessionStorage.getItem("languageCode");
        this.productPageDescription = "";
        
        this._operationService.get(url.mainUrl+"Project/GetProjectTranslationDetail/?projectId="+this.projectId+"&LanguageCode="+this.languageCode).subscribe(
            data=>{
               
                this.objProjectAdditionalModel = data;
                if( strType == "ONLOAD" ){
                    this.objProjectTranslationModel = data;
                } else {
                    $("#terms-condition").modal("show");
                    this.termsCondAccessability = this.objProjectAdditionalModel[strType];
                }
            },error=>{
                
            }
        )
    }
    mappAddressToCart($event){
        this._operationService.put(url.mainUrl+"cart/UpdateCartAddress/?cartId="+this.objCartDetails.Id+"&billingId="+$event.billingId+"&shippingId="+$event.shippingId,{}).subscribe(
            data=>{
                this.getCartContents();
            }
        )
    }
    refreshCartData($event){
        // this.getCartContents();
    }
    public inscriptionOrder:Array<Inscription> = new Array<Inscription>();
    public productTemplateURL:any="";

    getInscriptedImage(dependentProduct:DependentProduct,order:OrderDetails){
        this.inscriptionOrder = order.inscriptionDetail;
        this.objDependentProduct = dependentProduct;
        this.productTemplateURL = order.ScreenTemplate;
        this.getTemplates(order.OrderId,this.objCartDetails.Id,this.objCartDetails.project.Value);
        $("#kstemppreview").modal("show");
    }

    closeScreenTemplates(){
        $("#kstemppreview").modal("hide");
        this.appendedHtml = "";
    }
    getTemplates(orderId,cartId,projectGuid){
        this._operationService.get(url.mainUrl+"cart/GetPreviewImage/?orderId="+orderId+"&cartId="+cartId+"&IsGuest="+this.boolIsGuestUser+"&ProjectGuid="+projectGuid).subscribe(
            data=>{
                this.appendedHtml = data.ResponseData;
            },error=>{

            }
        )
    }
    get getTemplateUrl(){
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.productTemplateURL);
        // return "";
    }
    get getSelectedAddress(){
        return this.arrobjSelectedAddressModel;
    }
    get getCartId(){
        return this.objCartDetails.Id;
    }
    proceedToPayment(){
        this.boolSubmit = false;
        // this.angularForm.patchValue({name:"PiyushPallaw"});
        // console.log(JSON.stringify(this.angularForm.getRawValue()))
        $("#logdelete").modal("show");
    }

    buildPaymentForm(paymentData,objPaymentModel) {

        if( this.boolSkipAddress == true ){
            if(paymentData.shippingAddress == null){
                paymentData.shippingAddress = Object.assign({},new AddressModel)
            }
        }
        try{
            this.angularForm = this.objFormBuilder.group({
                sitereference:objPaymentModel.Sitereference,
                stprofile:"default",
                currencyiso3a:this.getCurrentCurrency(),
                mainamount:paymentData.cartTotalAmount,
                version:2,
                locale: this.LanguageCulture,
                orderreference:paymentData.CartGuid,
                // billingfirstname:paymentData.billingAddress.CustomerName, 
                billingfirstname:paymentData.customerDetails.FirstName, 
                billinglastname:paymentData.customerDetails.LastName, 
                billingcountryiso2a:paymentData.billingAddress.countryModel.Code, 
                billingstreet:paymentData.billingAddress.Address1,
                billingpremise:"",
                billingtown:paymentData.billingAddress.City,
                billingcounty:paymentData.billingAddress.StateProvinceModel,
                billingpostcode:paymentData.billingAddress.ZipPostalCode,
                billingtelephone:paymentData.billingAddress.PhoneNumber,
                billingtelephonetype:"W",
                // customerfirstname:paymentData.shippingAddress.CustomerName,
                customerfirstname:paymentData.customerDetails.FirstName, 
                customerlastname:paymentData.customerDetails.LastName,
                customerstreet:paymentData.shippingAddress.Address1,
                customertown:paymentData.shippingAddress.City,
                customercounty:paymentData.shippingAddress.StateProvinceModel,
                customerpostcode:paymentData.shippingAddress.ZipPostalCode,
                customertelephone:paymentData.shippingAddress.PhoneNumber,
                customertelephonetype:"H",
                declinedurlredirect:this.paymentCancel,
                successfulurlredirect:this.paymentSuccessUrl, 
                ruleidentifier:"STR-6"
            });
        } catch {}
    }
    getResourceJSON(resourceFile){

        this._operationService.getLanguageJson(url.languageJsonUrl+resourceFile+".json").subscribe(
            data=>{
                this.objResourceJSON = data;
            }
        )
    }
    public filterFileFormat:string="";
    getFileFormat() {

        this._operationService.get(url.mainUrl + 'DMS/GetFileTypes').subscribe(
            data => {
                this.arrobjFileFormate = data;
                this.filterFileFormat = "";
                this.arrobjFileFormate.forEach((value,index)=>{
                    
                    if( this.filterFileFormat == "" ){
                        this.filterFileFormat = value.Value
                    } else {

                        this.filterFileFormat = this.filterFileFormat +','+ value.Value;
                    }
                })
            },error => {

            }
        )
    }

    get allowedFileFormat(){

        return this.filterFileFormat;
    }
    get getResourceMapping(){
        return this.objResourceJSON;
    }
    
    get firstNamePattern(){
        const regex = /^[a-zA-Z]*$/;
        var objEmailRegExp = new RegExp(regex);
        return objEmailRegExp;        
    }
    get getEmailPattern(){

        const regex = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/;
        var objEmailRegExp = new RegExp(regex, "i");
        return objEmailRegExp;
    }
    get getCartType(){
        return this.objCartDetails.Type;
    }
    get getMinHeightWidth(){
        return {minHeight:this.objProductDetails.PM_MinimumImageHeight,minWidth:this.objProductDetails.PM_MinimumImageWidth}
    }

    checkImageQuality(){
        var isImageQualityGood:boolean=true;
        if(this.imageJson.File != undefined && this.imageJson.File != "" ){

            if( this.pmImage.nativeElement.naturalWidth > 0 && this.pmImage.nativeElement.naturalWidth < (this.objProductDetails.PM_MinimumImageWidth -50)  ){
                isImageQualityGood = false;
            }
        } 
        return isImageQualityGood;
    }
    @ViewChild('faangleicone') faangleicone: ElementRef;
    toggleClass(event: any, strclass: string) {
        const hasClass = event.target.classList.contains(strclass);

        if(hasClass) {
            
            this.faangleicone.nativeElement.classList.remove(strclass);
            this.faangleicone.nativeElement.classList.add('fa-angle-up');
        } else {
            this.faangleicone.nativeElement.classList.add(strclass);
            this.faangleicone.nativeElement.classList.remove('fa-angle-up');
        }
    }
    public appendedHtml:any;
    public signin:any;
    signInPromGroup(){

        this.signin = new FormGroup({
                                    email: new FormControl(null, Validators.required),
                                    password: new FormControl(null, Validators.required),
                                    captcha: new FormControl(),
                                });
    }
    public boolIsRecaptchaSubmitted:boolean=false;
    confirmCaptcha($event){
        this.boolIsRecaptchaSubmitted = $event;
    }
    get isRecaptchaSubmitted(){
        return this.boolIsRecaptchaSubmitted;
    }
    get getsitekey(){
        return this.strsiteKey;
    }
    stripTags(replaceString):string{
        return replaceString.replace(/<[^>]*>/g, '');
    }
    enterData($event){
        
    }

    public boolIsStockAvailble:boolean=true;
    getVoucherStockAvailability(){
        this._operationService.get(url.mainUrl+"Product/VoucherStockAvailability/?projectId="+this.projectId).subscribe(
            data=>{
                if( data.StockAvailabilityStatus == "Fail" || data.StockAvailabilityStatus == "OutOfStock" ){

                    this.boolIsStockAvailble = this.boolIsVoucherApplied;
                } else {
                    this.boolIsStockAvailble = true;
                }
            },error=>{

            }
        )
    }
    public objProjectTranslationModel:ProjectTranslationModel=new ProjectTranslationModel();
    public boolShowPaymentForm:boolean=false;
    getCustomerProfile(customerId,isCheckOut){
        this._operationService.get(url.umsUrl+"Admin/Customer/GetCustomerProfileDetails/?customerid="+customerId).subscribe(
            data=>{
                this.showAddressList = true;
                this.objCustomerModel = data;
                this.objCustomerModel.Id = data.CustomerId
                this.objCustomerModel.IsGuestUser = false;
                this.objUser = this.objCustomerModel;
                this.setSessionData(this.objCustomerModel);
                this.transferGuestToCustomerCart(this.objCustomerModel);
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    get getCartDetails(){
        return this.objCartDetails; 
    }
    getProductDetailPopUP(objDependentProduct:DependentProduct){
        this.objDependentProduct = objDependentProduct;
        $("#glass-momento").modal("show");

    }

    get defaultActiveLanguage(){
        return sessionStorage.getItem("DefaultLangauge");
    }
    getProductDescriptionByLanguage(){
        var localLanguageDescription:any={};
        if( this.objDependentProduct.ShortDescription == undefined ){
            return "";
        } else {
            localLanguageDescription = JSON.parse(this.objDependentProduct.ShortDescription);
            return localLanguageDescription[this.defaultActiveLanguage];
        }
    }
    editinscription($event){
        let el = document.getElementById('targetstep2');
        el.scrollIntoView();
    }
    buildPaymentURLs(url,replaceString){
        var strUrl:any = url.replace("[baseurl]",replaceString);
        return strUrl;
    }
    get ismemberShippedAllowed(){
        var isMemberShip:boolean=false;
        if( sessionStorage.getItem("acceptMemberShip") != undefined && sessionStorage.getItem("acceptMemberShip") != null){

            isMemberShip = JSON.parse(sessionStorage.getItem("acceptMemberShip"));
        }
        return isMemberShip;
    }
    get taxType(){
        try{
            return this.objCartDetails.TaxType.Value;
        } catch {
            return "UKTAX";
        }
    }
}
