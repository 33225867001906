import { Component, OnInit } from '@angular/core';
import { operationService } from '../../_services/operation.service';
import { DataSharedService } from '../../_services/dataSharedService';
import { TranslateService } from '@ngx-translate/core';
import * as url from '../../../environments/environment';
import { DatePipe} from '@angular/common';
import { Language,MappedLanguageModel, PreRegistrationModel } from '../../models/language.model';
import { ComponentintractService } from '../../_services/conponentinteraction.service';
import { ProjectAdditionalModel } from '../../models/project.model';
import { NewsContentModel,NewsTitleModel,BuildData } from '../../models/newsmodel';
import { DocumentTypeCodeEnum,ObjectTypeEnum,DocumentTypeCategoryEnum } from '../../models/documenttypecode.enum';
import { ImageDocumentUpload,DocumentProperties,UploadImage,DocumentMapping } from '../../models/documentMapping.model';
import { ProjectSliderImageContent,ProjectSliderImage } from '../../models/sliderimagecontent';
import { ActivityModel } from '../../models/activity.model';
import { ProjectStatus } from '../../models/project.enum';

@Component({
  selector: 'general-landing-page',
  templateUrl: './general-landing-page.html',
  providers: [operationService, DatePipe]
})
export class GeneralLandingComponent implements OnInit{
    

    public objProjectDetails:any={};
    public routeUrl:any;
    public objProjectAdditionalModel:ProjectAdditionalModel=new ProjectAdditionalModel();
    public arrobjMappedLanguageModel:Array<MappedLanguageModel> = new Array<MappedLanguageModel>();
    public objActivityModel:ActivityModel = new ActivityModel();
    public arrobjNewsContentModel:Array<NewsContentModel> = new Array<NewsContentModel>();
    public arrobjBuildData:Array<BuildData> = new Array<BuildData>();
    public objMappedLanguageModel:MappedLanguageModel = new MappedLanguageModel();
    public projectId:any;
    public OrganisationId:any;
    public ProjectName:any;
    public iframe_html:any;
    public ObjectTypeEnum : typeof ObjectTypeEnum = ObjectTypeEnum;
    public DocumentTypeCodeEnum : typeof DocumentTypeCodeEnum = DocumentTypeCodeEnum;
    public objUploadImage:UploadImage=new UploadImage();
    public objProjectBackGroundImage:DocumentMapping=new DocumentMapping();
    public objDocumentProperties:DocumentProperties=new DocumentProperties();
    public arrobjProjectSliderImage:Array<DocumentMapping> = new Array<DocumentMapping>();

    public boolIsConfirmEmailEntered:string = '';
    PreRegistrationObj = new PreRegistrationModel();
    confirmEmail: string;

    public get ProjectStatus(): typeof ProjectStatus{
        return ProjectStatus;
    }

    constructor(private translate: TranslateService,private objComponentintractService:ComponentintractService,private _operationService: operationService, private _DataSharedService: DataSharedService, private datePipe:DatePipe){}
    ngOnInit() {

        var arrUrlDetails = window.location.href.split('/');
        this.routeUrl = arrUrlDetails[url.urlIndex];
        this.GetProjectByCode(); 
        $('.carousel').carousel({
          interval: 2000
        })   
    }
    GetProjectByCode(){
        this._operationService.get(url.bricsAPI+'Project/GetProjectByCode/?projectCode='+this.routeUrl).subscribe(
            data => {
                this.objProjectDetails = data;
                this.setProjectData(this.objProjectDetails);
                this.getProjectBackgroundImage();
                this.getOrganizationMappedLanguages(data);
            },error=>{
               this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )  
    }
    getProjetcSliderImage(projectId,languageCode){
        this.arrobjProjectSliderImage = [];
        this._operationService.get(url.bricsAPI+"Project/GetProjectSlidersByLang/?ProjectID="+projectId+"&languageCode="+languageCode).subscribe(
            data=>{
                this.arrobjProjectSliderImage = data;
            },error=>{

            }
        )
    }
    getOrganizationMappedLanguages(objProject){
        this._operationService.get(url.bricsAPI+"Organization/GetOrganizationMappedLanguages/?organizationId="+objProject.OrganisationId).subscribe(
            data=>{
                this.arrobjMappedLanguageModel = data;
                var langMappingId = sessionStorage.getItem("defaultLanguageId");
                if( langMappingId == null ){
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.IsDefault==true)[0];
                } else {
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.Id==JSON.parse(langMappingId))[0];
                }
                this.setLanguageSessionData(this.objMappedLanguageModel);
                this.getActivity(this.objProjectDetails.ProjectId,this.objMappedLanguageModel.ResourceFile);
                this.getNews(this.objProjectDetails.ProjectId,this.ObjectTypeEnum.PROJECT,this.objMappedLanguageModel.ResourceFile);
                
                this.getProjectTranslationDetail(this.ObjectTypeEnum.PROJECT,this.objMappedLanguageModel.LanguageCode);
                var objProjectFilterDocument = new DocumentMapping();
                objProjectFilterDocument.ObjectId = objProject.ProjectId;
                objProjectFilterDocument.ObjectType = ObjectTypeEnum.PROJECT;
                objProjectFilterDocument.languagecode = this.objMappedLanguageModel.ResourceFile;
                objProjectFilterDocument.DocumentTypeCategory = DocumentTypeCategoryEnum.ProjectImage;

                this.getProjectImages(objProjectFilterDocument);
            },error=>{

            }
        )
    }
    getNews(objectId,objectType,languageCode){
        this.arrobjNewsContentModel = [];
        this._operationService.get(url.bricsAPI+"News/GetNewsDetailsByLang?ObjectId="+objectId+"&ObjectType="+objectType+"&languageCode="+languageCode).subscribe(
            data=>{
                this.arrobjNewsContentModel = data;
                this.buildNewsCursoleJSON(this.arrobjNewsContentModel);
            },error=>{

            }
        )
    }
    public arrobjNewsCursole:any;
    buildNewsCursoleJSON(arrobjNewsContentModel:Array<NewsContentModel>){
        var dataPerKey = 3;
        var arrlenght = JSON.stringify(arrobjNewsContentModel.length/dataPerKey).split(".");
        
        var resultantLength:any;

        resultantLength = arrlenght.length==1? JSON.parse(arrlenght[0]):JSON.parse(arrlenght[0])+1;

        for( resultantLength;resultantLength>0;resultantLength-- ){
            let objBuildData:BuildData = new BuildData();
            this.arrobjBuildData.push(objBuildData);
        }

        var startdataCounter:any=0;
        var enddataCounter:any=0;

        this.arrobjBuildData.forEach((value,index)=>{
            startdataCounter = index*dataPerKey;
            enddataCounter = startdataCounter + dataPerKey;

            while(startdataCounter < enddataCounter){
                if( arrobjNewsContentModel.length <= startdataCounter){
                    return true;
                }
                value.newsContent.push(arrobjNewsContentModel[startdataCounter]);
                startdataCounter++;
            } 
        })
    }
    getProjectBackgroundImage(){
        this.objDocumentProperties.IsDefault = true;
        this.objDocumentProperties.ObjectId = this.projectId;
        this.objDocumentProperties.ObjectType = ObjectTypeEnum.PROJECT
        this.objDocumentProperties.DocumentType = this.DocumentTypeCodeEnum.PROJECTBACKGROUNDIMAGE;
        this._operationService.post(url.mainUrl+"DMS/GetDocuments",this.objDocumentProperties).subscribe(
            data=>{
                this.objUploadImage = data[0];
                // this.tilesBackBackgroundImage = this.objUploadImage.FilePath;
            },error=>{
                this.objUploadImage = new UploadImage();
            }
        )
    }
    get getProjectBackGroundImage(){

        var filePath:any="";
        try{

            if( this.objProjectBackGroundImage.FilePath ){
                filePath = this.objProjectBackGroundImage.FilePath;
            }
            return filePath;
        } catch {
            return filePath;
        }
    }

    getProjectTranslationDetail(objectType,lngCode?){
        
        // Project/GetProjectTranslationDetail/?projectId=953028595&languageCode=ENG
        this._operationService.get(url.mainUrl+"Project/GetProjectTranslationDetail/?projectId="+this.projectId+"&LanguageCode="+lngCode).subscribe(
            data=>{
                this.objProjectAdditionalModel = data; //.Translations;
                sessionStorage.setItem("ProjectName", this.objProjectAdditionalModel.ProjectName);

                this.objComponentintractService.clearComponentdata();

                if(this.objProjectAdditionalModel.ProjectFooter){
                    this.objComponentintractService.setComponentdata({FooterText:this.objProjectAdditionalModel.ProjectFooter});
                };
            },error=>{
                
            }
        )
    }
    setLanguageSessionData(objMappedLanguageModel):boolean{
        sessionStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        localStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        
        sessionStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        localStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        
        sessionStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        localStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        
        sessionStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        localStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        
        sessionStorage.setItem("languageCode",this.objMappedLanguageModel.LanguageCode);
        localStorage.setItem("languageCode",btoa(this.objMappedLanguageModel.LanguageCode));
        
        sessionStorage.setItem("DefaultLangauge",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("DefaultLangauge",btoa(this.objMappedLanguageModel.ResourceFile.trim()));
        
        sessionStorage.setItem("activeLanguage",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("activeLanguage",btoa(this.objMappedLanguageModel.ResourceFile.trim()));
        
        sessionStorage.setItem("activeLanguageId",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("activeLanguageId",this.objMappedLanguageModel.ResourceFile.trim());

        this.translate.setDefaultLang((objMappedLanguageModel.ResourceFile.trim()).toLowerCase());

        return true;
    }
    setProjectData(data){
        this.projectId = data.ProjectId;
        this.ProjectName = data.ProjectName;
        this.OrganisationId = data.OrganisationId;

        /*Session for project details*/
        sessionStorage.setItem("ProjectName", this.ProjectName);
        sessionStorage.setItem("projectId", JSON.stringify(this.projectId));
        sessionStorage.setItem("OrganisationId", JSON.stringify(this.OrganisationId));
        sessionStorage.setItem("OrganisationGuid", data.OrganisationGuid);
        sessionStorage.setItem("ProjectGUID", data.ProjectGUID);

        this.objComponentintractService.setComponentdata({boolLaod:false});
    }
    closeVideo(){
        
    }

    getActivity(projectId,languageCode){
        
        this._operationService.get(url.mainUrl+"Project/GetActivityList?projectId="+projectId+"&languageCode="+languageCode).subscribe(
            data=>{
                this.objActivityModel = data;
            },error=>{

            }
        )
    }
    getProjectImages(objProjectDocument:DocumentMapping){

        this._operationService.post(url.bricsAPI+"DMS/FetchDocuments",objProjectDocument).subscribe(
            data=>{
                this.arrobjProjectSliderImage = data.filter(item=>item.DocumentType == DocumentTypeCodeEnum.PROJECTSLIDEIMAGE)
                this.objProjectBackGroundImage = data.filter(item=>item.DocumentType == DocumentTypeCodeEnum.HOMEPAGEIMAGE1)[0];
            },error=>{

            }
        )
    }
    get getCursoleData(){
        return this.arrobjBuildData;
    }


    
    OnSubmitPreRegistration(){
        delete this.PreRegistrationObj.isTermAndConditionCheckboxChecked 
        this.PreRegistrationObj.ProjectId = sessionStorage.getItem("projectId"); 
        this.PreRegistrationObj.CreatedDate = null;
        this._operationService.post(`${url.bricsAPI}PreRegistration/AddPreRegistration`,this.PreRegistrationObj).subscribe({
            next: (resp:any)=>{
                this.PreRegistrationObj = new PreRegistrationModel();
                this.boolIsConfirmEmailEntered = 'SUCCESS';
                this.confirmEmail = undefined;
                // this.objProjectDetails.IsPreRegistrationEnabled = !this.objProjectDetails.IsPreRegistrationEnabled
            },
            error: (err:any)=>{
                if((err._body.match("DUPLICATE")) != null){
                    this.boolIsConfirmEmailEntered = 'SUCCESS';       
                }
            }
        });
    }

   

    matchEmail($event){
        this.boolIsConfirmEmailEntered = 'EMAIL';
    }

  
}