/// <reference path="../../toastr.d.ts" />
import { Component, HostListener, OnInit,Input, OnDestroy,OnChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { operationService } from '../../_services/operation.service';
import { loginService } from '../../_services/login.service';
import { DataSharedService } from '../../_services/dataSharedService';
import { DatePipe } from '@angular/common';
import * as url from '../../../environments/environment';
import { NgForm } from '@angular/forms';
import { Observable ,  Subscription } from 'rxjs';
import { error } from 'util';
import * as moment from 'moment';
import { element } from 'protractor';
import { secureTradeJson, secureTradeCustomerPhone, secureTradeRequest } from '../../models/secureTradeJson';
import { StockAvailability } from '../../models/stockavailability.model';
import { EnquiryData } from '../../models/enquiryData.model';
import { ComponentintractService } from '../../_services/conponentinteraction.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'contact-us',
  templateUrl: './contactus.component.html',
  providers: [operationService, loginService, DatePipe]
})

export class ContactUsCoponent implements OnInit, OnDestroy,OnChanges, OnDestroy {

	@Input("boolIsChange") boolIsChange:boolean;
	@Input("objProjectDetails") objProjectDetails:any;
	@Input("objProjectTranslationDetail") objProjectTranslationDetail:any;
	@Output() CloseModel = new EventEmitter<boolean>();
	public boolIsInitiated:boolean=false;
	public objEnquiryData:EnquiryData = new EnquiryData();
	public IsLoggedIn:boolean;
	public arrObjQuestionType:any=[];
	public boolconfirmStatus:boolean=true;
	public confirmStatus:boolean=true;
	public enqueryData:any={};
	public contactCommentLength:any;
	subscription: Subscription;
	public ckEditorConfig:any;
	public routeUrl:any;
	public Editor = ClassicEditor
	constructor(private router: Router,private objoperationService: operationService, private _loginService: loginService, private _router: Router, private _datePipe: DatePipe, private _DataSharedService: DataSharedService,private objComponentintractService:ComponentintractService) {
		
	}
	ngOnInit() {
		var arrUrlDetails = window.location.href.split('#');
        var baseUrl = arrUrlDetails[0];
        this.routeUrl = arrUrlDetails[1].split("/")[1];

		this.ckEditorConfig = this.buildCkeditorConfigJSON();
		this.boolIsInitiated = true;
		this.contactCommentLength = url.contactCommentLength;
		$("#enquiry--modal").modal("show");
		this.setEnquiryData();
		this.getQuestionTypeList();
	}
	ngOnChanges() {
		if( this.boolIsInitiated ){
			this.ckEditorConfig = this.buildCkeditorConfigJSON();
			this.boolconfirmStatus=true;
			this.contactCommentLength = url.contactCommentLength;
			$("#enquiry--modal").modal("show");
			this.setEnquiryData();
			this.getQuestionTypeList();
		}
	} 

	ngOnDestroy(){

	}

	closeContactUsForm(){
		this.CloseModel.emit(false);
	}

	buildCkeditorConfigJSON(){

		return ClassicEditor.defaultConfig = {
	      toolbar: {
	        items: [
	          'heading',
	          '|',
	          'bold',
	          'italic',
	          '|',
	          'bulletedList',
	          'numberedList',
	          '|',
	          'undo',
	          'redo'
	        ]
	      },
	      height :600,
	      image: {},
	      table: {},
	      debounce:"500",
	      language: 'en'
	    }
	}

	setUserDetails(){
		if(sessionStorage.getItem("isLogedin") == 'true'){

		}
	}
	setEnquiryData(){
		this.objEnquiryData = new EnquiryData();
	    this.objEnquiryData.CustomerId = 0;
	    this.objEnquiryData.CustomerName = '';
	    this.objEnquiryData.CustomerEmail = '';

		this.objEnquiryData.ProjectId = parseInt(sessionStorage.getItem('projectId'));
		this.objEnquiryData.OrganizationId = parseInt(sessionStorage.getItem('OrganisationId'));
	}
	getQuestionTypeList(){

    	if(sessionStorage.getItem("isLogedin") == 'undefined'){
        	this.IsLoggedIn = false;
    	}else{
        	this.IsLoggedIn =true;
    	}
		
	    // this.objoperationService.get(url.mainUrl+"ChatBox/GetCustomerQuestions/?ProjectCode="+this.routeUrl+"&IsLoggedIn="+this.IsLoggedIn).subscribe
		this.objoperationService.get(url.mainUrl+"ChatBox/GetCustomerQuestions/?ProjectCode="+this.routeUrl+"&IsLoggedIn="+this.IsLoggedIn+"&languageCode="+sessionStorage.getItem('languageCode')).subscribe(
	        data=>{
	            this.arrObjQuestionType = data;
	        },
	        error=>{
	            
	        }
	    )
	}
	submitQuery(){
		this.objComponentintractService.setComponentdata({boolLaod:true});
		this.objEnquiryData.CreatedOn = new Date();
		this.objEnquiryData.HasAutoResponse = this.boolQuestionHasResponse;

		this.objoperationService.post(url.mainUrl+"ChatBox/SendQuery",this.objEnquiryData).subscribe(
			data=>{
				this.objComponentintractService.setComponentdata({boolLaod:false});
				this.confirmStatus = false;
			},error=>{
				var objError = JSON.parse(error._body);
                if(objError.Type == "InvalidRequest"){

                    toastr.error(objError.Message)
                }
				this.objEnquiryData = new EnquiryData();
				this.objComponentintractService.setComponentdata({boolLaod:false});
			}
		)
	}
	get boolQuestionHasResponse(){
		var question:any = [];
		var boolHasResponse:boolean=false;
		try{
			question = this.arrObjQuestionType.filter(item=>item.Id == this.objEnquiryData.QuestionType.Id);
			if( question.length > 0 ){
				boolHasResponse = question[0].HasAutoResponse;
			}
			return boolHasResponse;
		}catch{

			return boolHasResponse;
		}
	}
	showContent(){
		var boolShow:boolean=!this.boolQuestionHasResponse;
		return boolShow;
	}
	get isUserLoggedIn(){
        var boolLoggedIn:boolean = false;
        if( sessionStorage.getItem("isLogedin") && JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
            boolLoggedIn = true;
        }
        return boolLoggedIn;
    }
    closeContactUs(){
    	$("#enquiry--modal").modal("hide");
    	this.confirmStatus = true;
    }
    get getEmailPattern(){

        const regex = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/;
        var objEmailRegExp = new RegExp(regex, "i");
        return objEmailRegExp;
    }
}
