import { Inscription } from './ordermodel';
import { BaseModel } from './basemodel';
import { OrderDetails,PhotoMosaicOrder } from './ordermodel';

export class TilesModel extends BaseModel{
	public  CustomerId:any;
	public  CartId:any;
	public  CustomerGuid:any;
	public  CustomerName:any;
	public  OrganizationId:any;
	public  ProjectId:any;
	public  CurrencyId:any;
	public  ProductId:any;
	public  ProductGuid:any;
	public  Quantity:any;
	public  ProductName:any;
	public  ProductDesc:any;
	public  CartType:any;
	public  IsAccepted:any;
	public  IsAccessable:any;
	public  Orders:Array<OrderDetails> = new Array<OrderDetails>();		 
	public  SessionId:any;
	public  IsGuest:any;
	public  Notes:any;
}
export class TilesOrder{
	public CartId:any;
	public CartGuid:any;
	public OrderId:any;
	public OrderNumber:any;
	public CustomerId:any;
	public CustomerGuid:any;
	public ProductId:any;
	public ProductGuid:any;
	public Quantity:any;
	public ProductName:any;
	public ProductDesc:any;
	public ProductCost:any;
	public ProductType:any;
	public IsParent:any;
	public ParentProductId:any;
	public ParentOrderId:any;
	public OrderAction:any;
	public OrderStatusId:any;
	public IsGuest:any;
	public inscriptionDetail:Array<Inscription> = new Array<Inscription>();
	public DependentOrder:Array<TilesOrder> = new Array<TilesOrder>();
}