import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { operationService } from '../../../_services/operation.service';
import { DataSharedService } from '../../../_services/dataSharedService';
// import * as url from '../../../baseUrl';
import * as url from '../../../../environments/environment';
import { Observable } from 'rxjs';
//import { DOCUMENT } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './payment-failed.html',
  providers: [operationService]
})
export class PaymentFailedComponent {
  // routeUrl:string = window.location.href.split('#').pop().split('/')[1]; 
  public routeUrl: string = window.location.href.split('/')[3];
  CurrencyHtmlCode:string = sessionStorage.getItem("CurrencyHtmlCode");
    constructor(private _router: Router, private route: ActivatedRoute, private _operationService: operationService, private _DataSharedService: DataSharedService) { 
      }
}