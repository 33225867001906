export enum ProductTypeEnum{

	TilesProductType 		= 8,
	VoucherProductType 		= 6,
	StandardProductType 	= 9,
	PhotoMosaicProductType  = 7,
	MetalReplicaProductType = 10,
}
export enum ProductTypeCodeEnum{
	TilesProductTypeCode = "TILE",
	VoucherProductTypeCode = "VOU"
}
export enum ProjectTypeEnum{

	GeneralProjectType 	   = "GEN", 
	VoucherProjectType 	   = "VOU",
	PhotoMosiacProjectType = "PM",
	TilesProjectType       = "TILE"
}