import { Component, OnInit,ViewChild,ElementRef,OnChanges,Input } from '@angular/core';
import { operationService } from '../../_services/operation.service';
import { NewsContentModel,NewsTitleModel,BuildData } from '../../models/newsmodel';

@Component({
    selector: 'news-cursole',
    templateUrl: './newscursole.component.html',
    providers: [operationService]
})
export class NewsCursoleComponent implements OnInit,OnChanges{
	
	@Input("arrobjBuildData") arrobjBuildData:Array<BuildData> = new Array<BuildData>();
	constructor(objoperationService:operationService){}
	
	ngOnInit(){

	}
	ngOnChanges(){

	}

    getHtml(text,characterCount){
        var cleanText = text.replace(/<\/?[^>]+(>|$)/g, ""); 
        var tesxt = cleanText.substring(0,characterCount);
        return tesxt+"....";
    }

    public objNewsContentModel:any={};
    openNewsPopUp(objNewsContentModel){
        this.objNewsContentModel = objNewsContentModel;
        $("#news-popup").modal("show");
    }
}