import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { operationService } from '../../_services/operation.service';
import { DataSharedService } from '../../_services/dataSharedService';
// import * as url from '../../baseUrl';
import * as url from '../../../environments/environment';
declare var OpenSeadragon: any;
import { Observable ,  Subscription,EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { EmbedVideoService } from 'ngx-embed-video';
import { setTimeout, Number } from 'core-js/library/web/timers';
import {DatePipe} from '@angular/common';
import { ComponentintractService } from '../../_services/conponentinteraction.service';
import { DocumentTypeCodeEnum,ObjectTypeEnum,DocumentTypeCategoryEnum } from '../../models/documenttypecode.enum';
import { ImageDocumentUpload,DocumentProperties,UploadImage,DocumentMapping } from '../../models/documentMapping.model';
import { ProductListModel } from '../../models/productmodel';
import { Language,MappedLanguageModel, PreRegistrationModel } from '../../models/language.model';
import { ProjectAdditionalModel } from '../../models/project.model';
import { ProjectNewsModel } from '../../models/projectnews.model';
import { ProjectStatus } from '../../models/project.enum';
// declare var viewer: any;
@Component({
    selector: 'photomosaic-landing-page',
    templateUrl: './photomosaic-landing-page.html',
    providers: [operationService, EmbedVideoService, DatePipe]
})
export class PhotomosaicLandingComponent implements OnInit {
    public routeUrl: string = ""//window.location.href.split('/')[3];
    // public routeUrl: string = window.location.href.split('#').pop().split('/')[1];
    public zoomLabel: number = 0;
    public projectId: number;
    public OrganisationId: number;
    public listOfProduct: any = {};
    public productDetails: any = [];
    public backGroundImage: any = [];
    public randomImageList: any = [];
    public mostLikedImages: any = [];
    public projectDescripTion: any = {};
    public newsList: any = [];
    public firstTimeLoadStatus: boolean = false;
    public photoMosaicBackgroundImage: string;
    public safeURL: any;
    public  productImage: string;
    public iframe_html: any;
    public interval: any;
    public selectedNews: any = {};
    public ProjectName: string;
    public topNews: any = [];
    public uploadingStatus: boolean = false;
    public languageData: any = {}
    public photoMosaicBackgroundColor: string;
    public allProjectDetails: Array<any> = [];
    public photomosaicImagePath: string;
    public viewer: any;
    public clickData: any;
    public userEntryCode: any;
    public mainImage: any = [];
    public ProductId: number;
    public arrObjQuestionType:any=[];
    public IsLoggedIn:boolean = false;
    public CurrencyHtmlCode: string = sessionStorage.getItem("CurrencyHtmlCode");
    public tweet:string = "addd";
    public enqueryData:any = {};
    public newsOne: any = [];
    public newsSecond: any = [];
    public newsThird: any = [];
    public DocumentTypeCodeEnum : typeof DocumentTypeCodeEnum = DocumentTypeCodeEnum;
    public ObjectTypeEnum : typeof ObjectTypeEnum = ObjectTypeEnum;
    public DocumentTypeCategoryEnum : typeof DocumentTypeCategoryEnum = DocumentTypeCategoryEnum;
    public objProjectDetails:any={};
    public objProjectSliderImage:any={};
    public objDocumentProperties:DocumentProperties=new DocumentProperties();
    public arrobjImageDocumentUpload:Array<ImageDocumentUpload> = new Array<ImageDocumentUpload>();
    public arrobjProjectNewsModel:Array<ProjectNewsModel> = new Array<ProjectNewsModel>();
    public boolIsWallSoldOut:boolean=false;
    public subscription:Subscription;
    @ViewChild('briccardsection') public briccardsection:ElementRef;
    @ViewChild('video') public video:ElementRef;
    public objUploadImage:UploadImage=new UploadImage();
    public strSliderImageStep1:any=""
    public strSliderImageStep2:any=""
    public arrobjMappedLanguageModel:Array<MappedLanguageModel> = new Array<MappedLanguageModel>();
    public objMappedLanguageModel:MappedLanguageModel = new MappedLanguageModel();
    public arrObjProductListModel:Array<ProductListModel> = new Array<ProductListModel>();
    public objProjectAdditionalModel:ProjectAdditionalModel=new ProjectAdditionalModel();
    public arrobjProjectSliderImage:Array<DocumentMapping> = new Array<DocumentMapping>();
    
    public testing:any ={};

    public boolIsConfirmEmailEntered:string = '';
    PreRegistrationObj = new PreRegistrationModel();
    confirmEmail: string;

    public get ProjectStatus(): typeof ProjectStatus{
        return ProjectStatus;
    }

    constructor(private translate: TranslateService,private objComponentintractService:ComponentintractService,private _operationService: operationService, private _DataSharedService: DataSharedService, private _router: Router, private _sanitizer: DomSanitizer, private embedService: EmbedVideoService, private datePipe: DatePipe) {
        if (this._DataSharedService.VideoURL) {
            this.iframe_html = this.embedService.embed(this._DataSharedService.VideoURL, { query: { portrait: 0, color: '333' }, attr: { width: 400, height: 300 } });
        }
    }

    ngOnInit() {
        var arrUrlDetails = window.location.href.split('/');
        this.routeUrl = arrUrlDetails[url.urlIndex];
        this.subscription = this.objComponentintractService.getComponentdata().subscribe(
            data=>{
                if( data !=undefined ){
                    if( data.scrollToGiftSection ){
                        this.scrollToSection(this.briccardsection);
                    }
                    if( data.boolChangeCurrency ){
                        var langMappingId = sessionStorage.getItem("defaultLanguageId");
                        if( langMappingId == null ){
                            this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.IsDefault==true)[0];
                        } else {
                            this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.Id==JSON.parse(langMappingId))[0];
                        }
                        this.setLanguageSessionData(this.objMappedLanguageModel);
                        this.getProjectTranslationDetail(this.ObjectTypeEnum.PROJECT,this.objMappedLanguageModel.LanguageCode)
                    }
                }
            }
        )
        this.GetProjectByCode();
    }
    ngOnDestroy(){
        // this.subscription.unsubscribe();
        this.objComponentintractService.clearComponentdata();
    }
    getQuestionTypeList() {

        if(sessionStorage.getItem("isLogedin") == 'undefined'){
            this.IsLoggedIn = false;
        } else {

            this.IsLoggedIn =true;
        }
        this._operationService.get(url.bricsAPI+"ChatBox/GetQuestionList/?IsLoggedIn="+this.IsLoggedIn).subscribe(
            data=>{
                this.arrObjQuestionType = data;
            },
            error=>{

            }
        )
    }
    getProjectNews() {
        this._operationService.get(url.bricsAPI+'Project/GetProjectNewsDetail/?projectId='+this.projectId+'&languageCode='+sessionStorage.getItem("languageCode")).subscribe(
            data => {
                this.arrobjProjectNewsModel = data;
                this.readeMore();
            },
            error => {

            }
        )
    }
    readeMore() {
        var that = this;
        // Read more
        $(document).ready(function () {
            var maxL;
            $('.read-more-news').each(function (i, div) {
                if (i == 0)
                    maxL = 180;
                else
                    maxL = 80;
                var text = $(div).text();
                if (text.length > maxL) {

                    var begin = text.substr(0, maxL),
                        end = text.substr(maxL);

                    $(div).html(begin)
                        .append($(`<a class="readmore newsDetails" id="dynamicId_${i}" style="color:red" data-toggle="modal" data-target="#newsModal"/>`).attr('href', '#').html(' read more...'))

                }
            });

            $(".newsDetails").unbind("click").click(function () {
                that.selectedNews = that.newsList[parseInt((this.id).split("dynamicId_")[1])];
            })

        })
        // End Reade more 
    }
    public scrollTOGiftSection(){
        $("#buynow-modalform").modal("hide");
        this._router.navigate([this.routeUrl+'/voucher/'+this.projectId]);
    }
    public scrollToSection(specifiedSection:ElementRef):void {
        specifiedSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    }
    get pmbackGroundImage(){
        var image:any="";
        if( this.photoMosaicBackgroundImage != undefined ){
            image = this.photoMosaicBackgroundImage;
        }
        return image;
    }
    GetProjectByCode(){
        this._operationService.get(url.bricsAPI+'Project/GetProjectByCode/?projectCode='+this.routeUrl).subscribe(
            data => {
                this.objProjectDetails = data;
                this.allProjectDetails = data;
                this.setProjectData(data);
                this.stockAvalibalityCheck(this.objProjectDetails.ProjectGUID);
                this.getOrganizationMappedLanguages(data);
            },error=>{
               this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )  
    }
    getProjectImages(objProjectDocument:DocumentMapping){
        this.arrobjProjectSliderImage = [];
        this._operationService.post(url.bricsAPI+"DMS/FetchDocuments",objProjectDocument).subscribe(
            data=>{
                sessionStorage.setItem("filepath", data[0].FilePath)
                this.arrobjProjectSliderImage = data.filter(item=>item.DocumentType == DocumentTypeCodeEnum.PROJECTSLIDEIMAGE)
                data.forEach((value,index)=>{

                    switch (value.DocumentType) {
                        case DocumentTypeCodeEnum.PROJECTBACKGROUNDIMAGE:
                            this.photoMosaicBackgroundImage = value.FilePath;
                            break;
                        case DocumentTypeCodeEnum.HOMEPAGEIMAGE1:
                            this.strSliderImageStep1 = value.FilePath;
                            break;
                        case DocumentTypeCodeEnum.HOMEPAGEIMAGE2:
                            this.strSliderImageStep2 = value.FilePath;
                            break;
                        
                        default:
                            // code...
                            break;
                    }
                });
            },error=>{

            }
        )
    }
    getOrganizationMappedLanguages(objProject){
        this._operationService.get(url.bricsAPI+"Organization/GetOrganizationMappedLanguages/?organizationId="+objProject.OrganisationId).subscribe(
            data=>{
                this.arrobjMappedLanguageModel = data;
                var langMappingId = sessionStorage.getItem("defaultLanguageId");
                if( langMappingId == null ){
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.IsDefault==true)[0];
                } else {
                    this.objMappedLanguageModel = this.arrobjMappedLanguageModel.filter(item=>item.Id==JSON.parse(langMappingId))[0];
                }
                this.setLanguageSessionData(this.objMappedLanguageModel);
                this.getProjectTranslationDetail(this.ObjectTypeEnum.PROJECT,this.objMappedLanguageModel.LanguageCode);
                this.getAllProduct(this.objMappedLanguageModel.CurrencyId);
                this.checkProjetcStatus(this.objMappedLanguageModel.ResourceFile);

                var objProjectFilterDocument = new DocumentMapping();
                objProjectFilterDocument.ObjectId = objProject.ProjectId;
                objProjectFilterDocument.ObjectType = ObjectTypeEnum.PROJECT;
                objProjectFilterDocument.languagecode = this.objMappedLanguageModel.ResourceFile;
                objProjectFilterDocument.DocumentTypeCategory = DocumentTypeCategoryEnum.ProjectImage;

                this.getProjectImages(objProjectFilterDocument);
            },error=>{

            }
        )
    }
    setLanguageSessionData(objMappedLanguageModel):boolean{
        sessionStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        localStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);

        sessionStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        localStorage.setItem("currencyId",this.objMappedLanguageModel.CurrencyId);
        
        sessionStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        localStorage.setItem("defaultLanguageId",this.objMappedLanguageModel.Id);
        
        sessionStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        localStorage.setItem("currencyIcon",this.objMappedLanguageModel.CurrencyIcon);
        
        sessionStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        localStorage.setItem("CurrencyCode",this.objMappedLanguageModel.CurrencyCode);
        
        sessionStorage.setItem("languageCode",this.objMappedLanguageModel.LanguageCode);
        localStorage.setItem("languageCode",btoa(this.objMappedLanguageModel.LanguageCode));
        
        sessionStorage.setItem("DefaultLangauge",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("DefaultLangauge",btoa(this.objMappedLanguageModel.ResourceFile.trim()));
        
        sessionStorage.setItem("activeLanguage",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("activeLanguage",btoa(this.objMappedLanguageModel.ResourceFile.trim()));
        
        sessionStorage.setItem("activeLanguageId",this.objMappedLanguageModel.ResourceFile.trim());
        localStorage.setItem("activeLanguageId",this.objMappedLanguageModel.ResourceFile.trim());
        
        this.translate.setDefaultLang((objMappedLanguageModel.ResourceFile.trim()).toLowerCase());

        return true;
    }
    setProjectData(data){
        this.allProjectDetails = data;
        this.projectId = data.ProjectId;
        this.ProjectName = data.ProjectName;
        this.OrganisationId = data.OrganisationId;

        /*Session for project details*/
        sessionStorage.setItem("projectId", JSON.stringify(this.projectId));
        sessionStorage.setItem("OrganisationId", JSON.stringify(this.OrganisationId));
        sessionStorage.setItem("OrganisationGuid", data.OrganisationGuid);
        sessionStorage.setItem("ProjectGUID", data.ProjectGUID);
        
        sessionStorage.setItem("projectStatus",data.ProjectStatus);
        localStorage.setItem("projectStatus",btoa(data.ProjectStatus));

        sessionStorage.setItem("acceptMemberShip",data.IsMemberShipEnabled);
        localStorage.setItem("acceptMemberShip",btoa(data.IsMemberShipEnabled));

        this.objComponentintractService.setComponentdata({boolLaod:false});
    }
    /* File Upload */
    
    isuploadimageFlag: boolean = false;
    previewImage: boolean = false;
    imageJson: any = {};
    file: File;
    imagePath: string;
    size: any;
    width: any;
    height: any;

    getAllProduct(currencyId?) {

        this._operationService.get(url.bricsAPI+'Product/?projectId='+this.projectId+'&documentType=PRODUCT_IMAGE&imageType=THUMBNAIL&CurrentCurrencyId='+currencyId).subscribe(
            data => {
                this.arrObjProductListModel = data.filter(item => item.ProductTypeCode != "VOU" && item.ProductTypeCode != "PM" );
                let filterPhotomosaic = data.filter(item => item.ProductTypeCode == "PM" && item.Published);
                this.listOfProduct = filterPhotomosaic[0];
                let productId = this.listOfProduct.ProductId;
                sessionStorage.setItem("productId", productId);
                sessionStorage.setItem("productGuid", this.listOfProduct.ProductGuid);
                this.firstTimeLoadStatus = true;
                this.getProductDetails(this.listOfProduct.ProductGuid);
            },error => {

            }
        )
    }
    getProductDetails(ProductGuid){
        this._operationService.get(url.mainUrl+'Product/?ProductGuid='+ProductGuid+"&CurrentCurrencyId="+sessionStorage.getItem("currencyId")).subscribe(
            data=>{
                sessionStorage.setItem("maxfilesize", data.MaximumImageFileSize);
                localStorage.setItem("maxfilesize", data.MaximumImageFileSize);
                
                sessionStorage.setItem("minimumFileSize", data.MinimumImageFileSize);
                localStorage.setItem("minimumFileSize", data.MinimumImageFileSize);
            },error=>{
                
            }
        )
    }
    /* Generate GUID -- */
    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    

    findoutmore(){
        this.objComponentintractService.setComponentdata({findOutMore:true});
    }

    getProductImages(objectId,ObjectType,IsDefault,documentType,index?){

        var docPreGetData = {ObjectId:objectId,ObjectType:ObjectType,IsDefault:IsDefault,DocumentType:documentType}
        this._operationService.post(url.bricsAPI+"DMS/GetDocuments",docPreGetData).subscribe(
            data=>{
                this.arrobjImageDocumentUpload = data;
                this.arrObjProductListModel[index].productImage = this.arrobjImageDocumentUpload;
            },error=>{

            }
        )
    }
    public homePageStepsHeader:any;
    gethomePageStepsHeader(){
        return this.homePageStepsHeader;
    }
    getProjectTranslationDetail(objectType,languageCode){
        this._operationService.get(url.mainUrl+"Project/GetProjectTranslationDetail/?projectId="+this.projectId+"&LanguageCode="+languageCode).subscribe(
            data=>{
                this.objProjectAdditionalModel = data;
                sessionStorage.setItem("ProjectName", this.objProjectAdditionalModel.ProjectName);

                this.objComponentintractService.clearComponentdata();
                
                if(this.objProjectAdditionalModel.ProjectFooter){
                    this.objComponentintractService.setComponentdata({FooterText:this.objProjectAdditionalModel.ProjectFooter});
                };

            },error=>{
                
            }
        )
    }
    get projectClosureMessage(){
        var errorMessage="";
        if(this.objProjectDetails.Status == 0){

            errorMessage = this.objProjectAdditionalModel.ProjectStatusMessage;
        }
        if( this.objProjectDetails.ProjectStatus ==  ProjectStatus.OPEN && this.objProjectDetails.IsCountDownEnabled == true ){

            errorMessage = this.objProjectAdditionalModel.CountDownMessage;
        }
        return errorMessage;
    }
    closeVideo(){
        this.iframe_html = "";
    }
    openVideo(){

        if (this._DataSharedService.VideoURL) {
            this.iframe_html = this.embedService.embed(this._DataSharedService.VideoURL, { query: { portrait: 0, color: '333' }, attr: { width: 400, height: 550 } });
        }
        $("#video-modal").modal("show");
    }
    checkProjetcStatus(langCode){

        this._operationService.get(url.bricsAPI+"Project/GetProjectStatus?projectCode="+this.routeUrl+"&lang="+langCode).subscribe(
            data=>{
                sessionStorage.setItem("projectStatus",data.ProjectStatus);            
            },error=>{

            } 
        )
    }
    get showErrorMessage(){

        var boolShowError:boolean=false;
        if( this.objProjectDetails.ProjectStatus ==  ProjectStatus.CLOSED || this.objProjectDetails.ProjectStatus ==  ProjectStatus.HOLD || (this.objProjectDetails.ProjectStatus ==  ProjectStatus.OPEN && this.objProjectDetails.IsCountDownEnabled == true) ){
            boolShowError = true;
        }
        return boolShowError;
    }
    stockAvalibalityCheck(projectGUID){

        this._operationService.get(url.bricsAPI + "Product/IsPMStockAvailable/?projectGuid="+projectGUID).subscribe(
            data=>{
                this.boolIsWallSoldOut = !data;
            },error=>{

            }
        )
    }


    
    OnSubmitPreRegistration(){
        delete this.PreRegistrationObj.isTermAndConditionCheckboxChecked
        this.PreRegistrationObj.ProjectId = sessionStorage.getItem("projectId"); 
        this.PreRegistrationObj.CreatedDate = null;
        this._operationService.post(`${url.bricsAPI}PreRegistration/AddPreRegistration`,this.PreRegistrationObj).subscribe({
            next: (resp:any)=>{
                this.PreRegistrationObj = new PreRegistrationModel();
                this.boolIsConfirmEmailEntered = 'SUCCESS';
                this.confirmEmail = undefined;
                // this.objProjectDetails.IsPreRegistrationEnabled = !this.objProjectDetails.IsPreRegistrationEnabled
            },
            error: (err:any)=>{
                if((err._body.match("DUPLICATE")) != null){
                    this.boolIsConfirmEmailEntered = 'SUCCESS';       
                }
                
            }
        });
    }

   

    matchEmail($event){
        this.boolIsConfirmEmailEntered = 'EMAIL';
    }

  
}
