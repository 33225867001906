import { Component,OnInit,Input,Output,OnChanges,EventEmitter } from '@angular/core';
import { loginService } from '../../../_services/login.service';
import { operationService } from '../../../_services/operation.service';
import { DataSharedService } from '../../../_services/dataSharedService';
// import * as url from '../../baseUrl';
import * as url from '../../../../environments/environment';
import { AddressModel } from '../../../models/addressmodel';
import { CountryModel } from '../../../models/countrymodel';
@Component({
  selector: 'app-add-address',
  templateUrl: './appaddaddress.html',
  providers: [loginService, operationService]
})

export class AddAddressComponent implements OnInit,OnChanges{
	
	@Input("boolIsChange") boolIsChange:boolean=false;
	@Input("objResource") objResource:any={}
	@Input("strMode") strMode:string;
	@Input("boolAddressAvailable") boolAddressAvailable:boolean=false;
	@Input("objAddressModel") objAddressModel:AddressModel=new AddressModel();
    @Output() boolEmitData = new EventEmitter<boolean>();
	public userAddress:AddressModel=new AddressModel();
	public boolIsInitated:boolean;
	public addUpdateBtnStatus:boolean=false;
	public arrobjCountryModel:Array<CountryModel> = new Array<CountryModel>();
	public arrobjAddressModel:Array<AddressModel> = new Array<AddressModel>();
    public phoneNumberLength:any;
	constructor(private objoperationService: operationService,private _loginService: loginService){}
	ngOnInit(){
        this.phoneNumberLength = 10
		this.boolIsInitated = true;
		$("#addaddress").modal("show");
		this.userAddress = this.objAddressModel;
		this.getCountryList();
	}
	ngOnChanges(){
		if( this.boolIsInitated ){
			
			this.userAddress = this.objAddressModel;
			$("#addaddress").modal("show");
			this.getCountryList();
		}
	}
	getCountryList() {
        
        this.objoperationService.get(url.umsUrl + 'Admin/Master/GetAllCountries').subscribe(
            data => {
            	this.arrobjCountryModel = data;
            },error => {
    
            }
        )
    }

    addNewAddress() {

        if( !this.boolAddressAvailable ){
            this.userAddress.isDelivery = true;
            this.userAddress.isInvoice = true;
        }

        this.userAddress.CustomerId = parseInt(sessionStorage.getItem("UserId"));
        this._loginService.post(url.umsUrl + 'Admin/Customer/AddCustomerAddress', this.userAddress).subscribe(
            data => {
            	if( this.objResource.Briconomics != undefined ){

                	toastr.success(this.objResource.Briconomics.AddressModule.AddressAddSuccess);
            	}
                $("#addaddress").modal("hide");
                this.userAddress.Id = Number(data.ResponseData);
                this.boolEmitData.emit(true);
            },error => {
                $("#addaddress").modal("hide");
                toastr.error("Error");
            }
        )
    }
    updateAddress(){

        this._loginService.put(url.umsUrl + 'Admin/Customer/UpdateCustomerAddress', this.userAddress).subscribe(
            data => {
                $("#addaddress").modal("hide");
                this.userAddress = new AddressModel();
                this.boolEmitData.emit(true);
                toastr.success(this.objResource.Briconomics.AddressModule.AddressUpdateSuccess);
            },error => {

                toastr.error("Error");
                // toastr.error(this.languageData.Briconomics.alertMessage.error);
            }
        )
    }

    get getPhoneNumberPattern(){

        var tets='^\+?\d{10}$';
        // var regEx = new RegExp('^\+?\d{10}$')
        return tets
    }
}