import { Component,Output,OnChanges, OnInit,ViewChild,ElementRef,Input,Directive,EventEmitter,AfterViewInit,NgZone,Injector } from '@angular/core';

@Directive({
  selector: '[nbStriptags]'
})

export class StripHtmlTagsDirectives implements OnInit,OnChanges{

	@Input() inputStringData : string;
	@Output() strippedString = new EventEmitter<string>();
	ngOnInit(){
		this.stripTags();
	}

	ngOnChanges(){
		this.stripTags();
	}
	stripTags(){
		if( this.inputStringData != undefined ){

			this.strippedString.emit(this.inputStringData.replace(/<[^>]*>/g, ''));
		}
    }
}