import { Component, OnInit,EventEmitter,OnChanges, Input, Output,ViewChild,ElementRef } from '@angular/core';
@Component({
    selector: 'app-confirmdelete',
    templateUrl: './confirmdelete.component.html',
    inputs: ['value']
})
export class ConfirmdeleteComponent implements OnInit,OnChanges {
    @Output() confirmDeleteEventEmiter = new EventEmitter<boolean>();
    @Input('confirmmodalname') confirmmodalname: string="";
    @Input("Message") Message:string="";
    @Input("boolIsChanged") boolIsChanged:boolean;
    @ViewChild('deleteModel') private deleteModel: ElementRef;
    public boolIsInitiated:boolean=false;
    public boolShowCustomMessage:boolean=true;
    constructor() {}
    public strDeleteApiUrl:string;

    ngOnInit() {
        this.boolIsInitiated = true;
        // this.confirmmodalname = "model-delete"+this.confirmmodalname;
        if( this.Message == ""){
            this.boolShowCustomMessage = false;
        }
    }
    ngOnChanges(){
        if( this.boolIsInitiated === true ){
            this.boolShowCustomMessage = true;
            if( this.Message == ""){
                this.boolShowCustomMessage = false;
            }
            $("#model-delete-"+this.confirmmodalname).modal("show");
            $("#model-delete-"+this.confirmmodalname).appendTo("body");
            // $("#"+this.confirmmodalname).modal("show");
            // $("#"+this.confirmmodalname).appendTo("body");
        }
    }

    ngOnDestroy(){
        if( this.deleteModel ){

            this.deleteModel.nativeElement.remove();
        }
    }
    ProceedConfirm(){
        $("#model-delete-"+this.confirmmodalname).modal("hide");
        // $("#"+this.confirmmodalname).modal("hide");
        this.confirmDeleteEventEmiter.emit(true);
    }
}
