import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { operationService } from '../../../_services/operation.service';
import { DataSharedService } from '../../../_services/dataSharedService';
import * as url from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CartModel } from '../../../models/cart.model';
import { AddressModel } from '../../../models/addressmodel';
import { PaymentModel,PaymentGatewayType,PaymentHandlerModel,PaymentType } from '../../../models/payment.model';
import { CartTypeEnum,PaymentMethodEnum } from '../../../models/project.enum';
import { GTMSalesDataConversionModel,TransactionProducts } from '../../../models/gtm.datalayer.sales.conversion.model';
import { ObjectTypeEnum } from '../../../models/documenttypecode.enum';
declare var dataLayer:any;
@Component({
    selector: 'app-root',
    templateUrl: './payment-success.html',
    providers: [operationService, DatePipe]
})
export class PaymentSuccessComponent implements OnInit {
    url: any = '';
    // custom_text = 'Dear Friend, Please check this existing Project.';
    custom_text = 'So excited to be part of this!';
    whatsappUrl: any = '';
    facebook_shere: any = '';
    public apiCallCounter:any=0;
    public boolShoworderSummery:boolean=false;
    public orderreference:any;
    public objCartDetails:CartModel=new CartModel();
    public CartTypeEnum : typeof CartTypeEnum = CartTypeEnum;
    public activeLanguage:any;
    public ThankYouNote:any="";    
    public grandTotal:any=0;
    public orderTotal:any=0;
    public grandTotalDeliveryAmount:any=0;
    public ObjectTypeEnum : typeof ObjectTypeEnum = ObjectTypeEnum;
    public paymentInitiated:boolean = false;
    tweeter_shere: any = '';
    constructor(private objoperationService:operationService){}
    ngOnInit(){
        // this.url = encodeURIComponent(window.location.href);
        this.facebook_shere = encodeURIComponent(localStorage.getItem('url'));
        this.url = encodeURIComponent(localStorage.getItem('url'));
        this.tweeter_shere = 'Please share with your friends and family so they can also be part of this exciting project.  Thank you for your support! We will post regular order updates and installation news on here and on our Facebook page.&url=' + encodeURIComponent(localStorage.getItem('url')) ;
        this.whatsappUrl = 'whatsapp://send?text=' + encodeURIComponent(localStorage.getItem('url'));
        this.setSessionData();
        var queryString = "?"+window.location.href.split("?")[1];
        var arrUrlDetails = queryString.split('&');

        arrUrlDetails.forEach((value,index)=>{
            if( value.includes("orderreference",0) == true ){
                this.orderreference =  value.split("=")[1];
            }
            
        });
        this.boolShoworderSummery = false;

        
        if(queryString.includes("?errorcode=0") && queryString.includes("paymenttypedescription=MULTIBANCO") ){
            this.paymentInitiated = true;
            this.updateCartAfterPayment(queryString);
            
        }else{
            this.paymentInitiated = false;
            this.updateCartAfterPayment(queryString);
        }
        
    }
    setSessionData():boolean{
        sessionStorage.setItem("Email", localStorage.getItem("Email") ? atob(localStorage.getItem("Email")):"");
        sessionStorage.setItem("cartReference", localStorage.getItem("cartReference") ? atob(localStorage.getItem("cartReference")):"");
        sessionStorage.setItem("checkOutId", localStorage.getItem("checkOutId") ? localStorage.getItem("checkOutId"):"");
        sessionStorage.setItem("AuthType", localStorage.getItem("AuthType") ? localStorage.getItem("AuthType"):"");
        sessionStorage.setItem("UserId", localStorage.getItem("UserId") ? atob(localStorage.getItem("UserId")):"");
        sessionStorage.setItem("IsAdmin", localStorage.getItem("IsAdmin") ? atob(localStorage.getItem("IsAdmin")):"");
        sessionStorage.setItem("FirstName", localStorage.getItem("FirstName") ? localStorage.getItem("FirstName"):"");
        sessionStorage.setItem("isLogedin", localStorage.getItem("isLogedin") ? atob(localStorage.getItem("isLogedin")):"");
        sessionStorage.setItem("IsGuestUser", localStorage.getItem("IsGuestUser") ? atob(localStorage.getItem("IsGuestUser")):"");
        sessionStorage.setItem("languageCode", localStorage.getItem("languageCode") ? atob(localStorage.getItem("languageCode")):"");
        sessionStorage.setItem("CurentCompanyCode",localStorage.getItem("CurentCompanyCode") ? atob(localStorage.getItem("CurentCompanyCode")):"");
        sessionStorage.setItem("paymentZeroAmount",localStorage.getItem("paymentZeroAmount")?atob(localStorage.getItem("paymentZeroAmount")):"");
        sessionStorage.setItem("DefaultLangauge",localStorage.getItem("DefaultLangauge")?atob(localStorage.getItem("DefaultLangauge")):"");
        sessionStorage.setItem("activeLanguage",localStorage.getItem("activeLanguage")?atob(localStorage.getItem("activeLanguage")):"");
        sessionStorage.setItem("projectId",localStorage.getItem("projectId")?atob(localStorage.getItem("projectId")):"");
        sessionStorage.setItem("activeLanguageId",localStorage.getItem("activeLanguageId")?localStorage.getItem("activeLanguageId"):"");
        return true;
    }
    updateCartAfterPayment(queryString) {
        var objPaymentHandlerModel:PaymentHandlerModel=new PaymentHandlerModel();

        objPaymentHandlerModel.ReturnURL = queryString;
        objPaymentHandlerModel.PaymentType = PaymentType.SECURETRADING;

        this.objoperationService.put(url.mainUrl+'Cart/UpdateCartAfterPayment', objPaymentHandlerModel).subscribe(
            data => {

                if (queryString.includes("?errorcode=0") && queryString.includes("paymenttypedescription=MULTIBANCO")) {
                    this.paymentInitiated = true;
                    // this.getCartByGuid();
                } else {
                    this.paymentInitiated = false;
                }
                this.apiCallCounter = 3;
                sessionStorage.setItem("orderCount","0");
                sessionStorage.setItem("isCartAvailable","false");
                this.getCartByGuid();
            },error => {
                if( this.apiCallCounter < 3 ){
                    this.apiCallCounter = this.apiCallCounter + 1;
                    this.updateCartAfterPayment(queryString);
                }
            }
        )
    }

    /*Send Email*/
    confirmOrder(orderId){
        
        this.objoperationService.get(url.mainUrl+"Cart/ConfirmOrder/?cartGuid="+orderId+"&languageCode="+sessionStorage.getItem("activeLanguage")+"&sentStatus=false").subscribe(
            data=>{

            },error=>{

            }
        )
    }
    getCartByGuid(){
        this.objoperationService.get(url.mainUrl+"cart/GetCartByGuid/?cartGuid="+this.orderreference).subscribe(
            data=>{
                this.boolShoworderSummery = true;
                this.objCartDetails = data;
                this.generateGTMSalesDataConversion(this.objCartDetails)
                this.confirmOrder(this.objCartDetails.CartGuid);
                this.grandTotal=0;
                this.orderTotal=0;
                this.grandTotalDeliveryAmount=0;

                this.grandTotal = this.grandTotal +  (this.objCartDetails.OrderTotalInclTax + this.objCartDetails.OrderShippingInclTax) - (this.objCartDetails.CartVoucherTotalInclTax + this.objCartDetails.CartDiscountTotalInclTax);
                this.grandTotalDeliveryAmount = this.grandTotalDeliveryAmount + this.objCartDetails.OrderShippingInclTax;
                this.orderTotal = this.orderTotal + this.objCartDetails.OrderTotalInclTax - this.objCartDetails.CartDiscountTotalInclTax;
                
                /**
                this.objCartDetails.Orders.forEach((value,index)=>{
                    this.grandTotalDeliveryAmount = this.grandTotalDeliveryAmount + value.OrderDeliveryInclTax;
                    this.orderTotal = this.orderTotal + (value.PriceInclTax - value.DiscountAmountInclTax);
                    if(this.objCartDetails.Type == CartTypeEnum.TILESCART && value.DependentOrders != null && value.DependentOrders.length>0){
                        this.orderTotal = this.orderTotal + (value.DependentOrders[0].PriceInclTax - value.DependentOrders[0].DiscountAmountInclTax);                       
                    }
                });
                this.grandTotal = this.orderTotal + this.grandTotalDeliveryAmount - this.objCartDetails.CartVoucherTotalInclTax
                */

                if( this.grandTotal < 0 ){
                    this.grandTotal = 0;
                }
                this.boolShoworderSummery = true;
                this.getProjectTranslationDetail(this.objCartDetails.project.Id,this.ObjectTypeEnum.PROJECT,sessionStorage.getItem("languageCode"))
            },error=>{

            }
        )
    }

    generateGTMSalesDataConversion(objCartDetails:CartModel){

        var objGTMSalesDataConversionModel:GTMSalesDataConversionModel = new GTMSalesDataConversionModel();
        objGTMSalesDataConversionModel.transactionId = objCartDetails.CartGuid;
        objGTMSalesDataConversionModel.transactionAffiliation = "LiverPool";
        objGTMSalesDataConversionModel.transactionTotal = objCartDetails.cartTotalAmount;
        objGTMSalesDataConversionModel.transactionShipping = objCartDetails.Orders.length;
        
        objCartDetails.Orders.forEach((value,index)=>{
            let objTransactionProducts:TransactionProducts = new TransactionProducts();
            objTransactionProducts.sku = value.productDetails.ProductName;
            objTransactionProducts.price = value.PriceInclTax;
            objTransactionProducts.quantity = value.Quantity;
            objTransactionProducts.name = value.ItemName;
            
            objGTMSalesDataConversionModel.transactionProducts.push(objTransactionProducts);
        })

        dataLayer.push(objGTMSalesDataConversionModel);
        dataLayer.push({'triggerAnalytics':true});
    }
    getProjectTranslationDetail(objectId,objectType,languagecode){
        this.objoperationService.get(url.mainUrl + 'Project/GetProjectTranslationDetail/?projectId='+objectId+'&LanguageCode='+languagecode).subscribe(
            data => {
                try{

                    this.ThankYouNote = data.ThankYouNote != null? data.ThankYouNote:'';
                } catch{}
            },error=>{

            }
        )
    }

    getCurrentCurrencyIcon(){
        var strClass:any="";
        if( this.objCartDetails.CurrencyIcon ){
            strClass = this.objCartDetails.CurrencyIcon;
        }
        return strClass;
    } 

    getCustomerName(){
        var customerName:string;
        var firstName:string="";
        var lastName:string="";
        if( this.objCartDetails.customerDetails != undefined && this.objCartDetails.customerDetails != null ){
            firstName = (this.objCartDetails.customerDetails.FirstName != undefined && this.objCartDetails.customerDetails.FirstName !=null)?this.objCartDetails.customerDetails.FirstName:'';
            lastName = (this.objCartDetails.customerDetails.LastName != undefined && this.objCartDetails.customerDetails.LastName !=null)?this.objCartDetails.customerDetails.LastName:'';
        }
        customerName = firstName+" "+lastName;
        return customerName
    }

    get currencyIcon(){
        var strClass:any="";
        if( this.objCartDetails.CurrencyIcon ){
            strClass = this.objCartDetails.CurrencyIcon;
        }
        return strClass;
    }

    get getCartDetails(){
        return this.objCartDetails;
    }
}
