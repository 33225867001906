export class  GTMSalesDataConversionModel{
	public transactionId:any;
	public transactionAffiliation:any;
	public transactionTotal:any;
	public transactionTax:any;
	public transactionShipping:any;
	public transactionProducts:Array<TransactionProducts> = new Array<TransactionProducts>();
}
export class TransactionProducts{
	public sku:any;
	public name:any;
	public category:any;
	public price:any;
	public quantity:any;
}