import { Component, OnInit,OnChanges,EventEmitter, Input, Output,ViewChild,ElementRef } from '@angular/core';
import * as url from '../../../environments/environment';
import { operationService } from '../../_services/operation.service';
@Component({
    selector: 'app-recaptcha',
    templateUrl: './recaptcha.component.html'
})
export class  RecaptchaComponent implements OnInit,OnChanges {
    @Output() eventSubmit = new EventEmitter<boolean>();
	constructor(private objOperationService:operationService){}
	public strsiteKey:any;
	ngOnInit(){
		this.strsiteKey = url.sitekey;
	}
	ngOnChanges(){

		this.strsiteKey = url.sitekey;
	}
	get getsitekey(){
		return this.strsiteKey;
	}
	confirmCaptcha($event){
        if( $event != null ){
        	this.verifyToken($event);
        } else {
            this.eventSubmit.emit(false);
        }
    }

	verifyToken(token){
		
		this.objOperationService.get(url.mainUrl+"SiteConfiguration/VarifySites?tokenString="+token).subscribe(
			data=>{
				this.eventSubmit.emit(data);
			},error=>{
				this.eventSubmit.emit(false);
			}
		)
	}
}