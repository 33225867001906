import { Component,OnInit,Input,Output,EventEmitter,OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { loginService } from '../../../_services/login.service';
import { operationService } from '../../../_services/operation.service';
import { Observable } from 'rxjs';
import { DataSharedService } from '../../../_services/dataSharedService';
// import * as url from '../../baseUrl';
import * as url from '../../../../environments/environment';
import { AddressModel,AddressId } from '../../../models/addressmodel';
import { CountryModel } from '../../../models/countrymodel';
import { TaxTypeEnum } from '../../../models/project.enum';
@Component({
  selector: 'list-address',
  templateUrl: './listaddress.html',
  providers: [loginService, operationService]
})

export class ListAddressComponente implements OnInit,OnChanges{
    @Input("arrobjSelectedAddressModel") arrobjSelectedAddressModel:Array<AddressModel> = new Array<AddressModel>();
    @Input("objResource") objResource:any={};
    @Input("cartId") cartId:any;
    @Input("cartType") cartType:any;
    @Input("taxType") taxType:any;
    @Input("customerId") customerId:any;
    @Input("required") required:boolean = true;
    @Input("boolSkipShippingAddress") boolSkipShippingAddress:boolean=false;
    @Output() boolEmitData = new EventEmitter<boolean>();
    @Output() emitAddressId = new EventEmitter<AddressId>();

    public arrobjCopySelectedAddressModel:Array<AddressModel> = new Array<AddressModel>();

    public isAddressDataChange:boolean=false;
    public boolShow:boolean=false;
    public boolIsInitiated:boolean=false;
    public TaxTypeEnum : typeof TaxTypeEnum = TaxTypeEnum;
    constructor(private objoperationService: operationService, private objloginService: loginService) {}

    ngOnInit(){
        this.boolCopyAddress = false;
        this.boolIsInitiated=true;
        this.getCountryList();
    }
    ngOnChanges(){
        if( this.boolIsInitiated ){
            this.boolCopyAddress = false;
            this.getCountryList();
        }
    }
    public boolIsUpdate:boolean=false;
    changeAddress_OLD(isAddressDataChange,boolAdd){
        this.isAddressDataChange = isAddressDataChange;
        this.boolShow = true;
        this.boolIsUpdate = boolAdd; 
        this.arrobjCopySelectedAddressModel = new Array<AddressModel>();

        this.arrobjSelectedAddressModel.forEach((value,index)=>{
            this.arrobjCopySelectedAddressModel.push(Object.assign(new AddressModel(),value));
        });
    }
    get getSelectedAddress(){
        return this.arrobjCopySelectedAddressModel;
    }
    refreshCartContent($event){
        if( $event ){
            this.boolShow = false;
            this.boolEmitData.emit(true);
        }
    }
    get getCartId(){

        return this.cartId;
    }
    get getResourceMapping(){
        return this.objResource;
    }
    get getCartType(){
        return this.cartType;
    }
    get isSkipShippingAddress(){
        return this.boolSkipShippingAddress;
    }
    closeModal(){
        
    }

    changeAddress(){
        this.boolSubmitAddress = false;
        this.currentIndex = 0;
        if( this.arrobjSelectedAddressModel.length > 0 ){
            this.arrobjCopySelectedAddressModel = new Array<AddressModel>();
            this.arrobjSelectedAddressModel.forEach((value,index)=>{
                this.arrobjCopySelectedAddressModel.push(Object.assign(new AddressModel(),value));
            });
        }
        this.generateAddressArray();
        $("#chooseaddresses").modal("show");
    }
    public objAddressModel:AddressModel = new AddressModel();
    public currentIndex:any;
    public arrobjCountryModel:Array<CountryModel> = new Array<CountryModel>();

    /*Get Country List*/
    getCountryList() {
        
        this.objoperationService.get(url.umsUrl + 'Admin/Master/GetAllCountries').subscribe(
            data => {
                this.arrobjCountryModel = data;
            },error => {
    
            }
        )
    }
    /*Subbmit address and address vallidation*/
    public boolSubmitAddress:boolean = false;
    submitAddress(){
        var validationStatus:any={};
        validationStatus = this.validateAddress();
        var isValidBillingAddress:any = validationStatus.validBillingAddress;
        var isValidShippingAddress:any = validationStatus.validshipingAddress;
        if( !isValidShippingAddress ){
            toastr.error("Please Enter shipping Address");
            return false;
        }
        
        if( !isValidBillingAddress ){
            toastr.error("Please Enter billing address")
            return false;
        }
        this.boolSubmitAddress=true;
        if( this.taxType == TaxTypeEnum.USTAX ){

            var validateAddress:AddressModel = new AddressModel();
            var arrShippingAddress = this.arrobjCopySelectedAddressModel.filter(item=>item.isDelivery == true);
            
            if( arrShippingAddress.length == 0 ){
                validateAddress = this.arrobjCopySelectedAddressModel.filter(item=>item.isInvoice == true)[0];
            } else {
                validateAddress = arrShippingAddress[0];
            }
            this.validateTaxAddress(validateAddress);
        } else {
            this.addAddressBulk(this.arrobjCopySelectedAddressModel);
        }
    }
    changeCountry(){
        var arrCountry = this.arrobjCountryModel.filter(item=>item.Id == this.objAddressModel.countryModel.Id);
        if( arrCountry.length > 0 ){
            this.objAddressModel.countryModel.Code = arrCountry[0].TwoLetterIsoCode;
        }
        
    }
    /*Add address*/

    public boolShowAddressError:boolean=false;
    public addressErrorMessage:any="";

    validateTaxAddress(obAddress:AddressModel){
        this.objloginService.post(url.mainUrl+"Order/ValidateCustomerAddress",obAddress).subscribe(
            data=>{
                if( data == true ){
                    this.addAddressBulk(this.arrobjCopySelectedAddressModel);
                }
            },error=>{
                this.boolSubmitAddress = false;
                var objError = JSON.parse(error._body);
                if(objError.Type == "InvalidRequest"){
                    this.boolShowAddressError = true;
                    this.addressErrorMessage = objError.Message;
                    this.setTimer();
                }
            }
        )
    }

    addAddressBulk(arrobAddress:Array<AddressModel>){

        this.boolShowAddressError = false;
        this.objloginService.post(url.umsUrl+"Admin/Customer/AddCustomerAddresses",arrobAddress).subscribe(
            data=>{
                
                this.arrobjCopySelectedAddressModel  = new Array<AddressModel>();
                this.arrobjCopySelectedAddressModel  = data;
                this.extractAddressId(this.arrobjCopySelectedAddressModel);
            },error=>{
                this.boolSubmitAddress = false;
                var objError = JSON.parse(error._body);
                if(objError.Type == "InvalidRequest"){
                    this.boolShowAddressError = true;
                    this.addressErrorMessage = objError.Message;
                    this.setTimer();
                }
            }
        )
    }

    /*Extract Address Id*/
    extractAddressId(arrobAddress:Array<AddressModel>){

        var objAddressId:AddressId = new AddressId();

        arrobAddress.forEach((value,index)=>{
            if( value.isDelivery ){
                objAddressId.shippingId = value.Id
            } 
            if( value.isInvoice ) {
                objAddressId.billingId = value.Id  
            } 
        })
        $("#chooseaddresses").modal("hide");
        this.boolSubmitAddress = false;
        this.emitAddressId.emit(objAddressId);
    }
    public boolCopyAddress:boolean;
    /*Copy Address*/
    changeCopy($event){

        if( this.boolCopyAddress ){

            var objBillingAddress = this.arrobjCopySelectedAddressModel.filter(item=>item.isInvoice==true)[0]; 
            this.arrobjCopySelectedAddressModel = this.arrobjCopySelectedAddressModel.map((value,index)=>{
                if( value.isDelivery ){
                    let addressId:any = value.Id; 
                    let action:any = value.Action; 
                    Object.assign(value,objBillingAddress);
                    value.isDelivery = true;
                    value.isInvoice = false;
                    value.Action = action;
                    value.Id = addressId;
                }
                return value;
            })
        }
        this.currentIndex = 1
        this.objAddressModel = this.arrobjCopySelectedAddressModel.filter(item=>item.isDelivery==true)[0];
    }
    
    /*Select Address*/    
    selectAddress(objAddress:AddressModel,index){
        this.currentIndex = index;
        this.objAddressModel = objAddress;
    }
    /*Set the timer to hide the error message.*/
    public objTimer:any;
    setTimer(){

        // set showloader to true to show loading div on view
        this.objTimer     = Observable.timer(5000); // 5000 millisecond means 5 seconds
        var subscription = this.objTimer.subscribe(() => {
            // this.boolShowAddressError = false;
        });
    }

    /*Validate Address*/
    validateAddress(){
        var isValidBillingAddress:boolean = true;
        var isValidShippingAddress:boolean = true;

        this.arrobjCopySelectedAddressModel.forEach((value,inde)=>{
            if( value.isInvoice ){
                if(value.CustomerName =="" ){
                    isValidBillingAddress = false;
                }
                if(value.PhoneNumber =="" ){
                    isValidBillingAddress = false;
                }
                if(value.Address1 =="" ){
                     isValidBillingAddress = false;   
                }
                if(value.City =="" ){
                     isValidBillingAddress = false;   
                }
                if(value.ZipPostalCode =="" ){
                     isValidBillingAddress = false;   
                }
                if(value.countryModel.Id == undefined || value.countryModel.Id == 0 ){
                     isValidBillingAddress = false;   
                }
            }
            if( value.isDelivery ){

                if(value.CustomerName =="" ){
                    isValidShippingAddress = false;
                }
                if(value.PhoneNumber =="" ){
                    isValidShippingAddress = false;
                }
                if(value.Address1 =="" ){
                    isValidShippingAddress = false;   
                }
                if(value.City =="" ){
                    isValidShippingAddress = false;   
                }
                if(value.ZipPostalCode =="" ){
                    isValidShippingAddress = false;   
                }
                if(value.countryModel.Id == undefined || value.countryModel.Id == 0 ){
                    isValidShippingAddress = false;   
                }
            }
        })

        return {"validBillingAddress":isValidBillingAddress,"validshipingAddress":isValidShippingAddress};
    }

    /*Generate Address Array*/
    generateAddressArray(){
        var objBillingAddress = new AddressModel();
        if(this.arrobjCopySelectedAddressModel.length == 0){
            this.arrobjCopySelectedAddressModel = new Array<AddressModel>();

            objBillingAddress.isInvoice = true;
            objBillingAddress.Action = "add";
            objBillingAddress.CustomerId = this.customerId;
            this.arrobjCopySelectedAddressModel.push(objBillingAddress);
            if( !this.boolSkipShippingAddress ){
                let objShippingAddress = new AddressModel();
                objShippingAddress.isDelivery = true;
                objShippingAddress.Action = "add";
                objShippingAddress.CustomerId = this.customerId;
                this.arrobjCopySelectedAddressModel.push(objShippingAddress);
            }
        } else {
            if( !this.boolSkipShippingAddress ){
                let deliveryAddress:any = this.arrobjCopySelectedAddressModel.filter(item=>item.isDelivery==true);
                if( deliveryAddress.length == 0 ){
                    let objShippingAddress = new AddressModel();
                    objShippingAddress.isDelivery = true;
                    objShippingAddress.Action = "add";
                    objShippingAddress.CustomerId = this.customerId;
                    this.arrobjCopySelectedAddressModel.push(objShippingAddress); 
                }
            }
            objBillingAddress = this.arrobjCopySelectedAddressModel.filter(item=>item.isInvoice==true)[0]
        }
        this.objAddressModel = objBillingAddress;
    }

    isDeliveryAddress(){
        let deliveryAddress = this.arrobjSelectedAddressModel.filter(res=> res.isDelivery == true);
        return deliveryAddress.length > 0 ? true: false
    }
}
