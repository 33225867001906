import { BrowserModule,Title } from '@angular/platform-browser';
import { FormControl, FormsModule,ReactiveFormsModule,NgControlStatus} from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateModule, TranslateLoader} from "@ngx-translate/core";
import { HttpModule,BrowserXhr } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { AppComponent } from './app.component';
import{ HeaderComponent } from '../app/modules/layout/header/header.component';
import{ FooterComponent } from '../app/modules/layout/footer/footer.component';
import { LoginComponent} from './modules/login/login';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { SafeHtmlPipe } from './_services/safeHtml';
import { DataSharedService } from './_services/dataSharedService';
import { DialogComponent } from './dialog/dialog.component';
import { LightboxModule } from 'angular2-lightbox';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EmbedVideo } from 'ngx-embed-video';
// Load Module Component
import { GeneralLandingComponent } from './modules/general-landing-page/generalLandingPage';
import { PhotomosaicLandingComponent } from './modules/photomosaic-landing-page/photomosaicLandingPage';
import { HomePageComponent } from './modules/home-page/homePage';
import {PhotoUploadComponent} from './modules/photo-upload/photoUpload';
import { AddressComponent} from './modules/address/select-address/address';
import { CheckoutComponent} from './modules/myCart/myCart';
import {PhotomosaicGalleryComponent } from './modules/photomosaic-gallery/photomosaicGallery';
// payment gateway
import {PaymentSuccessComponent } from './modules/payment-gateway/payment-success/paymentSuccess'; 
import { PaymentFailedComponent} from './modules/payment-gateway/payment-faild/paymentFailed'; 
import { PaymentCancelComponent} from './modules/payment-gateway/payment-cancel/paymentCancel';
import { OrderHistoryComponent } from './modules/order-history/orderHistory';
import { CropImageComponent } from './global/image-cropper/cropImage.component';
import { NumberPipe } from './_services/number.pipe';
import {CustExtBrowserXhr} from './_services/cust-ext-browser-xhr';
import { CustomLocationStrategy } from './_services/HashLocationStrategy';
import {HashLocationStrategy, LocationStrategy,PathLocationStrategy} from "@angular/common";
import { BlockCopyPasteDirective } from './_directive/block-copy-paste.directive';
import { MyProfileComponent } from './modules/my-profile/my-profile.component';
import { ComponentintractService } from './_services/conponentinteraction.service';
import { ContactUsCoponent } from './modules/contact-us/contactus.component';
import { ConfirmdeleteComponent } from './global/confirmdelete/confirmdelete.component';
import { OrderDetailComponent } from './modules/order-details/orderdetail.component';
import { GiftVoucherComponent } from './modules/gift-voucher/gift-voucher.component';
import { PaymentZeroAmountComponent } from './modules/payment-gateway/payment-zero-amount/paymentzeroamount.component';
import { ListAddressComponente } from './modules/address/list-address/listaddress.componente';
import { AddAddressComponent } from './modules/address/add-address/addaddress.component';
import { SafePipe } from './Pipes/safe.pipe';
import { RecaptchaDirective } from './_directive/recaptcha.directive';
import { StripHtmlTagsDirectives } from './_directive/striphtmltags.directives';
import {GoogleAnalyticsService} from './_services/google-analytics.service';
import { PaymentFormComponent } from './modules/payment-gateway/payment-form/paymentform.component';
import { MycurrencyPipe } from './Pipes/custom.currencypipe';
import { EncryptionService } from './_services/aes-encryption.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaComponent } from './global/recaptcha/recaptcha.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { TilesProductComponent } from './modules/tiles-product/tilesproduct.component';
import { TilesHeaderComponent } from './modules/layout/tiles-header/tilesheader.component';
import { TilesOrderDetailsComponent } from './modules/tiles-order-details/tilesorderdetails.component';
import { FindMyPhotoComponent } from './modules/find-myphoto/findmyphoto.component';
import { OrganizationLandingComponent } from './modules/org-landing-page/orglandingpage.component';
import { OrgHeaderComponent } from './modules/layout/org-header/orgheader.component';
import { NewsCursoleComponent } from './global/news-curole/newscursole.component';
import { WhatsappUrlPipe } from './Pipes/whatsapp-url.pipe';
import { PmRetroComponent } from './modules/pm-retro/pm-retro.component';
const configurationParam = {
    withCredentials: true
};
// Test
const appRouter: Routes=[
    { path: ':ProjectName', children: [
            { path: '', redirectTo:'home', pathMatch: 'full',canActivate: [AuthGuard]},
            { path: 'home', component :HomePageComponent,canActivate: [AuthGuard]},
            { path: 'tiles/:projectId', component :TilesProductComponent,canActivate: [AuthGuard]},
            { path: 'ResetPassword/:salt', component :HomePageComponent },
            { path: 'photo-upload/:ProductGuid', component :PhotoUploadComponent, pathMatch: 'full',canActivate: [AuthGuard]},
            { path: 'pm-retro', component :PmRetroComponent, pathMatch: 'full',canActivate: [AuthGuard]},
            { path: 'address', component :AddressComponent },
            { path: 'cart', component :CheckoutComponent },
            { path: 'photomosaic-gallery', component :PhotomosaicGalleryComponent },
            { path: 'success', component :PaymentSuccessComponent },
            { path: 'success/:reference/:cartGUID', component :PaymentZeroAmountComponent },
            { path: 'cancel', component :PaymentCancelComponent },
            { path: 'failed', component :PaymentFailedComponent },
            { path: 'findmyphoto', component :FindMyPhotoComponent,canActivate: [AuthGuard] },
            { path: 'order-history', component :OrderHistoryComponent,canActivate: [AuthGuard] },
            { path: 'voucher/:projectId', component :GiftVoucherComponent,canActivate: [AuthGuard] },
            { path:'my-profile', component:MyProfileComponent},
            { path:'org', component:OrganizationLandingComponent},
            { path: '**', redirectTo: '', pathMatch: 'full', canActivate: [AuthGuard] }
        ]
    }
]

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    AddressComponent,
    CheckoutComponent,
    HomePageComponent,
    OrgHeaderComponent,
    PhotoUploadComponent,
    NewsCursoleComponent,
    GeneralLandingComponent,
    PhotomosaicLandingComponent,
    PhotomosaicGalleryComponent,
    DialogComponent,
    SafeHtmlPipe,
    FindMyPhotoComponent,
    PaymentSuccessComponent,
    PaymentFailedComponent,
    PaymentCancelComponent,
    OrderHistoryComponent,
    CropImageComponent,
    NumberPipe,
    MycurrencyPipe,
    WhatsappUrlPipe,
    BlockCopyPasteDirective,
    RecaptchaDirective,
    MyProfileComponent,
    ContactUsCoponent,
    ConfirmdeleteComponent,
    OrderDetailComponent,
    GiftVoucherComponent,
    ListAddressComponente,
    AddAddressComponent,
    PaymentZeroAmountComponent,
    SafePipe,
    PaymentFormComponent,
    TilesHeaderComponent,
    RecaptchaComponent,
    StripHtmlTagsDirectives,
    TilesProductComponent,
    TilesOrderDetailsComponent,
    OrganizationLandingComponent,
    WhatsappUrlPipe,
    PmRetroComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    EmbedVideo.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
         deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule ,
    RouterModule.forRoot(appRouter, {useHash: true }),
    HttpModule,
    BrowserAnimationsModule,
    LightboxModule,
    InfiniteScrollModule,
    RecaptchaModule,
    DeviceDetectorModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    CKEditorModule
  ],
  exports: [
    RouterModule
  ],
  providers: [EncryptionService,HttpUrlEncodingCodec,GoogleAnalyticsService,AuthGuard, DataSharedService,ComponentintractService,Title,
                {provide: LocationStrategy, useClass: HashLocationStrategy}
              ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
